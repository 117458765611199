import { Check, Close, Edit } from 'assets/icons';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { themed } from 'utils';

const Container = styled.form`
  display: inline-flex;
  flex-wrap: nowrap;
  gap: ${themed('spacing.s')};

  span {
    padding: ${themed('input.padding')};

    &:focus {
      outline: ${themed('input.focusOutline')};
      background-color: ${themed('input.background')};
    }
  }
  button {
    margin: ${themed('input.padding')};
    margin-left: 0;
    margin-right: 0;
  }
`;
const Editable = styled.span<{ $isUnsaved?: boolean }>`
  opacity: ${({ $isUnsaved }) => ($isUnsaved ? 0.6 : 1)};
`;

type EditableTextProps = {
  className?: string;
  onChange: (value: string) => void;
  children: string;
  isLoading?: boolean;
  canEdit: boolean;
  hideButtons?: boolean;
};

export const EditableText = ({ className, onChange, children, isLoading, canEdit, hideButtons }: EditableTextProps) => {
  const [isFocussed, setIsFocussed] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const isUnsaved = !isFocussed && !!value && value !== children;

  const save = useCallback(() => {
    if (!value || value === children) return;

    onChange(value);
    setTimeout(() => inputRef.current?.blur(), 100);
  }, [value, children, onChange, inputRef.current]);

  const reset = useCallback(() => {
    setValue(children);
    inputRef.current?.blur();
  }, [inputRef.current, children, setValue]);

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === 'Space') {
        e.preventDefault();
      }

      if (e.key === 'Enter') {
        save();
      }
    },
    [save]
  );

  const onBlur = useCallback(() => {
    save();
    setIsFocussed(false);
    setIsEditable(false);
  }, [save]);

  return (
    <Container className={className}>
      <Editable
        $isUnsaved={isUnsaved}
        contentEditable={isEditable}
        ref={inputRef}
        onKeyUp={onKeyUp}
        onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
        onFocus={() => setIsFocussed(true)}
        onBlur={onBlur}
        onInput={e => {
          e.preventDefault();
          setValue(e.currentTarget.textContent);
        }}
        suppressContentEditableWarning
      >
        <span
          onClick={e => {
            if (canEdit) {
              e.stopPropagation();
              if (e.detail === 2) {
                setIsEditable(canEdit);
                setTimeout(() => inputRef.current?.focus(), 10);
              }
            }
          }}
        >
          {children}
        </span>
      </Editable>
      {!hideButtons && (
        <>
          {isLoading ? (
            <a>
              <LoadingSpinner />
            </a>
          ) : isFocussed ? (
            <>
              <a onClick={save}>
                <Check />
              </a>
              <a onClick={reset}>
                <Close />
              </a>
            </>
          ) : (
            <a onClick={() => inputRef.current?.focus()}>
              <Edit />
            </a>
          )}
        </>
      )}
    </Container>
  );
};
