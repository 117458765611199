import { Defect, Nullable } from '@yourxx/support';

export const notNull = <T>(x: Nullable<T>): T => {
  if (!x) throw new Defect('unexpected null found');
  return x;
};

export const firstOr = <T>(xs: ReadonlyArray<T> = [], fallback: T) => xs[0] ?? fallback;

export const lastOr = <T>(xs: ReadonlyArray<T> = [], fallback: T) => xs[xs.length - 1] ?? fallback;
