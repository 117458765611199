import { ShowAsyncErrorAndGoBack, SlotId, ViewTransition } from 'pages';
import { OrderDetails as IOrderDetails, useOrders, useSlot } from 'providers';
import { Suspense, useMemo } from 'react';
import { Await, Link } from 'react-router-dom';

import { OrderMenu } from '../components';
import { SetupDemoOrderToolbar } from '../CustomerOrder/demo-wiring-and-examples';
import { OrderDetailsSkeleton } from './OrderDetailsSkeleton';

export const OrderDetails = ({ orderId }: { orderId: string }) => {
  const { loadOrderDetails } = useOrders();
  return (
    <Suspense fallback={<ViewTransition children={<OrderDetailsSkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={loadOrderDetails(orderId)}
        children={data => <ViewTransition children={<View data={data} />} />}
        // children={_data => <ViewTransition children={<OrderDetailsSkeleton />} />}
      />
    </Suspense>
  );
};

const View = ({ data: { customerId, orderId, details } }: { data: IOrderDetails }) => {
  useSlot(
    SlotId.OrderMenu,
    useMemo(() => <OrderMenu />, [])
  );

  useSlot(
    SlotId.FooterLeft,
    useMemo(() => <>10 PC9s 6 In Progress and 4 Submitted</>, [])
  );

  return (
    <div>
      <SetupDemoOrderToolbar />
      [Order Details [{customerId}] [{orderId}]]
      <div>{details}</div>
      <ul>
        <li>
          <Link to="00501-0001">00501-0001</Link>
        </li>
        <li>
          <Link to="00505-0005">00505-0005</Link>
        </li>
        <li>
          <Link to="00712-0000">00712-0000</Link>
        </li>
      </ul>
    </div>
  );
};
