import { setTimeout } from 'timers/promises';

import { isValidToken, persistedStore } from '../utils';
import { refreshAuthToken } from './session';

export const session = () => {
  let inProgress = false;
  const waitForInProgress = async () => {
    while (inProgress) {
      await setTimeout(200);
    }
  };
  const isTokenExpired = () => {
    const expiry = persistedStore.getAccessTokenExpiry();
    const forceRefreshOnMissingUserProfileKey = persistedStore.getUserProfile()?.requestedAt;
    return Boolean(!forceRefreshOnMissingUserProfileKey || !expiry || (expiry && Date.now() > expiry - 30 * 1000));
  };

  const refreshToken = async () => {
    await waitForInProgress();
    if (isTokenExpired()) {
      inProgress = true;
      await refreshAuthToken();
      inProgress = false;
    }
  };
  const setAuthHeader = (request: Request) => {
    const token = persistedStore.getAccessToken();
    if (isValidToken(token)) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }
    return request;
  };

  return { isTokenExpired, refreshToken, setAuthHeader, waitForInProgress };
};

export const client = () => {
  const { isTokenExpired, refreshToken, setAuthHeader } = session();

  const request = async <D>(request: Request): Promise<D> => {
    if (isTokenExpired()) {
      await refreshToken();
    }
    request = setAuthHeader(request);
    const response: Response = await fetch(request.clone());
    return (await response.json()) as D;
  };

  return { request };
};

export const apiClient = client();
