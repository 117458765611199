import type { EntityContext } from 'domain-events';

import { DomainEvent } from '../EventBus';

interface BasePayload {
  context: EntityContext;
  pc9s: string[];
}

type Attributes = Record<string, any>;

interface ProductsAddedPayload extends BasePayload {
  sourceAssortmentId?: string;
  attributes?: Attributes;
}

interface ProductsUpdatedPayload extends BasePayload {
  attributes: Attributes;
}

export class ProductsAdded extends DomainEvent<ProductsAddedPayload> {}

export class ProductsUpdated extends DomainEvent<ProductsUpdatedPayload> {}

export class ProductsRemoved extends DomainEvent<BasePayload> {}

// TODO: FailedAddingProducts, FailedUpdatingProducts, FailedRemovingProducts
