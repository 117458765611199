import React from 'react';

import type { FeatureFlags } from '@yourxx/types';
import { canUseAll, canUseSome } from '@yourxx/ui-utils';

type FeatureFlagProperties = {
  children: React.ReactNode | React.ReactNode[];
  flags: ReadonlyArray<FeatureFlags>;
  flagCheck?: 'all' | 'some';
  missing?: boolean; // <FeatureFlag missing feature={xyz}></FeatureFlag>
};

export const FeatureFlag = ({ flags, flagCheck = 'all', missing, children }: FeatureFlagProperties) => {
  const canAccess = flagCheck === 'some' ? canUseSome(flags) : canUseAll(flags);
  const shouldShow = missing ? !canAccess : canAccess;
  return shouldShow ? <>{children}</> : null;
};
