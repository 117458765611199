import { parse, ParseStepResult } from 'papaparse';
import { type LocalisedStringFor, useLocalisation } from 'providers/LocalisationProvider';
import { useCallback, useState } from 'react';

import { StorySetBuilder } from '@yourxx/types';
import { uploadStorySets } from '@yourxx/ui-utils';

export type ErrorText = {
  message: string;
};

const REQUIRED_HEADERS = ['cluster', 'season', 'story'];
const PC9_HEADER = /^PC9.*$/i;
const MATCH_PC9 = /^[A-Z0-9]{5}-[A-Z0-9]{4}$/i;

type RequiredColumns = {
  cluster: string;
  season: string;
  story: string;
  pc9: string;
  gender?: string;
};

export type CsvRecord = RequiredColumns & Record<string, string>;

type GroupedCsvRecord = Omit<RequiredColumns, 'pc9'> & {
  pc9s: Array<string>;
};

type UploadResult = {
  errors: ErrorText[];
  result: Map<string, StorySetBuilder>;
};

export const useUploadFile = () => {
  const [str] = useLocalisation();
  const [errors, setErrors] = useState<ErrorText[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const translationRecords: UploadResult = { errors: [], result: new Map<string, StorySetBuilder>() };

  const onUpload = useCallback((file: any) => {
    setIsLoading(true);
    setErrors([]);
    translationRecords.errors = [];
    translationRecords.result = new Map<string, StorySetBuilder>();
    let line = 0;

    // Validate File name and type
    let fileNameParts: FileNameParts;
    try {
      fileNameParts = validateFileName(file.name, str);
    } catch (e) {
      setErrors([{ message: (e as Error).message }]);
      setIsLoading(false);
      return;
    }

    parse(file, {
      header: true,
      skipEmptyLines: 'greedy',
      transformHeader: header => {
        const lc = header.trim().toLowerCase();
        console.log('header', lc);
        return lc;
      },
      step: (results: ParseStepResult<CsvRecord>, parser) => {
        if (line === 0 && translationRecords.errors.length) {
          setErrors(translationRecords.errors);
          setIsLoading(false);
          return parser.abort();
        }

        line = line + 1;
        try {
          const r = validateCsvRecord(results.data, fileNameParts, str);
          const storySet =
            translationRecords.result.get(r.story) ??
            ({
              season: r.season,
              minCount: 0,
              storyName: r.story,
              onlyInRegions: [fileNameParts.region],
              selectedPC9s: new Set<string>()
            } as StorySetBuilder);
          r.pc9s.forEach(pc9 => storySet.selectedPC9s.add(pc9));
          translationRecords.result.set(r.story, storySet);
        } catch (e) {
          translationRecords.errors.push({
            message: str('Admin.SuggestedStory.fileEntryHasError', { line, error: (e as Error).message })
          });
        }
      },
      complete: async () => {
        console.log('complete');

        const { errors, result } = translationRecords;
        if (errors.length) {
          console.log('errors: ', errors);
          setErrors(errors);
          setIsLoading(false);
          return;
        }

        const storySets = [];
        for (const storySet of result.values()) {
          storySets.push({
            ...storySet,
            minCount: storySet.selectedPC9s.size,
            selectedPC9s: [...storySet.selectedPC9s]
          });
        }

        // Send Data to Api
        try {
          await uploadStorySets({ storySets });
          setIsLoading(false);
          setErrors([]);
        } catch (e: any) {
          setErrors([{ message: str('Admin.SuggestedStory.errorSavingData', { errorMessage: e.message }) }]);
          setIsLoading(false);
        }
      }
    });
  }, []);

  return {
    errors,
    isLoading,
    onUpload
  };
};

export const validateCsvRecord = (
  r: CsvRecord,
  filenameParts: FileNameParts,
  str: LocalisedStringFor
): GroupedCsvRecord => {
  const errors: string[] = [];

  const objectKeys = Object.keys(r);
  REQUIRED_HEADERS.forEach(requiredKey => {
    if (!objectKeys.includes(requiredKey)) {
      errors.push(str('Admin.SuggestedStory.missingRequiredColumn', { column: requiredKey }));
    }
  });

  const { story, season, cluster } = r;

  if (!errors.length) {
    if (!cluster) {
      errors.push(str('Admin.SuggestedStory.missingCluster'));
    } else if (filenameParts.region !== cluster) {
      errors.push(
        str('Admin.SuggestedStory.filenameClusterNotMatching', { cluster, filenameCluster: filenameParts.region })
      );
    }

    if (!season) {
      errors.push(str('Admin.SuggestedStory.missingSeason'));
    } else if (filenameParts.season !== season) {
      errors.push(
        str('Admin.SuggestedStory.filenameSeasonNotMatching', { season, filenameSeason: filenameParts.season })
      );
    }

    if (!story?.trim()) {
      errors.push(str('Admin.SuggestedStory.nameMissing'));
    }
  }

  const groupedRecord: GroupedCsvRecord = {
    cluster,
    season,
    story,
    pc9s: []
  };

  let hasPc9s: boolean = false;
  objectKeys.forEach(key => {
    if (!PC9_HEADER.test(key)) {
      return;
    }

    const value: string = r[key as keyof CsvRecord]?.trim().toUpperCase();
    if (MATCH_PC9.test(value)) {
      hasPc9s = true;
      groupedRecord.pc9s.push(value);
    } else if (value) {
      hasPc9s = true;
      errors.push(`${key} - ${str('Admin.SuggestedStory.notAValidPC9', { value })}`);
    }
  });

  if (!hasPc9s) {
    errors.push(str('Admin.SuggestedStory.missingPC9Column'));
  }

  if (errors.length) {
    throw new Error(errors.join('; '));
  }

  return groupedRecord;
};

const FILE_NAME_FORMAT =
  /^(?<prefix>StorySet)_(?<season>H[12]\d{2})_(?<region>\w{3})_(?<date>\d{4}-\d{2}-\d{2})\.(?<extension>csv|xlsx?)$/i;
export type FileNameParts = {
  prefix: string;
  season: string;
  region: string;
  date: string;
  extension: string;
};

export class InvalidStoryFilename extends RangeError {}

export const validateFileName = (name: string, str: LocalisedStringFor): FileNameParts => {
  const match = name.match(FILE_NAME_FORMAT);
  if (!match) {
    const errorMessage = [
      str('Admin.SuggestedStory.filenameNotValid', { filename: name }),
      str('Admin.SuggestedStory.filenameFormatHint', {
        season: str('general.season'),
        region: str('general.region')
      }),
      `e.g. StorySet_H124_NEU_2023-10-17.csv`
    ].join(' ');

    throw new InvalidStoryFilename(errorMessage);
  }

  return match.groups as FileNameParts;
};
