import { FeatureFlag, FileUpload, FlexWrapper, LoadingSpinner, UploadFileTypes } from 'components';
import { useLocalisation } from 'providers';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { FeatureFlags } from '@yourxx/types';

import { useUploadFile } from './hooks';

const Wrapper = styled.div`
  height: ${rem(320)};
  width: 100%;
  margin: ${rem(20, 0, 0, 0)};
  padding: ${rem(20)};
`;
const ResultContainer = styled.div`
  width: 100%;
  padding: ${rem(20)};
`;
// TODO: Replace with Toast
const ErrorContainer = styled.div`
  padding: ${rem(5)};
  border: ${rem(1)} solid crimson;
  border-radius: ${themed('spacing.m')};
  background-color: rgba(255, 0, 0, 0.4);
  color: ${themed('color.black')};

  h3 {
    margin: ${rem(16, 0, 8, 0)};
  }
`;
const SuccessContainer = styled.div`
  padding: ${rem(5)};
  border: ${rem(1)} solid rgba(76, 175, 80);
  border-radius: ${themed('spacing.m')};
  background-color: rgba(76, 175, 80, 0.3);
  color: ${themed('color.black')};
`;

export const SuggestedStorySets = () => {
  const [str] = useLocalisation();
  const { isLoading, onUpload, errors } = useUploadFile();
  const [initialise, setInitialise] = useState<boolean>(true);
  const [filename, setFilename] = useState<string>('');

  const handleFileUpload = useCallback((files: any[]) => {
    setInitialise(false);
    setFilename(files[0].name);
    onUpload(files[0]);
  }, []);

  return (
    <FeatureFlag flags={[FeatureFlags.AdminStorySets]}>
      <Wrapper>
        <FileUpload
          title={str('Admin.SuggestedStory.fileUploadHint')}
          label={str('Admin.SuggestedStory.uploadFileLabel')}
          fileAccept={[UploadFileTypes.CSV]}
          handleFiles={handleFileUpload}
          multipleFiles={false}
        />
        <ResultContainer>
          {isLoading && <LoadingSpinner label={str('Admin.SuggestedStory.processingUploadedFile')} />}
          {!isLoading && errors.length > 0 && (
            <ErrorContainer>
              <h3>{str('Admin.SuggestedStory.errorUploadingFile', { filename })}</h3>
              <ul>
                {errors.map(({ message }) => (
                  <li>{message}</li>
                ))}
              </ul>
            </ErrorContainer>
          )}
          {!initialise && !isLoading && errors.length === 0 && (
            <FlexWrapper>
              <SuccessContainer>{str('Admin.SuggestedStory.successfulUpload', { filename })}</SuccessContainer>
            </FlexWrapper>
          )}
        </ResultContainer>
      </Wrapper>
    </FeatureFlag>
  );
};
