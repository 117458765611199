export const getImageFormat = async () => {
  if (!window.createImageBitmap) return 'jpg';
  if (await testBlob('data:image/avif;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=')) return 'avif';
  if (await testBlob('data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=')) return 'webp';
  return 'jpg';
};

const testBlob = async (data: string): Promise<boolean> => {
  const blob: Blob = await fetch(data).then(r => r.blob());
  return window
    .createImageBitmap(blob)
    .then(() => true)
    .catch(() => false);
};
