import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const GenericInput = styled.input`
  font-size: ${themed('font.size.m')};
  padding: ${rem(10)} ${themed('spacing.m')};
  background-color: ${themed('color.white')};
  color: ${themed('color.black')};
  border-radius: ${themed('spacing.s')};
  border: solid ${rem(1)} transparent;
  appearance: none;
  outline: none;
  box-sizing: border-box;
  will-change: box-shadow, border;
  transition:
    box-shadow 0.2s ease-out,
    border 0.2s ease-out;

  @media (hover: hover) {
    &:hover {
      box-shadow: ${themed('boxShadow')};
    }
  }
  &:focus {
    border: solid ${rem(1)} ${themed('color.black')};
  }
  &:placeholder {
    color: ${themed('color.grey')};
  }
  &:disabled {
    pointer-events: none;
    color: ${themed('color.grey')};
  }
`;

export type InputProps = InputHTMLAttributes<HTMLInputElement>;

export const Input = GenericInput;
