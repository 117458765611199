import { format } from 'date-fns';
import type { LocalisedStringFor } from 'providers';

import type { CustomerAssortmentArray } from '@yourxx/types';

export const filterOptionToRenderedLabel = ({
  str,
  filterId,
  optionValue,
  finalAssortments
}: {
  str: LocalisedStringFor;
  filterId: string;
  optionValue: any;
  finalAssortments: ReadonlyArray<CustomerAssortmentArray> | undefined;
}) => {
  switch (filterId) {
    case 'notInFinalAssortment': {
      const match = finalAssortments?.find(item => item.assortmentId === optionValue);
      return match?.assortmentName ?? String(optionValue);
    }
    case 'ranking': {
      if (!optionValue) return str('Line.addProduct.rankingLabels.notSet');

      const number = parseInt(String(optionValue));
      if (number < 1 || number > 6) return String(number);
      return str(`Line.addProduct.rankingLabels.${String(number) as '1' | '2' | '3' | '4' | '5' | '6'}`);
    }
    case 'firstOnFloor': {
      return format(String(optionValue), 'MMM yyyy');
    }
    case 'mandatory': {
      return str(optionValue ? 'general.yes' : 'general.no');
    }
    case 'replen': {
      return str(optionValue ? 'general.yes' : 'general.no');
    }
    default:
      return String(optionValue);
  }
};
