import { Skeleton as Sk } from 'components';

export const CustomerOrdersIndexSkeleton = () => {
  return (
    <>
      <Sk.Row>
        <Sk.Col>
          <Sk.Spacer $size="xl" />
          <Sk.Table rows={20} />
        </Sk.Col>
      </Sk.Row>
    </>
  );
};
