import { Dialog } from 'components';
import { Entity, type EntityContext } from 'domain-events';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import { getPC9s, plpRemoveConfirmTag, pluralise } from 'utils';

import type { ProductListData, UIProduct } from '@yourxx/types';

import {
  ErrorIcon,
  ErrorMessage,
  ProductName,
  ProductRemovalReport,
  RemovedProductsList,
  StyledLoader
} from './styling';
import { State, useRemoveProduct } from './useRemoveProduct';

export const useRemoveProductUI = ({ context }: { context: EntityContext | undefined }) => {
  const { toastService, productsService } = useServices();
  const { customerId, assortmentId, lineId } = useParams();

  const state = useRemoveProduct<UIProduct<ProductListData>>({
    context,
    service: productsService,
    afterRemoved: removed => {
      toastService.send(
        <>
          {removed.length} {pluralise(removed.length, 'product has', 'products have')} been successfully removed from
          the {context?.entity === Entity.Line ? 'line' : 'assortment'}.
        </>
      );
    }
  });

  if (state.state === State.PendingConfirmation || state.state === State.Removing)
    return {
      ui: (
        <Dialog
          title="Confirmation required"
          onClose={() => {
            if (state.state === State.PendingConfirmation) state.cancel();
          }}
          confirm={{
            label:
              state.state === State.Removing ? (
                <StyledLoader />
              ) : (
                `Remove ${state.selectedProducts.length} ${pluralise(state.selectedProducts.length, 'product', 'products')}`
              ),
            disabled: state.state === State.Removing,
            handler: () => {
              plpRemoveConfirmTag({
                pc9s: getPC9s(state.selectedProducts),
                assortmentId,
                customerId: customerId ?? lineId
              });
              if (state.state === State.PendingConfirmation) state.confirm();
            }
          }}
          cancel={{
            label: 'Cancel',
            disabled: state.state === State.Removing,
            handler: () => {
              if (state.state === State.PendingConfirmation) state.cancel();
            }
          }}
          content={
            <ProductRemovalReport>
              <p>
                You are about to remove the following products from the{' '}
                {context?.entity === Entity.Line ? 'line' : 'assortment'}:
              </p>
              <RemovedProductsList $withErrors={false}>
                {state.selectedProducts.map(item => (
                  <li key={item.pc9}>
                    <ProductName>
                      <span>{item.name || <>&ndash;</>}</span>
                    </ProductName>
                    <div>{item.pc9}</div>
                  </li>
                ))}
              </RemovedProductsList>
            </ProductRemovalReport>
          }
        />
      ),
      state
    };

  if (state.state === State.ShowingErrorReport)
    return {
      ui: (
        <Dialog
          title="Errors while removing products"
          onClose={state.cancel}
          cancel={{ label: 'Close', handler: state.cancel }}
          content={
            <ProductRemovalReport>
              <p>Some products could not be successfully removed from the assortment:</p>
              <RemovedProductsList $withErrors={true}>
                {state.report.errors.map(entry => (
                  <li key={entry.product.pc9}>
                    <ProductName>
                      <span>{entry.product.name || <>&ndash;</>}</span>
                    </ProductName>
                    <div>{entry.product.pc9}</div>
                    <div>
                      <ErrorIcon /> {entry.reason}
                    </div>
                  </li>
                ))}
              </RemovedProductsList>

              <p>The following products have been removed from the assortment successfully:</p>
              <RemovedProductsList $withErrors={true}>
                {state.report.removed.map(product => (
                  <li key={product.pc9}>
                    <ProductName $isRemoved={true}>
                      <span>{product.name || <>&ndash;</>}</span>
                    </ProductName>
                    <div>{product.pc9}</div>
                    <div></div>
                  </li>
                ))}
              </RemovedProductsList>
            </ProductRemovalReport>
          }
        />
      ),
      state
    };

  if (state.state === State.ShowingError) {
    return {
      ui: (
        <Dialog
          title="Failed to remove products"
          onClose={state.cancel}
          cancel={{ label: 'Cancel', handler: state.cancel }}
          confirm={{ label: 'Retry', handler: state.retry }}
          content={
            <ErrorMessage>
              <ErrorIcon /> {state.error.message}
            </ErrorMessage>
          }
        />
      ),
      state
    };
  }

  return { ui: null, state };
};
