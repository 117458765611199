import { Stripe } from 'assets/icons';
import { Checkbox } from 'components/Checkbox';
import styled, { keyframes } from 'styled-components';
import { formatPercentage, rem, themed } from 'utils';

const FilterItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(6)};
  border-radius: ${themed('borderRadius')};
  padding: ${rem(12, 4)};
  background-color: ${themed('color.offWhite')};

  // Checkbox-wrapping label
  label div {
    width: ${themed('spacing.l')};
    height: ${themed('spacing.l')};
  }
`;
const ItemFilterContainer = styled.div`
  border-bottom: ${rem(1)} solid ${themed('color.greyMid')};
  background-color: ${themed('color.white')};
`;
const StyledDivider = styled.div`
  border-bottom: ${rem(1)} solid ${themed('color.greyLight')};
  margin: 0 ${themed('spacing.m')};
`;
const ItemFilterContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(6)};
  padding: ${rem(8, 6, 12, 10)};
`;
const ColorDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.s')};
  width: 100%;
`;
const ColourDetail = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.s')};
`;
const BarChart = styled.div<{ $widthPercentage: number; $itemColor: string }>`
  width: ${({ $widthPercentage }) => `${$widthPercentage}%`};
  min-width: ${themed('spacing.s')};
  background: ${({ $itemColor }) => ColourGroup[$itemColor as keyof typeof ColourGroup]};
  height: ${themed('spacing.s')};
  border-radius: ${themed('borderRadius')};
  will-change: width;
  animation-timing-function: linear;
  animation-duration: ${themed('transition.duration')};
  animation-name: ${keyframes`
    from { width: 0; }
  `};
`;
const ItemLabel = styled.span`
  ${themed('typography.h4')};
  text-transform: uppercase;
`;
const ItemCount = styled.span`
  color: ${themed('color.grey')};
  ${themed('typography.h5')};
  text-transform: uppercase;
`;
const ItemTotalPercentage = styled.span`
  margin-left: auto;
  margin-right: 0;
  color: ${themed('color.grey')};
  ${themed('typography.h5')};
  text-transform: uppercase;
`;

export const ColourGroup = {
  PINK: '#F0B2B3',
  BLUE: '#105DA4',
  GREY: '#B9C1C6',
  BROWN: '#6C3230',
  BLACK: '#242627',
  RED: '#FC7474',
  GREEN: '#D1DE9E',
  YELLOW: '#FDEC96',
  WHITE: '#FFFFFF',
  ORANGE: '#FAA950',
  PURPLE: '#5B4767',
  KHAKI: '#D5BE9F',
  STRIPE: `url(${Stripe})`
};

type Option = { value: unknown; label: string; count: number; percentOfTotal: number };

export const ColourFilter = ({
  className,
  value,
  options,
  onChange
}: {
  className?: string;
  value: Option | null;
  options: ReadonlyArray<Option>;
  onChange: (option: Option) => void;
}) => (
  <ItemFilterContainer className={className}>
    <StyledDivider />
    <ItemFilterContent>
      {options.map(option => (
        <FilterItem key={String(option.value)}>
          <Checkbox checked={value?.value === option.value} onChange={() => onChange(option)} />
          <ColorDetailContainer>
            <ColourDetail>
              <ItemLabel>{option.label}</ItemLabel>
              <ItemCount>({option.count} items)</ItemCount>
              <ItemTotalPercentage>{formatPercentage(option.percentOfTotal)} of total</ItemTotalPercentage>
            </ColourDetail>
            <BarChart $widthPercentage={option.percentOfTotal * 100} $itemColor={String(option.value)} />
          </ColorDetailContainer>
        </FilterItem>
      ))}
    </ItemFilterContent>
  </ItemFilterContainer>
);
