import styled from 'styled-components';
import { rem, themed } from 'utils';

const Root = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${themed('spacing.xxl')};
  padding-bottom: ${themed('spacing.xxl')};
`;
const Line = styled.div`
  flex: 1;
  width: 100%;
  height: ${rem(1)};
  background-color: ${themed('color.grey')};
`;
const Text = styled.div`
  padding-left: ${themed('spacing.m')};
  padding-right: ${themed('spacing.m')};
  background-color: ${themed('color.white')};
  text-transform: uppercase;
`;

export const Divider = ({ className, children }: { className?: string; children?: React.ReactNode }) => (
  <Root className={className}>
    <Line />
    <Text>{children || null}</Text>
    <Line />
  </Root>
);
