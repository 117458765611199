import { Tooltip } from 'components/Tooltip';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${rem(45)};
  box-sizing: border-box;

  & > ul {
    flex-shrink: 0;
    display: flex;
    gap: ${rem(12)};
    padding: 0 0 ${themed('spacing.l')};
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;
const TriggerContainer = styled.li<{
  $isDisabled?: boolean;
  $isSelected: boolean;
  $fullWidth: boolean;
  $borderWidth: number;
  $boldSelectedOnly?: boolean;
}>`
  position: relative;
  z-index: 1;
  background-color: transparent;
  display: flex;
  flex: ${({ $fullWidth }) => ($fullWidth ? 1 : 0)};
  align-items: center;
  height: 100%;
  min-width: ${({ $fullWidth }) => ($fullWidth ? 'unset' : rem(150))};
  justify-content: center;
  ${themed('typography.h3')};
  user-select: none;
  border: none;
  text-transform: uppercase;
  transition-property: background-color;
  transition-duration: ${themed('transition.duration')};
  transition-timing-function: ${themed('transition.timing')};
  color: ${({ $isDisabled }) => themed($isDisabled ? 'color.greyMid' : 'color.black')};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ $isDisabled }) => ($isDisabled ? 'inherit' : themed('color.greyLight'))};
    }
  }
  &:active {
    background-color: ${({ $isDisabled }) => ($isDisabled ? 'inherit' : themed('color.greyMid'))};
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${({ $borderWidth }) => rem($borderWidth)};
    background-color: ${({ $isSelected }) => ($isSelected ? themed('color.red') : themed('color.greyMid'))};
    will-change: background-color;
    transition-property: background-color;
    transition-duration: ${themed('transition.duration')};
    transition-timing-function: ${themed('transition.timing')};
  }
`;
const TabContent = styled.span<{ $isSelected: boolean; $boldSelectedOnly?: boolean }>`
  text-align: center;
  text-transform: uppercase;
  font-weight: ${({ $isSelected, $boldSelectedOnly }) =>
    !$boldSelectedOnly || ($boldSelectedOnly && $isSelected)
      ? themed('font.weight.bold')
      : themed('font.weight.regular')};
  padding: 0 ${rem(18)};
`;
const StyledTooltip = styled(Tooltip)`
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export type TabProps = {
  className?: string;
  value: string;
  isSelected?: boolean;
  onClick: VoidFunction;
  borderWidth?: number;
  isDisabled?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
  boldSelectedOnly?: boolean;
  tooltip?: string;
};

export const Tabs = ({ className, children }: { className?: string; children: React.ReactNode }) => (
  <Root className={className}>
    <ul>{children}</ul>
  </Root>
);

export const Tab = ({
  className,
  value,
  onClick,
  children,
  isDisabled,
  fullWidth = true,
  isSelected = false,
  borderWidth = 4,
  boldSelectedOnly,
  tooltip
}: TabProps) => (
  <TriggerContainer
    className={className}
    key={`container_${value}`}
    $isDisabled={isDisabled}
    $fullWidth={fullWidth}
    $isSelected={isSelected}
    onClick={() => !isDisabled && onClick()}
    $borderWidth={borderWidth}
    $boldSelectedOnly={boldSelectedOnly}
  >
    {tooltip ? (
      <StyledTooltip key={`trigger_${value}`} text={tooltip} position="bottom">
        <TabContent $isSelected={isSelected} $boldSelectedOnly={boldSelectedOnly}>
          {children}
        </TabContent>
      </StyledTooltip>
    ) : (
      <TabContent key={`trigger_${value}`} $isSelected={isSelected} $boldSelectedOnly={boldSelectedOnly}>
        {children}
      </TabContent>
    )}
  </TriggerContainer>
);
