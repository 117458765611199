import { FlexWrapper } from 'components/FlexWrapper';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useLocalisation } from 'providers';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { themed } from 'utils';

import { BoardsList } from './BoardsList';
import { CompareContent } from './CompareContent';
import { useCurrentCanvasContext } from './CurrentCanvasContext';
import { useGetBoards, useGetProducts } from './hooks';

const Content = styled.div<{ $withBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: ${themed('color.greyLight')};
  padding: ${themed('spacing.l')} ${themed('spacing.xl')} ${themed('spacing.xl')};
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  border-top-color: ${themed('color.greyLight')};
  border-top-width: ${themed('spacing.m')};
  border-top-style: ${({ $withBorder }) => ($withBorder ? 'solid' : 'none')};
`;

export const Board = () => {
  const { assortmentId, lineId, canvasBoardId } = useCurrentCanvasContext();
  const [isLoadingBoards, setIsLoadingBoards] = useState<boolean>(false);
  const [_isLoadingProductsList, setIsLoadingProductsList] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [str] = useLocalisation();

  const { getProducts } = useGetProducts();
  const { getBoards } = useGetBoards();

  useEffect(() => {
    getProducts({
      handleLoading: () => setIsLoadingProductsList(true),
      handleSuccess: () => setIsLoadingProductsList(false),
      handleError: () => {
        setErrorMessage(str('Landing.lines.retrievingError'));
        setIsLoadingProductsList(false);
      }
    });

    getBoards({
      handleLoading: () => setIsLoadingBoards(true),
      handleSuccess: () => setIsLoadingBoards(false),
      handleError: () => {
        setErrorMessage(str('Landing.lines.retrievingError'));
        setIsLoadingBoards(false);
      }
    });
  }, [lineId, assortmentId]);

  return (
    <Content $withBorder={!isLoadingBoards && !errorMessage && !canvasBoardId}>
      {isLoadingBoards && <LoadingSpinner label={str('Canvas.board.loading')} />}
      {errorMessage && (
        <FlexWrapper>
          <p>{errorMessage}</p>
        </FlexWrapper>
      )}
      {!isLoadingBoards && !errorMessage && !canvasBoardId && <BoardsList />}
      {!isLoadingBoards && !errorMessage && canvasBoardId && <CompareContent />}
    </Content>
  );
};
