import { Compare, Grid, List, Pause, Plus, Unit } from 'assets/icons';
import { Button } from 'components/Button';
import { SortDetails, SortPopOver } from 'components/SortPopOver';
import { Tab, Tabs } from 'components/Tabs';
import { Tooltip } from 'components/Tooltip';
import type { ExportOption } from 'pages/Products';
import { Download } from 'pages/Products/Download';
import { useLocalisation } from 'providers';
import { useEffect, useState } from 'react';
import { ProductListMode } from 'routes';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { FeatureFlags, type ProductListData, type UIProduct } from '@yourxx/types';
import { canUse, canUseSome } from '@yourxx/ui-utils';

import { FilterButton } from '../FilterButton';

const StyledTabs = styled(Tabs)`
  width: 100%;

  li {
    max-width: ${rem(200)};
  }
`;
const Toolbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${themed('spacing.m')};
  color: ${themed('color.black')};
  ${themed('typography.h4')};

  li:last-child > button {
    padding-right: 0;
  }
`;
const SelectableButtons = styled(Button)<{ $isSelected?: boolean; $largerIcon?: boolean }>`
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: ${rem(5)};
  background-color: ${({ $isSelected }) => ($isSelected ? themed('color.black') : 'transparent')};
  color: ${({ $isSelected }) => themed($isSelected ? 'color.white' : 'color.black')};
  padding: ${themed('spacing.m')};
  ${themed('typography.h4')};

  svg {
    width: ${({ $largerIcon }) => ($largerIcon ? rem(36) : themed('font.size.l'))};
    height: ${({ $largerIcon }) => ($largerIcon ? rem(30) : themed('font.size.l'))};
    padding-left: ${({ $largerIcon }) => ($largerIcon ? '0' : 'unset')};

    * {
      stroke: ${({ $isSelected }) => themed($isSelected ? 'color.white' : 'color.black')};
    }
  }
`;
const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 ${themed('spacing.xxxl')} ${themed('spacing.xl')} ${themed('spacing.xxxl')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
  }
`;

export enum TabType {
  ASSORTMENT,
  PARENT,
  PRODUCT,
  STORIES,
  SUMMARY
}
export enum ExportType {
  PDF = 'pdf',
  XLS = 'xls'
}

/**
 * @deprecated In favour of layout slots and new CommonLayout route component.
 */
export type ProductGridFilterProps = {
  className?: string;
  customersOrLines: string;
  productPageType: string | undefined;
  onStoriesSelect?: VoidFunction;
  onAssortmentSelect?: VoidFunction;
  onParentLineSelect?: VoidFunction;
  onFinalSelect?: VoidFunction;
  onSummarySelect?: VoidFunction;
  onRefresh?: VoidFunction;
  onViewTypeSelect?: (type: ProductListMode) => void;
  onAuditTrailSelect?: VoidFunction;
  isExporting?: boolean;
  exportOptions?: ReadonlyArray<ExportOption>;
  sortDetails?: {
    currentSort?: SortDetails<UIProduct<ProductListData>>;
    onSort: (sortDetails: SortDetails<UIProduct<ProductListData>>) => void;
  };
  onFilter?: VoidFunction;
  filtersCount?: number;
  isFilterSelected?: boolean;
  isAuditTrailSelected?: boolean;
  selectedView: ProductListMode;
  allowedViews?: ReadonlyArray<ProductListMode>;
  onAddProduct?: VoidFunction;
  isAddingProduct?: boolean;
  parentLineName?: string;
};

/**
 * @deprecated In favour of layout slots and new CommonLayout route component.
 */
export const ProductGridFilter = ({
  className,
  customersOrLines,
  productPageType,
  onStoriesSelect,
  onAssortmentSelect,
  onParentLineSelect,
  onFinalSelect,
  onSummarySelect,
  onRefresh,
  onViewTypeSelect,
  onAuditTrailSelect,
  isExporting,
  exportOptions,
  sortDetails,
  onFilter,
  filtersCount = 0,
  selectedView,
  allowedViews = [ProductListMode.Grid, ProductListMode.List, ProductListMode.Columns],
  isFilterSelected,
  isAuditTrailSelected,
  onAddProduct,
  isAddingProduct,
  parentLineName
}: ProductGridFilterProps) => {
  const [str] = useLocalisation();
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.ASSORTMENT);

  const canViewParentLineName = canUse(FeatureFlags.Assortment_ViewParentLineName);
  const canViewLines = canUseSome([FeatureFlags.Assortment_ViewCollection, FeatureFlags.LineCollection_View]);
  const canViewParentLine = canUse(FeatureFlags.Assortment_ViewParentCollection);
  const showParentLineTab = canViewLines && canViewParentLine;

  useEffect(() => {
    switch (productPageType) {
      case 'product':
      case 'products':
        setSelectedTab(TabType.ASSORTMENT);
        break;
      case 'parent':
        setSelectedTab(showParentLineTab ? TabType.PARENT : TabType.ASSORTMENT);
        break;
      case 'final':
        setSelectedTab(TabType.PRODUCT);
        break;
      case 'stories':
        setSelectedTab(TabType.STORIES);
        break;
      case 'summary':
        setSelectedTab(TabType.SUMMARY);
        break;
      default:
        break;
    }
  }, [productPageType]);

  return (
    <Container className={className}>
      <StyledTabs>
        {onAssortmentSelect && (
          <Tab
            value="assortment"
            borderWidth={1}
            boldSelectedOnly
            onClick={() => {
              setSelectedTab(TabType.ASSORTMENT);
              onAssortmentSelect();
            }}
            isSelected={selectedTab === TabType.ASSORTMENT}
          >
            {str(
              customersOrLines === 'customers' ? 'ProductListing.filters.assortment' : 'ProductListing.filters.line'
            )}
          </Tab>
        )}
        {showParentLineTab && onParentLineSelect && parentLineName && (
          <Tooltip text={parentLineName} noPadding disableTooltipOnly={!canViewParentLineName}>
            <Tab
              value="parentLine"
              borderWidth={1}
              boldSelectedOnly
              onClick={() => {
                setSelectedTab(showParentLineTab ? TabType.PARENT : TabType.ASSORTMENT);
                onParentLineSelect();
              }}
              isSelected={selectedTab === TabType.PARENT}
            >
              {str('ProductListing.filters.parentLine')}
            </Tab>
          </Tooltip>
        )}
        {onFinalSelect && (
          <Tab
            value="product"
            borderWidth={1}
            boldSelectedOnly
            onClick={() => {
              setSelectedTab(TabType.PRODUCT);
              onFinalSelect();
            }}
            isSelected={selectedTab === TabType.PRODUCT}
          >
            {str('ProductListing.filters.product')}
          </Tab>
        )}
        {onStoriesSelect && (
          <Tab
            value="stories"
            borderWidth={1}
            boldSelectedOnly
            onClick={() => {
              setSelectedTab(TabType.STORIES);
              onStoriesSelect();
            }}
            isSelected={selectedTab === TabType.STORIES}
          >
            {str('ProductListing.filters.stories')}
          </Tab>
        )}
        {onSummarySelect && (
          <Tab
            value="summary"
            borderWidth={1}
            boldSelectedOnly
            onClick={() => {
              setSelectedTab(TabType.SUMMARY);
              onSummarySelect();
            }}
            isSelected={selectedTab === TabType.SUMMARY}
          >
            {str('ProductListing.filters.summary')}
          </Tab>
        )}
      </StyledTabs>
      <Toolbar>
        {onRefresh && (
          <SelectableButtons onClick={onRefresh} $largerIcon>
            <Compare />
          </SelectableButtons>
        )}
        {allowedViews.map(view => (
          <Tooltip
            key={view}
            text={str(
              {
                grid: 'ProductListing.viewMode.grid' as const,
                list: 'ProductListing.viewMode.list' as const,
                columns: 'ProductListing.viewMode.byCategory' as const
              }[view]
            )}
            noPadding
          >
            <SelectableButtons $isSelected={selectedView === view} onClick={() => onViewTypeSelect?.(view)}>
              {{ grid: <Grid />, list: <List />, columns: <Pause /> }[view]}
            </SelectableButtons>
          </Tooltip>
        ))}
        {onAuditTrailSelect && (
          <Tooltip text={str('AssortmentFinalization.auditTrail.title').toUpperCase()} noPadding>
            <SelectableButtons $isSelected={!!isAuditTrailSelected} onClick={onAuditTrailSelect}>
              <Unit />
            </SelectableButtons>
          </Tooltip>
        )}
        {exportOptions && (
          <Tooltip text={str('ProductListing.download.label').toUpperCase()} noPadding>
            <Download isExporting={isExporting} options={exportOptions} />
          </Tooltip>
        )}
        {onAddProduct && productPageType !== 'parent' && (
          <Tooltip text={str('Canvas.actions.addProducts').toUpperCase()} noPadding>
            <SelectableButtons onClick={onAddProduct} $isSelected={Boolean(isAddingProduct)}>
              <Plus />
            </SelectableButtons>
          </Tooltip>
        )}
        {sortDetails && (
          <SortPopOver
            currentSort={sortDetails.currentSort}
            onSort={sortDetails.onSort}
            options={[
              { key: 'vlp', label: str('ProductListing.sort.by.vlp') },
              { key: 'name', label: str('ProductListing.sort.by.name') },
              { key: 'pc9', label: str('ProductListing.sort.by.pc9') },
              { key: 'newness', label: str('ProductListing.sort.by.newness') },
              { key: 'color', label: str('ProductListing.sort.by.color') },
              { key: 'gender', label: str('ProductListing.sort.by.gender') },
              { key: 'mfpCategory', label: str('ProductListing.sort.by.category') },
              { key: 'category2', label: str('ProductListing.sort.by.fit') },
              { key: 'ranking', label: str('ProductListing.sort.by.ranking') },
              { key: 'wsp', label: str('ProductListing.sort.by.wsp') },
              { key: 'rrp', label: str('ProductListing.sort.by.rrp') },
              { key: 'lastUpdatedAt', label: str('ProductListing.sort.by.lastModified') }
            ]}
          />
        )}
        {onFilter && (
          <Tooltip text={str('ProductListing.filters.title').toUpperCase()} noPadding>
            <FilterButton onClick={onFilter} isActive={!!isFilterSelected} counter={filtersCount} />
          </Tooltip>
        )}
        {/*<SelectableButtons $largerIcon>*/}
        {/*  <Menu />*/}
        {/*</SelectableButtons>*/}
      </Toolbar>
    </Container>
  );
};
