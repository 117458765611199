import {
  AssortmentInsightsCount,
  AssortmentInsightsResponse,
  ConfigParams,
  CustomerInsightsCount,
  CustomerInsightsResponse,
  InsightsProductDetails,
  StorySetsRequest,
  UpdateInsightsItemStatusResponse,
  UpdateInsightsResponse
} from '@yourxx/types';
import { FeatureFlags } from '@yourxx/types';

import { apiEndpoints, canUse, toRequest } from '../../utils';
import { apiClient } from '../client';

export enum InsightType {
  RECOMMENDATION = 'RECOMMENDATION',
  SUGGESTION = 'SUGGESTION'
}

export enum InsightStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  REMOVED = 'REMOVED'
}

export enum InsightItemStatus {
  SUGGESTED = 'SUGGESTED',
  ADDED_TO_ASSORTMENT = 'ADDED_TO_ASSORTMENT',
  REJECTED = 'REJECTED'
}

export const getCustomerInsights = async (customerId: string): Promise<CustomerInsightsResponse> => {
  const url = apiEndpoints.insightsGetForCustomer({ customerId });
  return insights<CustomerInsightsResponse>(url);
};
export const getCustomerInsightsCount = async (customerId: string): Promise<CustomerInsightsCount> => {
  const url = apiEndpoints.insightsCountForCustomer({ customerId });
  const data = await insights<CustomerInsightsCount>(url);
  return data;
};
export const getAssortmentInsights = async (assortmentId: string): Promise<AssortmentInsightsResponse> => {
  const url = apiEndpoints.insightsGetForAssortment({ assortmentId });
  return insights<AssortmentInsightsResponse>(url);
};

export const getAssortmentInsightsCount = async (assortmentId: string): Promise<AssortmentInsightsCount> => {
  const url = apiEndpoints.insightsCountForAssortment({ assortmentId });
  const data = await insights<AssortmentInsightsCount>(url);
  return data;
};

export const totalRecommendations = (data: CustomerInsightsCount | AssortmentInsightsCount): number => {
  const { recommendations, suggestions } = data;
  let total = 0;
  if (recommendations) {
    total += (recommendations.new || 0) + (recommendations.pending || 0);
  }
  if (suggestions) {
    total += (suggestions.new || 0) + (suggestions.pending || 0);
  }
  return total;
};

const insights = async <T>(url: string): Promise<T> => {
  const viewRecommendations = canUse(FeatureFlags.InsightsRecommendations);
  const viewSuggestions = canUse(FeatureFlags.InsightsSuggestions);

  if (!viewRecommendations && !viewSuggestions) {
    return {} as T;
  }

  let params: ConfigParams | undefined = undefined;
  if (viewRecommendations && viewSuggestions) {
    params = undefined;
  } else if (viewRecommendations) {
    params = { type: 'recommendations' };
  } else if (viewSuggestions) {
    params = { type: 'suggestions' };
  }

  return apiClient.request<T>(toRequest({ path: url, method: 'GET', ...(params ? { params } : {}) }));
};

export const removeInsight = async (assortmentId: string, timestamp: string): Promise<UpdateInsightsResponse> => {
  const url = apiEndpoints.insightsUpdate({ assortmentId, timestamp });
  const body = { recommendation: { status: InsightStatus.REMOVED } };
  const request = toRequest({ path: url, method: 'PATCH', body: body });
  return apiClient.request<UpdateInsightsResponse>(request);
};

export const addProducts = async (
  insightType: InsightType,
  assortmentId: string,
  timestamp: string,
  sourceId: string,
  productIds: string[],
  productsOrdinals: string[]
): Promise<UpdateInsightsItemStatusResponse> => {
  const url = apiEndpoints.insightsAddProducts({ assortmentId });
  const body = { sourceId, productIds };
  const request = toRequest({ path: url, method: 'POST', body: body });
  await apiClient.request<UpdateInsightsResponse>(request);
  return markProductsAsAdded(insightType, assortmentId, timestamp, productsOrdinals);
};

export const markProductsAsAdded = async (
  insightType: InsightType,
  assortmentId: string,
  timestamp: string,
  productsOrdinals: string[]
): Promise<UpdateInsightsItemStatusResponse> => {
  const url = apiEndpoints.insightsUpdate({ assortmentId, timestamp });
  const items = productsOrdinals.map(ordinal => ({ ordinal, status: InsightItemStatus.ADDED_TO_ASSORTMENT }));
  const body = { [insightType === InsightType.SUGGESTION ? 'suggestion' : 'recommendation']: { items } };
  const request = toRequest({ path: url, method: 'PATCH', body: body });
  return apiClient.request<UpdateInsightsItemStatusResponse>(request);
};

export const rejectProducts = async (
  assortmentId: string,
  timestamp: string,
  productsOrdinals: string[]
): Promise<UpdateInsightsItemStatusResponse> => {
  const url = apiEndpoints.insightsUpdate({ assortmentId, timestamp });
  const items = productsOrdinals.map(ordinal => ({ ordinal, status: InsightItemStatus.REJECTED }));
  const body = { recommendation: { items } };
  const request = toRequest({ path: url, method: 'PATCH', body: body });
  return apiClient.request<UpdateInsightsItemStatusResponse>(request);
};

export const viewInsight = async (
  assortmentId: string,
  timestamp: string,
  type: InsightType
): Promise<UpdateInsightsResponse> => {
  const url = apiEndpoints.insightsUpdate({ assortmentId, timestamp });
  const body = { [type.toLocaleLowerCase()]: { status: InsightStatus.PENDING } };
  const request = toRequest({ path: url, method: 'PATCH', body: body });
  return apiClient.request<UpdateInsightsResponse>(request);
};

export const uploadStorySets = async (body: StorySetsRequest): Promise<void> => {
  const request = toRequest({ path: apiEndpoints.insightsUploadStorySets, method: 'POST', body: body });
  await apiClient.request<InsightsProductDetails[]>(request);
};
