import { Entity } from 'domain-events';

import { Defect } from '@yourxx/support';
import type { generateExport } from '@yourxx/ui-utils';

import { type EventBus } from '../EventBus';
import { ExportCompleted, ExportFailed, ExportRequested } from './events';
import type { ExportsService } from './ExportsService';

interface ExportsServiceParams {
  eventBus: EventBus;
  generateExport: typeof generateExport;
}

export const createExportsService = ({ generateExport, eventBus }: ExportsServiceParams): ExportsService => ({
  async export(command) {
    const exportRequested = new ExportRequested({ command });
    eventBus.emit(exportRequested);

    if (command.context.entity === Entity.Order)
      throw new Defect('Attempting to export products for an order, which is not supported.');

    const exportType = {
      [Entity.Assortment]: 'assortment',
      [Entity.FinalAssortment]: 'assortment',
      [Entity.Line]: 'line'
    }[command.context.entity];

    const exportParams: Parameters<typeof generateExport>[0] = {
      id: command.context.id,
      selectedPc9s: command.pc9s,
      exportFormat: command.exportId === 'xls' ? 'excel' : command.exportId === 'csv' ? 'csv' : 'pdf',
      exportType,
      sort: 'vlpOrder:asc',
      status: command.status,
      type: [
        exportType,
        command.exportId.includes('vlp') ? 'ByVlpOrder' : command.exportId.includes('csv') ? 'ByProduct' : 'ByCategory',
        command.exportId.includes('landscape') ? 'Landscape' : ''
      ].join('')
    };

    if (command.isForParentLine) exportParams.notInAssortment = true;
    if (command.preAppliedFilters) exportParams.preAppliedFilters = command.preAppliedFilters;
    if (command.pricingGroupId && command.context.entity === Entity.Line)
      exportParams.pricingGroupId = command.pricingGroupId;

    return generateExport(exportParams).then(response => {
      if (response.resource) {
        eventBus.emit(new ExportCompleted({ command, resourceUrl: response.resource }).trace(exportRequested));
        return { ...response, resourceUrl: response.resource };
      }
      eventBus.emit(
        new ExportFailed({ command, reason: response.error ?? 'Failed to download export' }).trace(exportRequested)
      );
      throw new Error(response.error);
    });
  }
});
