import { css, styled } from 'styled-components';
import { themed } from 'utils';

export const Col = styled.div<{ $size?: number }>`
  display: flex;
  flex-direction: column;
  ${({ $size }) =>
    !$size
      ? css`
          flex-grow: 1;
        `
      : css`
          width: ${`calc(100% * ${$size} / 12)`};
        `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: ${themed('spacing.xxxl')};

  & + & {
    margin-top: ${themed('spacing.xxl')};
  }
`;
