import { FullscreenExit, Info, ZoomIn, ZoomOut } from 'assets/icons';
import { Modal } from 'components/Modal';
import { PopOver } from 'components/PopOver';
import { ColorCodeWrapper, ColourContent, ColourSpan, PricingSection } from 'components/ProductDetails';
import { Tooltip } from 'components/Tooltip';
import { useLocalisation } from 'providers';
import { useCallback, useState } from 'react';
import { FullScreen as ReactFullScreen, type FullScreenHandle } from 'react-full-screen';
import styled from 'styled-components';
import { formatPrice, rem, themed } from 'utils';

import { ProductDetailsData, UIProduct } from '@yourxx/types';

import { ImageSliderButton, SliderCarousel, SliderImage } from './ImageSlider';
import { VerticalImageSlider } from './VerticalImageSlider';

const LabelsWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: ${themed('spacing.m')};
  left: 0;
  display: flex;
  align-items: flex-end;

  p {
    width: ${rem(130)};
    margin: 0;
    padding-left: ${themed('spacing.l')};
    padding-bottom: ${themed('spacing.m')};
    text-transform: uppercase;
    font-weight: ${themed('font.weight.semiBold')};
    border-bottom: solid ${rem(2)} ${themed('color.grey')};
  }
`;
const CarryOverLabel = styled.div`
  font-weight: ${themed('font.weight.bold')};
  text-transform: uppercase;
  background-color: ${themed('color.red')};
  color: ${themed('color.white')};
  padding: ${themed('spacing.l')};
  margin-right: ${themed('spacing.m')};
`;
const ContentSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;

  > div:last-of-type {
    width: 100%;
    height: 100%;
  }
`;
const Thumbnails = styled.div`
  width: ${rem(60)};
  margin: 0 ${themed('spacing.l')};
  height: auto;

  svg {
    height: ${rem(20)};
    width: ${rem(20)};
  }
`;
export const ChevronWrapper = styled.div<{ $direction: 'left' | 'right' }>`
  position: absolute;
  background-color: ${themed('color.white')};
  box-shadow: ${themed('boxShadow')};
  width: ${rem(38)};
  height: ${rem(38)};
  top: 45%;
  left: ${({ $direction }) => ($direction === 'left' ? rem(10) : 'auto')};
  right: ${({ $direction }) => ($direction === 'right' ? rem(10) : 'auto')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: ${themed('spacing.l')};
    width: ${themed('spacing.l')};
  }
`;
const ButtonsWrapper = styled.div`
  position: absolute;
  z-index: 1;
  bottom: ${themed('spacing.l')};
  right: ${themed('spacing.l')};
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.s')};
`;
const InfoPopover = styled.div`
  > span {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.light')};
  }
  > h3 {
    margin-top: 0;
    margin-bottom: ${themed('spacing.m')};
    font-size: ${themed('font.size.l')};
  }
  > h4 {
    margin-top: ${themed('spacing.l')};
    margin-bottom: ${themed('spacing.xs')};
    font-size: ${themed('font.size.m')};
  }
`;
const StyledPricingSection = styled(PricingSection)`
  font-weight: ${themed('font.weight.regular')};

  div {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 0;
    gap: ${themed('spacing.m')};
  }
`;

export const Fullscreen = ({
  isActive,
  handle,
  images,
  activeSlide,
  setActiveSlide,
  onClose,
  product
}: {
  isActive: boolean;
  handle: FullScreenHandle;
  images: SliderImage[];
  activeSlide: number;
  setActiveSlide: (index: number) => void;
  onClose: VoidFunction;
  product?: UIProduct<ProductDetailsData>;
}) => {
  const [str] = useLocalisation();
  const [isZoom, setIsZoom] = useState<boolean>(false);
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => handle.exit().then(onClose), [onClose, handle]);

  return (
    <ReactFullScreen handle={handle}>
      {isActive && (
        <Modal onClose={handleClose} hideCloseButton fullscreenMode className="imageSliderFullscreen">
          <LabelsWrapper>
            {!!(product?.newness && ['co', 'c/o'].includes(product.newness.toLowerCase())) && (
              <CarryOverLabel>{str('general.carryOverLabel')}</CarryOverLabel>
            )}
            <p>{images[activeSlide].imageType}</p>
          </LabelsWrapper>
          <ContentSection>
            <Thumbnails>
              <VerticalImageSlider
                activeSlide={activeSlide}
                onSlide={index => setActiveSlide?.(index)}
                images={images}
                imageSize={100}
                disableScrollIntoView
              />
            </Thumbnails>
            <SliderCarousel
              images={images}
              activeSlide={activeSlide}
              setActiveSlide={setActiveSlide}
              isZoom={isZoom}
              disableDots={true}
            />
          </ContentSection>
          <ButtonsWrapper>
            {product && (
              <PopOver
                isOpen={infoOpen}
                onClose={() => setInfoOpen(false)}
                trigger={
                  <ImageSliderButton onClick={() => setInfoOpen(true)} $isSelected={infoOpen}>
                    <Info />
                  </ImageSliderButton>
                }
                showCloseButton={false}
                paddedBorder
                positions={['top']}
                boundaryInset={16}
              >
                <InfoPopover>
                  <span>{product.pc9}</span>
                  <h3>{product.name}</h3>
                  <StyledPricingSection>
                    <div>
                      <span>{str('PDP.stats.wholesale')}</span>
                      <span>{formatPrice(product.currency, product.wsp)}</span>
                    </div>
                    <div>
                      <span>{str('PDP.stats.retail')}</span>
                      <span>{formatPrice(product.currency, product.rrp)}</span>
                    </div>
                  </StyledPricingSection>
                  <h4>{str('PDP.stats.colours')}</h4>
                  <ColourContent>
                    {product.color && (
                      <div>
                        <span>{str('PDP.stats.displayedColour')}</span>
                        <ColorCodeWrapper>
                          <Tooltip text={product.color.toLowerCase()} position="left">
                            <ColourSpan $colour={product.color} />
                          </Tooltip>
                        </ColorCodeWrapper>
                      </div>
                    )}
                    <div>
                      <span>{str('PDP.stats.coloursInAssortment')}</span>
                      <ColorCodeWrapper>
                        {product?.inColors?.map(color => (
                          <Tooltip key={color} text={color.toLowerCase()} position="left">
                            <ColourSpan $colour={color} />
                          </Tooltip>
                        ))}
                      </ColorCodeWrapper>
                    </div>
                    <div>
                      <span>{str('PDP.stats.coloursNotInAssortment')}</span>
                      <ColorCodeWrapper>
                        {product?.outColors?.map(color => (
                          <Tooltip key={color} text={color.toLowerCase()} position="left">
                            <ColourSpan $colour={color} />
                          </Tooltip>
                        ))}
                      </ColorCodeWrapper>
                    </div>
                  </ColourContent>
                </InfoPopover>
              </PopOver>
            )}
            {!images[activeSlide].isVideo && (
              <ImageSliderButton onClick={() => setIsZoom(prev => !prev)} $isSelected={isZoom}>
                {isZoom ? <ZoomOut /> : <ZoomIn />}
              </ImageSliderButton>
            )}
            <ImageSliderButton onClick={handleClose}>
              <FullscreenExit />
            </ImageSliderButton>
          </ButtonsWrapper>
        </Modal>
      )}
    </ReactFullScreen>
  );
};
