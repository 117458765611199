import type { FiltersSchema } from '@yourxx/support';

import type { FilterOptionsEntry } from './buildFilterOptions';

export const createStatusFilterOptions = (
  schema: FiltersSchema,
  statuses: Record<string, number>
): FilterOptionsEntry<string> | undefined => {
  const filter = schema.find(({ id }) => id === 'status');
  if (!filter) return;

  const totalCount = Object.values(statuses).reduce((sum, c) => sum + c, 0);

  const options = Object.entries(statuses).map(([status, count]: [string, number]) => ({
    value: status,
    label: status,
    count,
    percentOfTotal: totalCount !== 0 ? count / totalCount : 0
  }));

  return { filter, options };
};
