import { getSeasonH } from '@yourxx/support';
import { ApiGetLineOut } from '@yourxx/types';
import { getLinesData } from '@yourxx/ui-utils';

import { useLanding } from './useLanding';

export const useGetLines = (brand: string) => {
  const { setLinesData } = useLanding();

  const getLines = async ({
    season,
    handleLoading,
    handleSuccess,
    handleError
  }: {
    season?: string | null;
    handleLoading: (loading: boolean) => void;
    handleSuccess: (data?: ApiGetLineOut[]) => void;
    handleError?: VoidFunction;
  }) => {
    handleLoading(true);
    getLinesData({ brand, season: season ? getSeasonH(season) : undefined })
      .then(data => {
        setLinesData(data);
        handleSuccess();
      })
      .catch(e => {
        console.error(e);
        handleLoading(false);
        handleError?.();
      });
  };

  return { getLines };
};
