import { deserialiseFilters, type SerialisedFilter } from './Filter';
import { FilterCombination, type FilterCombinationId } from './FilterCombination';
import type { FiltersOrder } from './FiltersService';

export interface Sink {
  items: FilterCombination[];
  filtersOrder: FiltersOrder;
}

interface SerialisedCombination {
  id: FilterCombinationId;
  name: string;
  filters: SerialisedFilter[];
}

export const createLocalStorageSink = (keyPrefix: string): Sink => {
  const combinationsKey = `${keyPrefix}/combinations`;
  const orderingKey = `${keyPrefix}/ordering`;
  let items: FilterCombination[] = [];
  let ordering: FiltersOrder = [];

  try {
    const serialisedItems: SerialisedCombination[] = JSON.parse(localStorage.getItem(combinationsKey) ?? '[]');
    items = deserialiseCombinations(serialisedItems);
    ordering = JSON.parse(localStorage.getItem(orderingKey) ?? '[]');
  } catch (ignorable) {
    console.warn(ignorable);
  }

  return {
    get items() {
      return items;
    },

    set items(newItems) {
      localStorage.setItem(combinationsKey, JSON.stringify(newItems));
      items = newItems;
    },

    get filtersOrder() {
      return ordering;
    },

    set filtersOrder(newOrdering) {
      localStorage.setItem(orderingKey, JSON.stringify(newOrdering));
      ordering = newOrdering;
    }
  };
};

const deserialiseCombinations = (serialised: ReadonlyArray<SerialisedCombination>) => {
  const items: FilterCombination[] = [];

  for (const item of serialised) items.push(FilterCombination(item.id, item.name, deserialiseFilters(item.filters)));

  return items;
};
