import { ContextfulSearchProvider } from 'components';
import { MotionConfig } from 'framer-motion';
import type { ReactNode } from 'react';
import { ServicesProvider, useServices } from 'services/ServicesProvider';
import { ThemeProvider } from 'styled-components';
import { DefaultTheme, GlobalStyle } from 'theme';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { CanvasProvider } from './CanvasProvider';
import { InsightsProvider } from './InsightsProvider';
import { LinesProvider } from './LinesProvider';
import { LocalisationProvider } from './LocalisationProvider';
import { AnalyticsSetup, initialiseAnalytics, trackEvents } from './SetupAnalytics';
import { SlotsProvider } from './SlotsProvider';

const transition = {
  duration: 0.2,
  ease: 'backInOut'
};

const queryClient = new QueryClient();

export const AppProviders = ({ children }: { children: ReactNode }) => (
  <ServicesProvider>
    <QueryClientProvider client={queryClient}>
      <AnalyticsSetup init={initialiseAnalytics} track={trackEvents} />
      <LocalisationProvider locale="en" throwOnErrors={!import.meta.env.PROD}>
        <InsightsProvider>
          <CanvasProvider>
            <LinesProvider>
              <ContextfulSearchProvider>
                <ThemeProvider theme={DefaultTheme}>
                  <GlobalStyle theme={DefaultTheme} />
                  <MotionConfig transition={transition}>
                    <SlotsProvider>{children}</SlotsProvider>
                  </MotionConfig>
                  <ToastNotificationsSetup />
                </ThemeProvider>
              </ContextfulSearchProvider>
            </LinesProvider>
          </CanvasProvider>
        </InsightsProvider>
      </LocalisationProvider>
    </QueryClientProvider>
  </ServicesProvider>
);

const ToastNotificationsSetup = () => {
  const { toastService } = useServices();
  return toastService.RenderableToasts;
};
