import { useMemo } from 'react';
import { FiltersService } from 'services';

import { Defect, FiltersSchema } from '@yourxx/support';

import { customersFiltersSchema } from './customersFiltersSchema';

export const createCustomersFiltersService = ({ schema }: { schema: FiltersSchema }): FiltersService => {
  const defaultOrder = schema.map(({ id }) => id);

  return {
    async defaultFiltersOrder() {
      return defaultOrder;
    },
    async handle(_command) {
      throw new Defect('Persistence of customers filters is not supported.');
    },
    async loadFilterCombinations() {
      return [];
    },
    async loadFiltersOrder() {
      return defaultOrder;
    }
  };
};

export const useCreateCustomersFiltersService = () => {
  return useMemo(() => createCustomersFiltersService({ schema: customersFiltersSchema }), []);
};
