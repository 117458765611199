import { css, keyframes } from 'styled-components';

const shineKeyframes = keyframes`
  0% {
    left: -100%;
  }
  100% {
    left: 100%
  }
`;

export const loadingSkeletonAnimation = css`
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0.7) 50%,
      rgba(255, 255, 255, 0) 90%
    );
    -webkit-text-fill-color: transparent;
    animation: ${shineKeyframes} 3s infinite;
  }
`;
