const EndpointMarker = Symbol('EndpointSymbol');

export type EndpointParams = Record<string, number | string | boolean>;

export interface Endpoint<P extends EndpointParams = EndpointParams> {
  readonly _kind: typeof EndpointMarker;
  readonly uri: string;
  readonly params: Partial<P>;
  with(params: Partial<EndpointParams> | ((currentParams: Partial<EndpointParams>) => Partial<EndpointParams>)): this;
  matches<P2 extends EndpointParams>(other: Endpoint<P2>): this is Endpoint<P2>;
  equals(other: Endpoint<P>): boolean;
  toString(): string;
}

/**
 * Helper for interpolating parameters in URIs, while protecting from misconfigurations,
 * such as passing in the wrong parameters.
 *
 * Usage:
 *   ```ts
 *   const ArticlesEndpoint = Endpoint('/api/v2/articles/:articleId');
 *   const endpoint = ArticlesEndpoint.with({ articleId: 'some-article-id' });
 *
 *   // Convert to string (you can also use `endpoint.toString()`).
 *   fetch(`${endpoint}`).then({ ... });
 *   ```
 *
 * The parameters pattern matcher can also be configured.
 *
 * Example:
 * ```ts
 *   const ArticlesEndpoint = Endpoint('/api/v2/articles/{articleId}', p => `{${p}}`);
 * ```
 */
export const Endpoint = <P extends EndpointParams, T extends string = string>(
  uri: T,
  paramPatternMatcher = (p: string) => `:${p}`,
  initialParams = {} as Partial<P>
): Endpoint<P> => {
  const trimmedUri = uri.trim();
  if (trimmedUri.length === 0) throw new RangeError('Endpoint URI cannot be empty');

  const interpolatedURI = (function interpolateURI() {
    let result = trimmedUri;

    for (const key in initialParams) {
      let hasConsumedParam = false;
      if (typeof initialParams[key] === 'undefined') continue;

      result = result.replace(paramPatternMatcher(key), () => {
        hasConsumedParam = true;
        return String(initialParams[key]);
      });

      if (!hasConsumedParam) throw new RangeError(`Unrecognised param ":${key}" passed into Endpoint "${uri}"`);
    }

    return result;
  })();

  return {
    _kind: EndpointMarker,
    uri,
    params: initialParams,

    with: newParams => {
      const updatedParams =
        typeof newParams === 'function' ? newParams(initialParams) : { ...initialParams, ...newParams };

      return Endpoint(uri, paramPatternMatcher, updatedParams as Partial<P>);
    },

    matches: other => uri === other.uri,
    equals: other => interpolatedURI === other.toString(),
    toString: () => interpolatedURI
  };
};
