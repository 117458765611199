import { persistedStore } from '../..';
import { getLogout } from './login';

export const logout = async (params: { logout_reason?: string }) => {
  persistedStore.removeAccessToken();
  persistedStore.removeAccessTokenExpiry();
  persistedStore.removeRefreshToken();
  persistedStore.removeUserProfile();
  persistedStore.removeImgFormat();
  window.localStorage.removeItem('debug');
  if (params?.logout_reason && params.logout_reason === 'userInitiated') {
    await getLogout();
    persistedStore.clear();
  } else {
    persistedStore.setLoginRedirect();
  }
  const url = new URL(window.location.href);
  url.pathname = '/login';
  if (params?.logout_reason) {
    url.searchParams.set('logout_reason', params.logout_reason);
  }
  window.location.href = url.toString();
};
