import 'react-alice-carousel/lib/alice-carousel.css';

import { ChevronLeft, ChevronRight } from 'assets/icons';
import { useEffect, useState } from 'react';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Container = styled.div`
  position: relative;
`;
const ChevronWrapper = styled.div<{ $direction: 'left' | 'right'; $disabled: boolean }>`
  position: absolute;
  background-color: ${themed('color.white')};
  box-shadow: ${themed('boxShadow')};
  width: ${rem(38)};
  height: ${rem(38)};
  top: 50%;
  right: ${({ $direction }) => ($direction === 'right' ? rem(-20) : 'auto')};
  left: ${({ $direction }) => ($direction === 'left' ? rem(-20) : 'auto')};
  opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: ${themed('spacing.l')};
    width: ${themed('spacing.l')};
  }
`;
const StyledCarouselItem = styled.div`
  max-width: ${rem(400)};
  margin: auto;
  position: relative;
`;

export const Carousel = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  const items = Array.isArray(children)
    ? children.map((child, index) => <StyledCarouselItem key={index}>{child}</StyledCarouselItem>)
    : [<StyledCarouselItem key="0">{children}</StyledCarouselItem>];

  const [hideArrows, setHideArrows] = useState<boolean>(true);
  const [visibleItems, setVisibleItems] = useState<number>(0);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);
  const [prevDisabled, setPrevDisabled] = useState<boolean>(false);

  const responsive = new Array(20).fill(0).reduce(
    (obj, _, i) => ({
      ...obj,
      [i * 550]: { items: i + 1, itemFit: 'contain' }
    }),
    {}
  );

  const onUpdated = (e: EventObject) => {
    setCurrentSlide(e.item);
    setVisibleItems(e.itemsInSlide);
    setNextDisabled(e.isNextSlideDisabled);
    setPrevDisabled(e.isPrevSlideDisabled);
  };
  const onSlideChanged = (e: EventObject) => {
    setCurrentSlide(e.item);
    setNextDisabled(e.isNextSlideDisabled);
    setPrevDisabled(e.isPrevSlideDisabled);
  };
  useEffect(() => {
    setHideArrows(items.length <= visibleItems);
  }, [items, visibleItems]);

  if (!items.length) {
    return null;
  }
  return (
    <Container className={className}>
      <AliceCarousel
        items={items}
        activeIndex={currentSlide}
        responsive={responsive}
        onUpdated={onUpdated}
        onResized={onUpdated}
        onInitialized={onUpdated}
        onSlideChanged={onSlideChanged}
        disableDotsControls
        renderNextButton={() => {
          if (hideArrows) return null;
          return (
            <ChevronWrapper $direction="right" $disabled={nextDisabled}>
              <ChevronRight />
            </ChevronWrapper>
          );
        }}
        renderPrevButton={() => {
          if (hideArrows) return null;
          return (
            <ChevronWrapper $direction="left" $disabled={prevDisabled}>
              <ChevronLeft />
            </ChevronWrapper>
          );
        }}
      />
    </Container>
  );
};
