import { ImageSlider } from 'components/ImageSlider';
import styled from 'styled-components';
import { canvasImageFullscreenTag, canvasImageZoomTag, themed } from 'utils';

import { CanvasProductDetails } from '@yourxx/types';

import { ProductInfo } from './CompareInfo';
import { useCurrentCanvasContext } from './CurrentCanvasContext';
import { useCanvas } from './hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
  padding: ${themed('spacing.m')};

  > div {
    aspect-ratio: 1;
  }
`;

export const CompareImage = ({ product, onRemove }: { product: CanvasProductDetails; onRemove?: VoidFunction }) => {
  const { isImageZoomed, setIsImageZoomed } = useCanvas();
  const { canvasBoardId } = useCurrentCanvasContext();

  return (
    <Container>
      <ImageSlider
        images={product.images?.map(i => ({ url: i.default, imageType: i.imageType! })) ?? []}
        onRemove={onRemove}
        onFullscreenChange={() =>
          canvasImageFullscreenTag({
            assortmentId: product.assortmentId,
            customerId: product.customerId,
            comparisonId: canvasBoardId,
            pc9: product.pc9Code
          })
        }
        zoomedIn={isImageZoomed}
        onZoomChange={val => {
          setIsImageZoomed(val);
          canvasImageZoomTag({
            assortmentId: product.assortmentId,
            customerId: product.customerId,
            comparisonId: canvasBoardId,
            pc9: product.pc9Code
          });
        }}
        noVideo
        minimapOffset={{ bottom: 40 }}
      />
      <ProductInfo product={product} />
    </Container>
  );
};
