import { type ContextOf, Entity } from 'domain-events';

import { DomainEvent } from '../EventBus';
import type { AddProductsToStoryCommand } from './StoriesService';

type BasePayload = {
  command: AddProductsToStoryCommand;
  context: ContextOf<Entity.Assortment | Entity.FinalAssortment | Entity.Line>;
};

export class AddProductsToStoryRequested extends DomainEvent<BasePayload> {
  constructor(payload: Omit<BasePayload, 'context'>) {
    super({ ...payload, context: payload.command.context });
  }
}
export class AddedProductsToStory extends DomainEvent<BasePayload> {
  constructor(payload: Omit<BasePayload, 'context'>) {
    super({ ...payload, context: payload.command.context });
  }
}

type FailedAddingProductsToStoryPayload = BasePayload & { reason: string };

export class FailedAddingProductsToStory extends DomainEvent<FailedAddingProductsToStoryPayload> {
  constructor(payload: Omit<FailedAddingProductsToStoryPayload, 'context'>) {
    super({ ...payload, context: payload.command.context });
  }
}
