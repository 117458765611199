import { useCustomersData } from 'providers';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { UpdateLinePayload } from '@yourxx/types';
import { updateLine as updateLineApi } from '@yourxx/ui-utils';

export const useUpdateLine = () => {
  const { lineId } = useParams();
  const { loadProductsFor, clearCache } = useCustomersData();

  const updateLine = useCallback(
    async ({
      handleLoading,
      handleSuccess,
      handleError,
      handleFinally,
      attributes
    }: {
      handleLoading?: (loading: boolean) => void;
      handleSuccess?: VoidFunction;
      handleError?: VoidFunction;
      handleFinally?: VoidFunction;
      attributes: UpdateLinePayload;
    }) => {
      if (lineId) {
        handleLoading?.(true);
        // TODO: Move to service, emit appropriate event to handle cache clearing and products data reload.
        updateLineApi({ id: lineId, data: attributes })
          .then(() => {
            clearCache({ id: lineId, tag: 'line' });
            // TODO: find a way to await loadProductsFor
            loadProductsFor(lineId, 'line');
          })
          .then(handleSuccess)
          .catch(e => {
            console.error(e);
            handleError?.();
          })
          .finally(handleFinally);
      }
    },
    [lineId, clearCache, loadProductsFor]
  );
  return { updateLine };
};
