import {
  CreateBoardShareRequest,
  CreateCanvasBoardShareResponse,
  GetBoardShareRequest,
  GetCanvasBoardShareResponse
} from '@yourxx/types';

import { apiEndpoints, toRequest } from '../../utils';
import { apiClient } from '../client';

export const createBoardShare = async (body: CreateBoardShareRequest): Promise<CreateCanvasBoardShareResponse> => {
  const request = toRequest({ path: apiEndpoints.canvasBoardShare, method: 'POST', body: body });
  return apiClient.request<CreateCanvasBoardShareResponse>(request);
};

export const getBoardShare = async (params: GetBoardShareRequest): Promise<GetCanvasBoardShareResponse> => {
  const request = toRequest({ path: apiEndpoints.canvasBoardShare, params, method: 'GET' });
  return apiClient.request<GetCanvasBoardShareResponse>(request);
};
