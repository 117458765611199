import { useMemo } from 'react';
import { asErrorMessage, unique } from 'utils';

import { addProductsToStory } from '@yourxx/ui-utils';

import type { EventBus } from '../EventBus';
import { AddedProductsToStory, AddProductsToStoryRequested, FailedAddingProductsToStory } from './events';
import type { StoriesService } from './StoriesService';

export interface CreateStoriesServiceParams {
  eventBus: EventBus;
  addToStory?: (params: any) => Promise<void>;
}

export const createStoriesService = ({
  eventBus,
  addToStory = addProductsToStory
}: CreateStoriesServiceParams): StoriesService => {
  return {
    addProducts(command) {
      const requestEvent = new AddProductsToStoryRequested({ command });
      eventBus.emit(requestEvent);

      return addToStory({
        assortmentOrLineId: command.context.id,
        storyId: command.story.id,
        // Current API implementation wants ALL product ids (existing + new) even if we're trying to add new ones
        productIds: unique([...(command.story.products ?? []), ...command.products.map(({ pId }) => pId)])
      })
        .then(() => {
          const event = new AddedProductsToStory({ command }).trace(requestEvent);
          eventBus.emit(event);
        })
        .catch(error => {
          const event = new FailedAddingProductsToStory({ command, reason: asErrorMessage(error) }).trace(requestEvent);
          eventBus.emit(event);
          throw error;
        });
    },

    filterStoriesForContext(stories, context) {
      return stories.filter(story => (context === 'assortment' ? story.type === 'Custom' : story.type !== 'Custom'));
    }
  };
};

export const useCreateStoriesService = (params: CreateStoriesServiceParams): StoriesService => {
  return useMemo(() => createStoriesService(params), [params]);
};
