import { useMemo } from 'react';
import { Manifest } from 'utils';

import { getAuditTrail } from '@yourxx/ui-utils';

import { AuditTrailEvent, type AuditTrailService, type Persistence, type SeenRecords } from './AuditTrailService';
import { persistence } from './persistence';

export interface Options {
  getAuditTrail: typeof getAuditTrail;
  persistence: Persistence;
}

const defaults: Options = {
  getAuditTrail,
  persistence
};

export const createAuditTrailService = ({
  getAuditTrail = defaults.getAuditTrail,
  persistence = defaults.persistence
}: Partial<Options> = defaults): AuditTrailService => {
  let manifest = Manifest();
  const cache: Record<string, Promise<AuditTrailEvent[]>> = {};

  const seenRecords: SeenRecords = persistence.load() ?? {};

  const service: AuditTrailService = {
    markAsSeen: (event: AuditTrailEvent) => {
      if (!(event.data.item_type in seenRecords)) seenRecords[event.data.item_type] = {};

      if (event.data.created_at <= seenRecords[event.data.item_type][event.data.item_id]) return;

      seenRecords[event.data.item_type][event.data.item_id] = event.data.created_at;

      persistence.save(seenRecords);
    },

    hasBeenSeen: (event: AuditTrailEvent) =>
      event.data.created_at <= seenRecords[event.data.item_type]?.[event.data.item_id],

    clearCache: assortmentId => {
      manifest = manifest.scheduleForReload('assortment', assortmentId);
    },

    isScheduledForReload: (assortmentId: string): boolean => manifest.isScheduledForReload('assortment', assortmentId),

    loadEvents: command => {
      if (!cache[command.assortmentId] || manifest.isScheduledForReload('assortment', command.assortmentId)) {
        manifest = manifest.remove('assortment', command.assortmentId);

        cache[command.assortmentId] = getAuditTrail(command).then(entries =>
          entries.map(entry => AuditTrailEvent.of(entry, service))
        );
      }

      return cache[command.assortmentId];
    }
  };

  return service;
};

export const useCreateAuditTrailService = (): AuditTrailService => useMemo(createAuditTrailService, []);
