import { Move, SpinnerAlt } from 'assets/icons';
import { Dialog } from 'components/Dialog';
import { AutoFocusInput, Label } from 'components/Input';
import { SlideInOut } from 'components/SlideInOut';
import { useLocalisation } from 'providers';
import type { FilterOptions } from 'services/ProductFiltersService/buildFilterOptions';
import { State } from 'services/ProductFiltersService/useFiltersState/types';
import type { UseProductFiltersReturn } from 'services/ProductFiltersService/useFiltersState/useFiltersState';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import type { AnyFilter, ProductFilterId } from '@yourxx/support';

import { FilterReorderView } from './components/FilterReorderView';
import { FilterView } from './components/FilterView';

const StyledSlideInOut = styled(SlideInOut)`
  height: 100%;
`;
const StyledLoader = styled(SpinnerAlt)`
  margin-left: ${themed('spacing.m')};
  width: ${rem(24)};
  height: ${rem(24)};
`;
const ReorderFilterButton = styled(Move)<{ $isClicked: boolean }>`
  align-self: center;
  background-color: ${({ $isClicked }) => ($isClicked ? themed('color.black') : 'none')};
  color: ${({ $isClicked }) => ($isClicked ? themed('color.white') : '')};
  border-radius: ${themed('spacing.s')};
  cursor: pointer;

  path {
    stroke: ${({ $isClicked }) => themed($isClicked ? 'color.white' : 'color.black')};
  }
`;

export type ProductFilterSlideOutProps = {
  className?: string;
  currency?: string;
  filterOptions: FilterOptions;
  state: UseProductFiltersReturn;
  onClose: VoidFunction;
  isOpen?: boolean;
  allowFilterCombinations?: boolean;
  allowReordering?: boolean;
  loadingFilters?: ReadonlyArray<ProductFilterId>;
  onFilterExpanded?: (filter: AnyFilter) => void;
};

export const FilterSlideOut = ({
  className,
  currency,
  state,
  filterOptions,
  onClose,
  isOpen = false,
  allowFilterCombinations = true,
  allowReordering = true,
  loadingFilters,
  onFilterExpanded
}: ProductFilterSlideOutProps) => {
  const [str] = useLocalisation();

  return (
    <>
      <StyledSlideInOut
        className={className}
        width={350}
        heading={
          <>
            {str('ProductListing.filters.title')}
            {(state.state === State.LoadingPersistedData || state.state === State.PersistingChanges) && (
              <StyledLoader />
            )}
          </>
        }
        isOpen={isOpen}
        // TODO: Add tag here. Probably not here though...
        onClose={onClose}
        headerControls={
          <>
            {allowReordering && (
              <ReorderFilterButton
                $isClicked={state.state === State.ReorderingFilters}
                onClick={() => {
                  if (state.state === State.Idle) state.reorder();
                  else if (state.state === State.ReorderingFilters) state.cancel();
                }}
              />
            )}
          </>
        }
      >
        {state.state === State.ReorderingFilters ? (
          <FilterReorderView
            order={state.filtersOrder.map(id => ({ id, label: id }))}
            onExit={state.cancel}
            canReset={!state.hasReset}
            onReset={state.reset}
            onUpdate={order => state.updateOrder(order.map(item => item.id))}
            onSave={state.confirm}
          />
        ) : (
          <FilterView
            currency={currency}
            filterOptions={filterOptions}
            allowFilterCombinations={allowFilterCombinations}
            filterCombinations={state.filterCombinations}
            selectedCombination={state.selectedCombination}
            activeCombination={state.activeCombination}
            changeActiveCombination={item => {
              if (state.state === State.Idle) state.changeActiveCombination(item);
            }}
            onSave={() => {
              if (state.state === State.Idle) state.save();
            }}
            onDelete={item => {
              if (state.state === State.Idle) state.delete(item);
            }}
            onUpdate={item => {
              if (state.state === State.Idle) state.rename(item);
            }}
            onFilterSet={filter => {
              if (state.state === State.Idle) state.set(filter);
            }}
            canClear={filterId => {
              return state.state === State.Idle ? state.canClear(filterId) : false;
            }}
            clear={filterId => {
              if (state.state === State.Idle) state.clear(filterId);
            }}
            canClearAll={state.state === State.Idle && state.canClearAll}
            onClearAll={() => {
              if (state.state === State.Idle) state.clearAll();
            }}
            loadingFilters={loadingFilters}
            onFilterExpanded={onFilterExpanded}
          />
        )}
      </StyledSlideInOut>
      {(state.state === State.NewFilterCombination || state.state === State.RenamingFilterCombination) && (
        <Dialog
          title={
            {
              [State.NewFilterCombination]: 'Save filter combination',
              [State.RenamingFilterCombination]: 'Rename filter combination'
            }[state.state]
          }
          cancel={{ label: 'Cancel', handler: state.cancel }}
          confirm={{ label: 'Save', handler: state.confirm, disabled: !state.canConfirm }}
          onClose={state.cancel}
          content={
            <Label text="Name">
              <AutoFocusInput placeholder="Filter Combination Name" value={state.name} onChange={state.inputName} />
            </Label>
          }
        />
      )}
      {state.state === State.PendingDeleteConfirmation && (
        <Dialog
          title="Delete filter combination"
          cancel={{ label: 'Cancel', handler: state.cancel }}
          confirm={{ label: 'Delete', handler: state.confirm }}
          onClose={state.cancel}
          content={<p>Are you sure, you want to delete &ldquo;{state.toBeDeleted.name}&rdquo;?</p>}
        />
      )}
    </>
  );
};
