import { AddProductSlideOut } from 'components';
import { Entity, type EntityContext, type SidePanelId, SidePanelOpened } from 'domain-events';
import { useCustomersData, useLocalisation } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import { plpAddProductConfirmTag, plpAddProductSearchTag, useEventSubscriptions } from 'utils';

import type { ProductListData, UIProduct } from '@yourxx/types';

import { filterEligibleAssortmentsAsAddSource } from '../filterEligibleAssortmentsAsAddSource';
import { useAddProduct } from './useAddProduct';
import type { UseSidePanelReturn } from './useSidePanel';

export const useAddProductUI = ({
  context,
  products,
  targetAssortmentSeason,
  canAddFromParentLine,
  parentLineId,
  sidePanel
}: {
  context: EntityContext | undefined;
  products: UIProduct<ProductListData>[];
  canAddFromParentLine: boolean;
  parentLineId?: string;
  targetAssortmentSeason?: string;
  sidePanel: UseSidePanelReturn<SidePanelId>;
}) => {
  const [str] = useLocalisation();
  const { eventBus, toastService, productsService } = useServices();
  const { isLoading, loadProductsFor, loadAssortmentsFor, productsFor, assortmentsFor } = useCustomersData();
  const { customerId, assortmentId, lineId } = useParams();

  const productsKeyedByPC9 = useMemo(
    () =>
      products.reduce<Record<string, UIProduct<ProductListData>>>((acc, product) => {
        acc[product.pc9] = product;
        return acc;
      }, {}),
    [products]
  );

  const mAssortmentsFor = useCallback(
    (customerId: string) => {
      const maybeAssortments = assortmentsFor(customerId);
      if (!maybeAssortments) return null;

      const season = targetAssortmentSeason?.toLowerCase();
      if (!season) return maybeAssortments;

      return {
        ...maybeAssortments,
        assortments: {
          [season]: filterEligibleAssortmentsAsAddSource(maybeAssortments.assortments?.[season] ?? [], parentLineId)
        }
      };
    },
    [assortmentsFor, parentLineId, targetAssortmentSeason]
  );

  const mProductsFor = useCallback(
    (assortmentOrLineId: string, maybeTag?: 'line' | 'parentLine') => {
      const { details, products } = productsFor(assortmentOrLineId, maybeTag ?? 'assortment') ?? {};
      if (!details?.parentId) return null;

      return {
        parentLineId: details.parentId,
        products: products?.filter(product => !(product.pc9 in productsKeyedByPC9)) ?? []
      };
    },
    [productsFor, productsKeyedByPC9]
  );

  const [shouldLoadData, setShouldLoadData] = useState(false);

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(SidePanelOpened, event => {
          if (event.payload.sidePanelId === 'addProduct') setShouldLoadData(true);
        })
      ],
      [eventBus]
    )
  );

  const mLoadAssortmentsFor = useCallback(() => {
    if (!shouldLoadData) return;
    if (context && 'customerId' in context) loadAssortmentsFor(context.customerId);
  }, [context, loadAssortmentsFor, shouldLoadData]);

  const mLoadProductsFor = useCallback(
    (assortmentId: string, maybeTag?: 'line' | 'parentLine') => {
      if (!shouldLoadData) return;
      loadProductsFor(assortmentId, maybeTag ?? 'assortment');
    },
    [loadProductsFor, shouldLoadData]
  );

  const onSearchFor = useCallback(
    (term: string) => {
      plpAddProductSearchTag({ term, assortmentId, customerId: customerId ?? lineId });
    },
    [assortmentId, customerId, lineId]
  );

  const addProductProps = useAddProduct({
    context,
    isLoading,
    parentLineId: canAddFromParentLine ? parentLineId : undefined,
    assortmentsFor: mAssortmentsFor,
    productsFor: mProductsFor,
    loadAssortmentsFor: mLoadAssortmentsFor,
    loadProductsFor: mLoadProductsFor,
    onSearchFor,
    onAdd: async ({ context, products }) => {
      return productsService
        .add({ context, items: products.map(p => ({ pc9: p.pc9 })) })
        .then(() => {
          plpAddProductConfirmTag({
            assortmentName: context.name,
            pc9s: products.map(p => p.pc9),
            assortmentId,
            customerId: customerId ?? lineId
          });
          toastService.send(
            str(
              context.entity === Entity.Line
                ? 'Assortment.addProduct.toast.successForLine'
                : 'Assortment.addProduct.toast.success',
              { count: products.length }
            )
          );
        })
        .catch(e => toastService.send(e instanceof Error ? e.message : `${e}`, 'error'));
    }
  });

  return (
    <AddProductSlideOut
      {...addProductProps}
      isOpen={sidePanel.id === 'addProduct'}
      onClose={() => sidePanel.set('addProduct')}
    />
  );
};
