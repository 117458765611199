import type { EntityContext, SidePanelId } from 'domain-events';
import { SidePanelClosed, SidePanelOpened } from 'domain-events';
import { useEffect } from 'react';
import { type EventBus } from 'services';
import { usePrevious } from 'utils';

export type UseTrackSidePanelProps = {
  eventBus: EventBus;
  sidePanelId?: SidePanelId;
  context: EntityContext | undefined;
};

export const useTrackSidePanel = ({ eventBus, sidePanelId, context }: UseTrackSidePanelProps): null => {
  const prevSidePanelId = usePrevious(sidePanelId);

  useEffect(() => {
    if (!context) return;

    if (!prevSidePanelId && sidePanelId) eventBus.emit(new SidePanelOpened({ sidePanelId, context }));
    if (prevSidePanelId && prevSidePanelId !== sidePanelId) {
      eventBus.emit(new SidePanelClosed({ sidePanelId: prevSidePanelId, context }));
      if (sidePanelId) eventBus.emit(new SidePanelOpened({ sidePanelId, context }));
    }
  }, [context, eventBus, prevSidePanelId, sidePanelId]);

  return null;
};
