import { Entity, FinalAssortmentViewed, ProductsFilterSet, SidePanelClosed, SidePanelOpened } from 'domain-events';
import { useCallback, useEffect } from 'react';
import { DomainEvent, ExportRequested, useServices } from 'services';
import { useEventSubscriptions } from 'utils';

import type { AnyFunction } from '@yourxx/support';

type AnalyticsEvent = { version: 2; event: string };

export const AnalyticsSetup = ({
  init,
  track
}: {
  init: AnyFunction;
  track: <E extends AnalyticsEvent>(payload: E) => void;
}) => {
  const { eventBus } = useServices();

  useEffect(() => {
    init();
  }, [init]);

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(ExportRequested, event => {
          if (
            event.payload.command.context.entity === Entity.Line ||
            event.payload.command.context.entity === Entity.Order
          )
            return;

          track({ version: 2, event: 'FA_download', assortment_id: event.payload.command.context.id });
        }),

        eventBus.on(FinalAssortmentViewed, event =>
          track({
            version: 2,
            event: 'FA_viewed',
            customer_id: event.payload.customerId,
            assortment_id: event.payload.id,
            fa_name: event.payload.name
          })
        ),

        eventBus.on(SidePanelOpened, event => {
          const tag = eventToTag(event);
          const payload = eventToPayload(event);
          if (!tag || !payload) return;

          track({ version: 2, event: tag, state: 'opened', ...payload });
        }),

        eventBus.on(SidePanelClosed, event => {
          const tag = eventToTag(event);
          const payload = eventToPayload(event);
          if (!tag || !payload) return;

          track({ version: 2, event: tag, state: 'closed', ...payload });
        }),

        eventBus.on(ProductsFilterSet, event => {
          if (event.payload.filter.value === null) return;

          const tag = eventToTag(event);
          const payload = eventToPayload(event);
          if (!tag || !payload) return;

          track({ version: 2, event: tag, filter_name: event.payload.filter.id, ...payload });
        })
      ],
      [eventBus, track]
    )
  );

  return null;
};

const toTagPrefix = (entity: Entity) =>
  ({
    [Entity.Line]: null,
    [Entity.Assortment]: null,
    [Entity.FinalAssortment]: 'FA',
    [Entity.Order]: 'OC'
  })[entity];

const eventToTag = (event: DomainEvent<unknown>) => {
  switch (true) {
    case event instanceof SidePanelOpened:
    case event instanceof SidePanelClosed: {
      const prefix = toTagPrefix(event.payload.context.entity);
      const suffix = { filters: 'filter', auditTrail: 'audit_trail', addProduct: 'add_PC9' }[event.payload.sidePanelId];

      if (!prefix || !suffix) return null;

      return `${prefix}_${suffix}`;
    }
    case event instanceof ProductsFilterSet: {
      const prefix = toTagPrefix(event.payload.context.entity);
      if (!prefix) return null;

      return `${prefix}_filter_select`;
    }
    default:
      return null;
  }
};

const eventToPayload = (event: DomainEvent<unknown>) => {
  switch (true) {
    case event instanceof SidePanelOpened:
    case event instanceof SidePanelClosed:
    case event instanceof ProductsFilterSet: {
      switch (event.payload.context.entity) {
        case Entity.FinalAssortment:
          return {
            customer_id: event.payload.context.customerId,
            assortment_id: event.payload.context.id,
            fa_name: event.payload.context.name
          };
        case Entity.Order:
          return {
            customer_id: event.payload.context.customerId,
            sheet_order_id: event.payload.context.id,
            order_sheet_name: event.payload.context.name,
            location_id: event.payload.context.locationId
          };
        default:
          return null;
      }
    }
    default:
      return null;
  }
};
