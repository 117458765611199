import { createContext, Dispatch, SetStateAction, useState } from 'react';

import { AssortmentInsightsCount, CustomerInsightsCount, InsightItem } from '@yourxx/types';

export type InsightsContextShape = {
  insights: InsightItem[];
  setInsights: Dispatch<SetStateAction<InsightItem[]>>;
  selectedInsights: InsightItem[];
  setSelectedInsights: Dispatch<SetStateAction<InsightItem[]>>;
  setInsightsCount: Dispatch<SetStateAction<CustomerInsightsCount | AssortmentInsightsCount | undefined>>;
  insightsCount: CustomerInsightsCount | AssortmentInsightsCount | undefined;
};

export const InsightsContext = createContext<InsightsContextShape>(undefined!);

export const InsightsProvider = ({ children }: { children?: React.ReactNode }) => {
  const [insights, setInsights] = useState<InsightItem[]>([]);
  const [selectedInsights, setSelectedInsights] = useState<InsightItem[]>([]);
  const [insightsCount, setInsightsCount] = useState<CustomerInsightsCount | AssortmentInsightsCount | undefined>();

  const value = {
    insights,
    setInsights,
    selectedInsights,
    setSelectedInsights,
    insightsCount,
    setInsightsCount
  };

  return <InsightsContext.Provider value={value}>{children}</InsightsContext.Provider>;
};
