import { Filter, FiltersSchema, FilterType } from './Filter';

export const filtersSchema: FiltersSchema = [
  Filter('gender', FilterType.Single),
  Filter('status', FilterType.Multi),
  Filter('newness', FilterType.Multi),
  Filter('seasonality', FilterType.Multi),
  Filter('distribution', FilterType.Multi),
  Filter('segmentation', FilterType.Multi),
  Filter('replen', FilterType.Single),
  Filter('story', FilterType.Multi),
  Filter('tops', FilterType.Multi),
  Filter('bottoms', FilterType.Multi),
  Filter('fit', FilterType.Multi),
  Filter('accessories', FilterType.Multi),
  Filter('footwear', FilterType.Multi),
  Filter('firstOnFloor', FilterType.Multi),
  Filter('fabric', FilterType.Multi),
  Filter('sustainability', FilterType.Multi),
  Filter('price', FilterType.PriceRange),
  Filter('pricePos', FilterType.Multi),
  Filter('ranking', FilterType.Multi),
  Filter('storeGrades', FilterType.Multi),
  Filter('mandatory', FilterType.Single),
  Filter('notInFinalAssortment', FilterType.Multi),
  Filter('color', FilterType.Single)
];
