import { Copy } from 'assets/icons';
import { type LocalisedStringFor } from 'providers';
import { useCallback } from 'react';
import styled from 'styled-components';
import { copyToClipboard, rem, themed, useEventSubscriptions } from 'utils';

import { EventBus } from '../EventBus';
import { ToastRequested } from '../ToastService';
import { ExportCompleted, ExportFailed } from './events';

export const useHandleExportEvents = ({ eventBus, str }: { eventBus: EventBus; str: LocalisedStringFor }) => {
  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(ExportCompleted, event => {
          const content = (
            <SuccessfulExportMessage
              str={str}
              exportLabel={event.payload.command.displayName}
              onCopyToClipboard={() => {
                copyToClipboard(event.payload.resourceUrl)
                  .then(() => {
                    const content = <>{str('ProductListing.download.copiedToClipboard')}</>;
                    eventBus.emit(new ToastRequested({ type: 'success', content }).trace(event));
                  })
                  .catch(() => {
                    const content = <>{str('ProductListing.download.failedCopyingToClipboard')}</>;
                    eventBus.emit(new ToastRequested({ type: 'error', content }).trace(event));
                  });
              }}
            />
          );

          eventBus.emit(new ToastRequested({ type: 'success', content }).trace(event));
        }),

        eventBus.on(ExportFailed, event => {
          const content = (
            <FailedExportMessage
              str={str}
              reason={event.payload.reason}
              exportLabel={event.payload.command.displayName}
              onRetry={event.payload.command.retry}
            />
          );

          eventBus.emit(new ToastRequested({ type: 'error', content }).trace(event));
        })
      ],
      [eventBus, str]
    )
  );

  return null;
};

const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${rem(5)};
  margin: ${themed('spacing.m')} 0 0;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: ${themed('color.white')};
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.semiBold')};
  text-transform: uppercase;

  svg path {
    stroke: ${themed('color.white')};
  }
`;

const SuccessfulExportMessage = ({
  exportLabel,
  onCopyToClipboard,
  str
}: {
  exportLabel: string;
  onCopyToClipboard: VoidFunction;
  str: LocalisedStringFor;
}) => {
  return (
    <>
      {str('ProductListing.download.successful', { name: exportLabel })}
      <StyledButton onClick={onCopyToClipboard}>
        <Copy /> {str('ProductListing.download.copyToClipboard')}
      </StyledButton>
    </>
  );
};

const FailedExportMessage = ({
  reason,
  exportLabel,
  onRetry,
  str
}: {
  reason: string;
  exportLabel: string;
  onRetry: VoidFunction;
  str: LocalisedStringFor;
}) => {
  return (
    <div title={reason}>
      {str('ProductListing.download.failed', { name: exportLabel })}
      <StyledButton onClick={onRetry}>{str('general.retry')}</StyledButton>
    </div>
  );
};
