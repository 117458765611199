import { tagsCallback } from '.';

export enum InsightsTags {
  INSIGHTSHEADER = 'insights_header',
  INSIGHTSLANDINGPAGE = 'insights_landing_page',
  INSIGHTSCLOSE = 'insights_close',
  INSIGHTSEXPAND = 'insights_expand',
  INSIGHTSRECOMMENDATION = 'insights_recommendation',
  INSIGHTSMOREACCOUNT = 'insights_more_accounts',
  INSIGHTSPC9CHECKBOXSELECT = 'insights_PC9_checkbox_select',
  INSIGHTSPC9CHECKBOXUNSELECT = 'insights_PC9_checkbox_unselect',
  INSIGHTSADDTOASSORTMENT = 'insights_add_to_assortment',
  INSIGHTSREMOVEPC9 = 'insights_remove_PC9',
  INSIGHTSADDED = 'insights_added',
  INSIGHTSRECOMMENDATIONSELECT = 'insights_recommendation_select',
  INSIGHTSRECOMMENDATIONUNSELECT = 'insights_recommendation_unselect',
  INSIGHTSREMOVEINSIGHT = 'insights_remove_insight'
}

export const insightsHeaderClickTag = ({
  customerId,
  assortmentId
}: {
  customerId?: string;
  assortmentId?: string;
}) => {
  tagsCallback(InsightsTags.INSIGHTSHEADER, {
    customer_id: customerId,
    assortment_id: assortmentId
  });
};

export const insightsPageViewTag = ({ customerId, assortmentId }: { customerId?: string; assortmentId?: string }) => {
  tagsCallback(InsightsTags.INSIGHTSLANDINGPAGE, {
    customer_id: customerId,
    assortment_id: assortmentId
  });
};

export const insightsCloseClickTag = ({ customerId, assortmentId }: { customerId?: string; assortmentId?: string }) => {
  tagsCallback(InsightsTags.INSIGHTSCLOSE, {
    customer_id: customerId,
    assortment_id: assortmentId
  });
};

export const insightsExpandClickTag = ({
  customerId,
  assortmentId,
  recommendationId
}: {
  customerId: string;
  assortmentId: string;
  recommendationId: string;
}) => {
  tagsCallback(InsightsTags.INSIGHTSEXPAND, {
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId
  });
};

export const insightsRecommendationViewTag = ({
  customerId,
  assortmentId,
  recommendationId
}: {
  customerId: string;
  assortmentId: string;
  recommendationId: string;
}) => {
  tagsCallback(InsightsTags.INSIGHTSRECOMMENDATION, {
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId
  });
};

export const insightsMoreAccountClickTag = ({
  customerId,
  assortmentId,
  recommendationId,
  pc9Code
}: {
  customerId: string;
  assortmentId: string;
  recommendationId: string;
  pc9Code: string;
}) => {
  tagsCallback(InsightsTags.INSIGHTSMOREACCOUNT, {
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId,
    pc9_code: pc9Code
  });
};

export const insightsPC9CheckboxClickTag = ({
  isChecked,
  pc9Code,
  customerId,
  assortmentId,
  recommendationId
}: {
  isChecked: boolean;
  pc9Code: string;
  customerId: string;
  assortmentId: string;
  recommendationId: string;
}) => {
  tagsCallback(isChecked ? InsightsTags.INSIGHTSPC9CHECKBOXUNSELECT : InsightsTags.INSIGHTSPC9CHECKBOXSELECT, {
    pc9_code: pc9Code,
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId
  });
};

export const insightsAddToAssortmentClickTag = ({
  customerId,
  assortmentId,
  recommendationId,
  pc9Code
}: {
  customerId: string;
  assortmentId: string;
  recommendationId: string;
  pc9Code: string;
}) => {
  tagsCallback(InsightsTags.INSIGHTSADDTOASSORTMENT, {
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId,
    pc9_code: pc9Code
  });
};

export const insightsRemovePC9ClickTag = ({
  customerId,
  assortmentId,
  recommendationId,
  pc9Code
}: {
  customerId: string;
  assortmentId: string;
  recommendationId: string;
  pc9Code: string;
}) => {
  tagsCallback(InsightsTags.INSIGHTSREMOVEPC9, {
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId,
    pc9_code: pc9Code
  });
};

export const insightsRecommendationCheckboxClickTag = ({
  isSelected,
  customerId,
  assortmentId,
  recommendationId
}: {
  isSelected: boolean;
  customerId: string;
  assortmentId: string;
  recommendationId: string;
}) => {
  tagsCallback(isSelected ? InsightsTags.INSIGHTSRECOMMENDATIONSELECT : InsightsTags.INSIGHTSRECOMMENDATIONUNSELECT, {
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId
  });
};

export const insightsRemoveClickTag = ({
  customerId,
  assortmentId,
  recommendationId
}: {
  customerId: string;
  assortmentId: string;
  recommendationId: string;
}) => {
  tagsCallback(InsightsTags.INSIGHTSREMOVEINSIGHT, {
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId
  });
};

export const insightsAddedClickTag = ({
  customerId,
  assortmentId,
  recommendationId
}: {
  customerId: string;
  assortmentId: string;
  recommendationId: string;
}) => {
  tagsCallback(InsightsTags.INSIGHTSADDED, {
    customer_id: customerId,
    assortment_id: assortmentId,
    recommendation_id: recommendationId
  });
};
