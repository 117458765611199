import type { AnyFilter } from '@yourxx/support';

import type { FilterCombination } from './FilterCombination';

/**
 * TODO:
 *   - Avoid mutability:
 *     - Return new FilterCombination instance if modified, else return the same instance
 */
export const setFilterInCombination = <T extends FilterCombination>(item: T, filter: AnyFilter): T => {
  if (filter.value === null) {
    const withoutThisFilter = item.filters.filter(oldFilter => oldFilter.id !== filter.id);
    return { ...item, filters: withoutThisFilter };
  }

  let matchFound = false;
  const temp = item.filters.map(existingFilter => {
    if (existingFilter.id === filter.id) {
      matchFound = true;
      return filter;
    } else {
      return existingFilter;
    }
  });

  return {
    ...item,
    filters: matchFound ? temp : [...item.filters, filter]
  };
};
