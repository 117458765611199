import { Checkbox, Dialog } from 'components';
import { Textarea } from 'components/Textarea';
import { useLocalisation } from 'providers';
import { useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { PageDetails } from '@yourxx/types';

const Label = styled.p`
  margin: 0;

  span {
    font-weight: ${themed('font.weight.semiBold')};
  }
`;
const FeedbackOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${themed('spacing.m')};
  margin: ${themed('spacing.m')} 0;

  > div {
    display: flex;
    align-items: center;
    gap: ${themed('spacing.m')};
    padding: ${themed('spacing.m')};
    background-color: ${themed('color.white')};
    border-radius: ${themed('spacing.s')};
    will-change: box-shadow, border;
    transition:
      box-shadow 0.2s ease-out,
      border 0.2s ease-out;
    cursor: pointer;

    label {
      pointer-events: none;
    }

    @media (hover: hover) {
      &:hover {
        box-shadow: ${themed('boxShadow')};
      }
    }
  }
`;
const StyledTextarea = styled(Textarea)`
  min-height: ${rem(200)};
  margin-top: ${themed('spacing.m')};
`;

export const RemoveAFModal = ({
  onClose,
  onRemove,
  selectedProductsCount,
  pageDetails
}: {
  onClose: VoidFunction;
  onRemove: (feedback: string) => void;
  selectedProductsCount: number;
  pageDetails?: PageDetails;
}) => {
  const [str] = useLocalisation();
  const [feedback, setFeedback] = useState<string>('');

  const feedbackOptions = ['Unavailability of desired size', 'Exceeding budget', 'Alternate Product selected'];

  return (
    <Dialog
      title={str('AssortmentFinalization.modal.remove.title')}
      onClose={onClose}
      content={
        <>
          <Label>
            {str('AssortmentFinalization.modal.remove.subtitle')} <span>{pageDetails?.name}</span>
          </Label>
          <FeedbackOptions>
            {feedbackOptions.map(f => (
              <div key={f} onClick={() => setFeedback(feedback === f ? '' : f)}>
                <Checkbox checked={feedback === f} />
                <span>{f}</span>
              </div>
            ))}
          </FeedbackOptions>
          <Label>{str('general.or').toLowerCase()}</Label>
          <StyledTextarea placeholder={str('general.label')} onChange={e => setFeedback(e.target.value)} />
        </>
      }
      confirm={{
        label: str(`AssortmentFinalization.modal.remove.button.removeProduct${selectedProductsCount === 1 ? '' : 's'}`),
        handler: () => onRemove(feedback)
      }}
    />
  );
};
