import { styled } from 'styled-components';
import { themed } from 'utils';

import { PrimaryButton } from './PrimaryButton';

export const GhostButton = styled(PrimaryButton)`
  background-color: transparent;
  color: ${themed('color.black')};

  @media (hover: hover) {
    &:hover:not(:disabled) {
      background-color: ${themed('color.greyLight')};
      color: ${themed('color.black')};
    }
  }

  svg path {
    stroke: currentColor;
  }
`;
