import React from 'react';
import { useIAM } from 'utils';

type IAMProperties = {
  children: React.ReactNode | React.ReactNode[];
  action: string;
  prefix?: string;
};

export const IAM = ({ children, action, prefix }: IAMProperties) => {
  const { canUse } = useIAM();

  return canUse(action, prefix) ? <>{children}</> : null;
};
