import { useCallback, useState } from 'react';
import { type AuditTrailEvent, AuditTrailService } from 'services';

import { Nullable } from '@yourxx/support';

interface UseAuditTrailProps {
  service: AuditTrailService;
}

interface UseAuditTrailReturn {
  isLoading: boolean;
  eventsFor(assortmentId: string): Nullable<ReadonlyArray<AuditTrailEvent>>;
  clearCache(assortmentId: string): void;
}

export const useAuditTrail = ({ service }: UseAuditTrailProps): UseAuditTrailReturn => {
  const [data, setData] = useState<Record<string, AuditTrailEvent[] | Promise<AuditTrailEvent[]>>>({});
  const isLoading = Object.values(data).some(value => value instanceof Promise);

  const eventsFor = useCallback(
    (assortmentId: string) => {
      if (!data[assortmentId] || service.isScheduledForReload(assortmentId)) {
        setData(prev => ({
          ...prev,
          [assortmentId]: service
            .loadEvents({ assortmentId })
            .then(events => {
              setData(prev => ({ ...prev, [assortmentId]: events }));
              return events;
            })
            .catch(() => {
              setData(prev => {
                const newData = { ...prev };
                delete newData[assortmentId];
                return newData;
              });

              return [];
            })
        }));

        return null;
      }

      const events = data[assortmentId];
      if (events instanceof Promise) return null;
      return events;
    },
    [data, service]
  );

  const clearCache = useCallback((assortmentId: string) => service.clearCache(assortmentId), [service]);

  return {
    isLoading,
    eventsFor,
    clearCache
  };
};
