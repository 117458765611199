import TagManager from 'react-gtm-module';

import { Defect, once } from '@yourxx/support';

export type Config = { gtmId: string; preview: string; auth: string };
export type Configs = Map<string, Config>;

const configs: Configs = new Map();

export const initialiseAnalytics = once(() => {
  const config = configForUrl(configs, window.location.hostname);
  if (config) TagManager.initialize(config);
  else console.warn(new Defect('Analytics could not be initialised due to missing configuration.'));
});

const warnForMissingAndFallbackTo = (config: Config | undefined, id: string) => {
  console.warn(new Defect(`Analytics configuration is missing: ${id}. Attempting to use fallback configuration.`));
  if (!config) console.warn(new Defect(`Fallback configuration is missing, too.`));
  return config;
};

export const configForUrl = (configs: Configs, url: string) => {
  if (!configs.size) throw new Defect('No environment configurations have been defined for analytics setup.');

  const dev = configs.get('dev');

  if (url.match(/qa/i)) return configs.get('qa') ?? warnForMissingAndFallbackTo(dev, 'qa');
  if (url.match(/preprod/i)) return configs.get('preprod') ?? warnForMissingAndFallbackTo(dev, 'preprod');
  if (url.match(/yourxx\.levistrauss\.com/i)) return configs.get('prod') ?? warnForMissingAndFallbackTo(dev, 'prod');

  return dev;
};

configs.set('dev', { gtmId: 'GTM-ND3ZQ64', preview: 'env-78', auth: 'SIK_xOulK6IIDPDjuo2hCA' });
configs.set('qa', { gtmId: 'GTM-ND3ZQ64', preview: 'env-79', auth: 'iphOUXlVS6PJ7ufMRZGb9A' });
configs.set('preprod', { gtmId: 'GTM-ND3ZQ64', preview: 'env-80', auth: 'xnZMHuOaPSQ7P__HfwNgQQ' });
configs.set('prod', { gtmId: 'GTM-ND3ZQ64', preview: 'env-1', auth: 'wLa2L8yxoEiTuoaTyVOkpw' });
