import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Dialog } from 'components/Dialog';
import { FeatureFlag } from 'components/FeatureFlag';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Select } from 'components/Select';
import { Textarea } from 'components/Textarea';
import { format } from 'date-fns';
import { Entity, type EntityContext } from 'domain-events';
import { usePhasingOptions, usePhasingRangeState } from 'pages/Products';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import styled from 'styled-components';
import {
  plpAttributeInheritTag,
  plpAttributeMandatoryTag,
  plpAttributeNewnessTag,
  plpAttributeNotesTag,
  plpAttributePhasingEndTag,
  plpAttributePhasingStartTag,
  plpAttributeRankingTag,
  plpAttributeSaveChangesTag,
  plpAttributeStoreGradesTag,
  plpAttributeVlpOrderTag,
  RANKING_OPTIONS,
  rem,
  themed
} from 'utils';

import { Defect } from '@yourxx/support';
import { FeatureFlags, ProductListData, StoreGrade, UIProduct } from '@yourxx/types';
import { canUse, canUseSome } from '@yourxx/ui-utils';

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
  box-sizing: border-box;
`;
const Row = styled.div`
  display: flex;
  gap: ${themed('spacing.l')};

  > div {
    flex: 1;
  }

  textarea {
    background-color: ${themed('color.yellowLight')};
  }
`;
const Label = styled.div`
  display: flex;
  gap: ${themed('spacing.l')};

  span {
    flex: 1;
    font-weight: ${themed('font.weight.medium')};
  }

  label {
    display: flex;
    gap: ${themed('spacing.m')};
  }
`;
const VLPInput = styled(Input)`
  width: 100%;
  height: ${rem(38)};
  margin: ${themed('spacing.m')} 0 0;
`;
const StoreGrades = styled.div`
  display: flex;
  gap: ${themed('spacing.m')};
  margin: ${themed('spacing.m')} 0;

  button {
    background-color: ${themed('color.white')};
    font-weight: ${themed('font.weight.medium')};
    padding: ${themed('spacing.m')};
    gap: ${themed('spacing.m')};

    label {
      pointer-events: none;
    }
  }
`;
const StoreGradesInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${themed('font.size.s')};
  margin: ${themed('spacing.m')} 0;
  color: ${themed('color.red')};
`;
const NoteInfo = styled.span`
  display: block;
  font-size: ${themed('font.size.s')};
  margin: ${themed('spacing.m')} 0;
`;

enum InheritableProductAttributes {
  firstOnFloor,
  lastOnFloor,
  newness,
  ranking,
  mandatory,
  vlp
}

export const ProductAttributesModal = ({
  context,
  products,
  storeGradesOptions,
  newnessOptions,
  showMandatory,
  addProduct,
  onClose,
  onSuccess
}: {
  context: EntityContext | undefined;
  products: UIProduct<ProductListData>[];
  storeGradesOptions?: StoreGrade[];
  newnessOptions?: string[];
  showMandatory?: boolean;
  addProduct?: boolean;
  onClose: VoidFunction;
  onSuccess: VoidFunction;
}) => {
  const [str] = useLocalisation();
  const { productsService, toastService } = useServices();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { customerId, assortmentId: customerAssortmentId, lineId } = useParams();
  const customerOrLineId = customerId ?? lineId;

  const [canEditPhasing, canEditRanking, canEditNote, canEditMandatory] = [
    FeatureFlags.Product_EditPhasing,
    FeatureFlags.Product_EditRanking,
    FeatureFlags.Product_EditNote,
    FeatureFlags.Product_EditMandatory
  ].map(canUse);
  const canEditVlpOrder = canUseSome(
    context?.entity === Entity.Line
      ? [FeatureFlags.EditLineVLPOrder, FeatureFlags.Product_EditVlpOrder_Line]
      : [FeatureFlags.EditAssortmentVLPOrder, FeatureFlags.Product_EditVlpOrder_Assortment]
  );

  const returnIfEqual = useCallback(
    (selector: (p: UIProduct<ProductListData>) => any) => {
      let value;
      const firstValue = selector(products[0]);
      if (Array.isArray(firstValue)) {
        let commonValueSet = new Set(firstValue);
        products.forEach(p => {
          const currentValue = new Set(selector(p));
          commonValueSet = new Set([...commonValueSet].filter(v => currentValue.has(v)));
        });
        value = [...commonValueSet];
      } else if (products.every(p => selector(p) === firstValue)) {
        value = firstValue;
      }
      return value;
    },
    [products]
  );

  const { phasingOptions } = usePhasingOptions({ products });
  const { phasingStart, phasingEnd, setStart, setEnd } = usePhasingRangeState(
    returnIfEqual(p => p?.firstOnFloor),
    returnIfEqual(p => p?.lastOnFloor)
  );

  const [inheritedAttributes, setInheritedAttributes] = useState<string[]>([]);
  const [attributes, setAttributes] = useState<{
    pc9Code?: string;
    phasingStart?: string;
    phasingEnd?: string;
    newness?: string;
    mandatory?: boolean;
    ranking?: number;
    vlp?: number;
    storeGrades?: string[];
    note?: string;
  }>();

  useEffect(() => {
    setAttributes({
      phasingStart,
      phasingEnd,
      newness: returnIfEqual(p => p?.newness),
      mandatory: returnIfEqual(p => p?.mandatory),
      ranking: returnIfEqual(p => p?.ranking),
      vlp: returnIfEqual(p => p?.vlp),
      storeGrades: returnIfEqual(p => p?.storeGrades),
      note: returnIfEqual(p => p?.note?.comment)
    });
  }, [setAttributes, products, phasingStart, phasingEnd]);

  useEffect(() => {
    if (!addProduct) {
      const inheritedOnlyKeys = new Set<string>();
      products.forEach(p => {
        Object.keys(InheritableProductAttributes).forEach(inheritedKey => {
          // @ts-expect-error: p will not accept string
          if (!p.inherited?.[inheritedKey]) {
            inheritedOnlyKeys.add(
              inheritedKey === 'firstOnFloor'
                ? 'phasingStart'
                : inheritedKey === 'lastOnFloor'
                  ? 'phasingEnd'
                  : inheritedKey
            );
          }
        });
      });
      setInheritedAttributes([...inheritedOnlyKeys]);
    }
  }, [products, setInheritedAttributes, addProduct]);

  const updatedAttributes = useMemo(
    () => ({
      firstOnFloor: inheritedAttributes.includes('phasingStart') ? null : attributes?.phasingStart,
      lastOnFloor: inheritedAttributes.includes('phasingEnd') ? null : attributes?.phasingEnd,
      newness: inheritedAttributes.includes('newness') ? null : attributes?.newness,
      mandatory: inheritedAttributes.includes('mandatory') ? null : attributes?.mandatory,
      ranking: inheritedAttributes.includes('ranking') ? null : attributes?.ranking,
      vlp: inheritedAttributes.includes('vlp') ? null : attributes?.vlp,
      storeGrade: inheritedAttributes.includes('storeGrades') ? null : attributes?.storeGrades,
      note: inheritedAttributes.includes('note') ? null : attributes?.note
    }),
    [attributes, inheritedAttributes]
  );

  const handleUpdate = useCallback(() => {
    if (!context) throw new Defect('Missing context when adding/updating product(s).');
    setIsLoading(true);

    productsService[addProduct ? 'add' : 'update']({
      context,
      items: products,
      attributes: updatedAttributes
    })
      .then(() => toastService.send(<span>{str('PDP.product.updateSuccessful', { count: products.length })}</span>))
      .catch(_ => {
        toastService.send(<span>{str('PDP.product.updateFailed', { count: products.length })}</span>, 'error');
      })
      .finally(() => {
        if (!addProduct) {
          plpAttributeSaveChangesTag({
            pc9s: products.map(p => p.pc9),
            assortmentId: customerAssortmentId,
            customerId: customerOrLineId,
            updatedAttributes: JSON.stringify(updatedAttributes)
          });
        }
        setIsLoading(false);
        onSuccess();
      });
  }, [
    addProduct,
    context,
    onSuccess,
    products,
    productsService,
    str,
    toastService,
    plpAttributeSaveChangesTag,
    updatedAttributes,
    customerAssortmentId,
    customerOrLineId
  ]);

  const handleInherit = useCallback(
    (attr: string) => {
      setInheritedAttributes(prev => {
        plpAttributeInheritTag({
          inheritedAttribute: attr,
          pc9s: products.map(p => p.pc9),
          assortmentId: customerAssortmentId,
          customerId: customerOrLineId,
          selected: !prev.includes(attr)
        });
        return prev.includes(attr) ? prev.filter(a => a !== attr) : [...prev, attr];
      });
    },
    [setInheritedAttributes, plpAttributeInheritTag, products, customerAssortmentId, customerOrLineId]
  );
  const handlePlaceholder = useCallback(
    (attr: string) =>
      str(
        // @ts-expect-error: localisation will not accept string
        inheritedAttributes.includes(attr)
          ? 'Line.editProduct.inherit'
          : products.length > 1
            ? 'Line.editProduct.selectPlaceholder'
            : `Line.addProduct.${attr}`
      ),
    [inheritedAttributes, str]
  );

  const handleStoreGrades = (name: string) => {
    setAttributes(prev => {
      const storeGrades = prev?.storeGrades?.includes(name)
        ? prev.storeGrades.filter(sg => sg !== name)
        : prev?.storeGrades
          ? [...prev.storeGrades, name]
          : [name];
      plpAttributeStoreGradesTag({
        storeGrades,
        pc9s: products.map(p => p.pc9),
        assortmentId: customerAssortmentId,
        customerId: customerOrLineId
      });
      return {
        ...prev,
        storeGrades
      };
    });
  };

  if (!attributes) return null;
  return (
    <Dialog
      title={str(
        addProduct
          ? context?.entity === Entity.Line
            ? 'Line.addProduct.modalTitle'
            : 'Assortment.addProduct.modalTitle'
          : 'Line.editProduct.modalTitle'
      )}
      subtitle={str(addProduct ? 'Line.addProduct.infoPanel' : 'Line.editProduct.infoPanel', {
        count: products.length
      })}
      onClose={onClose}
      content={
        <ModalBody>
          <FeatureFlag flags={[FeatureFlags.Product_ViewPhasing]}>
            <Row>
              <div>
                <Label>
                  <span>{str('Line.addProduct.phasingStart')}</span>
                  {canEditPhasing && !addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.includes('phasingStart')}
                      onChange={() => handleInherit('phasingStart')}
                      label={str('Line.editProduct.inherit')}
                    />
                  )}
                </Label>
                <Select
                  options={phasingOptions.map(option => ({
                    key: option.value,
                    label: option.label
                  }))}
                  placeholder={handlePlaceholder('phasingStart')}
                  isDisabled={!canEditPhasing || inheritedAttributes.includes('phasingStart')}
                  onChange={(option: any) => {
                    plpAttributePhasingStartTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setStart(option.key);
                  }}
                  value={phasingStart && { label: format(phasingStart, 'MMM yy') }}
                />
              </div>
              <div>
                <Label>
                  <span>{str('Line.addProduct.phasingEnd')}</span>
                  {canEditPhasing && !addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.includes('phasingEnd')}
                      onChange={() => handleInherit('phasingEnd')}
                      label={str('Line.editProduct.inherit')}
                    />
                  )}
                </Label>
                <Select
                  options={phasingOptions.map(option => ({
                    key: option.value,
                    label: option.label
                  }))}
                  placeholder={handlePlaceholder('phasingEnd')}
                  isDisabled={!canEditPhasing || inheritedAttributes.includes('phasingEnd')}
                  onChange={(option: any) => {
                    plpAttributePhasingEndTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setEnd(option.key);
                  }}
                  value={phasingEnd && { label: format(phasingEnd, 'MMM yy') }}
                />
              </div>
            </Row>
          </FeatureFlag>
          <Row>
            <div>
              <Label>
                <span>{str('Line.addProduct.newness')}</span>
                {!addProduct && (
                  <Checkbox
                    checked={inheritedAttributes.includes('newness')}
                    onChange={() => handleInherit('newness')}
                    label={str('Line.editProduct.inherit')}
                  />
                )}
              </Label>
              <Select
                options={newnessOptions?.map(option => ({
                  key: option,
                  label: option
                }))}
                placeholder={handlePlaceholder('newness')}
                isDisabled={inheritedAttributes.includes('newness')}
                onChange={(option: any) => {
                  plpAttributeNewnessTag({
                    value: option.label,
                    pc9s: products.map(p => p.pc9),
                    assortmentId: customerAssortmentId,
                    customerId: customerOrLineId
                  });
                  setAttributes(prev => ({ ...prev, newness: option.key }));
                }}
                value={attributes.newness && { label: attributes.newness }}
              />
            </div>
            {showMandatory && (
              <div>
                <Label>
                  <span>{str('Line.addProduct.mandatory')}</span>
                  {canEditMandatory && !addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.includes('mandatory')}
                      onChange={() => handleInherit('mandatory')}
                      label={str('Line.editProduct.inherit')}
                    />
                  )}
                </Label>
                <Select
                  options={[
                    { key: true, label: str('Line.addProduct.mandatory.true') },
                    { key: false, label: str('Line.addProduct.mandatory.false') }
                  ]}
                  placeholder={handlePlaceholder('mandatory')}
                  isDisabled={!canEditMandatory || inheritedAttributes.includes('mandatory')}
                  onChange={(option: any) => {
                    plpAttributeMandatoryTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setAttributes(prev => ({ ...prev, mandatory: option.key }));
                  }}
                  value={
                    attributes.mandatory !== undefined && {
                      label: str(
                        attributes.mandatory ? 'Line.addProduct.mandatory.true' : 'Line.addProduct.mandatory.false'
                      )
                    }
                  }
                />
              </div>
            )}
          </Row>
          <Row>
            <FeatureFlag flags={[FeatureFlags.Product_ViewRanking]}>
              <div>
                <Label>
                  <span>{str('Line.addProduct.ranking')}</span>
                  {canEditRanking && !addProduct && (
                    <Checkbox
                      checked={inheritedAttributes.includes('ranking')}
                      onChange={() => handleInherit('ranking')}
                      label={str('Line.editProduct.inherit')}
                    />
                  )}
                </Label>
                <Select
                  options={RANKING_OPTIONS.map(option => ({
                    key: option.value,
                    label: str(option.key)
                  }))}
                  placeholder={handlePlaceholder('ranking')}
                  isDisabled={!canEditRanking || inheritedAttributes.includes('ranking')}
                  onChange={(option: any) => {
                    plpAttributeRankingTag({
                      value: option.label,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setAttributes(prev => ({ ...prev, ranking: option.key }));
                  }}
                  value={
                    attributes.ranking && {
                      // @ts-expect-error: localisation will not accept string
                      label: str(RANKING_OPTIONS.find(option => option.value === attributes.ranking).key)
                    }
                  }
                />
              </div>
            </FeatureFlag>
            <div>
              <Label>
                <span>{str('Line.addProduct.vlp')}</span>
                {canEditVlpOrder && !addProduct && (
                  <Checkbox
                    checked={inheritedAttributes.includes('vlp')}
                    onChange={() => handleInherit('vlp')}
                    label={str('Line.editProduct.inherit')}
                  />
                )}
              </Label>
              <VLPInput
                type="number"
                min="1"
                max="9999"
                placeholder={handlePlaceholder('vlp')}
                disabled={!canEditVlpOrder || inheritedAttributes.includes('vlp')}
                value={attributes.vlp ?? ''}
                onChange={e => {
                  plpAttributeVlpOrderTag({
                    value: e.target.value,
                    pc9s: products.map(p => p.pc9),
                    assortmentId: customerAssortmentId,
                    customerId: customerOrLineId
                  });
                  setAttributes(prev => ({ ...prev, vlp: +e.target.value }));
                }}
              />
            </div>
          </Row>
          {storeGradesOptions && (
            <Row>
              <div>
                <Label>
                  <span>{str('Line.addProduct.storeGrades')}</span>
                </Label>
                {!addProduct && products.length > 1 && (
                  <StoreGradesInfo>
                    <span>{str('Line.editProduct.storeGradesNote')}</span>
                    {attributes.storeGrades?.length === storeGradesOptions.length && (
                      <span>{str('Line.editProduct.storeGradesAllAdded')}</span>
                    )}
                    {(!attributes.storeGrades || attributes.storeGrades.length === 0) && (
                      <span>{str('Line.editProduct.storeGradesAllRemoved')}</span>
                    )}
                    {attributes.storeGrades &&
                      attributes.storeGrades.length > 0 &&
                      attributes.storeGrades.length < storeGradesOptions.length && (
                        <span>
                          {str('Line.editProduct.storeGradesChanged', {
                            added: attributes.storeGrades.length,
                            removed: storeGradesOptions.length - attributes.storeGrades.length
                          })}
                        </span>
                      )}
                  </StoreGradesInfo>
                )}
                <StoreGrades>
                  {storeGradesOptions.map(s => (
                    <Button key={s.name} onClick={() => handleStoreGrades(s.name ?? '')}>
                      <span>{s.name}</span>
                      <Checkbox checked={attributes.storeGrades?.includes(s.name ?? '')} />
                    </Button>
                  ))}
                </StoreGrades>
              </div>
            </Row>
          )}
          <FeatureFlag flags={[FeatureFlags.Product_ViewNote]}>
            <Row>
              <div>
                <Label>
                  <span>{str('Line.addProduct.note')}</span>
                </Label>
                <NoteInfo>{str('Line.addProduct.noteInfo')}</NoteInfo>
                <Textarea
                  placeholder={str('Line.addProduct.notePlaceholder')}
                  rows={6}
                  value={attributes.note ?? undefined}
                  onChange={e => {
                    plpAttributeNotesTag({
                      value: e.target.value,
                      pc9s: products.map(p => p.pc9),
                      assortmentId: customerAssortmentId,
                      customerId: customerOrLineId
                    });
                    setAttributes(prev => ({ ...prev, note: e.target.value }));
                  }}
                  disabled={!canEditNote}
                />
              </div>
            </Row>
          </FeatureFlag>
        </ModalBody>
      }
      cancel={{ label: str('general.cancel'), disabled: isLoading, handler: onClose }}
      confirm={{
        label: isLoading ? <LoadingSpinner /> : str(addProduct ? 'general.add' : 'general.save'),
        disabled: isLoading,
        handler: handleUpdate
      }}
    />
  );
};
