import type { Event } from '../../event-tracker';
import { AssortmentTypes } from '../assortments';

export type ApiGetCustomerIn = CustomerList & CustomerAssortment;
export type ApiGetCustomerOut = GetCustomerListResponse[] | GetCustomerAssortmentResponse | { error: string };

export const CustomerView = {
  List: 'list',
  Assortment: 'assortment'
} as const;

export type CustomerList = {
  type: typeof CustomerView.List;
  brand: string;
};

export type CustomerAssortment = {
  type: typeof CustomerView.Assortment;
  customerId: string;
  brand: string;
};

export type GetCustomerListResponse = {
  customerId: string;
  customerName: string;
  planningCd?: string;
  planningName?: string;
  assortments?: { season: string; active?: number; archived?: number; final?: number }[];
};

export type CustomerAssortmentArray = {
  assortmentId: string;
  assortmentName: string;
  assortmentType?: AssortmentTypes;
  archivedAt?: string;
  updatedAt?: string;
  publishedAt?: string;
  add?: number;
  pending?: number;
  drop?: number;
  remove?: number;
  parentName?: string;
  parentId?: string;
  region?: string;
  brand?: string;
};

export type CustomerAssortmentsSeasonMap = {
  [seasonKey: string]: Array<CustomerAssortmentArray>;
};

export type SeasonList = {
  seasonId: string;
  season: string;
  parentName: string;
  parentId: string;
  cutOff?: string;
};

export type CmApiLocations = {
  shipTo: CustomerAddress[];
  soldTo: CustomerAddress[];
  billTo: CustomerAddress[];
  payer: CustomerAddress[];
};

export type CmApiData = {
  planningCd?: string;
  planningName?: string;
  sapName?: string;
  salesOrg?: string;
  division?: string;
  channel?: string;
  language?: string;
  locations?: CmApiLocations;
  altCustomer?: CmApiData[];
  region?: string;
};

export type CustomerAddress = {
  key: string;
  city: string;
  name: string;
  isPrimary: boolean;
};

export type CustomerOrders = {
  orderId: string;
  soldTo: string;
  urlSlug?: string;
  totalLocations?: number;
  totalQuantity?: number;
  displayName?: string;
  totalProducts?: number;
  totalWsp?: number;
  assortmentId?: string;
  totalRrp?: number;
  status?: string;
};

export type GetCustomerAssortmentResponse = {
  customerId: string;
  customerName: string;
  urlSlug?: string;
  soldTo?: string;
  region?: string;
  brand?: string;
  seasons?: SeasonList[];
  assortments?: CustomerAssortmentsSeasonMap;
  orders?: CustomerOrders[];
} & CmApiData;

export type GetAuditTrailResponse = {
  event_id: string;
  item_id: string;
  item_type: string;
  created_at: string;
  created_by: string;
  first_name: string | null;
  last_name: string | null;
  name: Event;
  payload: { pc9s?: string[] };
}[];
