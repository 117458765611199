import { CopyAlt, Newness, Replen } from 'assets/icons';
import { Card } from 'components/Card';
import { Checkbox } from 'components/Checkbox';
import { Image } from 'components/Image';
import { Tag } from 'components/Tag';
import { useLocalisation } from 'providers';
import { useCallback, useMemo } from 'react';
import { useServices } from 'services';
import styled from 'styled-components';
import { formatPrice, rem, themed } from 'utils';

import { PageDetails, ProductListData, UIProduct } from '@yourxx/types';

const StyledCard = styled(Card)<{ $height: number }>`
  box-sizing: border-box;
  height: ${({ $height }) => rem($height)};
`;
const Container = styled.div`
  position: relative;
  height: 100%;
`;
const ProductCardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ProductCardToolbar = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${themed('spacing.m')};
  position: absolute;
  z-index: 2;
  width: 100%;

  > label {
    align-self: start;
  }
`;
const TagList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${themed('spacing.xs')};
`;
const StyledCheckbox = styled(Checkbox)`
  padding: ${themed('spacing.m')};
  margin: -${themed('spacing.m')};
`;
const ProductCardImageContainer = styled.div<{ $height: number }>`
  position: relative;
  height: ${({ $height }) => ($height === ProductCardHeights.S ? 75 : $height === ProductCardHeights.M ? 63 : 58)}%;
  user-select: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
const ProductStatus = styled.span`
  position: absolute;
  top: ${rem(40)};
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-size: ${themed('font.size.s')};
  color: ${themed('color.white')};
  background-color: ${themed('color.transparentLightBlack')};
  padding: ${themed('spacing.m')};
`;
const ProductCardStoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${themed('spacing.s')};
  position: absolute;
  bottom: ${themed('spacing.m')};
  left: ${themed('spacing.m')};
  width: calc(100% - ${themed('spacing.l')});
`;
const ProductCardStory = styled.span`
  padding: ${themed('spacing.s')};
  text-transform: uppercase;
  color: ${themed('color.black')};
  font-size: ${themed('font.size.xs')};
  border-radius: ${themed('spacing.s')};
  background-color: ${themed('color.offWhite')};
  max-width: -webkit-fill-available;
  flex-shrink: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ProductDetailContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${themed('spacing.m')} ${themed('spacing.l')};
`;
const ProductCardPc9 = styled.div`
  display: inline-flex;
  align-items: center;
  text-align: left;
  padding: ${themed('spacing.s')} 0;
  color: ${themed('color.grey')};
  font-size: ${themed('font.size.m')};
  font-weight: ${themed('font.weight.semiBold')};

  svg {
    width: ${rem(16)};
    height: ${rem(16)};
    margin-left: ${themed('spacing.xs')};
  }
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
const ProductCardName = styled.div`
  text-align: left;
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.semiBold')};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ProductCardFinish = styled.div`
  font-size: ${themed('font.size.s')};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ProductCardDetail = styled.div`
  font-size: ${themed('font.size.xs')};
  line-height: ${rem(12)};
  letter-spacing: 0;
  text-align: left;
  color: ${themed('color.grey')};
`;
const ProductCardPricingContainer = styled.div`
  display: grid;
  gap: ${themed('spacing.xs')};
  grid-template-columns: auto auto;
  justify-content: space-between;
`;
const ProductCardPricing = styled.div`
  color: ${themed('color.black')};
  font-size: ${themed('font.size.s')};

  span {
    display: block;
    font-weight: ${themed('font.weight.medium')};
  }
  &:last-of-type span {
    text-align: right;
  }
`;
const ProductCardExtraInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: space-between;
  padding-top: ${themed('spacing.m')};
`;
const StyledDivider = styled.div`
  border-bottom: ${themed('spacing.xs')} solid ${themed('color.greyLight')};
  margin: ${themed('spacing.m')} 0;
`;

export enum ProductCardHeights {
  S = 280,
  M = 320,
  L = 330
}
export type ProductCardProps = {
  className?: string;
  product: UIProduct<ProductListData>;
  pageDetails?: PageDetails;
  selectDisabled?: boolean;
  selectHidden?: boolean;
  canSelectItems?: boolean;
  isSelected?: boolean;
  onSelect?: VoidFunction;
  onClick?: VoidFunction;
  height?: ProductCardHeights;
};

export const ProductCard = ({
  className,
  product,
  pageDetails,
  selectDisabled = false,
  selectHidden = false,
  canSelectItems = true,
  isSelected,
  onSelect,
  onClick,
  height
}: ProductCardProps) => {
  const [str] = useLocalisation();
  const { toastService } = useServices();

  const pluraliseUnit = useCallback(
    (count: number) => (count === 1 ? str('general.unit.singular') : str('general.unit.plural')),
    [str]
  );
  const pluraliseDoor = useCallback(
    (count: number) => (count === 1 ? str('general.door.singular') : str('general.door.plural')),
    [str]
  );
  const isCarryOver = !!(product.newness && ['co', 'c/o'].includes(product.newness.toLowerCase()));
  const isReplen = product.replen === 'TRUE';
  const productDoors = product.storeGrades
    ?.map(sg => pageDetails?.storeGrades?.find(dsg => dsg.name === sg))
    .reduce((acc, sg) => acc + (sg?.numberOfDoors || 0), 0);
  const productExtraInfo = Boolean(productDoors || product.sellThrough || product.rateOfSale);
  const productFinish = product.finish?.replace(product.pc9, '');
  const productCost = pageDetails?.currency && (product.wsp || product.rrp);

  const cardHeight = useMemo(() => {
    if (height) return height;
    if (productExtraInfo && productFinish && productCost) return ProductCardHeights.L;
    if (productCost && (productExtraInfo || productFinish)) return ProductCardHeights.M;
    return ProductCardHeights.S;
  }, [height, productExtraInfo, productFinish, productCost]);

  return (
    <StyledCard className={className} isSelected={isSelected} onClick={onClick} showPointer $height={cardHeight}>
      <Container title={product.name}>
        <ProductCardToolbar>
          <TagList>
            {isCarryOver && (
              <Tag $backgroundColor={'red'}>
                <Newness />
                <p>{str('general.carryOverLabel')}</p>
              </Tag>
            )}
            {isReplen && (
              <Tag $backgroundColor={'gray'}>
                <Replen />
                <p>{str('general.replenLabel')}</p>
              </Tag>
            )}
          </TagList>
          {canSelectItems && (
            <StyledCheckbox
              id={product.pc9}
              disabled={selectDisabled}
              hidden={selectHidden}
              checked={isSelected}
              onChange={onSelect}
            />
          )}
        </ProductCardToolbar>
        <ProductCardContainer>
          <ProductCardImageContainer $height={cardHeight}>
            <Image
              width={512}
              height={512}
              src={`${product.url}&width=512&height=512`}
              alt={product.name}
              draggable={false}
            />
            {product.status.toLowerCase() !== 'add' && <ProductStatus>{product.status}</ProductStatus>}
            <ProductCardStoriesContainer>
              {product.story
                ?.slice(0, 3)
                .map(storyName => (
                  <ProductCardStory key={`${product.spId}_${storyName}`}>{storyName}</ProductCardStory>
                ))}
            </ProductCardStoriesContainer>
          </ProductCardImageContainer>
          <ProductDetailContainer>
            <div>
              <ProductCardPc9
                onClick={e => {
                  e.stopPropagation();
                  navigator.clipboard
                    .writeText(product.pc9)
                    .then(() => toastService.send(str('PDP.product.pc9Copied')));
                }}
              >
                {product.pc9}
                <CopyAlt />
              </ProductCardPc9>
              <ProductCardName>{product.name}</ProductCardName>
              {productFinish && <ProductCardFinish>{productFinish}</ProductCardFinish>}
            </div>
            <div>
              {productExtraInfo && (
                <ProductCardExtraInfoContainer>
                  <ProductCardDetail>{`${productDoors ?? 0} ${pluraliseDoor(productDoors ?? 0)}`}</ProductCardDetail>
                  <ProductCardDetail>
                    {`${product.sellThrough ?? 0} ${pluraliseUnit(product.sellThrough ?? 0)}`}
                  </ProductCardDetail>
                  <ProductCardDetail>
                    {str('Insights.productRateOfSale', { value: product.rateOfSale ?? 0 })}
                  </ProductCardDetail>
                </ProductCardExtraInfoContainer>
              )}
              {productCost && (
                <>
                  <StyledDivider />
                  <ProductCardPricingContainer>
                    <ProductCardPricing>
                      <span>{str('general.wsp')}</span>
                      {formatPrice(product.currency, product.wsp !== 0 ? product.wsp : undefined)}
                    </ProductCardPricing>

                    <ProductCardPricing>
                      <span>{str('general.rrp')}</span>
                      {formatPrice(pageDetails?.currency, product.rrp !== 0 ? product.rrp : undefined)}
                    </ProductCardPricing>
                  </ProductCardPricingContainer>
                </>
              )}
            </div>
          </ProductDetailContainer>
        </ProductCardContainer>
      </Container>
    </StyledCard>
  );
};
