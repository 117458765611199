import { PrimaryButton, ShowAsyncErrorAndGoBack, SlotId, ViewTransition } from 'pages';
import { OrderSummary as IOrderSummary, useLocalisation, useOrders, useSlot } from 'providers';
import { Suspense, useMemo } from 'react';
import { Await, Link } from 'react-router-dom';
import { useServices } from 'services';

import { OrderMenu } from '../components';
import { SetupDemoOrderToolbar } from '../CustomerOrder/demo-wiring-and-examples';
import { OrderSummarySkeleton } from './OrderSummarySkeleton';

export const OrderSummary = ({ orderId }: { orderId: string }) => {
  const { loadOrderSummary } = useOrders();
  return (
    <Suspense fallback={<ViewTransition children={<OrderSummarySkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={loadOrderSummary(orderId)}
        children={data => <ViewTransition children={<View data={data} />} />}
        // children={_data => <ViewTransition children={<OrderSummarySkeleton />} />}
      />
    </Suspense>
  );
};

const View = ({ data: { customerId, orderId, summary } }: { data: IOrderSummary }) => {
  const { toastService } = useServices();

  useSlot(
    SlotId.OrderMenu,
    useMemo(() => <OrderMenu />, [])
  );

  useSlot(
    SlotId.FooterRight,
    useMemo(
      () => <PlaceOrdersButton onClick={() => toastService.send('You clicked the "Place Orders" button.')} />,
      [toastService]
    )
  );

  return (
    <>
      <SetupDemoOrderToolbar />
      [Order Summary [{customerId}] [{orderId}]]
      <div>{summary}</div>
      <ul>
        <li>
          <Link to="00501-0001">00501-0001</Link>
        </li>
        <li>
          <Link to="00505-0005">00505-0005</Link>
        </li>
        <li>
          <Link to="00712-0000">00712-0000</Link>
        </li>
      </ul>
    </>
  );
};

const PlaceOrdersButton = ({ onClick }: { onClick: VoidFunction }) => {
  const [str] = useLocalisation();
  return <PrimaryButton onClick={onClick}>{str('Order.placeOrders')}</PrimaryButton>;
};
