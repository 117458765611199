import { ChevronLeft, ChevronRight, Delete, Fullscreen as FullscreenIcon, Link, ZoomIn, ZoomOut } from 'assets/icons';
import { Button } from 'components/Button';
import { ProductDetailsContext } from 'components/ProductDetails';
import { State } from 'pages/Products';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useFullScreenHandle } from 'react-full-screen';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { PageDetails, ProductDetailsData, UIImage, UIProduct } from '@yourxx/types';

import { Fullscreen } from './Fullscreen';
import ImageZoom, { MinimapOffset } from './ImageZoom';
import { Video } from './Video';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;
const SliderCarouselWrapper = styled.div`
  position: relative;
  user-select: none;
  height: 100%;
`;
const NavButton = styled(Button)<{ $direction: string }>`
  width: ${themed('spacing.xxl')};
  height: ${themed('spacing.xxl')};
  justify-content: center;
  background-color: ${themed('color.white')};
  color: ${themed('color.grey')};
  padding: 0;
  position: absolute;
  top: 50%;
  right: ${({ $direction }) => ($direction === 'right' ? '2%' : 'auto')};
  left: ${({ $direction }) => ($direction === 'left' ? '2%' : 'auto')};
  transform: translateY(-50%);
`;
const DotContainer = styled.div`
  position: absolute;
  display: flex;
  gap: ${themed('spacing.s')};
  justify-content: center;
  align-items: center;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
`;
const Dot = styled.div<{ $isActive: boolean }>`
  width: ${rem(10)};
  height: ${rem(10)};
  border-radius: 50%;
  background-color: ${({ $isActive }) => ($isActive ? themed('color.black') : themed('color.greyMid'))};
  cursor: pointer;
`;
const ButtonsWrapper = styled.div<{ $justify: string }>`
  width: calc(100% - ${themed('spacing.l')});
  display: flex;
  justify-content: ${({ $justify }) => $justify};
  gap: ${themed('spacing.s')};
  position: absolute;
  bottom: ${themed('spacing.m')};
  left: ${themed('spacing.m')};

  > div {
    display: flex;
    gap: ${themed('spacing.s')};
    justify-content: flex-end;
  }
`;
export const ImageSliderButton = styled(Button)<{ $isSelected?: boolean }>`
  width: ${themed('spacing.xxl')};
  height: ${themed('spacing.xxl')};
  color: ${({ $isSelected }) => ($isSelected ? themed('color.white') : themed('color.black'))};
  background-color: ${({ $isSelected }) => ($isSelected ? themed('color.black') : themed('color.white'))};

  svg {
    width: ${rem(20)};
    height: ${rem(20)};

    path {
      stroke: ${({ $isSelected }) => ($isSelected ? themed('color.white') : themed('color.black'))};
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${themed('color.black')};
      color: ${themed('color.white')};

      svg path {
        stroke: ${themed('color.white')};
      }
    }
  }
`;
const SlideWrapper = styled.div<{ $isVisible: boolean }>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: ${({ $isVisible }) => ($isVisible ? 'static' : 'absolute')};
  top: 0;
  left: 0;
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  will-change: opacity;
  transition: opacity 0.2s ease-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const ChevronWrapper = styled.div<{ $direction: 'left' | 'right' }>`
  position: absolute;
  background-color: ${themed('color.white')};
  box-shadow: ${themed('boxShadow')};
  width: ${rem(38)};
  height: ${rem(38)};
  top: 45%;
  left: ${({ $direction }) => ($direction === 'left' ? rem(10) : 'auto')};
  right: ${({ $direction }) => ($direction === 'right' ? rem(10) : 'auto')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: ${themed('spacing.l')};
    width: ${themed('spacing.l')};
  }
`;

export type SliderImage = UIImage & { isVideo?: boolean };
export type SliderProps = {
  className?: string;
  images: SliderImage[];
  product?: UIProduct<ProductDetailsData>;
  details?: PageDetails;
  onRemove?: VoidFunction;
  zoomedIn?: boolean;
  fullscreened?: boolean;
  showIcons?: boolean;
  onZoomChange?: (val: boolean) => void;
  onFullscreenChange?: (val: boolean) => void;
  allowZoom?: boolean;
  allowFullscreen?: boolean;
  defaultSlide?: number;
  onSlideChange?: (index: number) => void;
  disableDots?: boolean;
  noVideo?: boolean;
  minimapOffset?: MinimapOffset;
  onVideoPlay?: VoidFunction;
  onShowVideoEdit?: VoidFunction;
};

export const SliderCarousel = ({
  images,
  activeSlide,
  setActiveSlide,
  isZoom,
  isFullscreen,
  disableDots = false,
  minimapOffset,
  onVideoPlay
}: {
  images: SliderImage[];
  activeSlide: number;
  setActiveSlide: (index: number) => void;
  isZoom: boolean;
  isFullscreen?: boolean;
  disableDots?: boolean;
  minimapOffset?: MinimapOffset;
  onVideoPlay?: VoidFunction;
}) => {
  const getNextIndex = useCallback(
    (afterIncr: number) => (afterIncr < 0 ? images.length - 1 : afterIncr >= images.length ? 0 : afterIncr),
    [images.length]
  );
  const goto = useCallback(
    (incr: number) => {
      const afterIncr = activeSlide + incr;
      const nextIndex = getNextIndex(afterIncr);
      if (isFullscreen && images[nextIndex].isVideo) {
        setActiveSlide(getNextIndex(nextIndex + incr));
      } else {
        setActiveSlide(nextIndex);
      }
    },
    [activeSlide, images, isFullscreen]
  );

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (!isFullscreen) return;
      if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') goto(-1);
      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') goto(1);
    };
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [goto, isFullscreen]);

  return (
    <SliderCarouselWrapper>
      {images.map((slide, index) =>
        slide.isVideo ? (
          <SlideWrapper key="video" $isVisible={activeSlide === index}>
            <Video src={slide.url} onPlay={onVideoPlay} />
          </SlideWrapper>
        ) : (
          <SlideWrapper key={slide.url} $isVisible={activeSlide === index}>
            <ImageZoom
              image={slide}
              maxImageSize={isZoom ? (isFullscreen ? 4096 : 2048) : isFullscreen ? 2048 : 1024}
              minimapMaxWidth={isFullscreen ? '10%' : '15%'}
              minimapMaxHeight={isFullscreen ? '10%' : '15%'}
              scale={isZoom ? 4 : 1}
              offset={minimapOffset}
            />
          </SlideWrapper>
        )
      )}
      {images.length > 1 && (
        <>
          <NavButton $direction="left" onClick={() => goto(-1)}>
            <ChevronLeft />
          </NavButton>
          <NavButton $direction="right" onClick={() => goto(+1)}>
            <ChevronRight />
          </NavButton>
          {!isZoom && !disableDots && (
            <DotContainer>
              {images.map((_, index) => (
                <Dot key={index} onClick={() => goto(index - activeSlide)} $isActive={index === activeSlide} />
              ))}
            </DotContainer>
          )}
        </>
      )}
    </SliderCarouselWrapper>
  );
};

export const ImageSlider = ({
  className,
  images,
  product,
  onRemove,
  zoomedIn = false,
  fullscreened = false,
  showIcons = true,
  onZoomChange,
  onFullscreenChange,
  allowZoom = true,
  allowFullscreen = true,
  defaultSlide,
  onSlideChange,
  disableDots = false,
  noVideo = false,
  minimapOffset,
  onVideoPlay,
  onShowVideoEdit
}: SliderProps) => {
  // TODO: Refactor with custom hook
  const state = useContext(ProductDetailsContext);
  const fullscreenHandle = useFullScreenHandle();

  const [activeSlide, setActiveSlide] = useState<number>(defaultSlide ?? 0);
  const [isZoom, setIsZoom] = useState<boolean>(zoomedIn);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(fullscreened);

  useEffect(() => setIsZoom(zoomedIn), [zoomedIn]);
  useEffect(() => setIsFullscreen(fullscreened), [fullscreened]);
  useEffect(() => setActiveSlide(prev => defaultSlide ?? prev), [defaultSlide]);
  useEffect(() => onSlideChange?.(activeSlide), [activeSlide]);

  const enterFullscreen = useCallback(() => {
    onFullscreenChange?.(true);
    setIsFullscreen(true);
    fullscreenHandle.enter();
  }, [onFullscreenChange, setIsFullscreen, fullscreenHandle]);
  const exitFullscreen = useCallback(() => {
    onFullscreenChange?.(false);
    setIsFullscreen(false);
  }, [onFullscreenChange, setIsFullscreen]);

  const imagesWithVideo = images.find(i => i.isVideo && i.url);

  return (
    <Wrapper className={className}>
      <SliderCarousel
        images={images}
        activeSlide={activeSlide}
        setActiveSlide={setActiveSlide}
        isZoom={isZoom}
        isFullscreen={isFullscreen}
        disableDots={disableDots}
        minimapOffset={minimapOffset}
        onVideoPlay={onVideoPlay}
      />
      {showIcons && (
        <>
          <ButtonsWrapper $justify={onRemove ? 'space-between' : 'flex-end'}>
            {onRemove && (
              <ImageSliderButton onClick={onRemove}>
                <Delete />
              </ImageSliderButton>
            )}
            <div>
              {images[activeSlide].isVideo ? (
                <>
                  {!!images[activeSlide].url && (
                    <ImageSliderButton
                      onClick={() => {
                        if (state?.state === State.WithVideo) {
                          onShowVideoEdit?.();
                          state.edit();
                        }
                      }}
                      $isSelected={state?.state !== State.WithVideo}
                    >
                      <Link />
                    </ImageSliderButton>
                  )}
                  {allowFullscreen && images[activeSlide].url && (
                    <ImageSliderButton onClick={enterFullscreen}>
                      <FullscreenIcon />
                    </ImageSliderButton>
                  )}
                </>
              ) : (
                <>
                  {allowZoom && (
                    <ImageSliderButton
                      onClick={() => {
                        onZoomChange?.(!isZoom);
                        setIsZoom(prev => !prev);
                      }}
                      $isSelected={isZoom}
                    >
                      {isZoom ? <ZoomOut /> : <ZoomIn />}
                    </ImageSliderButton>
                  )}
                  {allowFullscreen && (
                    <ImageSliderButton onClick={enterFullscreen}>
                      <FullscreenIcon />
                    </ImageSliderButton>
                  )}
                </>
              )}
            </div>
          </ButtonsWrapper>
        </>
      )}
      <Fullscreen
        isActive={isFullscreen}
        handle={fullscreenHandle}
        images={imagesWithVideo ? images : images.filter(i => !i.isVideo)}
        activeSlide={activeSlide - (noVideo || imagesWithVideo ? 0 : 1)}
        setActiveSlide={index => setActiveSlide(index + (noVideo || imagesWithVideo ? 0 : 1))}
        onClose={exitFullscreen}
        product={product}
      />
    </Wrapper>
  );
};
