import { ProductListData, UIProduct } from '@yourxx/types';

export const productMultisortBy = <T extends UIProduct<ProductListData>>(key: keyof T) => {
  let sortBy: (keyof T)[] = [key];

  if (key !== 'pc9') sortBy.push('pc9');
  if (key === 'category2') sortBy = ['category0', 'category1', 'category2', 'pc9'];

  return sortBy;
};
