import { useLocalisation } from 'providers';
import { Key, useMemo, useRef } from 'react';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { chunkArray } from '@yourxx/support';
import { ProductSize, ProductSizes } from '@yourxx/types';

import { useSegmentsBreakpoints } from './useSegmentsBreakpoints';

const TableContainer = styled.div`
  width: 100%;
`;
const SingleLineTableEntry = styled.span`
  display: inline-block;
  margin-right: ${themed('spacing.m')};

  & > div {
    box-sizing: border-box;
    margin-right: ${rem(-1)};
    margin-bottom: ${rem(-1)};
    text-align: center;
  }
`;
const BulletPoint = styled.div`
  color: ${themed('color.red')};
  font-size: ${themed('font.size.l')};
`;
const StyledTable = styled.table<{ $isFullWidth: boolean }>`
  width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : '')};
  border-collapse: collapse;

  td,
  th {
    border: ${rem(1)} solid ${themed('color.greyMid')};
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.regular')};
  }
  th {
    font-weight: ${themed('font.weight.semiBold')};
  }
  > * {
    padding: ${rem(7, 15)};
    text-align: center;
    position: relative;
  }
`;

enum Direction {
  Table,
  Single
}
interface ParsedValue {
  type: Direction;
  table?: string[];
  sizer?: string[];
  leftList?: string[];
  rightList?: string[];
}

export const SizeGroup = ({ sizes }: { sizes: ProductSizes }) => {
  const [str] = useLocalisation();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const segments = useSegmentsBreakpoints(rootRef, 20);

  const parseArrayValue = (sizes: ProductSize[]): ParsedValue | null => {
    try {
      const table: string[] = [];
      const leftSet = new Set<string>();
      const rightSet = new Set<string>();
      const sizer = new Set<string>();

      sizes.forEach(size => {
        table.push(`${size.dimension1}:${size.dimension2}`);
        if (size.dimension2 === '-') {
          sizer.add(size.dimension1);
        } else {
          leftSet.add(size.dimension1);
          rightSet.add(size.dimension2);
        }
      });

      // 1D
      if (sizer.size > 0) {
        return {
          type: Direction.Single,
          sizer: sizer ? [...sizer] : []
        };
      }

      const leftList = leftSet ? [...leftSet] : [];
      const rightList = rightSet ? [...rightSet] : [];
      if (leftList && !leftList.some(e => isNaN(e as any))) leftList.sort();
      if (rightList && !rightList.some(e => isNaN(e as any))) rightList.sort();

      // 2D
      return {
        type: Direction.Table,
        table,
        leftList,
        rightList
      };
    } catch (err) {
      return null;
    }
  };

  const parsedValue = useMemo(
    () => parseArrayValue(sizes.map(s => ({ dimension1: s[0], dimension2: s[1] ?? '-' }))),
    [sizes, parseArrayValue]
  );

  if (!parsedValue) {
    return <div>{str('SizeGroup.error')}</div>;
  }
  if (parsedValue.type === Direction.Single && parsedValue.sizer) {
    return (
      <TableContainer>
        {parsedValue.sizer.map((size: string) => (
          <SingleLineTableEntry key={size}>
            <div>{size}</div>
            <BulletPoint>&bull;</BulletPoint>
          </SingleLineTableEntry>
        ))}
      </TableContainer>
    );
  }
  if (parsedValue.type === Direction.Table) {
    const { rightList, leftList, table } = parsedValue;
    if (rightList && leftList && table) {
      const columnChunks: string[][] = segments ? chunkArray(leftList, segments.segmentCount - 1) : [leftList];

      return (
        <TableContainer ref={rootRef}>
          {columnChunks.map((chunk: string[], i: Key | null | undefined) => (
            <StyledTable $isFullWidth={columnChunks.length === 1} key={i}>
              <tbody>
                <tr>
                  <th />
                  {chunk.map((v: string) => (
                    <th key={v}>{v}</th>
                  ))}
                </tr>
                {rightList.map((row: string) => (
                  <tr key={row}>
                    <th>{row}</th>
                    {chunk.map(col => (
                      <td key={col}>{table.includes(`${col}:${row}`) ? <BulletPoint>&bull;</BulletPoint> : null}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          ))}
        </TableContainer>
      );
    }
  }
  return null;
};
