import { Header, useSetupContextfulSearch } from 'components/Header';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useLocalisation } from 'providers';
import { Suspense, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes';
import styled from 'styled-components';
import { canvasViewTag, insightsHeaderClickTag, themed, useRoute } from 'utils';

import { FeatureFlags } from '@yourxx/types';
import { canUseSome } from '@yourxx/ui-utils';

const Main = styled.main<{ $noHeader?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // height: ${({ $noHeader }) => `calc(100vh${$noHeader ? '' : ` - 4rem`})`};
  background-color: ${themed('color.offWhite')};

  > div:only-child {
    flex: 1;
  }

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
    background-color: ${themed('color.offWhite')};
  }
`;

/**
 * @deprecated TODO: Soon to be removed.
 */
export const Wrapper = ({ children, noHeader }: { children?: React.ReactNode; noHeader?: boolean }) => {
  const [str] = useLocalisation();
  const navigate = useNavigate();
  const { route } = useRoute();
  const params = useParams();
  const { brandName, customerId, assortmentId: customerAssortmentId, lineId } = params;
  const customersOrLines = lineId ? 'lines' : 'customers';
  const customerOrLineId = customerId ?? lineId;
  const [currentTab, setCurrentTab] = useState<string>(customersOrLines ?? 'customers');
  const { currentContext = '', ...searchProps } = useSetupContextfulSearch();

  const onComparatorClick = useMemo(() => {
    // TODO: Simply this with route refactor
    if (
      route?.matches(routes.lineProducts) ||
      route?.matches(routes.lineCanvasBoard) ||
      route?.matches(routes.lineCanvasBoards)
    ) {
      return () => {
        canvasViewTag({
          assortmentId: customerAssortmentId,
          customerId: customerOrLineId,
          source: 'top_nav'
        });
        navigate(
          `${routes.lineCanvasBoards.with({
            ...route.params,
            productModalType: 'canvas',
            canvasType: 'comparator'
          })}`
        );
      };
    }
    if (
      route?.matches(routes.assortmentProducts) ||
      route?.matches(routes.assortmentCanvasBoard) ||
      route?.matches(routes.assortmentCanvasBoards) ||
      route?.matches(routes.assortmentInsights)
    ) {
      return () => {
        canvasViewTag({
          assortmentId: customerAssortmentId,
          customerId: customerOrLineId,
          source: 'top_nav'
        });
        navigate(
          `${routes.assortmentCanvasBoards.with({
            ...route.params,
            productModalType: 'canvas',
            canvasType: 'comparator'
          })}`
        );
      };
    }
  }, [route, routes, navigate, canvasViewTag]);

  const onInsightsClick = useMemo(() => {
    if (!canUseSome([FeatureFlags.InsightsRecommendations, FeatureFlags.InsightsSuggestions])) return;

    if (route?.matches(routes.assortments) || route?.matches(routes.customerInsights)) {
      return () => {
        insightsHeaderClickTag({ customerId: customerOrLineId, assortmentId: customerAssortmentId });
        navigate(
          `${routes.customerInsights.with({
            ...route.params,
            productModalType: 'insights'
          })}`
        );
      };
    }
    if (
      route?.matches(routes.assortmentProducts) ||
      route?.matches(routes.assortmentCanvasBoard) ||
      route?.matches(routes.assortmentCanvasBoards) ||
      route?.matches(routes.assortmentInsights)
    ) {
      return () => {
        insightsHeaderClickTag({ customerId: customerOrLineId, assortmentId: customerAssortmentId });
        navigate(
          `${routes.assortmentInsights.with({
            ...route.params,
            productModalType: 'insights'
          })}`
        );
      };
    }
  }, [route, routes, navigate]);

  const searchPlaceholderText = useMemo(
    () => ({
      [`${routes.customers}`]: str('Customers.searchPlaceholder'),
      [`${routes.assortments}`]: str('Landing.customers.searchAssortmentsPlaceholder'),
      [`${routes.assortmentProducts}`]: str('Landing.customers.searchProductsPlaceholder'),
      [`${routes.lines}`]: str('Landing.lines.searchPlaceholder'),
      [`${routes.lineProducts}`]: str('Landing.customers.searchProductsPlaceholder')
    }),
    [str]
  );

  return (
    <>
      {!noHeader && (
        <Header
          onLogoClick={() => navigate('/brands')}
          onComparatorClick={onComparatorClick}
          onInsightsClick={onInsightsClick}
          searchProps={{ ...searchProps, placeholder: searchPlaceholderText[currentContext] ?? '' }}
          showHeaderTabs={!!brandName}
          selectedTab={currentTab}
          onTabChange={tabId => {
            // TODO: Store the previous route so we can easily go back to the exact view we left when switching tabs
            setCurrentTab(tabId);
            navigate(`/${brandName}/${tabId}`);
          }}
        />
      )}
      <Main $noHeader={noHeader}>
        <Suspense fallback={<LoadingSpinner label={str('general.loading')} />}>
          <Outlet />
          {children}
        </Suspense>
      </Main>
    </>
  );
};
