import Logo from 'assets/landing/form.svg?react';
import { Button, FlexWrapper, LoadingSpinner } from 'components';
import { useLocalisation } from 'providers';
import { Link, redirect, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';
import { getLoginUrl, getTokenFromCodeGrant, persistedStore } from '@yourxx/ui-utils';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100vh;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ContentSection = styled.div`
  display: flex;
  align-items: center;
  flex: 10 0;
`;

export const Auth = () => {
  const [searchParams] = useSearchParams();
  const [str] = useLocalisation();

  const { isLoading, data, isError } = useQuery({
    queryKey: ['getTokenFromCodeGrant'],
    queryFn: async (): Promise<{ login?: string; redirect?: string }> => {
      const code = searchParams.get('code');
      if (code) {
        await getTokenFromCodeGrant(code);
      }
      const token = persistedStore.getAccessToken();
      if (token) {
        const loginRedirect = persistedStore.getLoginRedirect();
        persistedStore.removeLoginRedirect();
        const brands = persistedStore.getUserProfile()?.brands;
        if (loginRedirect) {
          return { redirect: loginRedirect };
        }
        if (brands && brands.length === 1) {
          return { redirect: `/${brands[0].toLowerCase()}/customers` };
        }
        return { redirect: `/brands` };
      }
      return getLoginUrl();
    }
  });

  if (data?.redirect) {
    window.location.href = data.redirect;
  }

  return (
    <Wrapper>
      <ContentSection>
        <FlexWrapper>
          <Logo />
          <div className="column is-half-tablet is-third-desktop">
            <div className="section content">
              {isLoading && <LoadingSpinner size={40} label={'Please wait while we log you in'} />}
              {isError && (
                <>
                  <h1 className="heading-4">Welcome to {str('Application.name')}</h1>
                  <div className="text-1">
                    <div>
                      {str('Application.name')} is our next generation digital showroom solution.
                      <br />
                      You are seeing this message because we have been unable to log you into the system. Please contact
                      an Administrator for access or click below to go back to try logging in again
                    </div>
                    {isLoading && <LoadingSpinner label={str('general.loading')} />}
                    {data?.login && (
                      <Link to={data.login}>
                        <Button key={data.login} onClick={() => redirect(data.login ?? 'login')}>
                          {str('Login.button')}
                        </Button>
                      </Link>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </FlexWrapper>
      </ContentSection>
    </Wrapper>
  );
};
