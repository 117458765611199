import { Download as DownloadIcon } from 'assets/icons';
import { PopOver, Tooltip } from 'components';
import { useLocalisation } from 'providers';
import { type ReactNode, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { plpAssortmentDownloadTypeTag } from 'utils';

import type { ExportOption } from '../hooks';
import { Option, OptionDesc, OptionIcon, OptionLabel, OptionsList, StyledButton, StyledLoader } from './styling';

export const Download = ({
  isExporting,
  options,
  trigger: renderTrigger
}: {
  isExporting?: boolean;
  options: ReadonlyArray<ExportOption> | undefined;
  trigger?: (props: { isDisabled: boolean; isActive: boolean; onClick: VoidFunction }) => ReactNode;
}) => {
  const [str] = useLocalisation();
  const [isOpen, setIsOpen] = useState(false);
  const { customerId, assortmentId, lineId } = useParams();
  const areAllOptionsDisabled = options?.every(option => option.disabled) ?? true;

  const onTriggerClick = useCallback(() => setIsOpen(prev => !prev), []);
  const triggerButton = renderTrigger?.({
    isDisabled: areAllOptionsDisabled,
    isActive: isOpen,
    onClick: onTriggerClick
  }) ?? (
    <StyledButton disabled={areAllOptionsDisabled} $isOpen={isOpen} onClick={onTriggerClick}>
      <span>{isExporting ? <StyledLoader /> : <DownloadIcon />}</span>
    </StyledButton>
  );

  const inProgressExportsLabel = useMemo(
    () =>
      options
        ?.filter(option => option.inProgress)
        .map(option => [option.label, option.desc].filter(Boolean).join(' '))
        .join(', '),
    [options]
  );

  const trigger =
    isExporting && inProgressExportsLabel ? (
      <Tooltip text={str('ProductListing.download.inProgress', { name: inProgressExportsLabel })} noPadding>
        {triggerButton}
      </Tooltip>
    ) : (
      triggerButton
    );

  return (
    <PopOver isOpen={isOpen} onClose={() => setIsOpen(false)} trigger={trigger}>
      <OptionsList>
        {options?.map(option => (
          <Option
            key={option.id}
            $isDisabled={option.disabled || option.inProgress}
            onClick={
              !option.inProgress
                ? () => {
                    // TODO: Move to a more appropriate place.
                    plpAssortmentDownloadTypeTag({
                      type: option.label,
                      assortmentId,
                      customerId: customerId ?? lineId
                    });
                    option.onClick();
                  }
                : undefined
            }
          >
            <OptionLabel>{option.label}</OptionLabel>
            {option.desc && <OptionDesc>{option.desc}</OptionDesc>}
            {option.icon && <OptionIcon>{option.icon}</OptionIcon>}
          </Option>
        ))}
      </OptionsList>
    </PopOver>
  );
};
