import { CustomerView, GetCustomerListResponse } from '@yourxx/types/src/api/base';

import { apiEndpoints, toRequest } from '../../utils';
import { apiClient } from '../client';

export const getCustomerList = async (params: { brand: string }): Promise<GetCustomerListResponse[]> => {
  const request = toRequest({
    path: apiEndpoints.customers,
    method: 'GET',
    params: { ...params, type: CustomerView.List }
  });
  return apiClient.request<GetCustomerListResponse[]>(request);
};
