import DockersImage from 'assets/landing/bg-overlay-right.jpg';
import Logo from 'assets/landing/form.svg?react';
import LandingImage from 'assets/landing/yourxx-h225-landing-levis-background-opt.jpeg';
import LeviImage from 'assets/landing/yourxx-h225-landing-page-levis-logo-opt.png';
import { Button, FlexWrapper, LoadingSpinner } from 'components';
import { useLocalisation } from 'providers';
import { useEffect } from 'react';
import { Link, redirect, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { useQuery } from '@tanstack/react-query';
import { getLoginUrl } from '@yourxx/ui-utils';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100vh;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const BrandsSection = styled.div`
  display: flex;
  flex: 44 0;
`;
const BrandsPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0;
  background-image: url(${LandingImage});
  background-position: 25% center;
  background-repeat: no-repeat;
  background-size: cover;

  &:before,
  &:after {
    content: '';
    width: 50%;
    height: 100%;
    background-image: url(${LeviImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 55%;
  }

  &:after {
    background-image: url(${DockersImage});
    background-size: cover;
  }
`;
const ContentSection = styled.div`
  display: flex;
  align-items: center;
  flex: 10 0;

  svg {
    min-width: ${rem(280)};
  }

  button {
    border: solid ${rem(1)} ${themed('color.greyMid')};
    margin-top: ${themed('spacing.xl')};
  }

  a {
    color: ${themed('color.black')};
    text-decoration: none;
  }
`;

export const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [str] = useLocalisation();

  const { isLoading, data, isError } = useQuery({
    queryKey: ['loginUrls'],
    queryFn: async () => getLoginUrl()
  });

  useEffect(() => {
    const logout_reason = searchParams.get('logout_reason');
    searchParams.delete('logout_reason');
    if (logout_reason) {
      toast(logout_reason, { position: 'bottom-right', hideProgressBar: true, autoClose: 60000 });
      setSearchParams(searchParams);
    }
  }, []);

  return (
    <Wrapper>
      <BrandsSection>
        <BrandsPicture />
      </BrandsSection>
      <ContentSection>
        <FlexWrapper>
          <Logo />
          {isLoading && <LoadingSpinner label={str('general.loading')} />}
          {isError && <div>Failed to load login URL</div>}
          {data && (
            <Link to={data.login}>
              <Button key={data.login} onClick={() => redirect(data.login)}>
                {str('Login.button')}
              </Button>
            </Link>
          )}
        </FlexWrapper>
      </ContentSection>
    </Wrapper>
  );
};
