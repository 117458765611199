import { useEffect } from 'react';
import styled from 'styled-components';
import { configsByType, EnvironmentType, getEnvConfig, isEnvironmentType, rem, themed } from 'utils';

const Label = styled.div<{ $type: string }>`
  position: absolute;
  z-index: 9999;
  top: ${rem(-12)};
  left: ${rem(-45)};
  width: ${rem(100)};
  height: ${rem(30)};
  padding: ${themed('spacing.m')};
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
  box-shadow: ${themed('boxShadow')};
  transform: rotate(-45deg);
  color: ${({ $type }) => themed(`color.${['staging', 'preprod'].includes($type) ? 'white' : 'black'}`)};
  background-color: ${({ $type }) =>
    themed(
      `color.${
        ['staging', 'preprod'].includes($type)
          ? 'greyDark'
          : ['dev', 'development'].includes($type)
            ? 'blue'
            : ['qa', 'testing'].includes($type)
              ? 'yellowLight'
              : 'green'
      }`
    )};
`;

export const EnvLabel = ({ className }: { className?: string }) => {
  const type = getEnvConfig().ENV_INDICATOR_TYPE as EnvironmentType;

  useEffect(() => {
    if (type && !isEnvironmentType(type)) {
      console.warn(`EnvironmentIndicator: Unknown environment type '${type}'`);
    }
  }, [type]);

  const config = configsByType[type];
  if (!config || !type || (type && !isEnvironmentType(type))) {
    return null;
  }
  return (
    <Label className={className} $type={type}>
      {config.text}
    </Label>
  );
};
