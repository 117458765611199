import { Reorder } from 'assets/icons';
import { Button } from 'components/Button';
import { useLocalisation } from 'providers';
import { useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { rem, themed } from 'utils';

import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { toCase } from '@yourxx/support';
import { ToCase } from '@yourxx/types';

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 ${themed('spacing.l')};
  height: 100%;
  animation-duration: ${themed('transition.duration')};
  animation-timing-function: ${themed('transition.timing')};
  animation-name: ${themed('animation.slideDown')};
`;
const Header = styled.div`
  padding: ${themed('spacing.l')} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
    ${themed('typography.h4')};
    font-weight: ${themed('font.weight.semiBold')};
    text-transform: uppercase;
  }
`;
const ResetButton = styled(Button)`
  padding: 0;
  align-self: center;
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.medium')};

  &:hover {
    color: ${themed('color.red')};
  }
`;
const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  margin: 0 -${themed('spacing.l')};
`;
const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 -${themed('spacing.l')};
  padding: ${rem(10, 16, 12, 16)};
  width: -webkit-fill-available;
  gap: ${rem(10)};
  background-color: ${themed('color.white')};
  will-change: transform;
  animation-duration: ${themed('transition.duration')};
  animation-timing-function: ${themed('transition.timing')};
  animation-name: ${keyframes`
    from { transform: translateY(2vh); }
    to { transform: translateY(0); }
  `};

  span {
    ${themed('typography.h4')};
    color: ${themed('color.grey')};
    display: block;
  }
  div {
    justify-content: space-between;
    display: flex;
    gap: ${rem(10)};

    button {
      flex-grow: 1;
    }
  }
`;
const ExitButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(29)};
  border: ${rem(1)} solid ${themed('color.black')};
  background-color: ${themed('color.white')};
  color: ${themed('color.black')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.medium')};
`;
const SaveButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(29)};
  background-color: ${themed('color.black')};
  color: ${themed('color.white')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.medium')};
`;
const ReorderButton = styled.button`
  position: relative;
  z-index: ${themed('zIndex.100')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.m')};
  width: 100%;
  border: none;
  border-bottom: ${rem(1)} solid ${themed('color.greyMid')};
  padding: ${themed('spacing.m')} ${themed('spacing.m')} ${themed('spacing.m')} ${themed('spacing.xxl')};
  background-color: ${themed('color.white')};
  will-change: box-shadow;
  transition-property: box-shadow;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};
  cursor: grab;

  &:active {
    z-index: ${themed('zIndex.200')};
    box-shadow: ${themed('boxShadow')};
    cursor: grabbing;
  }
  span {
    position: relative;
    top: ${rem(1)};
    flex-grow: 1;
    ${themed('typography.h4')};
    color: ${themed('color.black')};
    text-align: left;
    text-transform: uppercase;
  }
  svg {
    width: ${rem(24)};
    height: ${rem(24)};
  }
`;

type Item = { id: string; label: string };

export type FilterReorderViewProps<T extends Item = Item> = {
  className?: string;
  order: ReadonlyArray<T>;
  onExit: VoidFunction;
  onUpdate: (newOrder: ReadonlyArray<T>) => void;
  onSave: VoidFunction;
  onReset: VoidFunction;
  canReset: boolean;
};

export const FilterReorderView = <T extends Item>({
  className,
  order,
  onUpdate,
  onExit,
  onSave,
  onReset,
  canReset
}: FilterReorderViewProps<T>) => {
  const [str] = useLocalisation();

  const onDragEnd = useCallback(
    (e: DragEndEvent) => {
      const { active, over } = e;
      if (order && over && active.id !== over.id) {
        const oldFilter = order.find(item => item.id === active.id);
        const newFilter = order.find(item => item.id === over.id);
        const updatedFilters =
          oldFilter && newFilter ? arrayMove(order as T[], order.indexOf(oldFilter), order.indexOf(newFilter)) : order;

        onUpdate(updatedFilters);
      }
    },
    [order, onUpdate]
  );

  const SortableItem = ({ id, children }: { id: string; children: React.ReactNode }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

    return (
      <div ref={setNodeRef} style={{ transform: CSS.Transform.toString(transform), transition }}>
        <ReorderButton {...attributes} {...listeners}>
          {children}
          <Reorder />
        </ReorderButton>
      </div>
    );
  };

  return (
    <Body className={className}>
      <Header>
        <h4>{str('ProductListing.filters.rearrangeOrder')}</h4>
        <ResetButton onClick={onReset} disabled={!canReset}>
          <span>{str('ProductListing.filters.reset')}</span>
        </ResetButton>
      </Header>
      <Content>
        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
          <SortableContext items={order.map(item => item.id)} strategy={verticalListSortingStrategy}>
            {order.map(item => (
              <SortableItem id={item.id} key={item.id}>
                <span>{toCase[ToCase.Sentence](item.label)}</span>
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </Content>
      <Footer>
        <span>{str('ProductListing.filters.rearrangeOrderHint')}</span>
        <div>
          <ExitButton onClick={onExit}>{str('ProductListing.filters.exit')}</ExitButton>
          <SaveButton onClick={onSave}>{str('ProductListing.filters.save')}</SaveButton>
        </div>
      </Footer>
    </Body>
  );
};
