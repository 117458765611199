import { findById } from 'utils';

import { type AnyFilter, Defect, FilterType } from '@yourxx/support';

import { filtersSchema } from '../filtersSchema';

export const defaultStatuses = ['ADD', 'PENDING'] as const;

export const setupStatusFilterWithAddAndPending = (filter: AnyFilter) => {
  if (filter.type === FilterType.PriceRange) throw new Defect(`Status filter should not be of type ${filter.type}`);
  return filter.type === FilterType.Single ? filter.set(defaultStatuses[0]) : filter.set(defaultStatuses);
};

export const getDefaultProductsStatusFilter = () => {
  const filter = findById('status')(filtersSchema);
  if (!filter) throw new Defect('Status filter not found in schema.');
  return setupStatusFilterWithAddAndPending(filter);
};

export const getDefaultProductFilters = () => [getDefaultProductsStatusFilter()];
