import type { Nullable } from '@yourxx/support';

export class Task<T, C = void> {
  static of<P, D = void>(fn: () => Promise<P>, context?: D) {
    return new Task<P, D>(fn, context);
  }

  get isRunning() {
    return this._result instanceof Promise;
  }

  get result(): Nullable<T | FailedTaskError> {
    return this._result instanceof Promise ? null : this._result;
  }

  get startedAt(): Nullable<Date> {
    return this._startedAt;
  }

  get completedAt(): Nullable<Date> {
    return this._completedAt;
  }

  get duration(): Nullable<number> {
    if (!this._startedAt || !this._completedAt) return null;
    return this._completedAt.getTime() - this._startedAt.getTime();
  }

  run() {
    this._startedAt = new Date();
    this._result = this.taskFn()
      .then(result => {
        this._result = result;
        return result;
      })
      .catch(reason => {
        this._result = new FailedTaskError(reason);
        throw reason;
      })
      .finally(() => {
        this._completedAt = new Date();
      });

    return this._result;
  }

  private _startedAt: Nullable<Date> = null;
  private _completedAt: Nullable<Date> = null;
  private _result: Nullable<Promise<T> | T | FailedTaskError> = null;

  private constructor(
    private readonly taskFn: () => Promise<T>,
    public readonly context?: C,
    public readonly createdAt = new Date()
  ) {}
}

export class FailedTaskError extends Error {
  constructor(public readonly reason: any) {
    super();
  }
}
