import { PlayFill, Plus } from 'assets/icons';
import { Button } from 'components/Button';
import { IAM } from 'components/IAM';
import { ProductDetailsContext } from 'components/ProductDetails';
import { State } from 'pages/Products';
import { useLocalisation } from 'providers';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${themed('color.white')};

  video {
    position: relative;
    top: 0;
    left: 0;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    background-color: ${themed('color.white')};

    &::-webkit-media-controls-panel {
      background-image: none !important;
      filter: brightness(0.4);
    }

    &::-webkit-media-controls-fullscreen-button {
      display: none;
    }
  }
`;

const PlayButton = styled.button<{ $isVisible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 0;
  border-radius: ${themed('borderRadius')};
  padding: 0;
  width: ${rem(40)};
  height: ${rem(32)};
  background-color: ${themed('color.black')};
  transform: translate3d(-50%, -50%, 0);
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  will-change: opacity;
  transition-property: opacity;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};
  cursor: pointer;

  svg path {
    fill: ${themed('color.white')};
  }
`;

const AddVideoPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${themed('color.greyMid')};

  button {
    min-height: ${rem(36)};
    border-radius: ${themed('borderRadius')};
    background-color: transparent;
    color: ${themed('color.black')};
    ${themed('typography.h4')};

    @media (hover: hover) {
      &:hover {
        background-color: ${themed('color.black')};
        color: ${themed('color.white')};

        svg path {
          stroke: ${themed('color.white')};
        }
      }
    }
  }
`;

// TODO: Move to components when it's ready (refactored) to be reused.
export const Video = ({
  src,
  posterSrc,
  'data-testId': testId,
  onPlay,
  onPause,
  onEnd
}: {
  src: string;
  posterSrc?: string;
  'data-testId'?: string;
  onPlay?: VoidFunction;
  onPause?: VoidFunction;
  onEnd?: VoidFunction;
}) => {
  const [str] = useLocalisation();
  // TODO: Refactor with custom hook
  const state = useContext(ProductDetailsContext);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = useCallback(() => {
    if (videoRef.current?.paused) videoRef.current?.play();
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    const playCallback = () => {
      onPlay?.();
      setIsPlaying(true);
    };
    const pauseCallback = () => {
      onPause?.();
      setIsPlaying(false);
    };
    const endCallback = () => {
      if (video) {
        onEnd?.();
        video.currentTime = 0;
      }
    };
    video?.addEventListener('play', playCallback);
    video?.addEventListener('pause', pauseCallback);
    video?.addEventListener('ended', endCallback);

    return () => {
      video?.removeEventListener('play', playCallback);
      video?.removeEventListener('pause', pauseCallback);
      video?.removeEventListener('ended', endCallback);
    };
  }, []);

  return (
    <VideoContainer data-testid={testId}>
      {src ? (
        <>
          <video
            ref={videoRef}
            autoPlay={false}
            controls
            muted
            color="white"
            poster={posterSrc}
            controlsList="nodownload nofullscreen"
            disablePictureInPicture
          >
            <source src={src} />
          </video>
          <PlayButton $isVisible={!isPlaying} onClick={togglePlay}>
            <PlayFill />
          </PlayButton>
        </>
      ) : (
        <IAM action={`edit.video`}>
          <AddVideoPanel>
            <Button
              variant="secondary"
              onClick={() => {
                if (state?.state === State.Empty) state.addVideo();
              }}
            >
              <Plus />
              {str('PDP.labels.addVideo')}
            </Button>
          </AddVideoPanel>
        </IAM>
      )}
    </VideoContainer>
  );
};
