import { Copy, Paste } from 'assets/icons';
import { ShowAsyncErrorAndGoBack, SlotId, useAddBreadcrumb, ViewTransition } from 'pages';
import { OrderSizing as IOrderSizing, useOrders, useSlot } from 'providers';
import { Suspense, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { Await } from 'react-router-dom';
import { styled } from 'styled-components';
import { relativePath, rem, themed } from 'utils';

import { Defect } from '@yourxx/support';

import { useAddOrderToolbarControl } from '../OrderLayout';
import { OrderSizingSkeleton } from './OrderSizingSkeleton';

export const OrderSizing = ({ orderId, pc9 }: { orderId: string; pc9: string }) => {
  useAddBreadcrumb('Sizing', 3, relativePath('..'));
  const { loadOrderSizing } = useOrders();

  return (
    <Suspense fallback={<ViewTransition children={<OrderSizingSkeleton />} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={loadOrderSizing(orderId, pc9)}
        children={data => <ViewTransition children={<View data={data} />} />}
        // children={_data => <ViewTransition children={<OrderSizingSkeleton />} />}
      />
    </Suspense>
  );
};

const ProductName = styled.h2`
  margin: 0;
  ${themed('typography.h1')};
  font-size: ${rem(22)}; // Outside of theme
`;

const View = ({ data: { customerId, orderId, pc9, sizing } }: { data: IOrderSizing }) => {
  useSlot(
    SlotId.OrderMenu,
    useMemo(() => <ProductName>Product name for {pc9}</ProductName>, [pc9])
  );

  const { showBoundary } = useErrorBoundary();
  useAddOrderToolbarControl(
    useMemo(
      () => ({
        icon: <Copy />,
        label: 'Copy to (fails)',
        onClick: () => {
          showBoundary(new Defect("Testing the application's error boundary setup."));
        }
      }),
      [showBoundary]
    ),
    0
  );

  const [count, setCount] = useState(0);
  useAddOrderToolbarControl(
    useMemo(
      () => ({
        icon: <Paste />,
        label: 'Paste from',
        badgeContent: count > 0 ? String(count) : undefined,
        onClick: () => setCount(p => ++p)
      }),
      [count]
    ),
    1
  );

  useSlot(
    SlotId.FooterLeft,
    useMemo(() => <>Units 2000 of 10000</>, [])
  );

  return (
    <>
      [Order Sizing [{customerId}] [{orderId}] [{pc9}]]
      <div>{sizing}</div>
    </>
  );
};
