import { Delete, Edit, Info, Plus } from 'assets/icons';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { Dropdown } from 'components/Dropdown';
import { FeatureFlag } from 'components/FeatureFlag';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { type AnyFilter, FilterCombination, FilterType, type ProductFilterId } from 'services';
import type { FilterOption, FilterOptions } from 'services/ProductFiltersService/buildFilterOptions';
import styled from 'styled-components';
import { filterIdToRenderedName, rem, themed } from 'utils';

import { Defect, type Nullable } from '@yourxx/support';
import { FeatureFlags } from '@yourxx/types';

import { ChartFilter } from './ChartFilter';
import { ColourFilter } from './ColourFilter';
import { DefaultFilter } from './DefaultFilter';
import { FilterWidget } from './FilterWidget';

const FilterBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${themed('spacing.l')};
  animation-duration: ${themed('transition.duration')};
  animation-timing-function: ${themed('transition.timing')};
  animation-name: ${themed('animation.slideDown')};
`;
const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.s')};
  flex: 0;
  padding: ${rem(14)} 0 0 0;
  z-index: 3;

  > div {
    display: flex;
    align-items: center;
    flex-grow: 1;
    background: ${themed('color.white')};
    border-radius: ${themed('borderRadius')};
    min-height: ${rem(30)};

    > button {
      width: 100%;
    }
  }
`;
const FilterContainer = styled.div`
  margin: 0 -${themed('spacing.l')};
  flex-grow: 1;
`;
const ScrollableArea = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
`;
const AllStateTags = styled.div`
  padding: ${rem(13)} 0 ${themed('spacing.m')};
  display: flex;
  flex: 0;
  justify-content: space-between;
  text-transform: uppercase;

  span {
    opacity: 1;
    pointer-events: auto;
    cursor: pointer;
  }
`;
const DropdownTrigger = styled.div`
  flex-grow: 1;
  text-transform: none;
`;
const SelectItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  span {
    display: block;
    flex-grow: 1;
  }
  div {
    display: flex;
  }
`;
const WarningIcon = styled(Info)`
  width: ${themed('spacing.xl')};
  height: ${themed('spacing.xl')};
  animation: ${themed('animation.zoomOut')};
  animation-timing-function: ${themed('transition.timing')};
  animation-duration: ${themed('transition.duration')};

  path {
    stroke: ${themed('color.red')};
  }
`;
const Notice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${themed('spacing.m')};
  padding: ${themed('spacing.l')} ${themed('spacing.m')};
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.regular')};
  text-align: center;

  p {
    margin: 0;
  }
  button {
    ${themed('typography.h4')};
  }
`;
const ToggleExpandButton = styled(Button)`
  margin: 0;
  padding: ${themed('spacing.m')} ${themed('spacing.s')};
  ${themed('typography.h4')};
`;
const ClearAllButton = styled(Button)`
  margin: 0;
  padding: ${themed('spacing.m')} ${themed('spacing.s')};
  ${themed('typography.h4')};
`;

export type FilterViewProps = {
  className?: string;
  currency?: string;
  filterOptions: FilterOptions;
  filterCombinations: ReadonlyArray<FilterCombination>;
  selectedCombination: Nullable<FilterCombination>;
  activeCombination: FilterCombination;
  changeActiveCombination: (item: FilterCombination) => void;
  onFilterSet: (filter: AnyFilter) => void;
  onSave: VoidFunction;
  onDelete: (item: FilterCombination) => void;
  onUpdate: (item: FilterCombination) => void;
  canClear(filterId: string): boolean;
  clear(filterId: string): void;
  canClearAll: boolean;
  onClearAll: VoidFunction;
  allowFilterCombinations: boolean;
  loadingFilters?: ReadonlyArray<ProductFilterId>;
  onFilterExpanded?: (filter: AnyFilter) => void;
};

const PRICE_TYPE_OPTIONS = [
  { value: 'wsp', label: 'WSP' },
  { value: 'rrp', label: 'RRP' }
] as const;

export const FilterView = ({
  className,
  currency,
  filterCombinations,
  filterOptions,
  onFilterSet,
  onSave,
  onDelete,
  onUpdate,
  selectedCombination,
  activeCombination,
  changeActiveCombination,
  canClear,
  clear,
  canClearAll,
  onClearAll,
  allowFilterCombinations,
  loadingFilters,
  onFilterExpanded
}: FilterViewProps) => {
  const [expanded, setExpanded] = useState<Map<string, boolean>>(new Map());
  const anyExpanded = useMemo(() => Array.from(expanded.values()).some(Boolean), [expanded]);
  const toggleExpandAll = useCallback(() => {
    setExpanded(state => {
      const someExpanded = Array.from(state.values()).some(Boolean);
      if (!someExpanded) filterOptions.forEach(({ filter }) => onFilterExpanded?.(filter));
      return new Map(filterOptions.map(entry => [entry.filter.id, !someExpanded]));
    });
  }, [filterOptions, onFilterExpanded]);

  useEffect(() => {
    // If the user already made some expansions, don't do anything.
    // This should only run once on mount.
    if (expanded.size) return;

    const expandedByDefaultDueToHavingAValue = new Map<string, boolean>();
    for (const filter of activeCombination.filters)
      if (canClear(filter.id)) expandedByDefaultDueToHavingAValue.set(filter.id, true);

    if (expandedByDefaultDueToHavingAValue.size) setExpanded(expandedByDefaultDueToHavingAValue);
  }, [activeCombination.filters, canClear, expanded.size]);

  return (
    <FilterBody className={className}>
      <DataContainer>
        {allowFilterCombinations && (
          <FilterHeader>
            <Dropdown
              trigger={<DropdownTrigger>{selectedCombination?.name ?? activeCombination.name}</DropdownTrigger>}
              placeHolder="Filter Combination"
              items={filterCombinations.map(fc => ({
                key: fc.id,
                label: (
                  <SelectItemContainer>
                    <span>{fc.name}</span>
                    <div>
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          onUpdate(fc);
                        }}
                      >
                        <Edit />
                      </Button>
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          onDelete(fc);
                        }}
                      >
                        <Delete />
                      </Button>
                    </div>
                  </SelectItemContainer>
                )
              }))}
              onSelect={id => {
                const maybeSelected = filterCombinations.find(item => item.id === id);
                if (!maybeSelected) throw new Defect('Selected FilterCombination not found in props.');
                changeActiveCombination(maybeSelected);
              }}
            />
            <FeatureFlag flags={[FeatureFlags.Filters_SaveFilterCombinations]}>
              <Plus onClick={onSave} />
            </FeatureFlag>
          </FilterHeader>
        )}
        <AllStateTags>
          <ToggleExpandButton onClick={toggleExpandAll}>
            {anyExpanded ? 'Collapse all' : 'Expand all'}
          </ToggleExpandButton>
          <ClearAllButton disabled={!canClearAll} onClick={onClearAll}>
            Clear all
          </ClearAllButton>
        </AllStateTags>
      </DataContainer>
      <FilterContainer>
        <ScrollableArea>
          {filterOptions.map(({ filter: emptyFilter, options }) => {
            const filter = activeCombination.filters.find(filter => filter.id === emptyFilter.id) ?? emptyFilter;
            if (!options.length && !filter.value) return null;

            let filterContent: JSX.Element = <></>;

            if (filter.type === FilterType.Single) {
              const Component = filter.id === 'color' ? ColourFilter : DefaultFilter;
              filterContent = (
                <Component
                  options={options}
                  value={options.find(option => option.value === filter.value) ?? null}
                  onChange={option => onFilterSet(filter.set(option.value))}
                />
              );
            } else if (filter.type === FilterType.Multi) {
              filterContent = (
                <DefaultFilter
                  value={
                    filter.value?.map(value => {
                      const availableOption = options.find(o => o.value === value);
                      if (availableOption) return availableOption;
                      else
                        return {
                          label: String(value),
                          value,
                          count: 0,
                          percentOfTotal: 0
                        };
                    }) ?? null
                  }
                  options={options}
                  onChange={option => onFilterSet(filter.set([option.value]))}
                />
              );
            } else if (filter.type === FilterType.PriceRange && options.length) {
              filterContent = (
                <ChartFilter
                  currency={currency}
                  typeOptions={PRICE_TYPE_OPTIONS}
                  priceOptions={options as ReadonlyArray<FilterOption<number>>}
                  type={filter.value?.type}
                  range={filter.value?.range}
                  onTypeChange={type => onFilterSet(filter.set({ type }))}
                  onRangeChange={range => onFilterSet(filter.set({ type: filter.value?.type ?? 'wsp', range }))}
                />
              );
            }

            const hasNoOptionsButHasFilterValue = !options.length && filter.value;
            if (hasNoOptionsButHasFilterValue)
              filterContent = (
                <Notice>
                  <WarningIcon />
                  <p>
                    No available data for this filter. However, a value has previously been set which yields no results.
                    Consider clearing it or switching to a different combination.
                  </p>
                  <Button variant="primary" onClick={() => onFilterSet(filter.clear())}>
                    Clear
                  </Button>
                </Notice>
              );

            return (
              <FilterWidget
                key={filter.id}
                isLoading={loadingFilters?.includes(filter.id)}
                isExpanded={expanded.get(filter.id) ?? false}
                onExpandToggle={() => {
                  const isCurrentlyExpanded = expanded.get(filter.id) ?? false;
                  const newIsExpanded = !isCurrentlyExpanded;
                  setExpanded(new Map(expanded.set(filter.id, newIsExpanded)));
                  if (newIsExpanded) onFilterExpanded?.(filter);
                }}
                label={
                  <>
                    {filter.type === FilterType.Multi && (
                      <Checkbox
                        checked={options.every(option => filter.value?.includes(option.value))}
                        onChange={isChecked => {
                          const newFilter = filter.clear();
                          if (!isChecked) onFilterSet(newFilter);
                          else {
                            const allValues = options.map(option => option.value);
                            onFilterSet(newFilter.set(allValues));
                          }
                        }}
                      />
                    )}
                    {hasNoOptionsButHasFilterValue && <WarningIcon />}
                    <LocalisedFilterName filterId={filter.id} />
                  </>
                }
                canClear={canClear(filter.id)}
                onClear={() => clear(filter.id)}
              >
                {filterContent}
              </FilterWidget>
            );
          })}
        </ScrollableArea>
      </FilterContainer>
    </FilterBody>
  );
};

const LocalisedFilterName = ({ filterId }: { filterId: string }) => {
  const [str] = useLocalisation();
  return <span>{filterIdToRenderedName(str, filterId)}</span>;
};
