import { Defect } from '@yourxx/support';

import { type FiltersSchema, FilterType } from '../index';

export const priceFilterTypeOrDefault = (filters: FiltersSchema, defaultType: 'wsp' | 'rrp') => {
  const filter = filters.find(filter => filter.id === 'price');

  if (!filter) return defaultType;

  if (filter.type !== FilterType.PriceRange)
    throw new Defect(
      'Expected FilterType.PriceRange: there should only be one filter with id "price" and the type must be FilterType.PriceRange.'
    );

  return filter.value?.type ?? defaultType;
};
