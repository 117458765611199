import { Dialog, LoadingSpinner } from 'components';
import { useLocalisation } from 'providers';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import type { Order } from '.';

const Content = styled.div`
  width: 100%;

  p {
    margin-top: 0;
    font-weight: ${themed('font.weight.medium')};
  }
  ul {
    list-style-type: none;
    max-height: ${rem(300)};
    overflow: auto;
    padding: ${themed('spacing.s')};
    margin: 0;
  }
  li {
    padding: ${themed('spacing.xs')};
    font-size: ${themed('font.size.s')};
  }
`;

export const OrderDeleteModal = ({
  orders,
  onClose,
  onConfirm,
  isLoading
}: {
  orders: Order[];
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  isLoading?: boolean;
}) => {
  const [str] = useLocalisation();

  return (
    <Dialog
      title={str('Order.modal.delete.title')}
      cancel={{ label: str('general.cancel') }}
      confirm={{
        label: isLoading ? <LoadingSpinner /> : str('Order.modal.delete.label.button', { count: orders.length }),
        handler: onConfirm,
        disabled: isLoading
      }}
      onClose={onClose}
      content={
        <Content>
          <p>{str('Order.modal.delete.label.info', { count: orders.length })}</p>
          <ul>
            {orders.map(o => (
              <li key={o.orderId}>{o.orderName}</li>
            ))}
          </ul>
        </Content>
      }
    />
  );
};
