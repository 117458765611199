import { Plus, Sort } from 'assets/icons';
import { Button, Image, Tab } from 'components';
import { compareAsc, compareDesc, format, isAfter, isBefore, startOfDay } from 'date-fns';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type { AuditTrailEvent } from 'services';
import { pluralise } from 'utils';

import type { Nullable } from '@yourxx/support';
import { Event } from '@yourxx/types/src/event-tracker';

import {
  Body,
  Card,
  Contents,
  GroupDate,
  RefreshButton,
  StyledLoader,
  StyledSlideInOut,
  StyledTabs,
  Toolbar
} from './styling';
import type { RemovedProduct } from './types';

interface AuditTrailPanelProps {
  currentUserId: string;
  isLoading: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
  events: Nullable<ReadonlyArray<AuditTrailEvent>>;
  onRefresh: VoidFunction;
  onViewingRemovedProducts: VoidFunction;
  removed: ReadonlyArray<RemovedProduct>;
}

const imageSize = '&width=256&height=256';
const dateFormat = 'dd MMM yyyy';

export const AuditTrailPanel = ({
  isLoading,
  isOpen,
  onClose,
  events,
  onRefresh,
  currentUserId,
  onViewingRemovedProducts,
  removed
}: AuditTrailPanelProps) => {
  const [str] = useLocalisation();
  const [selectedTab, setSelectedTab] = useState<'activity' | 'removed'>('activity');
  const [sortDir, setSortDir] = useState<'asc' | 'desc'>('desc');
  const [_, setRerenderTimestamp] = useState(new Date());
  const forceRerender = () => setRerenderTimestamp(new Date());

  useEffect(() => {
    if (selectedTab === 'removed') onViewingRemovedProducts();
  }, [onViewingRemovedProducts, selectedTab]);

  const getLogTitle = useCallback(
    (event: AuditTrailEvent, pc9s?: string[]) => {
      const eventTriggerByCurrentUser = event.data.created_by === currentUserId;
      const prefix = eventTriggerByCurrentUser ? 'You have' : `${event.data.first_name} ${event.data.last_name} has`;

      switch (event.data.name) {
        case Event.CREATE_ASSORTMENT:
          return `${prefix} created the assortment`;
        case Event.ADD_PC9S:
          return pc9s ? `${prefix} added ${countPC9s(pc9s)}` : '';
        case Event.REMOVE_PC9S:
          return pc9s ? `${prefix} removed ${countPC9s(pc9s)}` : '';
        case Event.DROP_PC9S:
          return pc9s ? `${prefix} dropped ${countPC9s(pc9s)}` : '';
        case Event.PUBLISH_ASSORTMENT:
          return `${prefix} published the assortment`;
        case Event.ARCHIVE_ASSORTMENT:
          return `${prefix} archived the assortment`;
        case Event.UNPUBLISH_ASSORTMENT:
          return `${prefix} unpublished the assortment`;
        default:
          return `Event type: ${event.data.name}`;
      }
    },
    [currentUserId]
  );

  const groupDateCompare = useCallback(
    (a: string, b: string) => {
      if (sortDir === 'asc') {
        return isBefore(startOfDay(a), startOfDay(b));
      } else {
        return isAfter(startOfDay(a), startOfDay(b));
      }
    },
    [sortDir]
  );

  const sortedEvents = useMemo(
    () =>
      [...(events ?? [])]?.sort((a, b) =>
        sortDir === 'asc'
          ? compareAsc(a.data.created_at, b.data.created_at)
          : compareDesc(a.data.created_at, b.data.created_at)
      ),
    [events, sortDir, compareAsc, compareDesc]
  );
  const removedProducts = useMemo(
    () => [...removed].sort((a, b) => (sortDir === 'asc' ? compareAsc(a.date, b.date) : compareDesc(a.date, b.date))),
    [removed, sortDir, compareAsc, compareDesc]
  );

  return (
    <StyledSlideInOut
      heading={
        <>
          {str('AssortmentFinalization.auditTrail.title')}
          {isLoading && <StyledLoader />}
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      width={350}
      headerControls={<RefreshButton onClick={onRefresh} />}
    >
      <Body>
        <Toolbar>
          <StyledTabs>
            <Tab
              value="activity"
              onClick={() => setSelectedTab('activity')}
              isSelected={selectedTab === 'activity'}
              boldSelectedOnly
              borderWidth={2}
            >
              {str('AssortmentFinalization.auditTrail.tabs.activity')}
            </Tab>
            <Tab
              value="removed"
              onClick={() => setSelectedTab('removed')}
              isSelected={selectedTab === 'removed'}
              boldSelectedOnly
              borderWidth={2}
            >
              {str('AssortmentFinalization.auditTrail.tabs.removedProducts')}
            </Tab>
          </StyledTabs>
          <Button onClick={() => setSortDir(prev => (prev === 'asc' ? 'desc' : 'asc'))}>
            <Sort />
          </Button>
        </Toolbar>
        <Contents>
          {selectedTab === 'activity' && (
            <>
              {!events?.length && !isLoading && <p>{str('AssortmentFinalization.auditTrail.noActivity')}</p>}
              <Virtuoso
                data={sortedEvents}
                itemContent={(i, event) => (
                  <Card
                    onMouseEnter={() => {
                      event.markAsSeen();
                      setTimeout(forceRerender, 0);
                    }}
                    key={event.data.event_id}
                    $isNew={!event.hasBeenSeen}
                    $display="block"
                    $hasGroupDate={
                      i === 0 ||
                      (sortedEvents[i - 1] &&
                        groupDateCompare(sortedEvents[i - 1].data.created_at, event.data.created_at))
                    }
                  >
                    {i === 0 && (
                      <GroupDate>
                        <span>{formatWithTimezone(event.data.created_at, dateFormat)}</span>
                        {!events?.[0].hasBeenSeen && <span>{str('AssortmentFinalization.auditTrail.sinceLast')}</span>}
                      </GroupDate>
                    )}
                    {sortedEvents[i - 1] &&
                      groupDateCompare(sortedEvents[i - 1].data.created_at, event.data.created_at) && (
                        <GroupDate>
                          <span>{formatWithTimezone(event.data.created_at, dateFormat)}</span>
                        </GroupDate>
                      )}
                    <span>{formatWithTimezone(event.data.created_at, 'hh:mm a')}</span>
                    <h4>{getLogTitle(event, event.data.payload.pc9s)}</h4>
                    {/*{a.assortmentTitle && <span>&ldquo;{event.assortmentTitle}&rdquo;</span>}*/}
                    {event.data.payload.pc9s && (
                      <div>
                        {event.data.payload.pc9s.map((pc9, i, sortedEvents) => (
                          <span key={`${event.data.name}-${i}`}>
                            {i === 0 && <span>&ldquo;</span>}
                            {pc9}
                            {i < sortedEvents.length - 2 && ', '}
                            {i === sortedEvents.length - 2 && <span> &amp; </span>}
                            {i === sortedEvents.length - 1 && <span>&rdquo;</span>}
                          </span>
                        ))}
                      </div>
                    )}
                  </Card>
                )}
              />
            </>
          )}
          {selectedTab === 'removed' && (
            <>
              {!removed && <p>{str('AssortmentFinalization.auditTrail.noProducts')}</p>}
              <Virtuoso
                data={removedProducts}
                itemContent={(i, product) => (
                  <Card
                    key={product.id}
                    $display="flex"
                    $hasGroupDate={
                      i === 0 || (removedProducts[i - 1] && groupDateCompare(removedProducts[i - 1].date, product.date))
                    }
                    $isGreyedOut={product.status === 'DROP'}
                  >
                    {i === 0 && (
                      <GroupDate>
                        <span>{format(product.date, dateFormat)}</span>
                      </GroupDate>
                    )}
                    {removedProducts[i - 1] && groupDateCompare(removedProducts[i - 1].date, product.date) && (
                      <GroupDate>
                        <span>{format(product.date, dateFormat)}</span>
                      </GroupDate>
                    )}
                    <Image src={`${product.image}${imageSize}`} />
                    <div>
                      <span>{product.pc9}</span>
                      <h4>{product.title}</h4>
                      {product.reason && (
                        <p>
                          <span>{str('AssortmentFinalization.auditTrail.reason')}: </span>
                          {product.reason}
                        </p>
                      )}
                    </div>
                    {product.status !== 'DROP' && (
                      <Button onClick={product.addBack}>
                        <Plus />
                        {str('AssortmentFinalization.auditTrail.addBack')}
                      </Button>
                    )}
                    {product.status === 'DROP' && (
                      <Button onClick={() => undefined}>{str('AssortmentFinalization.auditTrail.dropped')}</Button>
                    )}
                  </Card>
                )}
              />
            </>
          )}
        </Contents>
      </Body>
    </StyledSlideInOut>
  );
};

const countPC9s = (pc9s: string[]) => `${pc9s.length} ${pluralise(pc9s.length, 'PC9', 'PC9s')}`;

const formatWithTimezone = (timestamp: string, formatting: string) => {
  const date = new Date(timestamp);
  return format(date.valueOf() - date.getTimezoneOffset() * 60 * 1000, formatting);
};
