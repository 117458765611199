import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { IAM } from 'components/IAM';
import { useLocalisation } from 'providers';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserProfile, logout, persistedStore } from '@yourxx/ui-utils';

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.l')};
  position: relative;
  width: ${rem(600)};
  max-width: 100%;
  box-sizing: border-box;
`;
const ProfileBody = styled.div`
  flex: 1;

  p {
    font-size: ${themed('font.size.l')};
    font-weight: ${themed('font.weight.bold')};
    text-transform: uppercase;
  }

  span {
    display: block;
    text-transform: uppercase;
    margin-bottom: ${themed('spacing.m')};
  }
`;
const VersionInfo = styled.span`
  position: absolute;
  right: 0;
  bottom: -${themed('spacing.l')};
  color: ${themed('color.grey')};
  font-weight: ${themed('font.weight.bold')};
  font-style: italic;
  letter-spacing: ${rem(-0.1)};
`;

export const ProfileModal = ({ onClose }: { onClose: VoidFunction }) => {
  const [str] = useLocalisation();
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      if (persistedStore.getUserProfile()) {
        return persistedStore.getUserProfile();
      }
      return getUserProfile();
    }
  });

  const mutation = useMutation({ mutationFn: async () => logout({ logout_reason: str('Logout.userInitiated') }) });
  const refreshUser = useMutation({
    mutationFn: async () => {
      persistedStore.removeUserProfile();
      await queryClient.invalidateQueries({ queryKey: ['userProfile'] });
    }
  });

  const appVersionNumber = import.meta.env.VITE_VERSION || '0.0.0';

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {data && (
        <>
          <Dialog
            onClose={onClose}
            title={str('Admin.customers.forms.profileTab')}
            content={
              <Content>
                <Avatar size={100} />

                <ProfileBody>
                  <p>
                    {data.firstName && data.firstName} {data.lastName && data.lastName}
                  </p>
                  {data.email && <span>{data.email}</span>}
                  {data.role && <span>{data.role}</span>}
                  <IAM action={'refreshPermissions'} prefix={'profile'}>
                    <span>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          persistedStore.removeUserProfile();
                          refreshUser.mutate();
                        }}
                      >
                        {refreshUser.isPending ? 'Refreshing...' : 'Refresh Permissions'}
                      </Button>
                    </span>
                  </IAM>
                </ProfileBody>
                {appVersionNumber && <VersionInfo>{appVersionNumber}</VersionInfo>}
              </Content>
            }
            confirm={{
              disabled: !mutation.isIdle,
              label: str('general.logout'),
              handler: async () => {
                mutation.mutate();
              }
            }}
          />
        </>
      )}
    </>
  );
};
