import { ChevronDown } from 'assets/icons';
import { FlexWrapper, LoadingSpinner, Select, SelectOption, useContextfulSearch } from 'components';
import { format } from 'date-fns';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TreeView from 'react-accessible-treeview';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import styled from 'styled-components';
import { relativePath, rem, themed, toSeasonLabel, usePrevious } from 'utils';

import { searchLines, toReversed } from '@yourxx/support';
import { FeatureFlags, LinesApiResponse } from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

import { useGetLines, useLanding } from '../hooks';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding: 0 ${themed('spacing.xl')};
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
    background-color: ${themed('color.offWhite')};
  }
`;
const StyledFlexWrapper = styled(FlexWrapper)`
  background: ${themed('color.offWhite')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding: 0 ${themed('spacing.xl')};
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
  }
`;
const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.m')};
  padding: 0 0 ${themed('spacing.m')};

  > div {
    margin: 0;
  }
`;
const TreeWrapper = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  margin-top: ${rem(68)};
  padding: 0 0 ${themed('spacing.xxl')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding-left: ${themed('spacing.xl')};
    padding-right: ${themed('spacing.xl')};
  }

  ul {
    list-style-type: none;
    margin: 0;
  }
`;
const TreeItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  border-bottom: solid ${rem(1)} ${themed('color.greyMid')};
  padding: ${themed('spacing.l')} 0;
  will-change: background-color;
  transition: background-color 0.2s ease-out;

  &.tree-node__branch {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        background-color: ${themed('color.greyLight')};
      }
    }
  }
  p {
    font-size: ${themed('font.size.l')};
    font-weight: ${themed('font.weight.bold')};
    margin: 0;
    text-transform: uppercase;
  }
  svg {
    will-change: transform;
    transition: transform 0.2s ease-out;
    margin-left: -${themed('spacing.l')};
  }
  &.tree-node--expanded svg {
    transform: rotate(180deg);
  }
`;

const StyledLink = styled(Link)<{ $isGreyedOut?: boolean }>`
  color: ${({ $isGreyedOut }) => themed(`color.${$isGreyedOut ? 'grey' : 'black'}`)};
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const LineName = styled.h3<{ $isGreyedOut?: boolean }>`
  margin: 0;
  padding: 0;
  ${themed('typography.h2')};
  font-weight: ${themed('font.weight.bold')};
  color: ${({ $isGreyedOut }) => themed(`color.${$isGreyedOut ? 'grey' : 'black'}`)};
  text-transform: uppercase;
`;

const ItemStat = styled.div`
  color: ${themed('color.greyDark')};
  ${themed('typography.h4')};

  span {
    ${themed('typography.h4')};
  }
`;
const InlineFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  flex: 1;
`;

export const Lines = ({ brand, season: maybeSeason }: { brand: string; season: string }) => {
  const [str] = useLocalisation();
  const navigate = useNavigate();
  const { linesData } = useLanding();
  const { getLines } = useGetLines(brand);
  const [isLoading, setIsLoading] = useState<boolean>(!linesData);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const season = maybeSeason === 'season' ? undefined : maybeSeason;

  const goToSeason = useCallback(
    (season: string, replace = false) => navigate(relativePath(`../${season}`), { replace }),
    [navigate]
  );

  /**
   * Rules:
   * 1) When no season in URL go to latest
   * 2) If season in URL does not exist, go to latest and load the data
   * 3) If season in URL exists, load that season's data
   */

  const latestSeason = useMemo(() => linesData?.seasons?.[linesData.seasons.length - 1], [linesData?.seasons]);

  // 1)
  useEffect(() => {
    if (!season && latestSeason) goToSeason(latestSeason.name.toLowerCase(), true);
  }, [latestSeason, season, goToSeason]);

  // 2)
  // TODO:
  //   Do we actually need this? 404...

  const seasonOptions = useMemo(() => {
    if (!linesData?.seasons) return [];

    return toReversed(linesData.seasons).map(s => ({
      value: s.name.toLowerCase(),
      label: toSeasonLabel(s.name)
    }));
  }, [linesData?.seasons]);

  // Season from the URL
  const selectedSeason = useMemo<SelectOption | undefined>(() => {
    if (season) return { label: toSeasonLabel(season.toUpperCase()), value: season };
  }, [season]);

  // Load the data for the selected season
  const hasChangedSeason = usePrevious(selectedSeason) !== selectedSeason;
  useEffect(() => {
    if (!linesData || hasChangedSeason) {
      getLines({
        season: selectedSeason?.value.toUpperCase(),
        handleLoading: () => setIsLoading(true),
        handleSuccess: () => setIsLoading(false),
        handleError: () => {
          setErrorMessage(str('Landing.lines.retrievingError'));
          setIsLoading(false);
        }
      });
    }
  }, [selectedSeason, hasChangedSeason]);

  const lineIds = useMemo(() => linesData?.lines?.map?.(l => l.id) ?? [], [linesData?.lines]);
  const globalLine = useMemo(() => linesData?.lines?.find?.(l => l.parent === null), [linesData?.lines]);

  const { searchTerm, changeTerm, addSuggestions } = useContextfulSearch({ contextId: routes.lines.toString() });

  useEffect(() => {
    if (!searchTerm) return;
    const results = searchLines(searchTerm, linesData?.lines ?? []);

    if (results.length) {
      addSuggestions(
        results.slice(0, 10).map(result => ({
          label: result.item.name,
          onSelect: () => {
            changeTerm(result.item.name);
            navigate(result.item.id);
          }
        }))
      );
    }
  }, [addSuggestions, changeTerm, linesData?.lines, navigate, searchTerm]);

  if (isLoading) {
    return (
      <StyledFlexWrapper>
        <LoadingSpinner label={str('Landing.lines.loading')} />
      </StyledFlexWrapper>
    );
  }
  if (errorMessage) {
    return (
      <StyledFlexWrapper>
        <p>{errorMessage}</p>
      </StyledFlexWrapper>
    );
  }

  const userHasAdvancedAccess = canUse(FeatureFlags.LineTree_ViewAdvanced);
  const userCanViewGlobalLine = canUse(FeatureFlags.LineTree_ViewGlobalLine);
  const userCanViewArchived = canUse(FeatureFlags.LineTree_ViewArchived);

  const treeItem = (item: LinesApiResponse, nodeProps?: () => object) => {
    /**
     * It's easier to not render the item, as opposed to filtering the array
     * because we don't have to mess with parent-child relationship resolution,
     * and we avoid <TreeView /> freaking out.
     */
    if (!searchLines(searchTerm, [item]).length) return null;

    const isGlobal = !item.parent;
    const isPublished = Boolean(item.publishedAt);
    const isArchived = Boolean(item.archivedAt);

    if (isArchived && !userCanViewArchived) return null;

    const isPublishedAndNotArchived = isPublished && !isArchived;
    const isLineAvailable = userHasAdvancedAccess || isPublishedAndNotArchived;
    const hasProducts = +item.add! > 0 || +item.pending! > 0 || +item.remove! > 0 || +item.drop! > 0;
    const options = {
      showUrl: item.clickable && isLineAvailable && (isGlobal ? userCanViewGlobalLine : true),
      showCount: isLineAvailable && hasProducts,
      showStatus: !isPublishedAndNotArchived,
      showGreyedOut: !isPublishedAndNotArchived || !item.clickable
    };

    const lineName = <LineName $isGreyedOut={options.showGreyedOut}>{item.name}</LineName>;

    return (
      <TreeItem {...nodeProps?.()}>
        <InlineFlexWrapper>
          {item.children.length > 0 && item.parent !== null && <ChevronDown />}
          {options.showUrl ? (
            <StyledLink
              to={{
                pathname: `${item.id}/products/grid`,
                search: new URLSearchParams({ tempTitle: item.name }).toString()
              }}
              $isGreyedOut={options.showGreyedOut}
            >
              {lineName}
            </StyledLink>
          ) : (
            lineName
          )}
          <InlineFlexWrapper>
            {isArchived && <ItemStat>{str('general.archived')}</ItemStat>}
            {options.showCount && +item.add! > 0 && (
              <ItemStat>
                {str('Landing.lines.add')}: <span>{item.add}</span>
              </ItemStat>
            )}
            {options.showCount && +item.drop! > 0 && (
              <ItemStat>
                {str('Landing.lines.drop')}: <span>{item.drop}</span>
              </ItemStat>
            )}
            {options.showCount && +item.pending! > 0 && (
              <ItemStat>
                {str('Landing.lines.pending')}: <span>{item.pending}</span>
              </ItemStat>
            )}
            {options.showCount && +item.remove! > 0 && (
              <ItemStat>
                {str('Landing.lines.remove')}: <span>{item.remove}</span>
              </ItemStat>
            )}
            {!isPublished && <ItemStat>({str('general.notYetPublished')})</ItemStat>}
          </InlineFlexWrapper>
        </InlineFlexWrapper>
        {item.updatedAt && (
          <ItemStat>
            {str('Landing.lines.lastUpdate')}
            <span>&nbsp;{format(item.updatedAt as string, 'd MMM  yyyy, hh:mm')}</span>
          </ItemStat>
        )}
      </TreeItem>
    );
  };

  return (
    <Wrapper>
      <Toolbar>
        {!!linesData?.seasons?.length && (
          <Select
            options={seasonOptions}
            onChange={option => {
              const optionValue = option as SelectOption;
              goToSeason(optionValue.label.replace(/\s+/i, '').toLowerCase());
            }}
            value={selectedSeason}
            isSearchable={false}
            maxMenuHeight={300}
          />
        )}
        {!linesData?.lines?.length ? (
          <p>{str('Landing.lines.noneFound')}</p>
        ) : (
          <p>{str('Landing.lines.total', { count: linesData.lines.length })}</p>
        )}
      </Toolbar>
      {!!linesData?.lines?.length && (
        <TreeWrapper>
          {globalLine && treeItem(globalLine)}
          <TreeView
            data={linesData.lines}
            defaultExpandedIds={lineIds}
            nodeRenderer={({ element, getNodeProps }) => treeItem(element as LinesApiResponse, getNodeProps)}
          />
        </TreeWrapper>
      )}
    </Wrapper>
  );
};
