type Result<T, K> = K extends keyof T
  ? { key: K; values: { value: Required<T[K]>; count: number; percentOfTotal: number }[] }
  : never;

export const countSets = <T, K extends string>(
  items: ReadonlyArray<T>,
  schema: ReadonlyArray<K>,
  getter: (item: T, schemaKey: K) => T[keyof T] | undefined = (item, schemaKey) => item[schemaKey as unknown as keyof T]
) => {
  const countersIndex = {} as { [Key in K]: Map<T[keyof T], number> };

  for (const item of items)
    for (const attr of schema) {
      if (!countersIndex[attr]) countersIndex[attr] = new Map();
      const maybeValue = getter(item, attr);
      const values = (Array.isArray(maybeValue) ? maybeValue : [maybeValue]) as T[keyof T][];
      for (const valueAsKey of values)
        if (valueAsKey != null) countersIndex[attr].set(valueAsKey, (countersIndex[attr].get(valueAsKey) ?? 0) + 1);
    }

  const results: Result<T, K>[] = [];

  for (const key in countersIndex) {
    const values = Array.from(countersIndex[key].keys())
      .map(value => ({
        value,
        count: countersIndex[key].get(value) ?? 0,
        get percentOfTotal(): number {
          return this.count / values.reduce((sum, value) => sum + value.count, 0);
        }
      }))
      .sort((a, b) => (a.value > b.value ? 1 : -1));

    results.push({ key, values } as unknown as Result<T, K>);
  }

  return results;
};
