import { Checkbox } from 'components/Checkbox';
import styled, { css } from 'styled-components';
import { below, rem, themed } from 'utils';

const gutterWidth = 'spacing.xl';

export const Wrapper = styled.div<{ $groupsCount: number }>`
  display: flex;
  gap: ${themed(gutterWidth)};
  padding: 0 ${themed('spacing.xxxl')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding: 0 ${themed('spacing.xl')};
  }

  & > div {
    width: calc(100% / ${({ $groupsCount }) => $groupsCount});
  }
`;
export const Column = styled.div`
  position: relative;
  display: flex;
  gap: ${rem(14)};
  flex-direction: column;

  &:not(:last-of-type)::after {
    position: absolute;
    top: 0;
    left: calc(100% + calc(${themed(gutterWidth)} / 2));
    content: '';
    width: ${rem(1)};
    height: 100%;
    background-color: ${themed('color.black')};
  }
`;
export const ColumnHead = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  min-height: ${rem(38)};
  border-bottom: solid ${rem(1)} ${themed('color.greyMid')};
  padding: ${themed('spacing.m')} ${themed('spacing.m')} ${themed('spacing.m')} 0;

  h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    ${themed('typography.h2')};
    font-weight: ${themed('font.weight.bold')};

    span {
      font-weight: ${themed('font.weight.regular')};
      text-transform: uppercase;

      span {
        ${below(
          'm',
          css`
            display: none;
          `
        )}
      }
    }

    ${below(
      'm',
      css`
        ${themed('typography.h3')};
        font-weight: ${themed('font.weight.bold')};
      `
    )}
  }
`;
export const StyledCheckbox = styled(Checkbox)`
  position: relative;
  top: ${rem(-1)};
  pointer-events: none;
  width: ${rem(18)};
  height: ${rem(18)};
`;
export const StyledList = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'context'
})<{ $columns: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(100% / (1 + ${({ $columns }) => $columns})), 1fr));
  gap: ${rem(14)};

  ${below(
    'm',
    css`
      gap: ${rem(8)};
    `
  )}

  .product-card > span {
    ${themed('typography.h4')};

    ${below(
      'm',
      css`
        ${themed('typography.h5')};
      `
    )};
  }
`;
