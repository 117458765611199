import { CheckFill, ChevronDown, Time } from 'assets/icons';
import { Checkbox } from 'components/Checkbox';
import { Grid, GridItem } from 'components/Grid';
import { Tooltip } from 'components/Tooltip';
import { format, formatDistance, isToday, isYesterday } from 'date-fns';
import { useLocalisation } from 'providers';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { insightsRecommendationCheckboxClickTag, rem, themed } from 'utils';

import { InsightItem } from '@yourxx/types';
import { InsightStatus, InsightType, viewInsight } from '@yourxx/ui-utils';

import { useInsightKey, useInsights } from '../hooks';

const StyledGrid = styled(Grid)`
  width: 100%;
  text-align: center;
  line-height: 1.4;
  letter-spacing: ${rem(0.5)};
`;
const StyledTimeIcon = styled(Time)`
  width: ${themed('spacing.xl')};
  height: ${themed('spacing.xl')};
  fill: ${themed('color.blue')};
`;
const StyledCheckFillIcon = styled(CheckFill)`
  width: ${themed('spacing.l')};
  height: ${themed('spacing.l')};
  fill: ${themed('color.yellow')};
`;
const InsightText = styled.p`
  text-align: left;
  margin: 0;
`;
const ExpandInsightText = styled.p`
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.bold')};
  text-transform: capitalize;
  text-align: left;
  margin: ${themed('spacing.s')} 0;
`;
const MoreInfoText = styled.p`
  color: ${themed('color.grey')};
  text-align: left;
  margin: ${themed('spacing.xs')} 0;
  line-height: 1.2;
`;
const AssortmentName = styled.p`
  text-transform: capitalize;
  text-align: left;
  margin: ${themed('spacing.s')} 0;
  font-weight: ${themed('font.weight.semiBold')};
`;
const TriggerText = styled.span`
  text-transform: capitalize;
`;
const ChevronIcon = styled(ChevronDown)<{ $isExpanded: boolean }>`
  width: ${themed('spacing.l')};
  height: ${themed('spacing.l')};
  will-change: transform;
  transition: transform 0.2s ease-out;
  transform: ${({ $isExpanded }) => ($isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const AccordionHeader = ({ insightItem, isExpanded }: { insightItem: InsightItem; isExpanded: boolean }) => {
  const [str] = useLocalisation();
  const { insights, setInsights, selectedInsights, setSelectedInsights } = useInsights();
  const { getInsightKey } = useInsightKey();

  useEffect(() => {
    if (isExpanded && insightItem.status === InsightStatus.NEW) {
      viewInsight(insightItem.assortmentId, insightItem.timestamp, insightItem.type)
        .then(() => {
          setInsights(prev => {
            const updateInsights = prev.map(x => {
              if (getInsightKey(insightItem) === getInsightKey(x)) {
                return {
                  ...x,
                  status: InsightStatus.PENDING
                };
              } else {
                return x;
              }
            });
            return updateInsights;
          });
        })
        .catch(e => {
          console.error(e);
          toast.error(str('Insights.errorUpdatingStatus'), { type: 'error', theme: 'colored' });
        });
    }
  }, [str, isExpanded]);

  const onSelectChange = (val: boolean) => {
    const updatedItems = val
      ? [...selectedInsights, insightItem]
      : selectedInsights.filter(x => getInsightKey(x) !== getInsightKey(insightItem));
    setSelectedInsights(updatedItems);
  };

  const dateLabel = insightItem.timestamp
    ? isToday(insightItem.timestamp)
      ? str('general.time.today')
      : isYesterday(insightItem.timestamp)
        ? str('general.time.yesterday')
        : str('general.time.ago', { time: formatDistance(insightItem.timestamp, new Date()) })
    : '';
  const timeLabel = insightItem.timestamp ? format(insightItem.timestamp, 'hh:mm a') : '';

  return (
    <StyledGrid $col={12} $colTablet={12} $colMobile={12} $gap={1} $align="center">
      <GridItem $col={1}>
        {insightItem.status === InsightStatus.NEW && (
          <Tooltip text={str('Insights.status.new')}>
            <StyledTimeIcon />
          </Tooltip>
        )}
        {insightItem.status === InsightStatus.PENDING && (
          <Tooltip text={str('Insights.status.pending')}>
            <StyledCheckFillIcon />
          </Tooltip>
        )}
      </GridItem>
      <GridItem $col={insights.some(i => i.type !== InsightType.SUGGESTION) ? 6 : 7}>
        <InsightText>
          {str('Insights.productRecommendation')}
          {insightItem.type === InsightType.SUGGESTION ? ' (SBF)' : ''}
        </InsightText>
        <ExpandInsightText>
          {insightItem.type === InsightType.SUGGESTION
            ? str('Insights.addPC9ToAssortment', {
                thisOrThese:
                  insightItem.items?.length > 1
                    ? str('Insights.thesePC9s', { count: insightItem.items.length })
                    : str('Insights.thisPC9')
              })
            : `${str('Insights.expandAssortment')} `}
          {insightItem.type === InsightType.SUGGESTION
            ? ''
            : insightItem.items?.length > 1
              ? str('Insights.thesePC9s', { count: insightItem.items.length })
              : str('Insights.thisPC9')}
        </ExpandInsightText>
        <MoreInfoText>
          {insightItem.type !== InsightType.SUGGESTION
            ? `${
                insightItem.items?.length > 1
                  ? str('Insights.thesePC9sAre', { count: insightItem.items.length })
                  : str('Insights.thisPC9Is')
              } ${str('Insights.pc9sPopularInCluster')}`
            : insightItem.storyName || ''}
        </MoreInfoText>
        <AssortmentName>{insightItem.assortmentName}</AssortmentName>
      </GridItem>
      <GridItem $col={2}>
        <TriggerText>{dateLabel}</TriggerText>
      </GridItem>
      <GridItem $col={1}>
        <TriggerText>{timeLabel}</TriggerText>
      </GridItem>
      <GridItem $col={1}>
        <ChevronIcon $isExpanded={isExpanded} />
      </GridItem>
      {insights.some(i => i.type !== InsightType.SUGGESTION) && (
        <GridItem $col={1} $gap={1} $alignCenter>
          <Checkbox
            checked={!!selectedInsights.find(insight => getInsightKey(insight) === getInsightKey(insightItem))}
            onChange={isChecked => {
              insightsRecommendationCheckboxClickTag({
                isSelected: isChecked,
                customerId: insightItem.customer.customer_id,
                assortmentId: insightItem.assortmentId,
                recommendationId: getInsightKey(insightItem)
              });
              onSelectChange(isChecked);
            }}
            disabled={insightItem.status === InsightStatus.REMOVED || insightItem.type === InsightType.SUGGESTION}
          />
        </GridItem>
      )}
    </StyledGrid>
  );
};
