import { Fragment } from 'react';

export const PreText = ({ text }: { text: string }) => (
  <>
    {text?.split('\n')?.map((item: string, key: number) => (
      <Fragment key={key}>
        {item}
        <br />
      </Fragment>
    ))}
  </>
);
