export const getPathWithoutUuids = ({ path, params }: { path: string; params: Record<string, string | undefined> }) => {
  const { customersOrLines, customerOrLineId } = params;
  if (customersOrLines === 'lines' && customerOrLineId) {
    return path.replace(customerOrLineId, ':lineId');
  }
  if (customerOrLineId) {
    path = path.replace(customerOrLineId, ':customerId');
  }
  const { customerAssortmentId } = params;
  if (customerAssortmentId) {
    path = path.replace(customerAssortmentId, ':assortmentId');
  }
  return path;
};
