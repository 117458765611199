import { useMemo } from 'react';
import { asErrorMessage } from 'utils';

import { copyAssortment as defaultCopyAssortment, getSummary as defaultGetSummary } from '@yourxx/ui-utils';

import type { EventBus } from '../EventBus';
import type { AssortmentsService } from './AssortmentsService';
import { CopyAssortmentFailed, CopyAssortmentRequested, CopyAssortmentSuccessful } from './events';

export const createAssortmentsService = ({
  eventBus,
  copyAssortment = defaultCopyAssortment,
  getSummary = defaultGetSummary
}: {
  eventBus: EventBus;
  copyAssortment?: typeof defaultCopyAssortment;
  getSummary?: typeof defaultGetSummary;
}): AssortmentsService => {
  return {
    async handle(command) {
      const requestEvent = new CopyAssortmentRequested({ command });
      eventBus.emit(requestEvent);

      return copyAssortment({
        assortmentId: command.context.id,
        assortmentName: command.assortmentName.trim(),
        copyOptions: command.copyOptions,
        customers: command.targetCustomers
      })
        .then(() => eventBus.emit(new CopyAssortmentSuccessful({ command }).trace(requestEvent)))
        .catch(error => {
          eventBus.emit(new CopyAssortmentFailed({ command, reason: asErrorMessage(error) }).trace(requestEvent));
          throw error;
        });
    },

    async loadSummary(command) {
      return getSummary({ assortmentId: command.context.id, excludeCarryOver: command.excludeCarryOver });
    }
  };
};

export const useCreateAssortmentsService = ({ eventBus }: { eventBus: EventBus }) => {
  return useMemo(() => createAssortmentsService({ eventBus }), [eventBus]);
};
