import {
  HeaderSearch,
  type SearchSuggestion,
  SearchSuggestionsCleared,
  SearchSuggestionsCreated,
  useSetupContextfulSearch
} from 'components/Header';
import { SlotId } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { useServices } from 'services';
import { landingSearchTag, useEventSubscriptions } from 'utils';

/**
 * TODO: Next round of refactoring: Decide if we want to request rendering from each nested route which supports search.
 */
export const SearchSetup = () => {
  const [str] = useLocalisation();
  const { pathname } = useLocation();
  const { currentContext = '', ...searchProps } = useSetupContextfulSearch();

  const { eventBus } = useServices();
  const [searchSuggestions, setSearchSuggestions] = useState<readonly SearchSuggestion[]>();
  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(SearchSuggestionsCreated, event => {
          setSearchSuggestions(event.payload.suggestions);
        }),

        eventBus.on(SearchSuggestionsCleared, _ => {
          setSearchSuggestions(undefined);
        })
      ],
      [eventBus]
    )
  );

  const { changeTerm } = searchProps;
  const onSearch = useCallback(
    (searchTerm: string) => {
      landingSearchTag({ location: pathname, term: searchTerm });
      changeTerm(searchTerm);
    },
    [changeTerm, pathname]
  );

  const searchPlaceholderText = useMemo(
    () => ({
      [`${routes.customers}`]: str('Customers.searchPlaceholder'),
      [`${routes.assortments}`]: str('Landing.customers.searchAssortmentsPlaceholder'),
      [`${routes.assortmentProducts}`]: str('Landing.customers.searchProductsPlaceholder'),
      [`${routes.lines}`]: str('Landing.lines.searchPlaceholder'),
      [`${routes.lineProducts}`]: str('Landing.customers.searchProductsPlaceholder')
    }),
    [str]
  );

  useSlot(
    SlotId.HeaderToolbarRight,
    useMemo(
      () => (
        <HeaderSearch
          placeholder={searchPlaceholderText[currentContext] ?? ''}
          searchTerm={searchProps.searchTerm}
          suggestions={searchSuggestions}
          onChange={onSearch}
          debounceDelayInMs={200}
          disableAutoInputCapture
        />
      ),
      [currentContext, onSearch, searchPlaceholderText, searchProps.searchTerm, searchSuggestions]
    ),
    0
  );

  return null;
};
