import { createContext, useCallback, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { type Language, useServices } from 'services';

export interface LocalisationContext {
  isLoading: boolean;
  throwOnErrors: boolean;
  currentLocale: Language | null;
  changeLocale(locale: Language): void;
  availableLocales: ReadonlyArray<Language>;
}

export const LocalisationContext = createContext<LocalisationContext | null>(null);

export interface LocalisationProviderProps {
  locale?: string;
  // For non-production environments only (will throw on misconfigurations, e.g. wrong string id).
  throwOnErrors?: boolean;
}

/**
 * Hide the implementation details of how localised strings are queried and configured in the application.
 */
export const LocalisationProvider = ({
  children,
  locale: defaultLocale = 'en',
  throwOnErrors = !import.meta.env.PROD
}: LocalisationProviderProps & { children?: React.ReactNode }) => {
  const { localisationService: service } = useServices();
  const [isLoading, setIsLoading] = useState(true);
  const [currentLocale, setCurrentLocale] = useState<Language | null>(null);
  const [availableLocales, setAvailableLocales] = useState<ReadonlyArray<Language>>([]);
  const [locales, setLocales] = useState<Record<string, Record<string, string>>>({});

  // TODO: Refactor using state machine to avoid the pitfalls of relying on React hooks as trigger "when to do" things.
  //   https://stately.ai/docs/xstate
  useEffect(() => {
    let cancelled = false;
    const gotCancelled = () => cancelled;

    function loadLocale() {
      setIsLoading(true);

      service
        .supportedLocales()
        .then(result => {
          setAvailableLocales(result);
          return service.loadLocale(defaultLocale).then(record => {
            if (gotCancelled()) return;
            setLocales(prev => ({ ...prev, [defaultLocale]: record }));
            setCurrentLocale(result.find(item => item.locale === defaultLocale) ?? null);
            setIsLoading(false);
          });
        })
        .catch(async () => {
          // TODO: Add error handling when switching to state machine
          await new Promise(res => setTimeout(res, 1000));
          return loadLocale();
        });
    }

    loadLocale();

    return () => {
      cancelled = true;
    };
  }, [defaultLocale, service]);

  // TODO: Will be refactored to state machine, soon™
  const changeLocale = useCallback(
    (locale: Language) => {
      if (locale.locale in locales) {
        setCurrentLocale(locale);
        return;
      }

      setIsLoading(true);
      service
        .loadLocale(locale.locale)
        .then(record => {
          setLocales(prev => ({ ...prev, [locale.locale]: record }));
          setCurrentLocale(locale);
        })
        .catch(async () => {
          // TODO: Add error handling when switching to state machine
          await new Promise(res => setTimeout(res, 1000));
          changeLocale(locale);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [locales, service]
  );

  // TODO: (?) Lazily render children until locale record is loaded.
  return (
    <LocalisationContext.Provider
      value={{
        isLoading,
        throwOnErrors,
        // TODO: This will need to eventually be filtered by the user preferences
        availableLocales,
        currentLocale,
        changeLocale
      }}
    >
      <IntlProvider
        locale={currentLocale?.locale ?? defaultLocale}
        messages={locales[currentLocale?.locale ?? defaultLocale]}
      >
        {children}
      </IntlProvider>
    </LocalisationContext.Provider>
  );
};
