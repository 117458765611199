import { SpinnerAlt } from 'assets/icons';
import { Button } from 'components/Button';
import styled, { css } from 'styled-components';
import { rem, themed } from 'utils';

export const OptionsList = styled.ul`
  overflow: hidden;
  min-width: ${rem(220)};
  margin: -${themed('spacing.l')};
  border-radius: ${themed('spacing.m')};
  padding: ${themed('spacing.m')} 0;
  list-style: none;
  background-color: ${themed('color.white')};
`;

export const Option = styled.li<{ $isDisabled?: boolean }>`
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  box-sizing: border-box;
  min-height: ${rem(40)};
  padding: ${rem(12)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.s')};
  transition-property: background-color;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};

  span {
    opacity: ${({ $isDisabled }) => ($isDisabled ? 0.4 : 1)};
    transition-property: color, opacity;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};
  }

  ${({ $isDisabled }) =>
    $isDisabled
      ? ''
      : css`
          @media (hover: hover) {
            &:hover {
              background-color: ${themed('color.offWhite')};
            }
          }

          &:active {
            background-color: ${themed('color.black')};

            span {
              color: ${themed('color.white')};
            }
          }
        `}
`;

export const OptionLabel = styled.span`
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.bold')};
  flex-grow: 1;
`;

export const OptionDesc = styled.span`
  ${themed('typography.h4')};
  color: ${themed('color.grey')};
  font-weight: ${themed('font.weight.regular')};
`;

export const OptionIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${themed('color.greyMid')};
  }
`;

export const StyledButton = styled(Button)<{ $isOpen?: boolean }>`
  height: ${rem(34)};
  background-color: ${({ $isOpen }) => ($isOpen ? themed('color.black') : 'transparent')};
  color: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.medium')};

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(22)};
    height: ${rem(22)};
  }

  svg {
    margin: 0;
    width: ${rem(22)};
    height: ${rem(22)};

    * {
      stroke: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};
    }
  }
`;

export const StyledLoader = styled(SpinnerAlt)`
  width: ${rem(24)} !important;
  height: ${rem(24)} !important;

  path {
    stroke: unset;
  }
`;
