export const getApiUrl = (): string => {
  const { hostname, protocol } = window.location;
  if (hostname.includes('localhost')) {
    return 'http://localhost:3001/api';
  }
  return protocol + '//' + hostname + '/api';
};

type ImageUrlParams = { imageUrl: string; pc9: string; imageUrlParams: string; imgFmt: string; imageType?: string };
export const getImageUrl = ({ imageUrl, pc9, imageUrlParams, imgFmt, imageType }: ImageUrlParams): string => {
  if (imageType) {
    return `${imageUrl}/${pc9}?${imageUrlParams}&image_format=${imgFmt}&image_type=${imageType}`;
  }
  return `${imageUrl}/${pc9}?${imageUrlParams}&image_format=${imgFmt}`;
};
