import styled from 'styled-components';
import { rem, themed } from 'utils';

const expandedWidth = 300;
const underlineOffset = -3;
const clearIconWidth = 20;

export const RootStyles = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;

  // Pseudo-border
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${rem(1)};
    background-color: black;
    opacity: 0;
    will-change: opacity, transform;
    transition-property: opacity, transform;
    transition-duration: ${themed('transition.duration')};
    transition-timing-function: ${themed('transition.timing')};
    transform: translateY(${rem(underlineOffset)});
  }

  .input-wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    width: 0;
    visibility: hidden;
    will-change: width;
    transition-property: width;
    transition-duration: ${themed('transition.duration')};
    transition-timing-function: ${themed('transition.timing')};
  }

  input {
    overflow: hidden;
    border: 0;
    margin: 0;
    padding: ${rem(10)} 0 ${rem(10)} ${rem(3)};
    flex-grow: 1;
    background-color: transparent;
    color: ${themed('color.black')};
    font-family: ${themed('font.family')};
    font-size: ${themed('font.size.m')};
    line-height: ${rem(15)};

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &::placeholder {
      color: ${themed('color.grey')};
    }
  }

  .clear {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    margin-right: 0;
    will-change: transform, width, margin-right;
    transition-property: width, margin-right;
    animation-duration: ${themed('transition.duration')};
    animation-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};
    transition-timing-function: ${themed('transition.timing')};

    &[data-can-clear='true'] {
      visibility: visible;
      width: ${rem(clearIconWidth)};
      margin-right: ${rem(11)};
      animation-name: ${themed('animation.zoomOut')};
    }

    @media (hover: hover) {
      &:hover {
        svg * {
          stroke: ${themed('color.black')};
        }
      }
    }

    svg {
      width: 100%;

      * {
        stroke: ${themed('color.transparentMidBlack')};
        will-change: stroke;
        transition-property: stroke;
        transition-duration: ${themed('transition.duration')};
        transition-timing-function: ${themed('transition.timing')};
      }
    }
  }

  .trigger-icon {
    display: flex;
    align-items: center;
  }

  &[data-state='Expanding'],
  &[data-state='Expanded'] {
    .input-wrapper {
      visibility: visible;
      width: ${rem(expandedWidth - clearIconWidth)};
    }
  }

  &[data-state='Expanded'] {
    &::after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &[data-state='Collapsing'] {
    &::after {
      opacity: 0;
      transform: translateY(${rem(underlineOffset)});
    }

    .input-wrapper {
      width: 0;
      visibility: visible;
    }
  }

  &[data-state='Collapsed'] {
    .input-wrapper {
      width: 0;
      visibility: hidden;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .suggestions {
    &-list {
      overflow-y: auto;
      position: absolute;
      z-index: 500;
      top: calc(100% + ${themed('spacing.s')});
      left: 0;
      max-height: ${rem(240)};
      width: 100%;
      border-radius: ${themed('borderRadius')};
      padding: ${themed('spacing.s')} 0;
      background-color: ${themed('color.white')};
      box-shadow: ${themed('boxShadow')};
      animation-name: ${themed('animation.slideDown')};
      animation-timing-function: ${themed('transition.timing')};
      animation-duration: ${themed('transition.duration')};
    }

    &-item {
      overflow: hidden;
      cursor: pointer;
      max-width: 100%;
      padding: ${themed('spacing.m')} ${themed('spacing.m')};
      text-overflow: ellipsis;
      ${themed('typography.h3')};
      color: ${themed('color.black')};
      font-weight: ${themed('font.weight.regular')};
      will-change: back-ground-color, color;
      transition-property: background-color, color;
      transition-timing-function: ${themed('transition.timing')};
      transition-duration: 100ms;

      &[data-selected='true'] {
        background-color: ${themed('color.black')};

        & * {
          color: ${themed('color.white')};
        }
      }

      @media (hover: hover) {
        &:hover {
          background-color: ${themed('color.offWhite')};
          color: ${themed('color.black')};

          &[data-selected='true'] {
            background-color: ${themed('color.black')};

            & * {
              color: ${themed('color.white')};
            }
          }
        }
      }

      span {
        white-space: nowrap;
        max-width: 100%;
        font-variant: tabular-nums;
      }
    }
  }
`;
