import { createContext, useContext } from 'react';

import type { Nullable } from '@yourxx/support';

export const CurrentCanvasContext = createContext<Nullable<Record<string, string | undefined>>>(null);

export const useCurrentCanvasContext = () => {
  const value = useContext(CurrentCanvasContext);
  if (!value) throw new ReferenceError('useCurrentCanvasContext used outside its context provider.');
  return value;
};
