import { useEffect } from 'react';
import { useRoute, type ViewRoute } from 'utils';

export const TrackRoute = <R extends ViewRoute<any>>({ route, track }: { route: R; track: (route: R) => void }) => {
  const { route: currentRoute } = useRoute();
  const matches =
    currentRoute &&
    route.matches(currentRoute) &&
    Object.entries(route.params).every(([key, value]) => (currentRoute as R).params[key] === value);

  useEffect(() => {
    if (matches) track(currentRoute as R);
  }, [currentRoute, matches, track]);

  return null;
};
