import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { formatPrice, rem, themed } from 'utils';

const ItemFilterContainer = styled.div`
  border-bottom: ${rem(1)} solid ${themed('color.greyMid')};
  background-color: ${themed('color.white')};
`;
const StyledDivider = styled.div`
  border-bottom: ${themed('spacing.xs')} solid ${themed('color.greyLight')};
  padding: 0 ${themed('spacing.m')};
`;
const FilterContent = styled.div`
  display: block;
  padding: ${rem(10)};
`;
const PriceSelectButton = styled(Button)<{ $isSelected: boolean }>`
  padding: ${themed('spacing.s')} ${themed('spacing.m')};
  background-color: ${({ $isSelected }) => themed($isSelected ? 'color.black' : 'color.greyLight')};
  color: ${({ $isSelected }) => themed($isSelected ? 'color.white' : 'color.black')};
  ${themed('typography.h5')};
  text-align: center;
`;
const PriceTypeSelector = styled.div`
  display: flex;
  gap: ${themed('spacing.m')};
  margin-bottom: ${rem(10)};
`;
const ChartContainer = styled.div`
  height: ${rem(120)};
  display: flex;
  margin: 0 ${rem(10)};
  border-left: ${rem(2)} solid;
  border-bottom: ${rem(2)} solid;
  position: relative;
`;
const ChartItem = styled.div<{ $topLeft: number; $topRight: number }>`
  flex-grow: 1;
  background-color: ${themed('color.red')};
  clip-path: polygon(
    0% calc(100% * ${({ $topLeft }) => 1 - $topLeft}),
    100% calc(100% * ${({ $topRight }) => 1 - $topRight}),
    100% 100%,
    0% 100%
  );
`;
const PriceFilter = styled.div`
  display: flex;
  justify-content: space-between;

  input {
    height: ${rem(28)};
    padding: 0;
    box-sizing: border-box;
    padding-inline: ${themed('spacing.s')};
  }
`;
const ChartPriceData = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 ${rem(10)};
  background-color: ${themed('color.white')};

  span {
    ${themed('typography.h5')};
    font-variant-numeric: tabular-nums;
  }
`;
const PricingRange = styled.div<{ $width: number; $position: number }>`
  position: absolute;
  border-color: ${themed('color.grey')};
  border-style: dashed;
  background-color: ${themed('color.white')};
  border-top: none;
  border-bottom: none;
  width: ${({ $width }) => `${$width}%`};
  opacity: 0.6;
  height: 100%;
  margin-left: ${({ $position }) => `${$position}%`};
`;
const ChartPriceHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin: 0 ${rem(10)};
  ${themed('typography.h5')};
  font-variant-numeric: tabular-nums;
`;
const PricingLabelContainer = styled.div<{ $position: number; $width: number }>`
  margin-left: ${({ $position }) => `${$position}%`};
  width: ${({ $width }) => `${$width}%`};
  display: flex;
  justify-content: space-between;

  span {
    display: inline-block;
    ${themed('typography.h5')};
    font-variant-numeric: tabular-nums;
  }
`;
const ValueInput = styled(Input)`
  font-variant-numeric: tabular-nums;
  width: 5.4em;

  &:last-child {
    text-align: right;
  }
`;

export type Range = readonly [min: number, max: number];

export const ChartFilter = ({
  className,
  currency,
  range,
  onRangeChange,
  priceOptions,
  typeOptions,
  type,
  onTypeChange,
  inputStep = 1
}: {
  className?: string;
  currency?: string;
  priceOptions: ReadonlyArray<{ value: number; count: number; percentOfTotal: number }>;
  range?: Range;
  onRangeChange: (range: Range) => void;
  typeOptions: ReadonlyArray<{ value: string; label: string }>;
  type?: string;
  onTypeChange: (priceType: string) => void;
  inputStep?: number;
}) => {
  const highestPriceFrequency = useMemo(() => Math.max(...priceOptions.map(x => x.count)), [priceOptions]);
  const [chartMinPrice, chartMaxPrice] = [priceOptions[0].value, priceOptions[priceOptions.length - 1].value];
  const [minPrice, maxPrice] = range ?? [chartMinPrice, chartMaxPrice];
  const hasSelectedCustomRange = minPrice !== chartMinPrice || maxPrice !== chartMaxPrice;
  const [showStartFilterLabel, setShowStartFilterLabel] = useState(true);
  const [showEndFilterLabel, setShowEndFilterLabel] = useState(true);
  const [pricingLabelWidth, setPricingLabelWidth] = useState(0);
  const [pricingLabelMargin, setPricingLabelMargin] = useState(0);

  const calculatePercentageRatio = (val: number) => val / highestPriceFrequency;

  const calculateWidthOfFilter = useCallback(() => {
    const chartRange = chartMaxPrice - chartMinPrice;
    let filterRange = maxPrice - minPrice;
    if (minPrice <= chartMinPrice) {
      filterRange = filterRange - (chartMinPrice - minPrice);
    } else if (maxPrice >= chartMaxPrice) {
      filterRange = filterRange - (maxPrice - chartMaxPrice);
    }
    const percentageRange = (filterRange / chartRange) * 100;
    setPricingLabelWidth(percentageRange);
  }, [chartMaxPrice, chartMinPrice, maxPrice, minPrice]);

  const calculatePositionOfFilter = useCallback(() => {
    const chartRange = chartMaxPrice - chartMinPrice;
    let filterRange = maxPrice - minPrice;
    let result = 0;
    if (minPrice <= chartMinPrice) {
      result = 0;
      setShowStartFilterLabel(false);
      setShowEndFilterLabel(true);
    } else if (maxPrice >= chartMaxPrice) {
      filterRange = filterRange - (maxPrice - chartMaxPrice);
      result = 100 - (filterRange / chartRange) * 100;
      setShowStartFilterLabel(true);
      setShowEndFilterLabel(false);
    } else {
      const gap = minPrice - chartMinPrice;
      result = (gap / chartRange) * 100;
      setShowStartFilterLabel(true);
      setShowEndFilterLabel(true);
    }
    setPricingLabelMargin(result);
  }, [chartMaxPrice, chartMinPrice, maxPrice, minPrice]);

  useEffect(() => {
    calculateWidthOfFilter();
    calculatePositionOfFilter();
  }, [calculatePositionOfFilter, calculateWidthOfFilter]);

  const inputProps = minMaxProps([chartMinPrice, chartMaxPrice], [minPrice, maxPrice]);

  return (
    <ItemFilterContainer className={className}>
      <StyledDivider />
      <FilterContent>
        <PriceTypeSelector>
          {typeOptions.map(({ value, label }) => (
            <PriceSelectButton key={value} $isSelected={value === type} onClick={() => onTypeChange(value)}>
              {label}
            </PriceSelectButton>
          ))}
        </PriceTypeSelector>
        <ChartPriceHeader>
          <span>{highestPriceFrequency}</span>
          {hasSelectedCustomRange && (
            <PricingLabelContainer $width={pricingLabelWidth + 3} $position={pricingLabelMargin - 9}>
              <span>{showStartFilterLabel ? formatPrice(currency, minPrice) : ''}</span>
              {showEndFilterLabel && <span>{formatPrice(currency, maxPrice)}</span>}
            </PricingLabelContainer>
          )}
        </ChartPriceHeader>
        <ChartContainer>
          {priceOptions.map((item, i) => (
            <ChartItem
              key={item.value}
              $topLeft={calculatePercentageRatio(priceOptions[i - 1]?.count)}
              $topRight={calculatePercentageRatio(item.count)}
            />
          ))}
          {hasSelectedCustomRange && <PricingRange $width={pricingLabelWidth} $position={pricingLabelMargin} />}
        </ChartContainer>
        <ChartPriceData>
          <span>{formatPrice(currency, chartMinPrice)}</span>
          <span>{formatPrice(currency, chartMaxPrice)}</span>
        </ChartPriceData>
        <PriceFilter>
          <ValueInput
            type="number"
            step={inputStep}
            onChange={e => onRangeChange(inputProps.min.rangeFor(Number(e.target.value)))}
            value={inputProps.min.value}
            min={inputProps.min.min}
            max={inputProps.min.max}
          />
          <ValueInput
            type="number"
            step={inputStep}
            onChange={e => onRangeChange(inputProps.max.rangeFor(Number(e.target.value)))}
            value={inputProps.max.value}
            min={inputProps.max.min}
            max={inputProps.max.max}
          />
        </PriceFilter>
      </FilterContent>
    </ItemFilterContainer>
  );
};

export const minMaxProps = (maxRange: Range, range: Range) => {
  const [min, max] = range;

  return {
    min: {
      rangeFor: (newMin: number): Range => [newMin * 100, range[1]],
      value: min / 100,
      min: maxRange[0] / 100,
      max: max / 100
    },
    max: {
      rangeFor: (newMax: number): Range => [range[0], newMax * 100],
      value: max / 100,
      min: min / 100,
      max: maxRange[1] / 100
    }
  };
};
