import { Button } from 'components';
import { useLocalisation } from 'providers';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { themed } from 'utils';

import { useMutation } from '@tanstack/react-query';
import { patchAcceptedTerms } from '@yourxx/ui-utils';

const Wrapper = styled.div`
  ${themed('flexColumn')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${themed('spacing.xxxl')};
  overflow-x: scroll;
  overflow-y: scroll;
  > button {
    font-weight: ${themed('font.weight.medium')};
    padding: ${themed('spacing.m')} ${themed('spacing.xl')};
  }
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  margin-bottom: ${themed('spacing.xl')};
  padding: ${themed('spacing.xl')} 0;
`;

export const Terms = () => {
  const [str] = useLocalisation();
  const navigate = useNavigate();

  const saveAcceptTerms = useMutation({
    mutationFn: async () => {
      await patchAcceptedTerms();
      navigate(0);
    }
  });

  return (
    <Wrapper>
      <h1>{str('general.termsTitle')}</h1>
      <ScrollableContent>
        <section>
          Welcome to YourXX, a website specifically for selected Levi’s® and/or Dockers® dealers authorized by Levi
          Strauss Europe SCA/Comm.VA (&quot;LSE&quot;) or Levi Strauss &amp; Co. (&quot;LS&amp;Co.&quot;). YourXX allows
          you to visualize your assortment of Levi’s® and/or Dockers® branded products.
        </section>
        <section>
          These Terms and Conditions of Use (&quot;Ts&amp;Cs of Use&quot;) regulate the access and usage of YourXX,
          including any mobile and touch versions, and other electronic services made available to selected Levi’s®
          and/or Dockers® authorized dealers (&quot;Authorized Dealers&quot;), or portions of, or services provided
          through, them (collectively, the &quot;Site&quot;). These Ts&amp;Cs of Use apply to all Authorized Dealers who
          have been granted access to the Site, and contain important legal disclosures that you should read carefully.
          LS&amp;Co. and/or its affiliates and subsidiaries (&quot;we,&quot; &quot;us,&quot; &quot;our&quot;) and its
          Authorized Dealers (&quot;you&quot;) may be referred to throughout these Ts&amp;Cs of Use individually as a
          &quot;Party&quot; and collectively as the &quot;Parties&quot;.
        </section>
        <section>
          THESE Ts&amp;Cs OF USE SUPPLEMENT THE LSE TERMS &amp; CONDITIONS OF TRADING AND ANY OTHER RELATED AGREEMENTS
          APPLICABLE TO LS&amp;CO.’S AUTHORIZED DEALERS (COLLECTIVELY, &quot;AGREEMENTS&quot;) TO WHICH ALL AUTHORIZED
          DEALERS WITH ACCESS TO THE SITE ARE BOUND. EXCEPT AS EXPRESSLY NOTED TO THE CONTRARY, IN THE EVENT OF CONFLICT
          BETWEEN THESE Ts&amp;Cs OF USE AND THE TERMS OF THE AGREEMENT(S), THE TERMS OF THE AGREEMENTS SHALL CONTROL.
        </section>
        <section>
          <h2>1. ACCEPTANCE OF T&amp;Cs OF USE</h2>
          <p>
            LS&amp;Co., a company registered in the State of California, United States, owns and operates the Site. By
            using the Site, any service provided through the Site, or installing mobile or touch applications that allow
            access to content or services provided through the Site, if available, you agree to be bound by these
            Ts&amp;Cs of Use.
          </p>
        </section>
        <section>
          <h2>2. ABOUT THE SITE</h2>
          <p>The Site is a platform through which you can visualize your assortment of Products.</p>
          <ol>
            <li>
              You acknowledge that the Site does not enable you to modify your assortment of Products independently. The
              Site does not enable you to directly order nor pay for the assortment of Products. The assortment
              presented via YourXX does not constitute a legally binding agreement between you and us.
            </li>
          </ol>
          <p>As a condition of your use of the Site, you agree that:</p>
          <ul>
            <li>You have accepted and are in compliance with all Agreements applicable to Authorized Dealers;</li>
            <li>
              You have accessed the Site as an Authorized Dealer and are authorized to provide any and all information
              in support of your account, and all such information is accurate, true, current and complete;
            </li>
            <li>
              Your use of the Site will at all times comply with these T&amp;Cs of Use and will only be used by you, or
              your authorized agent in order to manage your account with LSE as an Authorized Dealer, and
            </li>
            <li>
              You will safeguard your password and supervise the use of your account, and agree that you are responsible
              for any use of your account, including uses which violate the terms of the Agreements.
            </li>
          </ul>
        </section>
        <section>
          <h2>3. PRODUCTS</h2>
          <p>
            In describing and portraying the Products on the Site, we attempt to be as accurate as possible. However, we
            do not warrant that the Product descriptions or other Content of the Site is accurate, complete, reliable,
            current or error free. We reserve the right to correct any typographical errors, inaccuracies or omissions
            that may relate to Product descriptions, pricing and availability from time to time without prior notice.
            You understand that prices are indicative and subject to change.
          </p>
          <p>
            We have made every effort to display as accurately as possible the colors and finishes of our Products that
            appear at the Site. We cannot guarantee that the computer monitor’s display of any color will be accurate,
            complete, reliable or error free. So colors and finishes will be indicative and do not have a
            binding/contractual value.
          </p>
        </section>
        <section>
          <h2>4. OWNERSHIP OF THE SITE AND ITS CONTENT - CONFIDENTIALITY</h2>
          <p>
            This Site is owned and controlled by LS&amp;Co. Unless otherwise agreed in writing, all materials on the
            Site, including text, graphics, information, content, images, illustrations, designs, icons, photographs,
            video clips, sounds, music, artwork, computer code and other materials, and the copyrights, trademarks,
            trade dress and/or other intellectual property rights in such materials (collectively, the
            &quot;Content&quot;), are owned, controlled and/or licensed by LS&amp;Co.
          </p>
          <p>
            You acknowledge that the Content, including but not limited to that relating to the Products, is of a highly
            confidential nature. You will not disclose the Content to any third party other than your employees,
            contractors, or agents having a “need to know&quot;. You will hold the Content in strict confidence and you
            will use the same degree of care to prevent the unauthorized use, dissemination or publication of the
            Content as used to protect your own confidential information of a similar nature, which in no event will be
            less than reasonable care.
          </p>
        </section>
        <section>
          <h2>6. YOUR ACCOUNT</h2>
          <p>
            Your account is non-transferrable. You are responsible for updating and correcting information you have
            submitted to create or maintain your account. You understand and agree that we have no responsibility for
            any incident arising out of, or related to, your account settings. You must safeguard your password and
            supervise the use of your account. You are solely responsible for maintaining the security of your account
            and maintaining settings that reflect your preferences. We will assume that anyone using the Site or
            transacting through your account is you. You agree that you are solely responsible for any activity that
            occurs under your account. Failure to maintain updated and correct information about your account may
            constitute a violation of your Agreement(s) with us, will cause your account to fall out of good standing
            leading to its cancellation.
          </p>
        </section>
        <section>
          <h2>7. DATA PRIVACY AND SECURITY</h2>
          <p>
            When you register and use this Site, Levi Strauss &amp;Co. and its affiliates (collectively
            “LS&amp;CO&quot;) may collect information, including personal information about you as data controllers. The
            main seat of LS&amp;CO. in Europe is at Airport Plaza –Rio Building Leonardo Da Vincilaan 19, 1831 Diegem
            Belgium.
          </p>
          <p>
            We also work with business partners who help us operate our Site and provide services to our customers: we
            consider these our trusted business partners. Our trusted business partners may change over time but we are
            committed to building relationships with third parties who are dedicated to providing you a safe and
            satisfying experience. Any reference to “we&quot; or “us&quot; may also refer to our trusted business
            partners or any LS&amp;CO group companies if and in so far they process personal information collected in
            the context of the App.
          </p>
          <p>
            When you interact with our Site in addition to your registration details we collect your browsing
            information, logs, usage data and other device information. We may also collect your IP address, browser,
            device information (such as your mobile device and its Unique Device Identifiers (UDIs)), time of visit and
            other information about your activities on Site through the use of technology such as cookies, web beacons
            and other tracking technologies. Providing this information is not mandatory and cookies can be disabled
            through your web browser; however, our Site may not offer the same functionalities when certain cookies are
            disabled.
          </p>
          <p>
            We collect such data in order to enable you to register on our Site and help us identify you and any
            accounts you hold with us; to process your assortment choices and to perform statistical analyses.
          </p>
          <p>
            Personal information we collect or process when you use our Site may be stored or processed by LS&amp;CO
            group of companies or other recipients listed above in countries other than the country in which your
            personal information originally was collected (this may include for example the United States, at our
            LS&amp;CO premises). The laws in those countries may not provide the same level of data protection compared
            to the country in which you initially provided your personal information. However, please rest assured that
            we implement all the necessary and appropriate legal, organizational and technical measures to protect your
            personal information. Furthermore, as regards the transfer of personal information to countries outside the
            EU, we have entered into so-called “Standard Contractual Clauses&quot; approved by the Commission of the
            European Union for international transfer of personal information to controllers or processors established
            in third countries as the case may be.
          </p>
          <p>
            Your personal information will be deleted when they are no longer necessary for the purpose for which they
            were collected.
          </p>
          <p>
            You can exercise your right of deletion, access, rectification and objection to our use of your personal
            information by sending an email to privacy@levi.com.
          </p>
        </section>
        <section>
          <h2>8. YOUR CONDUCT ON OUR SITE</h2>
          <p>
            You may use this Site only as permitted by these T&amp;Cs of Use and only in a manner consistent with the
            terms of your Agreement(s) with us and in accordance with all applicable laws, rules and regulations, and
            generally accepted practices or guidelines in the US and Europe. You agree not to use any
            &quot;deep-link&quot;, &quot;robot&quot; or other automatic or manual device, software, program, code,
            algorithm or methodology, to access, copy or monitor any portion of the Site, or in any way reproduce or
            circumvent the navigational structure or presentation of the Site, or obtain or attempt to obtain any
            materials or information through any means not purposely made available by us through the Site. We reserve
            the right to take measures to prevent any such activity.
          </p>
          <p>
            You agree not to gain or attempt to gain unauthorized access to any portion or feature of the Site, or any
            other system or network connected to the Site or to any of our business partners’ servers, systems or
            networks, by hacking, &quot;password-mining&quot; or using any other illegitimate method of accessing data.
          </p>
          <p>
            You agree not to probe, scan or test the vulnerability of the Site or any network connected to the Site, or
            breach the security or authentication measures on the Website or any network connected to any Website. You
            agree to not reverse look-up, trace or seek to trace any information on any other visitor to the Site, or
            any other Authorized Dealer, including any shopping account that is not held by you, in any way where the
            purpose is to discover materials or information, including but not limited to personal data as defined under
            European data protection legislation (including the GDPR) (hereinafter referred to as &quot;personal
            data&quot;) or other information that reasonably could be used to connect non-personal data to personal
            data.
          </p>
          <p>
            You agree not to take any action that would cause an unreasonably or disproportionately large load on the
            infrastructure of the Site or our systems or networks, or any systems or networks connected to the Website
            or to us in an attempt to overwhelm our systems to create a &quot;Denial of Service&quot; or similar attack.
          </p>
          <p>
            You agree not to use any device, technology or method to interfere or attempt to interfere with the proper
            functioning or features of the Site or any transaction occurring on the Site, or with any other Authorized
            Dealer’s use of the Site.
          </p>
          <p>
            You agree not to forge headers or otherwise manipulate identifiers in order to disguise the origin of any
            message or transmittal you send to us on or through any Site or any service offered on or through any Site.
            You agree not to impersonate or pretend that you are any other person or falsely claim you represent another
            person.
          </p>
        </section>
        <section>
          <h2>9. MODIFICATION OF THESE TERMS AND CONDITIONS OF USE</h2>
          <p>
            We reserve the right at all times to discontinue or modify any part of these Ts&amp;Cs of Use as we deem
            necessary or desirable. If we make changes that materially affect your use of the Site or our services we
            will notify you by posting notice of the change on the Site and or via email to the current email address
            registered with your account. Any changes to these Ts&amp;Cs of Use will be effective upon our posting of
            notice of the changes on our Site. We suggest that you revisit these T&amp;Cs of Use frequently to ensure
            that you stay informed of any such notifications of such changes. Your continued use of the Site or services
            available through it after any update of the Ts&amp;Cs of Use will constitute acceptance of the modified
            Ts&amp;Cs of Use. We also reserve the right to change or discontinue any aspect or feature of the Site
            including, but not limited to, services available through it, and requirements for its access or use.
          </p>
        </section>
        <section>
          <h2>11. UNSOLICITED IDEAS</h2>
          <p>
            If you send us unsolicited ideas, suggestions, material, images or other work in any form (&quot;unsolicited
            materials&quot;), you understand and agree that we have no obligation to review any unsolicited materials,
            nor to keep any unsolicited materials confidential; and notwithstanding any cover letter or other terms that
            accompany such unsolicited materials, we will own, and may use and redistribute unsolicited materials for
            any purpose, without restriction and free of any obligation to acknowledge or compensate you.
          </p>
        </section>
        <section>
          <h2>10. DISCLAIMER OF WARRANTY</h2>
          <p>
            In using the Site, you agree that neither LS&amp;Co., any of its subsidiaries, affiliates, nor any of their
            respective employees, agents, third party content providers or licensors, or any of its officers, directors,
            employees or agents, warrant that use of this Site will be uninterrupted or error free; nor do they make any
            warranty as to (i) the results that may be obtained from your relationship with us as an Authorized Dealer
            nor about (ii) the accuracy, completeness, reliability or completeness of any information, related to the
            Products made available through the Site. We hereby disclaim any and all representations, warranties and
            conditions, whether express or implied, as to the operation of the Site or the results that may be obtained
            through its use, directly or indirectly.
          </p>
        </section>
        <section>
          <h2>12. CHOICE OF LAW AND JURISDICTION</h2>
          <p>
            Any disputes arising out of or related to these Ts&amp;CS of Use and/or any use by you of the Site shall be
            governed by the laws of Belgium, without regard to its choice of law rules and without regard to conflicts
            of laws principles. You irrevocably consent to the exclusive jurisdiction and venue of the courts of
            Brussels for all proceedings in court under these Ts&amp;Cs of Use.
          </p>
        </section>
        <section>
          <h2>13. ADDITIONAL DISCLOSURES</h2>
          <p>
            No waiver by either us or you of any breach or default or failure to exercise any right allowed under these
            Ts&amp;Cs of Use is a waiver of any preceding or subsequent breach or default or a waiver or forfeiture of
            any similar or future rights under our Agreement. The section headings used herein are for convenience only
            and shall be of no legal force or effect. If a court of competent jurisdiction holds any provision of these
            Ts&amp;Cs of Use invalid, such invalidity shall not affect the enforceability of any other provisions
            contained in these Ts&amp;Cs of Use, and the remaining portions of these Ts&amp;Cs of Use shall continue in
            full force and effect.
          </p>
          <p>If you are contracting with LSE, correspondence should be directed to:</p>
          <p>Levi Strauss &amp; Co.</p>
          <p>Europe SCA/Comm.VA</p>
          <p>Airport Plaza - Rio Building</p>
          <p>19 Leonardo Da Vincilaan</p>
          <p>1831 Diegem</p>
          <p>Belgium</p>
        </section>
        <section>
          The provisions of these Ts&amp;Cs of Use apply equally to and are for the benefit of all companies in the
          LS&amp;Co. Group and any authorized third-party service providers, and each shall have the right to assert and
          enforce such provisions directly or on its own behalf.
        </section>
      </ScrollableContent>
      <Button variant="primary" disabled={!saveAcceptTerms.isIdle} onClick={() => saveAcceptTerms.mutate()}>
        {str('general.agree')}
      </Button>
    </Wrapper>
  );
};
