import { MotionOutlet } from 'components/MotionOutlet';
import { SlotId, Toolbar } from 'pages';
import { Slot } from 'providers';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.xxl')};
  padding: 0 0 ${rem(12)};

  &:empty {
    display: none;
  }

  > * {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
  }

  > div:nth-child(2) {
    justify-content: flex-end;
  }

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
    padding-left: ${themed('spacing.xl')};
    padding-right: ${themed('spacing.xl')};
    background-color: ${themed('color.offWhite')};
  }
`;

export const CustomerLayout = () => {
  return (
    <Container>
      <Top>
        <Slot id={SlotId.CustomerMenu} dontRenderEmpty />
        <Slot id={SlotId.CustomerToolbar} Container={Toolbar.Container} Wrapper={Toolbar.ItemWrapper} dontRenderEmpty />
      </Top>
      <MotionOutlet animate />
    </Container>
  );
};
