import { SlotId, Toolbar } from 'pages';
import { Slot } from 'providers';
import type { ReactNode } from 'react';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { MainNav } from './MainNav';
import { YourXXLogo } from './YourXXLogo';

const Container = styled.div`
  position: relative;
  background-color: ${themed('color.white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.xxl')};
  padding: ${themed('spacing.xs')} ${themed('spacing.xl')};

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${rem(1)};
    background-color: ${themed('color.greyLight')};
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

const HeaderTopToolbar = styled.div`
  flex-grow: 1;
  justify-content: flex-end;
`;

const ToolbarContainer = ({ children }: { children: ReactNode }) => {
  return (
    <HeaderTopToolbar>
      <Toolbar.Container>{children}</Toolbar.Container>
    </HeaderTopToolbar>
  );
};

export const Header = () => (
  <>
    <Container>
      <YourXXLogo />
      <Slot id={SlotId.HeaderTopToolbar} Container={ToolbarContainer} Wrapper={Toolbar.ItemWrapper} />
    </Container>
    <MainNav />
  </>
);
