import { useEnsureRouteParams, useIAM } from 'utils';

import { CustomerOrder } from './CustomerOrder';

export const CustomerOrderRoute = () => {
  const [orderId, season] = useEnsureRouteParams('orderId', 'season');
  const { canUse } = useIAM();
  if (canUse(`orders.view`)) {
    return <CustomerOrder orderId={orderId} season={season} />;
  }
  return null;
};
