import { useLocalisation } from 'providers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import { canvasNewComparisonTag, useRoute } from 'utils';

import { createBoard as apiCreateBoard } from '@yourxx/ui-utils';

import { useCanvas } from './useCanvas';

type BoardDetails = { pc9Codes?: string[]; name?: string; redirect?: boolean };
export type UseCreateBoard = {
  createBoard: (details?: BoardDetails) => void;
  isCreatingBoard: boolean;
  hasError: string | undefined;
};

export const useCreateBoard = ({
  customerId,
  assortmentId,
  lineId
}: {
  customerId?: string;
  assortmentId?: string;
  lineId?: string;
}): UseCreateBoard => {
  const [str] = useLocalisation();
  const { setBoards } = useCanvas();
  const { route, params } = useRoute();
  const navigate = useNavigate();
  const [isCreatingBoard, setIsCreatingBoard] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();

  const createBoard = (details?: BoardDetails) => {
    setIsCreatingBoard(true);
    apiCreateBoard({
      lineId,
      assortmentId,
      pc9Codes: details?.pc9Codes,
      name: details?.name ?? str('Canvas.board.newName')
    })
      .then(board => {
        setBoards(prev => [...new Set([...prev, board])]);
        canvasNewComparisonTag({
          assortmentId,
          customerId,
          comparisonId: board.boardId
        });
        setIsCreatingBoard(false);
        if (details?.redirect && route) {
          if (route.matches(routes.lineProducts)) {
            navigate(
              `${routes.lineCanvasBoard.with({
                ...params,
                productModalType: 'canvas',
                canvasType: 'comparator',
                canvasBoardId: board.boardId
              })}`
            );
          }
          if (route.matches(routes.assortmentProducts)) {
            navigate(
              `${routes.assortmentCanvasBoard.with({
                ...params,
                productModalType: 'canvas',
                canvasType: 'comparator',
                canvasBoardId: board.boardId
              })}`
            );
          }
        }
      })
      .catch(() => setHasError('Error creating board'));
  };

  return { createBoard, isCreatingBoard, hasError };
};
