import type { Customer } from 'services';

import { Defect, toggleArrayValue } from '@yourxx/support';

export interface CopyAssortmentState {
  inheritedAssortmentName: string;
  assortmentName: string;
  targetCustomers: readonly Customer[];
  copyOptions: readonly { id: string; name: string; isDisabled: boolean; isSelected: boolean }[];
  changeName(newName: string): this;
  toggleOption(optionId: string): this;
  toggleCustomer(...customers: Customer[]): this;
  isValid: boolean;
}

export const CopyAssortmentState = (params: {
  assortmentName: string;
  customAssortmentName?: string;
  selectedCopyOptions?: string[];
  targetCustomers?: Customer[];
  labeler: (id: string) => string;
}): CopyAssortmentState => {
  const supportedCopyOptions = ['products', 'productAttributes', 'storeGrades', 'stories'];
  params.selectedCopyOptions ??= ['products'];

  return {
    inheritedAssortmentName: params.assortmentName,
    assortmentName: params.customAssortmentName ?? '',
    targetCustomers: params.targetCustomers ?? [],
    copyOptions: supportedCopyOptions.map(id => ({
      id,
      name: params.labeler(id),
      get isDisabled() {
        return id === 'productAttributes' && !params.selectedCopyOptions?.includes('products');
      },
      get isSelected(): boolean {
        return params.selectedCopyOptions?.includes(id) ?? false;
      }
    })),
    changeName(customAssortmentName: string) {
      return CopyAssortmentState({ ...params, customAssortmentName });
    },
    toggleOption(id: string) {
      if (!supportedCopyOptions.includes(id))
        throw new Defect(
          `Attempted to set unknown option "${id}". Supported option ids are: ${supportedCopyOptions.join(', ')}.`
        );

      const selectedCopyOptions = toggleArrayValue([id], params.selectedCopyOptions ?? []).filter(
        (id, _, ids) => !(id === 'productAttributes' && !ids.includes('products'))
      );

      return CopyAssortmentState({ ...params, selectedCopyOptions });
    },
    toggleCustomer(...restCustomers) {
      const targetCustomers = toggleArrayValue(restCustomers, params.targetCustomers ?? []);
      return CopyAssortmentState({ ...params, targetCustomers });
    },
    get isValid() {
      return !!params.targetCustomers?.length && !!params.assortmentName.trim();
    }
  };
};
