import { Console } from 'console';

import { AuditEvent, AuditLog } from '@yourxx/types/src/audit';
import { ToCase } from '@yourxx/types/src/enum';
import { LogAttributes, LoggerMessage, LogInfo, LogLevel } from '@yourxx/types/src/logger';
import { MonitoringSchema } from '@yourxx/types/src/monitoring';
import { MessageType, TeamsMessage } from '@yourxx/types/src/notifications';

import { mergeArraysInNestedObjects, transformObject } from '../utils';

export const loggerLevels: { [Key in LogLevel]: LogInfo } = {
  TRACE: { severityNumber: 1, level: 'TRACE', method: 'trace' },
  DEBUG: { severityNumber: 5, level: 'DEBUG', method: 'debug' },
  INFO: { severityNumber: 9, level: 'INFO', method: 'info' },
  WARN: { severityNumber: 13, level: 'WARN', method: 'warn' },
  ERROR: { severityNumber: 17, level: 'ERROR', method: 'error' },
  FATAL: { severityNumber: 21, level: 'FATAL', method: 'error' },
  CRITICAL: { severityNumber: 24, level: 'CRITICAL', method: 'error' },
  SILENT: { severityNumber: 28, level: 'SILENT', method: 'error' },
  TEAMS: { severityNumber: 99, level: 'WARN', method: 'info' },
  AUDIT: { severityNumber: 99, level: 'WARN', method: 'info' }
} as const;

export const Logger = () => {
  const getConsole = () => {
    if (process.env.IS_LOCAL !== 'true' && typeof Console === 'function') {
      return new Console({ stdout: process.stdout, stderr: process.stderr });
    }
    return console;
  };

  const config: { logLevel: LogInfo; console: Console; coldStart: boolean } = {
    logLevel: loggerLevels[(process.env.LOG_LEVEL ?? 'WARN') as LogLevel],
    console: getConsole(),
    coldStart: true
  };

  let persistedAttributes: { [k: string]: any } = {};

  const formatMessage = (data: any) => {
    return `${JSON.stringify(data, null, process.env.IS_LOCAL === 'true' ? 2 : 0)}\n`;
  };

  const createMessage = ({ level, message }: { level: LogLevel; message: LoggerMessage }) => {
    const { audit: _, notify: __, ...persisted } = getPersistedAttributes();
    const baseMessage = { level, timestamp: new Date(), ...persisted };
    if (typeof message === 'string') {
      return formatMessage({ ...baseMessage, message });
    }
    return formatMessage({ ...baseMessage, message });
  };

  const processLog = (logLevel: LogInfo, message: LoggerMessage) => {
    if (logLevel.severityNumber >= config.logLevel?.severityNumber) {
      config.console[logLevel.method](createMessage({ level: logLevel.level, message }));
    }
  };

  const init = () => {
    persistedAttributes = {};
    persistedAttributes.coldStart = config.coldStart;
    config.coldStart = false;
  };

  const addPersistedAttribute = (attributes: LogAttributes) => {
    persistedAttributes = mergeArraysInNestedObjects(persistedAttributes, attributes);
    return persistedAttributes;
  };

  const getPersistedAttributes = () => ({ ...persistedAttributes });

  const addAuditAttributes = <T = AuditEvent>(input: T) => {
    addPersistedAttribute({ audit: { ...getAuditAttributes(), ...input } });
    return getAuditAttributes();
  };

  const getAuditAttributes = () => {
    return getPersistedAttributes().audit;
  };

  return {
    trace: (input: LoggerMessage) => processLog(loggerLevels.TRACE, input),
    debug: (input: LoggerMessage) => processLog(loggerLevels.DEBUG, input),
    error: (input: LoggerMessage) => processLog(loggerLevels.ERROR, input),
    warn: (input: LoggerMessage) => processLog(loggerLevels.WARN, input),
    info: (input: LoggerMessage) => processLog(loggerLevels.INFO, input),
    log: (input: LoggerMessage) => processLog(loggerLevels.INFO, input),
    teams: (input: TeamsMessage) => {
      if (input.messageStatus === MessageType.FAILED) {
        processLog(loggerLevels.ERROR, { error: input.error, message: input.text });
      }

      const notify = {
        destination: 'msteams',
        ...mergeArraysInNestedObjects(persistedAttributes?.notify, input)
      };
      config.console.warn(formatMessage({ level: 'WARN', timestamp: new Date(), type: 'notify', notify }));
    },
    audit: <T = AuditEvent>(input: T) => {
      const auditPayload = { isoTime: new Date().toISOString(), ...logger.getAuditAttributes(), ...input };
      const audit: AuditLog<T> = transformObject(auditPayload, { toCase: ToCase.Camel });
      config.console.warn(formatMessage({ level: 'WARN', timestamp: new Date(), type: 'audit', audit: audit }));
    },
    alert: (event: MonitoringSchema) => {
      const alert: MonitoringSchema = transformObject(event, { toCase: ToCase.Camel });
      config.console.warn(formatMessage({ level: 'WARN', timestamp: new Date(), type: 'alert', alert: alert }));
    },
    addAuditAttributes,
    getAuditAttributes,
    addPersistedAttribute,
    getPersistedAttributes,
    init,
    setServiceName: (serviceName: string) => addPersistedAttribute({ serviceName: [serviceName] }),
    setTeamsChannel: (channel: string) => addPersistedAttribute({ notify: { channel } }),
    setTeamsHeading: (heading: string) => addPersistedAttribute({ notify: { heading } }),
    setTeamsSubHeading: (subHeading: string) => addPersistedAttribute({ notify: { subHeading } })
  };
};

export const logger = Logger();
