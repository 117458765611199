export const getBoardImagePc9Codes = (currentPc9Codes: string[] = [], newItems: { pc9Code?: string }[] = []) => {
  const pc9CodeList = currentPc9Codes;
  for (let i = 0; i < newItems?.length; i++) {
    const newPc9 = newItems[i].pc9Code;
    if (newPc9 && !pc9CodeList.includes(newPc9)) {
      pc9CodeList.push(newPc9);
    }
  }
  return [...new Set(pc9CodeList)].sort().slice(0, 4);
};
