import { useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { Language } from 'services';

import { Nullable } from '@yourxx/support';
import { LocalisationStrings } from '@yourxx/translations/en';

import { LocalisationContext } from './LocalisationProvider';

export type LocalisedStringFor = (
  stringId: keyof LocalisationStrings,
  params?: Record<string, number | string | boolean | undefined>
) => string;

interface Helpers {
  currentLocale: Nullable<Language>;
  changeLocale(locale: Language): void;
  availableLocales(): ReadonlyArray<Language>;
}

export const useLocalisation = (): readonly [str: LocalisedStringFor, isLoading: boolean, helpers: Helpers] => {
  const value = useContext(LocalisationContext);

  if (!value)
    throw new ReferenceError(
      'Hook "useLocalisation" used outside its provider. Make sure <LocalisationProvider /> is a parent to your components.'
    );

  const intl = useIntl();

  const localisedStringFor = useCallback<LocalisedStringFor>(
    (stringId, params) => {
      if (!value.currentLocale) return stringId;

      const string = intl.formatMessage({ id: stringId }, params);

      // intl return the ID as fallback when it does not recognise it.
      if (value.throwOnErrors && string === stringId)
        throw new RangeError(`Unrecognised localisation string id '${stringId}' passed.`);

      return string;
    },
    [intl, value]
  );

  const helpers = useMemo<Helpers>(
    () => ({
      currentLocale: value.currentLocale,
      changeLocale: value.changeLocale,
      availableLocales: () => value.availableLocales
    }),
    [value.availableLocales, value.changeLocale, value.currentLocale]
  );

  return [localisedStringFor, value.isLoading, helpers] as const;
};
