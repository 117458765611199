import { SlotId } from 'pages';
import { useSlot } from 'providers';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { themed } from 'utils';

const StyledLink = styled(Link)`
  @media (hover: hover) {
    &:hover {
      color: ${themed('color.grey')};
    }
  }
`;

export const useAddBreadcrumb = (label: string, position: number, url?: string) => {
  useSlot(
    SlotId.PageBreadcrumbs,
    useMemo(() => {
      if (url) return <StyledLink to={url}>{label}</StyledLink>;
      return <span>{label}</span>;
    }, [label, url]),
    position
  );
};
