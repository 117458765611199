import type { AnyFunction } from '@yourxx/support';

export const debounce = <F extends AnyFunction>(cb: F, delay = 200) => {
  let timeoutId: number;

  return (...params: Parameters<F>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = Number(
      setTimeout(() => {
        cb(...params);
      }, delay)
    );
  };
};
