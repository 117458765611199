export enum EnvironmentPrefix {
  qa = 'qa',
  dev = 'dev',
  preprod = 'pre-prod',
  prod = 'prod',
  local = 'local'
}

export interface EnvVariables {
  ENV_INDICATOR_TYPE: string;
}

export type EnvConfig = Record<EnvironmentPrefix, EnvVariables>;

export const configsByType = {
  local: { text: 'Local' },
  development: { text: 'Dev' },
  testing: { text: 'QA' },
  staging: { text: 'Preprod' },
  production: null,
  dev: { text: 'Dev' },
  qa: { text: 'QA' },
  preprod: { text: 'Preprod' },
  prod: null
};

export type EnvironmentType = keyof typeof configsByType;

export const isEnvironmentType = (value: unknown): value is EnvironmentType =>
  /* eslint-disable-next-line no-prototype-builtins */
  typeof value === 'string' && configsByType.hasOwnProperty(value);

export const getEnvConfig = (): EnvVariables => envConfig[getEnvPrefix()];

export const getEnvPrefix = (): EnvironmentPrefix => {
  const hostname = window.location.hostname;
  switch (true) {
    case hostname.includes('qa'):
      return EnvironmentPrefix.qa;
    case hostname.includes('preprod'):
      return EnvironmentPrefix.preprod;
    case hostname === 'yourxx.levistrauss.com':
      return EnvironmentPrefix.prod;
    case hostname.includes('localhost'):
      return EnvironmentPrefix.local;
    default:
      return EnvironmentPrefix.dev;
  }
};

export const envConfig: EnvConfig = {
  [EnvironmentPrefix.qa]: { ENV_INDICATOR_TYPE: 'qa' },
  [EnvironmentPrefix.dev]: { ENV_INDICATOR_TYPE: 'dev' },
  [EnvironmentPrefix.local]: { ENV_INDICATOR_TYPE: 'dev' },
  [EnvironmentPrefix.preprod]: { ENV_INDICATOR_TYPE: 'preprod' },
  [EnvironmentPrefix.prod]: { ENV_INDICATOR_TYPE: 'prod' }
};
