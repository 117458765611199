export interface RetryUntilConfig {
  maxRetries?: number;
  initialDelayMs?: number;
  timeoutMs?: number;
}

export const retryUntil = <TInterim, TReturn, P extends unknown[]>(
  task: (...args: P) => Promise<TInterim | TReturn>,
  done: (result: TInterim | TReturn) => result is TReturn,
  customConfig: RetryUntilConfig = defaults
) => {
  const { maxRetries, initialDelayMs, timeoutMs } = {
    ...defaults,
    ...customConfig
  };
  let attempts = 0;
  let retryDelay = initialDelayMs;
  let startTime = 0; // 0 Means unset

  const reset = () => {
    attempts = 0;
    retryDelay = initialDelayMs;
    startTime = 0;
  };

  return async function attempt(...args: P): Promise<TReturn> {
    const now = Date.now();
    if (!startTime) startTime = now;

    const elapsedTime = now - startTime;
    if (elapsedTime > timeoutMs) {
      // We capture state before resetting
      const retryTimeout = new RetryTimeout(attempts, elapsedTime, task.name);
      reset();
      throw retryTimeout;
    }

    if (++attempts > maxRetries) {
      // We capture state before resetting
      const tooManyRetries = new TooManyRetries(attempts - 1, task.name);
      reset();
      throw tooManyRetries;
    }

    const result: TInterim | TReturn = await task(...args);
    if (done(result)) {
      reset();
      return result;
    }
    await new Promise(resolve => setTimeout(resolve, retryDelay));
    retryDelay *= 2;
    return attempt(...args);
  };
};

export class RetryUntilError extends Error {}

export class TooManyRetries extends RetryUntilError {
  constructor(
    public readonly attempts: number,
    taskName?: string
  ) {
    super(`Too many attempts retrying${taskName ? ` task ${taskName}` : ''}.`);
  }
}

export class RetryTimeout extends RetryUntilError {
  constructor(
    public readonly attempts: number,
    public readonly elapsedTime: number,
    taskName?: string
  ) {
    super(`Giving up on retrying${taskName ? ` task ${taskName}` : ''} after ${elapsedTime}ms.`);
  }
}

const defaults: Required<RetryUntilConfig> = {
  maxRetries: 10,
  initialDelayMs: 200,
  timeoutMs: 90 * 1000
};
