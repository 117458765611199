import { Button } from 'components/Button';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { themed } from 'utils';

const Group = styled.div<{ $background?: string }>`
  display: inline-flex;
  background-color: ${({ $background }) => ($background ? $background : themed('color.greyLight'))};
  border-radius: ${themed('borderRadius')};
`;
const Item = styled(Button)<{ $isChecked?: boolean; $colors?: { bg: string; on: string; off: string } }>`
  background-color: ${({ $isChecked, $colors }) =>
    $isChecked ? ($colors ? $colors.on : themed('color.black')) : $colors ? $colors.off : themed('color.greyLight')};
  color: ${({ $isChecked }) => ($isChecked ? themed('color.white') : themed('color.black'))};
  padding: ${themed('spacing.m')};
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.regular')};
`;

export const ToggleGroup = ({
  className,
  items,
  value,
  onChange,
  colors
}: {
  className?: string;
  items: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
  colors?: { bg: string; on: string; off: string };
}) => {
  const [checkedItem, setCheckedItem] = useState<string>(value);

  useEffect(() => onChange?.(checkedItem), [checkedItem, onChange]);

  return (
    <Group className={className} role="group" $background={colors?.bg}>
      {items.map(({ value, label }) => (
        <Item
          type="button"
          role="radio"
          aria-checked={value === checkedItem}
          $isChecked={value === checkedItem}
          key={value}
          value={value}
          onClick={() => setCheckedItem(value)}
          $colors={colors}
        >
          {label}
        </Item>
      ))}
    </Group>
  );
};
