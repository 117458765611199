import { Check } from 'assets/icons';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Wrapper = styled.label<{
  $checked: boolean;
  $disabled: boolean;
  $hidden: boolean;
  $rounded: boolean;
  $extraPadding: boolean;
}>`
  padding: ${({ $extraPadding }) => ($extraPadding ? themed('spacing.m') : 0)};
  flex-shrink: 0;
  border: none;
  opacity: ${({ $hidden, $disabled }) => ($hidden ? 0 : $disabled ? 0.25 : 1)};
  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
  cursor: ${({ $disabled, $hidden }) => ($hidden ? 'none' : $disabled ? 'not-allowed' : 'pointer')};

  div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${rem(20)};
    height: ${rem(20)};
    border-radius: ${({ $rounded }) => ($rounded ? '50%' : themed('spacing.s'))};
    background-color: ${({ $checked, $disabled }) =>
      $checked ? themed('color.black') : $disabled ? themed('color.greyLight') : themed('color.transparentLightBlack')};
  }
  input {
    position: absolute;
    inset: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }
`;
const StyledCheckbox = styled.span<{ $type: string }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ $type }) => ($type === 'icon' ? 'transparent' : themed('color.red'))};
  border-radius: ${themed('spacing.s')};
`;

export const Checkbox = ({
  className,
  checked = false,
  onChange,
  label,
  disabled = false,
  hidden = false,
  stopPropagation = true,
  type = 'icon',
  rounded = false,
  extraPadding = false,
  id
}: {
  className?: string;
  checked?: boolean;
  onChange?: (val: boolean) => void;
  label?: string;
  disabled?: boolean;
  hidden?: boolean;
  stopPropagation?: boolean;
  type?: 'filled' | 'icon';
  rounded?: boolean;
  extraPadding?: boolean;
  id?: string;
}) => (
  <Wrapper
    className={className}
    $checked={checked}
    $disabled={disabled}
    $hidden={hidden}
    $rounded={rounded}
    $extraPadding={extraPadding}
    onClick={e => stopPropagation && e.stopPropagation()}
  >
    {label}
    <div>
      <input
        id={id}
        type="checkbox"
        role="checkbox"
        aria-checked={checked}
        checked={checked}
        disabled={disabled}
        onChange={e => onChange?.(e.target.checked)}
      />
      <StyledCheckbox $type={type}>{type === 'icon' && checked && <Check />}</StyledCheckbox>
    </div>
  </Wrapper>
);
