import { useCallback, useMemo, useState } from 'react';

export interface UseSidePanelReturn<T> {
  id: T | undefined;
  set(id: T): void;
  is(expectedId: T): boolean;
}

export const useSidePanel = <T>(initial?: T): UseSidePanelReturn<T> => {
  const [id, setId] = useState<T | undefined>(initial);

  const set = useCallback((id: T) => {
    setId(prev => (prev === id ? undefined : id));
  }, []);

  const is = useCallback((expectedId: T) => id === expectedId, [id]);

  return useMemo(
    () => ({
      id,
      set,
      is
    }),
    [id, is, set]
  );
};
