import { InsightsAlt } from 'assets/icons';
import { FlexWrapper, Insights, Modal, useGetInsights, useInsights } from 'components';
import { SlotId, Toolbar } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { insightsHeaderClickTag, relativePath, rem, themed } from 'utils';

const NotificationLabel = styled.div`
  border-radius: 50%;
  background-color: ${themed('color.red')};
  color: ${themed('color.white')};
  position: absolute;
  top: 0;
  right: ${rem(-4)};
  width: ${rem(18)};
  height: ${rem(18)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${themed('font.size.xs')};
  letter-spacing: ${rem(-0.5)};
  line-height: 0;
`;

/**
 * TODO: Render in nested route once ProductsPage gets split up.
 */
const Setup = () => {
  const [str] = useLocalisation();
  const location = useLocation();
  const navigate = useNavigate();
  const { customerId, assortmentId } = useParams();
  const hasRequestedInsights = /\/insights\/*$/i.test(location.pathname);

  const { getInsightsCount } = useGetInsights();
  const { insightsCount } = useInsights();
  const [newCount, setNewCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const hasAnyInsights = newCount > 0 || pendingCount > 0;
  const isDisabled = !hasAnyInsights;

  useEffect(() => {
    getInsightsCount();
  }, [getInsightsCount]);

  useEffect(() => {
    if (insightsCount) {
      const newCount = (insightsCount.recommendations?.new ?? 0) + (insightsCount.suggestions?.new ?? 0);
      const pendingCount = (insightsCount.recommendations?.pending ?? 0) + (insightsCount.suggestions?.pending ?? 0);
      setNewCount(newCount);
      setPendingCount(pendingCount);
    }
  }, [insightsCount]);

  const onClose = useCallback(() => navigate(relativePath('..')), [navigate]);

  useEffect(() => {
    if (hasRequestedInsights && !hasAnyInsights) onClose();
  }, [hasAnyInsights, hasRequestedInsights, onClose]);

  const hint = useMemo(
    () =>
      hasAnyInsights && (
        <FlexWrapper>
          <span>
            {newCount > 0 &&
              str('Header.newInsights', {
                count: newCount
              })}
          </span>
          <span>
            {pendingCount > 0 &&
              str('Header.pendingInsights', {
                count: pendingCount
              })}
          </span>
        </FlexWrapper>
      ),
    [hasAnyInsights, newCount, pendingCount, str]
  );

  useSlot(
    SlotId.HeaderToolbarRight,
    useMemo(
      () => (
        <Toolbar.Item
          // The key here ensures the link updates when route changes, making sure the relative path is kept in sync.
          key={location.pathname}
          icon={
            <>
              <InsightsAlt />
              {newCount > 0 && <NotificationLabel>{newCount > 9 ? '9+' : newCount}</NotificationLabel>}
            </>
          }
          to={relativePath('./insights')}
          isDisabled={isDisabled}
          onClick={() => {
            insightsHeaderClickTag({ customerId, assortmentId });
          }}
          hint={{
            text: hint,
            position: 'left',
            offset: [58]
          }}
        />
      ),
      [assortmentId, customerId, hint, isDisabled, location.pathname, newCount]
    ),
    2
  );

  useSlot(
    SlotId.Modals,
    useMemo(
      () =>
        hasRequestedInsights && (
          <Modal onClose={onClose} hideCloseButton>
            <Insights onClose={onClose} />
          </Modal>
        ),
      [hasRequestedInsights, onClose]
    )
  );

  return null;
};

export const InsightsSetup = () => {
  return <Setup />;
};
