import { retryUntil } from '@yourxx/support';
import {
  type AssortmentSummaryResponse,
  type CopyAssortmentResults,
  type CreateAssortmentPayload,
  type CreateAssortmentResults,
  CustomerView,
  type GetAuditTrailResponse,
  type GetCustomerAssortmentResponse,
  PricingGroup,
  type UpdateAssortmentPayload,
  type UpdateAssortmentStatus
} from '@yourxx/types';

import { apiEndpoints, toRequest } from '../../utils';
import { ExportResponse, pollExport } from '../admin/exports';
import { apiClient } from '../client';

export const getCustomerAssortments = async (params: {
  brand?: string;
  customerId?: string;
}): Promise<GetCustomerAssortmentResponse> => {
  const request = toRequest({
    path: apiEndpoints.customers,
    method: 'GET',
    params: { ...params, type: CustomerView.Assortment }
  });
  return apiClient.request<GetCustomerAssortmentResponse>(request);
};

export const getAuditTrail = async ({ assortmentId }: { assortmentId: string }) => {
  const url = apiEndpoints.auditTrail({ assortmentId });
  const request = toRequest({ path: url, method: 'GET', params: { pageSize: 100 } });
  return apiClient.request<GetAuditTrailResponse>(request);
};

export const updateAssortment = async ({
  id,
  data: body
}: {
  id: string;
  data: UpdateAssortmentPayload;
}): Promise<UpdateAssortmentStatus> => {
  const request = toRequest({ path: apiEndpoints.editAssortment({ assortmentId: id }), method: 'PATCH', body });
  return apiClient.request<UpdateAssortmentStatus>(request);
};

export const createAssortment = async (payload: CreateAssortmentPayload): Promise<CreateAssortmentResults> => {
  const request = toRequest({ path: apiEndpoints.assortments, method: 'POST', body: payload });
  return apiClient.request<CreateAssortmentResults>(request);
};

export const generateExport = async (payload: {
  exportType: string;
  exportFormat: string;
  id: string;
  notInAssortment?: boolean;
  selectedPc9s: string[];
  sort: string;
  type: string;
  status: string[];
  preAppliedFilters?: Record<string, string | string[]>;
  pricingGroupId?: string;
}): Promise<ExportResponse> => {
  return pollExport(toRequest({ path: apiEndpoints.export, method: 'POST', body: payload }), 50);
};

export const copyAssortment = async (payload: {
  assortmentId: string;
  assortmentName: string;
  copyOptions: readonly string[];
  customers: readonly string[];
}): Promise<Record<string, string>> => {
  const request = toRequest({
    path: apiEndpoints.copyAssortment({ assortmentId: payload.assortmentId }),
    method: 'POST',
    body: payload
  });
  let response = await apiClient.request<CopyAssortmentResults>(request);

  if (response && 'error' in response) throw new Error(response.error);
  if (response && 'message' in response) throw new Error(response.message);

  if (response && 'url' in response)
    response = await retryUntil(
      (path: string) => apiClient.request<CopyAssortmentResults>(toRequest({ path, method: 'GET' })),
      (response): response is { customerAssortments: Record<string, string> } => 'customerAssortments' in response,
      { initialDelayMs: 1000 }
    )(response.url);

  if (response && 'customerAssortments' in response) return response.customerAssortments;

  throw new Error('Could not copy assortment');
};

export const getSummary = async ({
  assortmentId,
  excludeCarryOver
}: {
  assortmentId: string;
  excludeCarryOver: boolean;
}) => {
  const request = toRequest({
    path: apiEndpoints.assortmentSummary({ assortmentId }),
    method: 'GET',
    params: { excludeCarryOver: String(excludeCarryOver) }
  });

  const response = await apiClient.request<AssortmentSummaryResponse>(request);

  // TODO: Use retryUntil?
  if ('error' in response) throw new Error(response.error);
  if ('message' in response) throw new Error(response.message);
  if (!('hits' in response)) throw new Error('Could not get assortment summary');

  return response.hits;
};

export const getPricingGroups = async ({ season }: { season: string }): Promise<{ hits: PricingGroup[] }> => {
  const request = toRequest({ path: apiEndpoints.pricingGroups({ season }), method: 'GET' });
  return apiClient.request<{ hits: PricingGroup[] }>(request);
};
