import { RefObject, useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const processResize = (currentWidth: number, segmentWidth: number) => {
  const newSegmentCount = Math.floor(currentWidth / segmentWidth);
  const newWidth = newSegmentCount * segmentWidth;
  return { width: newWidth, segmentCount: newSegmentCount };
};

/** Hook that triggers an update every time the width of the given element passes over a breakpoint defined by segments of equal given width. */
export const useSegmentsBreakpoints = <T extends HTMLElement>(ref: RefObject<T>, segmentWidth: number) => {
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const [values, setValues] = useState<{ segmentCount: number; width: number } | null>(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries: ResizeObserverEntry[] = []) => {
      entries.forEach(entry => {
        setValues(prevState => {
          const res = processResize(entry.contentRect.width, segmentWidth);
          if (res.width !== prevState?.width || res.segmentCount !== prevState.segmentCount) {
            return res;
          }
          return prevState;
        });
      });
    });

    if (ref.current) resizeObserverRef.current.observe(ref.current);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [ref, segmentWidth]);

  return values;
};
