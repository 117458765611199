import { countSets } from './countSets';
import { AnyFilter, FiltersSchema } from './Filter';
import { FiltersOrder } from './FiltersService';

export type FilterOption<V = unknown> = { value: V; label: string; count: number; percentOfTotal: number };

export interface FilterOptionsEntry<V = unknown> {
  filter: AnyFilter;
  options: ReadonlyArray<FilterOption<V>>;
}

export type FilterOptions<V = unknown> = ReadonlyArray<FilterOptionsEntry<V>>;

interface BuildFilterOptionsParams<T, K extends string> {
  schema: FiltersSchema;
  ordering: FiltersOrder;
  items: ReadonlyArray<T>;
  valueGetter?: (item: T, schemaKey: K) => T[keyof T] | undefined;
  labeler?: (filterId: string, optionValue: Required<T[keyof T]>) => string;
  isEligible?: (entry: FilterOptionsEntry<Required<T[keyof T]>>) => boolean;
  override?: (filter: AnyFilter) => null | FilterOptionsEntry<Required<T[keyof T]>>;
  optionsSorter?: (
    filterId: string,
    options: ReadonlyArray<FilterOption<Required<T[keyof T]>>>
  ) => ReadonlyArray<FilterOption<Required<T[keyof T]>>>;
}

export const buildFilterOptions = <T, K extends string>({
  schema,
  ordering,
  items,
  valueGetter,
  labeler,
  isEligible = () => true,
  override = () => null,
  optionsSorter = (_filterId, options) => options
}: BuildFilterOptionsParams<T, K>): FilterOptions => {
  const filterOptions: FilterOptionsEntry[] = [];

  const countedSets = countSets(
    items,
    schema.map(filter => filter.id as K),
    valueGetter
  );
  for (let i = 0; i < ordering.length; i++) {
    const filterId = ordering[i];
    const filter = schema.find(item => item.id === filterId);
    const set = countedSets.find(set => set.key === filterId);

    if (!filter) continue;

    const maybeOverride = override(filter);
    if (maybeOverride && isEligible(maybeOverride)) {
      filterOptions.push(maybeOverride);
      continue;
    }

    const entry: FilterOptionsEntry<Required<T[keyof T]>> = {
      filter,
      options: optionsSorter(
        filter.id,
        set?.values.map(item => ({
          value: item.value,
          label: labeler?.(filter.id, item.value) ?? String(item.value),
          count: item.count,
          percentOfTotal: item.percentOfTotal
        })) ?? []
      )
    };

    if (isEligible(entry)) filterOptions.push(entry);
  }

  return filterOptions.filter(entry => entry.options.length);
};
