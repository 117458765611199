import { ApiEndpoints } from '@yourxx/types';
import { AcceptTermsResponse, UserProfileContext } from '@yourxx/types/src/user';

import { getImageFormat, persistedStore, toRequest } from '../../utils';
import { iam } from '../../utils/permissions';
import { apiClient } from '../client';

export const getUserProfile = async (user?: UserProfileContext): Promise<UserProfileContext | null> => {
  persistedStore.removeUserProfile();
  const imgFmt = await getImageFormat();
  persistedStore.setImgFormat(imgFmt);
  if (user) {
    persistedStore.setUserProfile(user);
    iam.updateAccess(user?.ui);
    return user;
  }
  const accessToken = persistedStore.getAccessToken();
  if (!accessToken) {
    console.error('No access token set');
    return null;
  }
  const headers = new Headers();
  headers.set('Authorization', `Bearer ${accessToken}`);
  const request = toRequest({ path: ApiEndpoints.userProfile, method: 'GET', headers });
  const userProfileFetchResponse = await fetch(request);
  if (!userProfileFetchResponse.ok) {
    console.error('Failed to fetch profile');
    return null;
  }
  const userProfile: UserProfileContext = await userProfileFetchResponse.json();
  persistedStore.setUserProfile(userProfile);
  iam.updateAccess(userProfile?.ui);
  return userProfile;
};

export const patchAcceptedTerms = async (): Promise<AcceptTermsResponse> => {
  const request = toRequest({ path: ApiEndpoints.acceptUserTerms, method: 'PATCH' });
  const { user, acceptedTermsAt } = await apiClient.request<AcceptTermsResponse>(request);
  await getUserProfile(user);
  return { user, acceptedTermsAt };
};
