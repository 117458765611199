import { SimpleNav } from 'pages';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';

export const OrderMenu = () => {
  const [str] = useLocalisation();

  const items = useMemo(
    () => [
      { to: '.', label: str('Order.menu.Order') },
      { to: 'summary', label: str('Order.menu.Summary') }
    ],
    [str]
  );

  return <SimpleNav id="order-menu" items={items} activeOnRouteEnd />;
};
