import { MotionOutlet } from 'components';
import { SlotId, Toolbar } from 'pages';
import { Slot } from 'providers';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

const Container = styled.div`
  padding: 0 0 ${themed('spacing.xxl')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.xxl')};
  padding: ${rem(12)} 0;

  > * {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
  }

  > div:nth-child(2) {
    justify-content: flex-end;
  }
`;

export const OrderLayout = () => {
  return (
    <Container>
      <Top>
        <Slot id={SlotId.OrderMenu} />
        <Slot id={SlotId.OrderToolbar} Container={Toolbar.Container} Wrapper={Toolbar.ItemWrapper} />
      </Top>
      <MotionOutlet animate />
    </Container>
  );
};
