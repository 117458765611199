export enum AssortmentSummaryRowValuesTypeEnum {
  Currency = 'currency',
  Number = 'number',
  Percentage = 'percentage'
}

export enum ExportAssortmentType {
  AssortmentExport = 'assortmentExport',
  LineAssortmentExport = 'lineAssortmentExport',
  SizeGrids = 'sizeGrids'
}

export enum ExportFormat {
  Pdf = 'pdf',
  Csv = 'csv',
  Excel = 'excel'
}

export enum ExportType {
  Assortment = 'assortment',
  Line = 'line',
  SizeGrids = 'SizeGrids'
}

export enum ExportViewType {
  AssortmentByProduct = 'assortment',
  AssortmentByCategory = 'assortmentByCategory',
  AssortmentByCategoryLandscape = 'assortmentByCategoryLandscape',
  AssortmentByVlpOrder = 'assortmentByVlpOrder',
  AssortmentByVlpOrderLandscape = 'assortmentByVlpOrderLandscape',
  LineByProduct = 'lineByProduct',
  LineByCategory = 'lineByCategory',
  LineByCategoryLandscape = 'lineByCategoryLandscape',
  LineByVlpOrder = 'lineByVlpOrder',
  LineByVlpOrderLandscape = 'lineByVlpOrderLandscape',
  SizeGrids = 'SizeGrids'
}

export enum ProductStatus {
  ADD = 'ADD',
  DROP = 'DROP',
  PENDING = 'PENDING',
  REMOVE = 'REMOVE',
  Unknown = 'unknown'
}

export enum Region {
  LSE = 'LSE',
  LSA = 'LSA',
  AMA = 'AMA',
  ANZ = 'ANZ',
  NEU = 'NEU',
  SEU = 'SEU',
  SEA = 'SEA',
  GCH = 'GCH',
  USA = 'USA',
  CAN = 'CAN',
  CNF = 'CNF',
  KOR = 'KOR',
  JPN = 'JPN',
  LAT = 'LAT',
  MEA = 'MEA',
  ZAF = 'ZAF'
}

export enum ReportType {
  YXXMEDIA01 = 'YXXMEDIA01',
  YXXCUST01 = 'YXXCUST01',
  YXXASSORT01 = 'YXXASSORT01',
  YXXBATCH01 = 'YXXBATCH01',
  YXXUSERS01 = 'YXXUSERS01',
  YXXUSERS02 = 'YXXUSERS02',
  YXXASSORTDIST01 = 'Products_Outside_Distribution_Blocks'
}

export enum StoryItemType {
  Media = 'media',
  Product = 'product'
}

export enum StoryThumbnailAemPosterTypeEnum {
  AemPoster = 'aemPoster'
}

export enum StoryThumbnailMediaImageTypeEnum {
  MediaImage = 'mediaImage'
}

export enum VideoLanguage {
  EN = 'EN',
  FR = 'FR',
  DE = 'DE',
  ES = 'ES',
  IT = 'IT'
}

export enum WedgeColType {
  StoreGrade = 'storeGrade',
  DeliveryMonth = 'deliveryMonth'
}

export enum WedgeRowType {
  ColourGroup = 'colourGroup',
  Category = 'category',
  Default = 'default'
}

export enum XStorySectionType {
  Hero = 'hero',
  Intro = 'intro',
  Details = 'details',
  Style = 'style'
}

export enum XStoryType {
  Brand = 'Brand',
  Moment = 'Moment',
  Delivery = 'Delivery',
  Custom = 'Custom'
}

export enum ProductDeliveryTypeEnum {
  Ao = 'ao',
  Pb = 'pb'
}

export enum Indexes {
  partitionKey = 'partitionKey',
  sortKey = 'sortKey'
}

export enum GlobalIndexes {
  sortKeyIndex = 'sortKeyIndex',
  userIdIndex = 'userIdIndex',
  idIndex = 'idIndex'
}

export enum ApiEndpoints {
  authToken = '/auth/token',
  authRefresh = '/auth/refresh',
  userProfile = '/user/profile',
  customerWithParams = '/customers?sort=name%3Aasc&archived=false&deleted=false',
  canvas = '/v2/canvas',
  canvasProduct = '/v2/canvas/products',
  canvasProductDetails = '/v2/canvas/product/details',
  canvasBoardItems = '/v2/canvas/board/items',
  canvasBoardItem = '/v2/canvas/board/item',
  canvasBoardShare = '/v2/canvas/board/share',
  canvasCompareBoard = '/v2/canvas/board/compare',
  canvasBoards = '/v2/canvas/boards',
  canvasBoard = '/v2/canvas/board',
  insightsProductDetails = '/v2/insights/product/details',
  insightsGetForAssortment = '/assortments/{id}/insights',
  insightsCountForAssortment = '/assortments/{id}/insights/count',
  insightsGetForCustomer = '/customers/{id}/insights',
  insightsCountForCustomer = '/customers/{id}/insights/count',
  insightsUpdate = '/assortments/{id}/insights/{timestamp}',
  insightsAddProducts = '/assortments/{id}/products',
  insightsUploadStorySets = '/insights/upload/storysets',
  assortmentProduct = '/assortments/{id}/products/{productId}?pricingGroupId={pricingGroupId}&language={language}',
  assortmentProductUpdate = '/assortments/{id}/products/{productId}',
  lines = '/lines?brand={brand}',
  customerAssortments = 'assortments?customerId={customerId}',
  productSearch = '/v2/products/search',
  stories = '/assortments/{id}/xstories?offset={offset}&limit={limit}&hidden={hidden}',
  customers = '/v2/customer',
  product = '/v2/product',
  line = '/v2/line',
  permissionData = '/v2/permissions',
  permissionActions = '/v2/permissions/actions',
  permissionActionbyId = '/v2/permissions/actions/{id}',
  permissionPolicies = '/v2/permissions/policies',
  permissionPolicyById = '/v2/permissions/policies/{id}',
  permissionRoles = '/v2/permissions/roles',
  permissionRoleById = '/v2/permissions/roles/{id}',
  userPermissions = '/v2/permissions/users',
  authUrl = '/auth/url',
  authLoginUrl = '/auth/login?redirect=false',
  allBrands = '/brands',
  acceptUserTerms = '/user/accept-terms',
  assortments = '/v2/assortments'
}

export enum SfnExecutionStatus {
  ABORTED = 'ABORTED',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  SUCCEEDED = 'SUCCEEDED',
  TIMED_OUT = 'TIMED_OUT'
}

export enum PropertyAlias {
  apid = 'assortment_product_id',
  aid = 'assortment_id',
  avs = 'available_sizes',
  brand = 'brand',
  catg = 'categories',
  co = 'is_carry_over',
  color = 'color',
  colfm = 'color_family',
  creat = 'created_at',
  detail = 'detail',
  disct = 'distribution_categories',
  dlv = 'deliveries',
  draft = 'draft',
  fabnm = 'fabric_name',
  fabwt = 'fabric_weight',
  fabin = 'fabric_innovation',
  fof = 'first_on_floor',
  fit = 'fitting',
  gender = 'gender',
  intr = 'introduction',
  lid = 'line_id',
  lof = 'last_on_floor',
  logox = 'logo_execution',
  lpid = 'line_product_id',
  mersg = 'merchandising_size_group',
  mfp = 'mfp_category',
  mnd = 'mandatory',
  name = 'name',
  note = 'note',
  nwns = 'newness',
  pc5 = 'pc5_code',
  pc9 = 'pc9_code',
  pcgid = 'product_category_group_id',
  pid = 'product_id',
  plnlf = 'planned_lifecycle',
  pricep = 'price_positioning',
  prices = 'prices',
  rnk = 'ranking',
  ros = 'rate_of_sale',
  rplfin = 'replacer_finish',
  rpbpc9 = 'replaced_by_pc9',
  rplpc9 = 'replaces_pc9',
  season = 'season',
  seled = 'selling_end_date',
  selsd = 'selling_start_date',
  seltr = 'sell_through',
  spid = 'season_product_id',
  ssnl = 'seasonality',
  status = 'status',
  susst = 'sustainability_story',
  sustg = 'sustainability_tags',
  updat = 'updated_at',
  vlp = 'vlp_order',
  wam = 'win_at_marketplace',
  wos = 'weeks_on_sale',
  store_grade = 'storeGrade'
}

export enum Brand {
  Levi = 'Levi',
  Dockers = 'Dockers',
  Signature = 'Signature',
  Denizen = 'Denizen'
}

export enum Policy {
  Administrator = 'Administrator - API Access',
  AccountManager = 'Account Manager - API Access',
  Planner = 'Planner - API Access',
  Merchandiser = 'Merchandiser - API Access',
  Marketeer = 'Marketeer - API Access',
  ReadOnly = 'Read-Only - API Access',
  Customer = 'Customer - API Access',
  MyCanvas = 'MyCanvasDefault'
}

export enum FileType {
  CONSO,
  ROS
}

export enum ObjectType {
  AUDIT = 'Audit',
  EVENT = 'Event',
  RECOMMENDATION = 'Recommendation',
  RECOMMENDATION_ITEM = 'RecommendationItem',
  STORY_SET_PRODUCTS = 'StorySetProducts',
  STORY_SUGGESTION = 'StorySuggestion',
  STORY_SUGGESTION_ITEM = 'StorySuggestionItem'
}

export enum RecommendationStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  REMOVED = 'REMOVED'
}

export enum RecommendationItemStatus {
  SUGGESTED = 'SUGGESTED',
  ADDED_TO_ASSORTMENT = 'ADDED_TO_ASSORTMENT',
  REJECTED = 'REJECTED'
}

export enum ValidationType {
  error = 'error',
  boolean = 'boolean',
  value = 'value'
}

export enum AwsSecretStates {
  current = 'AWSCURRENT',
  pending = 'AWSPENDING',
  pendingTest = 'PENDING_TEST'
}

export enum ToCase {
  Camel = 'camel',
  Snake = 'snake',
  Kebab = 'kebab',
  Space = 'space',
  Sentence = 'sentence',
  UpperFirstSpace = 'upperFirstSpace'
}

export enum AssortmentCopyOptions {
  PRODUCTS = 'products',
  PRODUCT_ATTRIBUTES = 'productAttributes',
  STORE_GRADES = 'storeGrades',
  STORIES = 'stories'
}
