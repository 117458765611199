import { motion } from 'framer-motion';
import { SlotId } from 'pages';
import { Slot } from 'providers';
import { styled } from 'styled-components';
import { themed } from 'utils';

import { Toolbar } from '../components';
import { Breadcrumb, Breadcrumbs } from './Breadcrumbs';

const Container = styled(motion.div)`
  display: flex;
  gap: ${themed('spacing.s')};
  flex-direction: column;
  padding: ${themed('spacing.l')} ${themed('spacing.xl')};
  background-color: ${themed('color.offWhite')};
`;

const Top = styled.div`
  display: flex;
  gap: ${themed('spacing.xxl')};

  &:empty {
    display: none;
  }

  > * {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;

    &:nth-child(2) {
      justify-content: flex-end;
    }
  }
`;

const Middle = styled.div`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};

  &:empty {
    display: none;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};

  &:empty {
    display: none;
  }
`;

const PageTitle = styled.h1`
  display: flex;
  margin: 0;
  ${themed('typography.h1')};
  font-weight: ${themed('font.weight.extraBold')};
  text-transform: uppercase;

  &:empty {
    display: none;
  }
`;

const PageToolbarRight = styled.div`
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  gap: ${themed('spacing.s')};
  margin-left: auto;

  &:empty {
    display: none;
  }
`;

export const PageHeader = () => {
  return (
    <Container>
      <Top>
        <Slot id={SlotId.PageBreadcrumbs} Container={Breadcrumbs} Wrapper={Breadcrumb} dontRenderEmpty />
        <Slot id={SlotId.Status} dontRenderEmpty />
      </Top>
      {/* TODO: Provide a way of knowing if nested Slots are empty, in order to remove parent elements from the DOM */}
      <Details>
        <Middle>
          <Slot id={SlotId.PageTitle} Container={PageTitle} dontRenderEmpty />
          <Slot id={SlotId.PageToolbar} Container={Toolbar.Container} Wrapper={Toolbar.ItemWrapper} dontRenderEmpty />
          <Slot id={SlotId.PageToolbarRight} Container={PageToolbarRight} dontRenderEmpty />
        </Middle>
        <Slot id={SlotId.PageDetails} dontRenderEmpty />
      </Details>
    </Container>
  );
};
