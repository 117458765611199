import { Close, Remove } from 'assets/icons';
import { Button } from 'components/Button';
import { FlexWrapper } from 'components/FlexWrapper';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Tooltip } from 'components/Tooltip';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import styled from 'styled-components';
import { insightsPageViewTag, insightsRemoveClickTag, rem, themed } from 'utils';

import { InsightType, removeInsight } from '@yourxx/ui-utils';

import { useGetInsights, useInsightKey, useInsights } from './hooks';
import { InsightsList } from './InsightsList';

const Wrapper = styled.div`
  height: 90vh;
  max-width: 90vw;
  width: ${rem(1300)};
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${themed('spacing.xl')} ${themed('spacing.xl')} ${themed('spacing.m')};
  width: 100%;
  box-sizing: border-box;
`;
const Title = styled.h1`
  font-size: ${themed('font.size.xl')};
  margin: 0;
  position: relative;
  top: -${themed('spacing.s')};
`;
const CloseButton = styled(Button)`
  position: relative;
  top: -${themed('spacing.m')};
  right: -${themed('spacing.m')};

  svg {
    width: ${themed('font.size.xl')};
    height: ${themed('font.size.xl')};
  }
`;
const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding: ${themed('font.size.m')} ${themed('font.size.xl')};
  position: relative;
  z-index: 1;

  button {
    font-weight: ${themed('font.weight.medium')};
    align-items: center;
    padding: 0;
  }
  svg {
    width: ${themed('font.size.xl')};
    height: ${themed('font.size.xl')};
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: ${themed('color.greyLight')};
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`;
const InsightsWrapper = styled.div`
  position: relative;
  flex: 1;
`;
const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: ${themed('color.transparentLightBlack')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Insights = ({ onClose }: { onClose: VoidFunction }) => {
  const [str] = useLocalisation();
  const { selectedInsights, setSelectedInsights, insights } = useInsights();
  const { toastService } = useServices();
  const { customerId, assortmentId: customerAssortmentId, lineId } = useParams();
  const customerOrLineId = customerId ?? lineId;
  const { getInsights } = useGetInsights();
  const { getInsightKey } = useInsightKey();

  const [loadingState, setLoadingState] = useState<'loading' | 'loaded' | 'removing' | 'removed'>('loading');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isInsightLoadingTagSet, setIsInsightLoadingTagSet] = useState<boolean>(false);

  useEffect(() => {
    if (!isInsightLoadingTagSet) {
      insightsPageViewTag({
        customerId: customerOrLineId ? 'assortment' : 'customer',
        assortmentId: customerAssortmentId
      });
      setIsInsightLoadingTagSet(true);
    }
  }, [isInsightLoadingTagSet, customerAssortmentId, customerOrLineId, insightsPageViewTag, setIsInsightLoadingTagSet]);

  useEffect(() => handleGetInsights({ initialLoad: true }), []);

  const handleGetInsights = useCallback(
    ({ initialLoad, removedCount }: { initialLoad: boolean; removedCount?: number }) => {
      getInsights({
        handleLoading: loading =>
          setLoadingState(loading ? (initialLoad ? 'loading' : 'removing') : initialLoad ? 'loaded' : 'removed'),
        handleSuccess: () => {
          if (!initialLoad && removedCount) {
            setSelectedInsights([]);
            toastService.send(str('Insights.successfullyRemoved', { count: removedCount }));
          }
        },
        handleError: () => setErrorMessage(str('Insights.loadingError'))
      });
    },
    [getInsights, setLoadingState, setSelectedInsights, toastService, setErrorMessage]
  );

  const removeSelected = useCallback(() => {
    if (selectedInsights.length > 0) {
      setLoadingState('removing');
      Promise.all(
        selectedInsights.map(insight => {
          insightsRemoveClickTag({
            assortmentId: insight.customer.customer_id,
            customerId: insight.assortmentId,
            recommendationId: getInsightKey(insight)
          });
          return removeInsight(insight.assortmentId, insight.timestamp);
        })
      )
        .then(data => {
          handleGetInsights({ initialLoad: false, removedCount: data.length });
        })
        .catch(e => {
          console.error(e);
          setLoadingState('removed');
          setSelectedInsights([]);
          toastService.send(str('Insights.removingError', { count: selectedInsights.length }), 'error');
        });
    }
  }, [
    str,
    selectedInsights,
    setLoadingState,
    insightsRemoveClickTag,
    getInsightKey,
    removeInsight,
    handleGetInsights,
    setSelectedInsights,
    toastService
  ]);

  return (
    <Wrapper>
      {loadingState === 'loading' && <LoadingSpinner label={str('Insights.loading')} />}
      {loadingState !== 'loading' && errorMessage && (
        <FlexWrapper>
          <p>{errorMessage}</p>
        </FlexWrapper>
      )}
      {loadingState !== 'loading' && !errorMessage && insights.length > 0 && (
        <>
          <Header>
            <Title>{str('Insights.plural')}</Title>
            <CloseButton onClick={onClose}>
              <Close />
            </CloseButton>
          </Header>
          <Content>
            {insights.some(i => i.type !== InsightType.SUGGESTION) && (
              <Menu>
                <Tooltip
                  text={str('Insights.rejectProductsHint')}
                  position="bottom"
                  disabled={loadingState === 'removing' || selectedInsights.length <= 0}
                >
                  <Button
                    disabled={loadingState === 'removing' || selectedInsights.length <= 0}
                    onClick={removeSelected}
                  >
                    <Remove />
                    {selectedInsights.length <= 0
                      ? str('Insights.selectToRemove')
                      : str('Insights.removeSelected', { count: selectedInsights.length })}
                  </Button>
                </Tooltip>
              </Menu>
            )}
            <InsightsWrapper>
              {loadingState === 'removing' && (
                <LoadingWrapper>
                  <LoadingSpinner label={str('Insights.removing', { count: selectedInsights.length })} />
                </LoadingWrapper>
              )}
              <InsightsList />
            </InsightsWrapper>
          </Content>
        </>
      )}
    </Wrapper>
  );
};
