import { Checkbox } from 'components/Checkbox';
import { Image } from 'components/Image';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Product = styled.div`
  user-select: none;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.s')};
  cursor: pointer;

  > span {
    user-select: text;
    display: flex;
    justify-content: center;
    width: 100%;
    color: ${themed('color.grey')};
    ${themed('typography.h5')};
  }
`;

const CheckboxWrapper = styled.div`
  position: absolute;
  top: ${themed('spacing.s')};
  right: ${themed('spacing.s')};
`;

const StyledCheckbox = styled(Checkbox)`
  pointer-events: none;
  width: ${rem(18)};
  height: ${rem(18)};
`;

const Thumbnail = styled.div<{ $isSelected: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: ${themed('borderRadius')};
  min-height: ${rem(73)};
  background-color: ${themed('color.white')};

  &::before {
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    border: solid ${rem(2)} ${({ $isSelected }) => themed($isSelected ? 'color.red' : 'color.black')};
    opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0)};
    will-change: opacity, border-color;
    transition-property: opacity, border-color;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};
  }

  @media (hover: hover) {
    &:hover::before {
      opacity: 1;
    }
  }

  img {
    object-fit: contain;
    width: 100%;
    aspect-ratio: 1;
  }
`;

export const ProductMiniCard = <P extends { pc9: string; name: string; url: string }>({
  className,
  product,
  onClick,
  onSelect,
  canSelectItems = true,
  isSelected,
  imageSize = 192
}: {
  className?: string;
  product: P;
  onClick?: VoidFunction;
  onSelect?: VoidFunction;
  canSelectItems?: boolean;
  isSelected: boolean;
  imageSize?: number;
}) => {
  return (
    <Product key={product.pc9} onClick={onClick} className={className}>
      <Thumbnail $isSelected={isSelected}>
        <Image src={`${product.url}&width=${imageSize}&height=${imageSize}`} alt={product.name} />
      </Thumbnail>
      {canSelectItems && onSelect && (
        <CheckboxWrapper
          onClick={event => {
            // When onClick performs the same action as onSelect, prevent firing twice
            event.stopPropagation();
            onSelect();
          }}
        >
          <StyledCheckbox checked={isSelected} />
        </CheckboxWrapper>
      )}
      <span>{product.pc9}</span>
    </Product>
  );
};
