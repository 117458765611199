import { Button, Checkbox, Dialog, Input, LoadingSpinner, Select, SelectOption, Toggle } from 'components';
import { Entity, type EntityContext } from 'domain-events';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServices } from 'services';
import styled from 'styled-components';
import { plpAddToFaAddTag, plpAddToFaCreateNewTag, plpAddToFaCreateTag, rem, themed, useRoute } from 'utils';

import { CustomerAssortmentArray, UUID } from '@yourxx/types';
import { PageDetails, ProductListData, UIProduct } from '@yourxx/types/src/api/base';
import { createAssortment } from '@yourxx/ui-utils';

const TypeButtons = styled.div`
  display: flex;
  gap: ${themed('spacing.l')};

  > button {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: ${themed('color.white')};
    border-radius: ${themed('spacing.m')};
    height: ${rem(80)};
    position: relative;
    will-change: box-shadow;
    transition: box-shadow 0.2s ease-out;

    @media (hover: hover) {
      &:hover {
        box-shadow: ${themed('boxShadow')};
      }
    }

    label {
      pointer-events: none;
      position: absolute;
      top: ${themed('spacing.m')};
      right: ${themed('spacing.m')};
    }
  }
`;

const PreferencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
  padding: ${themed('spacing.l')} 0;
  align-items: end;

  label {
    display: flex;
    flex-direction: row;
    gap: ${themed('spacing.m')};

    input {
      margin: ${themed('spacing.m')} 0;
    }
  }
`;
const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
  min-height: ${rem(200)};

  label {
    display: flex;
    flex-direction: column;

    input {
      margin: ${themed('spacing.m')} 0;
    }
  }
`;
const ToastMessage = styled.p`
  font-size: ${themed('font.size.m')};
  margin-top: ${themed('spacing.xs')};
`;
const ToastLink = styled.p`
  display: inline;
  color: ${themed('color.white')};
  font-size: ${themed('font.size.m')};
  font-weight: ${themed('font.weight.semiBold')};
  margin-bottom: ${themed('spacing.m')};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const SuccessToastMessage = ({
  isNew,
  assortmentName,
  onGoTo
}: {
  isNew?: boolean;
  assortmentId: string;
  assortmentName: string;
  onGoTo?: VoidFunction;
}) => {
  const [str] = useLocalisation();
  return (
    <>
      <ToastMessage>
        {str(`AssortmentFinalization.modal.toast.success.${isNew ? 'create' : 'add'}`, { assortmentName })}
      </ToastMessage>
      {onGoTo && (
        <ToastLink onClick={onGoTo}>
          {str('AssortmentFinalization.modal.goToLabel', { name: assortmentName })}
        </ToastLink>
      )}
    </>
  );
};

export const NewAFModal = ({
  onClose,
  onCreate,
  onGoTo,
  context: sourceContext,
  pageDetails,
  selectedProducts,
  finalAssortments
}: {
  onClose: VoidFunction;
  onCreate: (assortmentId: string, navigateToAF: boolean) => void;
  onGoTo?: VoidFunction;
  context: EntityContext | undefined;
  pageDetails: PageDetails;
  selectedProducts: UIProduct<ProductListData>[];
  finalAssortments?: CustomerAssortmentArray[];
}) => {
  const [str] = useLocalisation();
  const { productsService, toastService } = useServices();
  const navigate = useNavigate();
  const { route } = useRoute();

  const [afType, setAfType] = useState<'new' | 'add'>();
  const [isNavigateToAFChecked, setNavigateToAFChecked] = useState(false);
  const [assortmentName, setAssortmentName] = useState<string>('FA-');
  // const [assortmentCategory, setAssortmentCategory] = useState<SelectOption>();
  const [existingAssortment, setExistingAssortment] = useState<SelectOption>();
  const [allowCreate, setAllowCreate] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const items = selectedProducts.map(product => ({
    pc9: product.pc9,
    status: 'ADD',
    firstOnFloor: product.inherited?.firstOnFloor ? product.firstOnFloor : null,
    lastOnFloor: product.inherited?.lastOnFloor ? product.lastOnFloor : null,
    newness: product.inherited?.newness ? product.newness : null,
    ranking: product.inherited?.ranking ? product.ranking : null,
    vlp: product.inherited?.vlp ? product.vlp : null,
    note: product.inherited?.note ? product.note : null,
    rateOfSale: product.rateOfSale && product.rateOfSale > 0 ? product.rateOfSale : undefined,
    sellThrough: product.sellThrough && product.sellThrough > 0 ? product.rateOfSale : undefined,
    weeksOnSale: product.weeksOnSale && product.weeksOnSale > 0 ? product.rateOfSale : undefined,
    deliveries: product.deliveries,
    storeGrade: product.storeGrades
  }));

  useEffect(() => {
    setAllowCreate(
      afType === 'new'
        ? assortmentName.length > 3 // && !!assortmentCategory
        : afType === 'add'
          ? !!existingAssortment
          : false
    );
  }, [afType, assortmentName, existingAssortment]); // assortmentCategory

  const createAssortmentCallback = useCallback(() => {
    if (!sourceContext || !('customerId' in sourceContext)) return;
    if (!pageDetails.season) return;

    setIsLoading(true);
    createAssortment({
      name: assortmentName,
      displayName: assortmentName,
      season: pageDetails.season,
      customerId: sourceContext.customerId as UUID,
      parentLineId: pageDetails.parentId as UUID,
      type: 'FINAL'
    })
      .then(async ({ assortment_id: id, name }) => {
        plpAddToFaCreateTag({
          assortmentId: sourceContext.id,
          customerId: sourceContext.customerId,
          faName: name
          // category: assortmentCategory
        });
        const newFinalAssortmentContext: EntityContext = {
          entity: Entity.FinalAssortment,
          customerId: sourceContext.customerId,
          id,
          name
        };

        await productsService.add({
          context: newFinalAssortmentContext,
          sourceAssortmentId: sourceContext.id,
          items
        });
        toastService.send(
          <SuccessToastMessage
            isNew
            assortmentId={id}
            assortmentName={assortmentName}
            onGoTo={
              route && !isNavigateToAFChecked
                ? () => {
                    onGoTo?.();
                    navigate(`${route.with({ assortmentId: id, productPageType: 'final' })}`);
                  }
                : undefined
            }
          />
        );
        onCreate(id, isNavigateToAFChecked);
      })
      .catch(e => toastService.send(e instanceof Error ? e.message : `${e}`, 'error'))
      .finally(() => setIsLoading(false));
  }, [
    assortmentName,
    isNavigateToAFChecked,
    navigate,
    onCreate,
    pageDetails.parentId,
    pageDetails.season,
    productsService,
    route,
    items,
    sourceContext,
    toastService
  ]);

  const addToAssortmentCallback = useCallback(() => {
    if (!existingAssortment || !sourceContext || !('customerId' in sourceContext)) return;
    const assortmentId = existingAssortment.value;

    setIsLoading(true);
    const existingFinalAssortmentContext: EntityContext = {
      entity: Entity.FinalAssortment,
      customerId: sourceContext.customerId,
      id: assortmentId,
      name: existingAssortment.label
    };

    productsService
      .add({
        context: existingFinalAssortmentContext,
        sourceAssortmentId: sourceContext.id,
        items
      })
      .then(() => {
        plpAddToFaAddTag({
          assortmentId: sourceContext.id,
          customerId: sourceContext.customerId,
          faName: existingFinalAssortmentContext.name,
          faId: existingFinalAssortmentContext.id
        });

        toastService.send(
          <SuccessToastMessage
            assortmentId={assortmentId}
            assortmentName={existingAssortment.label}
            onGoTo={
              route && !isNavigateToAFChecked
                ? () => {
                    onGoTo?.();
                    navigate(`${route.with({ assortmentId, productPageType: 'final' })}`);
                  }
                : undefined
            }
          />
        );

        onCreate(assortmentId, isNavigateToAFChecked);
      })
      .catch((e: any) => toastService.send(e instanceof Error ? e.message : `${e}`, 'error'))
      .finally(() => setIsLoading(false));
  }, [
    existingAssortment,
    isNavigateToAFChecked,
    navigate,
    onCreate,
    productsService,
    route,
    sourceContext,
    toastService
  ]);

  return (
    <Dialog
      title={str('AssortmentFinalization.modal.add.title')}
      subtitle={pageDetails?.displayName}
      onClose={onClose}
      minHeight={200}
      content={
        <>
          <TypeButtons>
            <Button
              onClick={() => {
                plpAddToFaCreateNewTag({
                  assortmentId: sourceContext?.id,
                  customerId: sourceContext && 'customerId' in sourceContext ? sourceContext.customerId : undefined
                });
                setAfType('new');
              }}
            >
              {str('AssortmentFinalization.modal.add.buttons.createNew')}
              <Checkbox checked={afType === 'new'} rounded />
            </Button>
            <Button
              onClick={() => {
                plpAddToFaCreateNewTag({
                  assortmentId: sourceContext?.id,
                  customerId: sourceContext && 'customerId' in sourceContext ? sourceContext.customerId : undefined
                });
                setAfType('add');
              }}
            >
              {str('AssortmentFinalization.modal.add.buttons.addToExisting')}
              <Checkbox checked={afType === 'add'} rounded />
            </Button>
          </TypeButtons>
          {(afType === 'new' || afType === 'add') && (
            <PreferencesWrapper>
              <Toggle
                defaultChecked={isNavigateToAFChecked}
                onChange={checked => setNavigateToAFChecked(checked)}
                label={str('AssortmentFinalization.modal.add.label.navigateToFinalAssortment', {
                  action: afType === 'new' ? str('general.create') : str('general.add')
                })}
              />
            </PreferencesWrapper>
          )}

          {afType === 'new' && (
            <OptionsWrapper>
              <label>
                {str('AssortmentFinalization.modal.add.label.assortmentName')}
                <Input
                  type="text"
                  placeholder="FA-"
                  value={assortmentName}
                  onChange={e =>
                    setAssortmentName(e.target.value.length < 3 ? 'FA-' : `FA-${e.target.value.replaceAll('FA-', '')}`)
                  }
                />
              </label>
              {/* <label>
              {str('AssortmentFinalization.modal.add.label.selectCategory')}
              <Select
                options={categories}
                onChange={option => setAssortmentCategory(option as SelectOption)}
                value={assortmentCategory}
                maxMenuHeight={120}
              />
            </label> */}
            </OptionsWrapper>
          )}
          {afType === 'add' && (
            <OptionsWrapper>
              <label>
                {str('AssortmentFinalization.modal.add.label.selectAssortment')}
                <Select
                  options={finalAssortments?.map(a => ({
                    value: a.assortmentId,
                    label: `${a.assortmentName} (${(a.add ?? 0) + (a.pending ?? 0)})`
                  }))}
                  onChange={option => setExistingAssortment(option as SelectOption)}
                  value={existingAssortment}
                  maxMenuHeight={120}
                />
              </label>
            </OptionsWrapper>
          )}
        </>
      }
      cancel={{ label: str('general.cancel'), disabled: isLoading, handler: onClose }}
      confirm={{
        label: isLoading ? (
          <LoadingSpinner />
        ) : (
          str(afType === 'new' ? 'general.create' : afType === 'add' ? 'general.add' : 'general.next')
        ),
        disabled: !allowCreate || isLoading,
        handler: afType === 'new' ? createAssortmentCallback : addToAssortmentCallback
      }}
    />
  );
};
