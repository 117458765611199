import { useCallback, useState } from 'react';

type UseDoubleBufferStateReturn<T> = [T, (state: T | ((prev: T) => T)) => void, swap: VoidFunction];

export const useDoubleBufferState = <T>(initialA: T, initialB: T): UseDoubleBufferStateReturn<T> => {
  const [track, setTrack] = useState<'A' | 'B'>('A');
  const [a, setA] = useState<T>(initialA);
  const [b, setB] = useState<T>(initialB);
  const swap = useCallback(() => setTrack(current => (current === 'A' ? 'B' : 'A')), []);
  return [track === 'A' ? a : b, track === 'A' ? setA : setB, swap];
};
