import { useMemo } from 'react';

import type { FiltersSchema, ProductFilterId } from '@yourxx/support';
import { FeatureFlags } from '@yourxx/types';
import { canUse as defaultCanUse } from '@yourxx/ui-utils';

import { filtersSchema } from './filtersSchema';

type UseFiltersSchemaProps = { schema?: FiltersSchema; canUse?: typeof defaultCanUse };

const filterPermissionMapping: Record<ProductFilterId, FeatureFlags> = {
  pricePos: FeatureFlags.Filters_FilterPricePositioning,
  story: FeatureFlags.Filters_FilterStory,
  notInFinalAssortment: FeatureFlags.Filters_FilterNotInAssortment,
  seasonality: FeatureFlags.Filters_FilterSeasonality,
  distribution: FeatureFlags.Filters_FilterDistribution
} as const;

export const useFiltersSchema = ({ schema = filtersSchema, canUse = defaultCanUse }: UseFiltersSchemaProps = {}) => {
  const configuredSchema = useMemo(() => {
    return schema.filter(filter => {
      if (!(filter.id in filterPermissionMapping)) return true;
      return canUse(filterPermissionMapping[filter.id]);
    });
  }, [canUse, schema]);

  return {
    filtersSchema: configuredSchema
  };
};
