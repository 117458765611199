import styled from 'styled-components';
import { rem, themed } from 'utils';

const Label = styled.label<{ $hasLabel?: boolean; $align: string }>`
  position: relative;
  display: inline-block !important;
  height: ${rem(25)};
  line-height: ${rem(25)};
  margin-right: ${({ $hasLabel }) => ($hasLabel ? rem(58) : 0)};

  span {
    position: absolute;
    top: 0;
    left: ${({ $align }) => ($align === 'left' ? '100%' : 'auto')};
    right: ${({ $align }) => ($align === 'right' ? '100%' : 'auto')};
    height: 100%;
    width: ${rem(50)};
    margin-left: ${({ $hasLabel }) => ($hasLabel ? themed('spacing.m') : 0)};
    background-color: ${themed('color.greyMid')};
    border-radius: ${rem(25)};
    will-change: box-shadow;
    transition: box-shadow 0.2s ease-out;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      height: ${rem(25)};
      width: ${rem(25)};
      border-radius: 50%;
      background-color: ${themed('color.grey')};
      will-change: background-color, transform;
      transition:
        background-color 0.2s ease-out,
        transform 0.2s ease-out;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      + span:before {
        background-color: ${themed('color.black')};
        transform: translateX(${rem(25)});
      }
    }
  }
  @media (hover: hover) {
    &:hover span {
      box-shadow: ${themed('boxShadow')};
    }
  }
`;

export const Toggle = ({
  className,
  onChange,
  checked,
  defaultChecked,
  label,
  align = 'left'
}: {
  className?: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  defaultChecked?: boolean;
  label?: string;
  align?: 'left' | 'right';
}) => (
  <Label className={className} $hasLabel={!!label} $align={align}>
    {label}
    <input
      type="checkbox"
      onChange={e => onChange?.(e.target.checked)}
      checked={checked}
      defaultChecked={defaultChecked}
    />
    <span />
  </Label>
);
