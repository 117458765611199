import { type AnyFilter, DomainEvent } from 'services';

import type { SidePanelId } from './SidePanelId';

export class FinalAssortmentViewed extends DomainEvent<ContextOf<Entity.FinalAssortment>> {}

export enum Entity {
  Line = 'Line',
  Assortment = 'Assortment',
  FinalAssortment = 'FinalAssortment',
  Order = 'Order'
}

export type ContextOf<T extends Entity, C extends EntityContext = EntityContext> = C extends { entity: T } ? C : never;

export type EntityContext =
  | { entity: Entity.Assortment; id: string; name: string; customerId: string }
  | { entity: Entity.FinalAssortment; id: string; name: string; customerId: string }
  | { entity: Entity.Order; id: string; name: string; customerId: string; locationId: string }
  | { entity: Entity.Line; id: string; name: string };

interface SidePanelPayload {
  sidePanelId: SidePanelId;
  context: EntityContext;
}

export class SidePanelOpened extends DomainEvent<SidePanelPayload> {}

export class SidePanelClosed extends DomainEvent<SidePanelPayload> {}

export class ProductsFilterSet extends DomainEvent<{ context: EntityContext; filter: AnyFilter }> {}
