import { Toast } from 'components/Toast';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { type EventBus } from '../EventBus';
import { ToastRequested } from './events';
import type { ToastService } from './ToastService';

export const useCreateToastService = ({ eventBus }: { eventBus: EventBus }): ToastService => {
  const send = useCallback<ToastService['send']>((content, type = 'success') => {
    toast(content, { type });
  }, []);

  useEffect(
    () =>
      eventBus.on(ToastRequested, event => {
        send(event.payload.content, event.payload.type);
      }),
    [eventBus, send]
  );

  return {
    RenderableToasts: <Toast />,
    send
  };
};
