import { useContext } from 'react';

import { OrdersContext } from './OrdersContext';

export const useOrders = () => {
  const value = useContext(OrdersContext);

  if (!value)
    throw new ReferenceError(
      'Hook "useOrders" used outside its provider. Make sure <OrdersProvider /> is a parent to your components.'
    );

  return value;
};
