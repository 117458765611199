import { Button } from 'components/Button';
import { useLocalisation } from 'providers';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ForecastView = () => {
  const [str] = useLocalisation();
  const { pathname } = useLocation();
  const goToV1 = useCallback(() => (window.location.href = pathname.replace('/final/', '/products/')), [pathname]);

  return (
    <Container>
      <p>{str('PDP.forecast.noForecastAvailable')}</p>
      <Button onClick={goToV1}>{str('general.goToV1')}</Button>
    </Container>
  );
};
