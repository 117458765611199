import { createContext, type ReactNode, useCallback, useRef } from 'react';
import { multiSort } from 'utils';

import { type Nullable } from '@yourxx/support';

export interface SlotContextValue {
  registerSlot(slotId: string, triggerRenderCallback: VoidFunction): VoidFunction;
  getSlotContent(slotId: string): { id: string; content: ReactNode }[];
  renderInSlot(slotId: string, id: string, content: ReactNode, position?: number): VoidFunction;
}

export const SlotsContext = createContext<Nullable<SlotContextValue>>(null);

type State = Map<string, { id: string; content: ReactNode; position: number }[]>;

export const SlotsProvider = ({ children }: { children: ReactNode }) => {
  const state = useRef<State>(new Map());

  const setState = (newState: State | ((prevState: State) => State)) => {
    state.current = typeof newState === 'function' ? newState(state.current) : newState;
  };

  const triggerRenderCallbacks = useRef<Map<string, VoidFunction>>(new Map());
  const triggerSlotRerender = useCallback((slotId: string) => {
    triggerRenderCallbacks.current.get(slotId)?.();
  }, []);

  const registerSlot = useCallback((slotId: string, triggerRenderCallback: VoidFunction) => {
    setState(prev => {
      if (prev.has(slotId)) return prev;
      return new Map(prev).set(slotId, []);
    });

    triggerRenderCallbacks.current.set(slotId, triggerRenderCallback);

    return () => {
      setState(prev => {
        if (!prev.has(slotId)) return prev;
        prev.delete(slotId);
        return new Map(prev);
      });

      triggerRenderCallbacks.current.delete(slotId);
    };
  }, []);

  const getSlotContent = useCallback((slotId: string) => {
    return multiSort(state.current.get(slotId) ?? [], { by: ['position'], dir: 1 });
  }, []);

  const renderInSlot = useCallback(
    (slotId: string, id: string, content: ReactNode, position = 0) => {
      setState(prev => {
        const current = prev.get(slotId);

        if (!current) return prev;

        return new Map(prev).set(slotId, [...current, { id, content, position }]);
      });

      triggerSlotRerender(slotId);

      return () => {
        setState(prev => {
          const current = prev.get(slotId);
          if (!current) return prev;

          return new Map(prev).set(
            slotId,
            current.filter(entry => entry.id !== id)
          );
        });

        triggerSlotRerender(slotId);
      };
    },
    [triggerSlotRerender]
  );

  return (
    <SlotsContext.Provider value={{ registerSlot, getSlotContent, renderInSlot }}>{children}</SlotsContext.Provider>
  );
};
