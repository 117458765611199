import type { UseCustomersReturn } from 'providers/CustomersDataProvider/useCustomers';
import { useCallback } from 'react';
import { CopyAssortmentSuccessful, type EventBus } from 'services';
import { useEventSubscriptions } from 'utils';

export const useHandleAssortmentCopied = ({
  eventBus,
  clearCache
}: {
  eventBus: EventBus;
  clearCache: UseCustomersReturn['clearCache'];
}) => {
  const clearTargetCustomersCache = useCallback(
    (event: CopyAssortmentSuccessful) =>
      clearCache(...event.payload.command.targetCustomers.map(id => ({ id, tag: 'customerAssortments' as const }))),
    [clearCache]
  );

  useEventSubscriptions(
    useCallback(
      () => [eventBus.on(CopyAssortmentSuccessful, clearTargetCustomersCache)],
      [clearTargetCustomersCache, eventBus]
    )
  );

  return null;
};
