import { Delete, Plus, Reorder } from 'assets/icons';
import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Select, type SelectOption } from 'components/Select';
import { Tab, Tabs } from 'components/Tabs';
import { Toggle } from 'components/Toggle';
import { Tooltip } from 'components/Tooltip';
import { format } from 'date-fns';
import { useUpdateAssortment } from 'pages/Products';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useServices } from 'services';
import styled from 'styled-components';
import { rem, themed, toSeasonLabel, toSortableSeason } from 'utils';

import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  CreateAssortmentResults,
  FeatureFlags,
  GetCustomerAssortmentResponse,
  PageDetails,
  SeasonList,
  UpdateAssortmentPayload,
  UUID
} from '@yourxx/types';
import { StoreGradeData } from '@yourxx/types/src/dao/assortment';
import { canNotUse, createAssortment } from '@yourxx/ui-utils';

const AssortmentTabs = styled(Tabs)`
  height: ${themed('spacing.xl')};

  span {
    ${themed('typography.h3')};
  }
`;
const ModalContent = styled.div`
  margin-top: ${themed('spacing.l')};
  max-height: 50vh;
  min-height: ${rem(220)};
  width: 100%;
  overflow: auto;
  box-sizing: border-box;

  label {
    display: flex;
    flex-direction: column;

    input {
      margin: ${themed('spacing.m')} 0;
    }
  }
  p {
    font-weight: ${themed('font.weight.medium')};

    span {
      font-weight: ${themed('font.weight.bold')};
    }
  }
`;
const TogglesWrapper = styled.div`
  display: flex;
  gap: ${themed('spacing.l')};
  margin: ${themed('spacing.m')} 0;
`;
const StoreGradesHeader = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;

  span {
    padding: ${themed('spacing.m')} ${themed('spacing.m')} ${themed('spacing.m')} ${rem(64)};
    flex: 1;

    &:last-of-type {
      padding-left: 0;
      width: ${rem(112)};
      flex: none;
    }
  }
`;
const StoreGradeRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: ${themed('spacing.m')} 0;
  gap: ${themed('spacing.m')};
  box-sizing: border-box;

  input {
    flex: 1;

    &:last-of-type {
      flex: none;
      width: ${rem(80)};
    }
  }
  button {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.regular')};

    svg {
      width: ${rem(24)};
      height: ${rem(24)};
    }
  }
`;
const StoreGradesValidation = styled.span`
  display: block;
  font-size: ${themed('font.size.s')};
  color: ${themed('color.red')};
`;
const ReorderButton = styled(Button)`
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

type AssortmentModalProperties = {
  customerData?: GetCustomerAssortmentResponse;
  pageDetails?: PageDetails;
  selectedSeason?: string;
  onClose: VoidFunction;
  onCreate?: (data: CreateAssortmentResults) => void;
  onEdit?: (data?: UpdateAssortmentPayload) => void;
  onError?: VoidFunction;
};

export const AssortmentModal = ({
  customerData,
  pageDetails,
  selectedSeason,
  onClose,
  onCreate,
  onEdit,
  onError
}: AssortmentModalProperties) => {
  const [str] = useLocalisation();
  const { toastService } = useServices();
  const { updateAssortment } = useUpdateAssortment();
  const [activeTab, setActiveTab] = useState<'details' | 'storeGrades'>('details');
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [assortmentName, setAssortmentName] = useState<string>(pageDetails?.displayName ?? '');
  const [assortmentSeason, setAssortmentSeason] = useState<SelectOption & SeasonList>(() => {
    const defaultSeason = customerData?.seasons?.find(s => s.season.toLowerCase() === selectedSeason);
    return {
      value: defaultSeason?.seasonId ?? '',
      label: defaultSeason?.season
        ? `${defaultSeason.season.slice(0, 2)} ${defaultSeason.season.slice(2)}`
        : `${pageDetails?.seasonH?.slice(0, 2)} ${pageDetails?.seasonH?.slice(2)}`,
      season: defaultSeason?.season ?? '',
      seasonId: defaultSeason?.seasonId ?? '',
      parentId: defaultSeason?.parentId ?? '',
      parentName: defaultSeason?.parentName ?? pageDetails?.parentName ?? '',
      cutOff: defaultSeason?.cutOff ?? ''
    };
  });
  const [isArchivedAssortment, setIsArchivedAssortment] = useState<boolean>(!!pageDetails?.archivedAt);
  const [isFinalAssortment, setIsFinalAssortment] = useState<boolean>(pageDetails?.assortType === 'FINAL' || false);
  const [storeGrades, setStoreGrades] = useState<StoreGradeData[]>(
    pageDetails?.storeGrades?.sort((a, b) => (a?.index > b?.index ? 1 : -1)) ?? []
  );
  const [storeGradesValid, setStoreGradesValidation] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setAllowSubmit(
      (isFinalAssortment && assortmentName.length > 3) ||
        (!isFinalAssortment &&
          assortmentName.length > 0 &&
          !!assortmentSeason &&
          storeGrades.every(sg => sg.name && sg.name.length > 0) &&
          storeGradesValid)
    );
  }, [assortmentName, assortmentSeason, storeGrades, storeGradesValid, setAllowSubmit]);

  useEffect(() => {
    setStoreGradesValidation(new Set(storeGrades.map(({ name }) => name)).size === storeGrades.length);
  }, [storeGrades, setStoreGradesValidation]);

  const createAssortmentCallback = useCallback(() => {
    setIsLoading(true);
    if (assortmentSeason && customerData) {
      createAssortment({
        name: assortmentName,
        displayName: assortmentName,
        season: assortmentSeason.seasonId,
        customerId: customerData.customerId as UUID,
        parentLineId: assortmentSeason.parentId as UUID,
        archived: isArchivedAssortment,
        type: isFinalAssortment ? 'FINAL' : undefined,
        storeGrade: storeGrades
      })
        .then(data => {
          toastService.send(<span>{str('Assortment.create.success', { assortmentName })}</span>);
          onCreate?.(data);
          setIsLoading(false);
        })
        .catch(() => {
          toastService.send(<span>{str('Assortment.create.error', { assortmentName })}</span>);
          onError?.();
          setIsLoading(false);
        });
    }
  }, [
    createAssortment,
    customerData,
    assortmentName,
    assortmentSeason,
    isArchivedAssortment,
    isFinalAssortment,
    storeGrades
  ]);

  const editAssortmentCallback = useCallback(() => {
    if (assortmentName) {
      updateAssortment({
        attributes: {
          name: assortmentName,
          displayName: assortmentName,
          archived: isArchivedAssortment,
          type: isFinalAssortment ? 'FINAL' : null,
          storeGrade: storeGrades
        },
        handleLoading: () => setIsLoading(true),
        handleSuccess: () => {
          toastService.send(<span>{str('Assortment.edit.success', { assortmentName })}</span>);
          onEdit?.();
        },
        handleError: () => {
          toastService.send(<span>{str('Assortment.edit.error', { assortmentName })}</span>, 'error');
          onError?.();
        },
        handleFinally: () => setIsLoading(false)
      });
    }
  }, [updateAssortment, pageDetails, assortmentName, isArchivedAssortment, isFinalAssortment, storeGrades]);

  useEffect(
    () =>
      setAssortmentName(prev => (isFinalAssortment ? `FA-${prev.replaceAll('FA-', '')}` : prev.replaceAll('FA-', ''))),
    [isFinalAssortment, setAssortmentName]
  );

  const subtitle = useMemo(() => {
    const data = customerData ?? pageDetails;
    if (data && data.customerName) {
      const extraDetails = [data.region, data.brand];
      return `${data.customerName} ${extraDetails.length > 0 ? `(${extraDetails.filter(Boolean).join(', ')})` : ''}`;
    }
  }, [customerData, pageDetails]);

  const getStoreGradeIdentifier = (storeGrade: StoreGradeData) => storeGrade.assortmentStoreGradeId ?? storeGrade.index;

  const onDragEnd = useCallback(
    (e: DragEndEvent) => {
      const { active, over } = e;
      if (storeGrades && over && active.id !== over.id) {
        setStoreGrades(psg => {
          const oldStoreGrade = psg.find(sg => getStoreGradeIdentifier(sg) === active.id);
          const newStoreGrade = psg.find(sg => getStoreGradeIdentifier(sg) === over.id);
          return oldStoreGrade && newStoreGrade
            ? arrayMove(psg, psg.indexOf(oldStoreGrade), psg.indexOf(newStoreGrade)).map((sg, i) => ({
                ...sg,
                index: i
              }))
            : psg;
        });
      }
    },
    [storeGrades, setStoreGrades, arrayMove]
  );

  const updateStoreGrade = useCallback(
    ({ storeGrade, attributes }: { storeGrade: StoreGradeData; attributes: Partial<StoreGradeData> }) =>
      setStoreGrades(prev => [
        ...prev.map(psg =>
          getStoreGradeIdentifier(psg) === getStoreGradeIdentifier(storeGrade) ? { ...psg, ...attributes } : psg
        )
      ]),
    [setStoreGrades, getStoreGradeIdentifier]
  );

  const SortableItem = useCallback(({ storeGrade }: { storeGrade: StoreGradeData }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: getStoreGradeIdentifier(storeGrade)
    });

    return (
      <StoreGradeRow ref={setNodeRef} style={{ transform: CSS.Transform.toString(transform), transition }}>
        <ReorderButton {...attributes} {...listeners}>
          <Tooltip text={str('general.reorder')} position="right" disableOnMouseDown>
            <Reorder />
          </Tooltip>
        </ReorderButton>
        <Input
          type="text"
          name="storeGradeName"
          placeholder={str('Admin.assortments.forms.storeGrades.name.placeholder')}
          value={storeGrade.name}
          onChange={e => updateStoreGrade({ storeGrade, attributes: { name: e.target.value } })}
        />
        <Input
          type="number"
          name="storeGradeDoors"
          value={storeGrade.numberOfDoors}
          min={0}
          onChange={e => updateStoreGrade({ storeGrade, attributes: { numberOfDoors: +e.target.value } })}
        />
        <Button
          onClick={() =>
            setStoreGrades(prev =>
              prev
                .filter(psg => getStoreGradeIdentifier(psg) !== getStoreGradeIdentifier(storeGrade))
                .map((sg, i) => ({ ...sg, index: i }))
            )
          }
        >
          <Tooltip text={str('general.delete')} position="left">
            <Delete />
          </Tooltip>
        </Button>
      </StoreGradeRow>
    );
  }, []);

  return (
    <Dialog
      title={str(onEdit ? 'Assortment.Modal.edit.title' : 'Assortment.Modal.create.title')}
      subtitle={subtitle}
      onClose={onClose}
      content={
        <>
          <AssortmentTabs>
            <Tab
              value="details"
              onClick={() => setActiveTab('details')}
              isSelected={activeTab === 'details'}
              fullWidth={false}
              borderWidth={2}
            >
              {str('Assortment.Modal.tabs.details')}
            </Tab>
            <Tab
              value="storeGrades"
              onClick={() => setActiveTab('storeGrades')}
              isSelected={activeTab === 'storeGrades'}
              fullWidth={false}
              borderWidth={2}
            >
              {str('Assortment.Modal.tabs.storeGrades')}
            </Tab>
          </AssortmentTabs>
          <ModalContent>
            {activeTab === 'details' && (
              <>
                <label>
                  {str('Assortment.Modal.name')}
                  <Input
                    type="text"
                    placeholder={isFinalAssortment ? 'FA-' : str('Assortment.Modal.namePlaceholder')}
                    value={assortmentName}
                    disabled={!onCreate && canNotUse(FeatureFlags.Assortment_EditTitle)}
                    onChange={e =>
                      setAssortmentName(
                        isFinalAssortment
                          ? e.target.value.length < 3
                            ? 'FA-'
                            : `FA-${e.target.value.replaceAll('FA-', '')}`
                          : e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  {str('Assortment.Modal.season')}
                  <Select
                    options={customerData?.seasons
                      ?.sort((a, b) => (toSortableSeason(a.season) > toSortableSeason(b.season) ? -1 : 1))
                      .map(s => ({
                        value: s.seasonId,
                        label: toSeasonLabel(s.season),
                        season: s.season,
                        seasonId: s.seasonId,
                        parentId: s.parentId,
                        parentName: s.parentName,
                        cutOff: s.cutOff
                      }))}
                    onChange={option => setAssortmentSeason(option as SelectOption & SeasonList)}
                    placeholder={str('Assortment.Modal.seasonPlaceholder')}
                    defaultValue={assortmentSeason}
                    value={assortmentSeason}
                    maxMenuHeight={120}
                    isDisabled={!onCreate}
                  />
                </label>
                {assortmentSeason && (
                  <>
                    <p>
                      {str('Assortment.Modal.parentLineNote')}
                      <span>{assortmentSeason.parentName}</span>
                    </p>
                    <p>
                      {str('Assortment.Modal.cutoff')}
                      <span>
                        {assortmentSeason.cutOff
                          ? format(assortmentSeason.cutOff, 'MMM dd, yyyy')
                          : str('general.notAvailable')}
                      </span>
                    </p>
                  </>
                )}
                <TogglesWrapper>
                  <Toggle
                    defaultChecked={isArchivedAssortment}
                    onChange={checked => setIsArchivedAssortment(checked)}
                    label="Archived"
                  />
                  <Toggle
                    defaultChecked={isFinalAssortment}
                    onChange={checked => setIsFinalAssortment(checked)}
                    label="Final Assortment"
                  />
                </TogglesWrapper>
              </>
            )}
            {activeTab === 'storeGrades' && (
              <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
                <StoreGradesHeader>
                  <span>{str('general.name')}</span>
                  <span>{str('general.door.plural')}</span>
                </StoreGradesHeader>
                <SortableContext
                  items={storeGrades.map(sg => getStoreGradeIdentifier(sg))}
                  strategy={verticalListSortingStrategy}
                >
                  {storeGrades.map(sg => (
                    <SortableItem key={getStoreGradeIdentifier(sg)} storeGrade={sg} />
                  ))}
                </SortableContext>
                <StoreGradeRow>
                  <Button
                    onClick={() =>
                      setStoreGrades(prev => [...prev, { name: '', numberOfDoors: 1, index: prev.length }])
                    }
                  >
                    {str('Assortment.Modal.storeGrades.add')}
                    <Plus />
                  </Button>
                </StoreGradeRow>
              </DndContext>
            )}
            {!storeGradesValid && (
              <StoreGradesValidation>{str('Assortment.Modal.storeGrades.validation')}</StoreGradesValidation>
            )}
          </ModalContent>
        </>
      }
      cancel={{ label: str('general.cancel'), disabled: isLoading, handler: onClose }}
      confirm={{
        label: isLoading ? <LoadingSpinner /> : str(onEdit ? 'general.edit' : 'general.create'),
        disabled: !allowSubmit || isLoading,
        handler: onEdit ? editAssortmentCallback : createAssortmentCallback
      }}
    />
  );
};
