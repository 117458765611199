import { useCallback, useEffect, useRef } from 'react';
import type { AddVideoCommand, ProductVideoService, RemoveVideoCommand, VideoCommand } from 'services';
import { usePrevious } from 'utils';
import { assign, createMachine } from 'xstate';

import { useMachine } from '@xstate/react';
import { Defect, type Nullable } from '@yourxx/support';

export enum State {
  Empty = 'Empty',
  Editing = 'Editing',
  PersistingChanges = 'PersistingChanges',
  WithVideo = 'WithVideo',
  PendingConfirmation = 'PendingConfirmation'
}

enum Action {
  Edit = 'Edit',
  InputUrl = 'InputUrl',
  Confirm = 'Confirm',
  SetVideo = 'SetVideo',
  Cancel = 'Cancel',
  Remove = 'Remove',
  Reset = 'Reset'
}

export interface UseProductVideoProps {
  pc9: string;
  videoUrl: Nullable<string>;
  service: ProductVideoService;
  onSave?: VoidFunction;
}

export type UseProductVideoReturn =
  | { state: State.Empty; addVideo: VoidFunction }
  | {
      state: State.Editing;
      videoUrl: string;
      canConfirm: boolean;
      confirm: VoidFunction;
      cancel: VoidFunction;
      inputUrl(url: string): void;
      canRemove: boolean;
      remove: VoidFunction;
    }
  | { state: State.PendingConfirmation; confirm: VoidFunction; cancel: VoidFunction }
  | { state: State.WithVideo; videoUrl: string; edit: VoidFunction }
  | { state: State.PersistingChanges };

export const useProductVideo = ({ pc9, videoUrl = '', service }: UseProductVideoProps): UseProductVideoReturn => {
  const stateMachine = useRef(createStateMachine(videoUrl));
  const [state, send] = useMachine(stateMachine.current, {
    services: {
      handle: async context => {
        if (!context.command) throw new Defect('Expected command to be set in the context.');
        return service.handle(context.command);
      }
    }
  });

  useEffect(() => {
    if (videoUrl) send({ type: Action.SetVideo, videoUrl });
  }, [send, videoUrl]);

  // Reset state when viewing a different PC9 (which may not have a videoUrl)
  const prevPC9 = usePrevious(pc9);
  useEffect(() => {
    if (state.value === State.WithVideo && prevPC9 !== pc9 && prevPC9) {
      send({ type: Action.Reset });
    }
  }, [pc9, prevPC9, send, state.value]);

  const confirm = useCallback(() => send({ type: Action.Confirm }), [send]);
  const cancel = useCallback(() => send({ type: Action.Cancel }), [send]);

  const edit = useCallback(
    () => send({ type: Action.Edit, command: { type: 'add', pc9, videoUrl: videoUrl ?? '' } }),
    [pc9, send, videoUrl]
  );

  const remove = useCallback(() => {
    if (!videoUrl) throw new Defect('Expected to have a video URL to be removed.');
    send({ type: Action.Remove, command: { type: 'remove', pc9, videoUrl } });
  }, [pc9, send, videoUrl]);

  const inputUrl = useCallback(
    (url: string) => {
      if (state.context.command?.type !== 'add') throw new Defect('Expected "add" command to be set in context.');
      return send({ type: Action.InputUrl, command: { ...state.context.command, videoUrl: url } });
    },
    [send, state.context.command]
  );

  switch (state.value) {
    case State.Empty:
      return { state: state.value, addVideo: edit };
    case State.Editing: {
      if (!state.context.command) throw new Defect('Expected command to be set in the context.');
      return {
        state: state.value,
        videoUrl: state.context.command.videoUrl,
        inputUrl,
        canConfirm: state.can({ type: Action.Confirm }),
        confirm,
        cancel,
        canRemove: Boolean(videoUrl),
        remove
      };
    }
    case State.PendingConfirmation:
      return { state: state.value, cancel, confirm };
    case State.PersistingChanges:
      return { state: state.value };
    case State.WithVideo: {
      if (state.context.videoUrl === null) throw new Defect('Expected video URL to be set in the context.');
      return { state: state.value, videoUrl: state.context.videoUrl, edit };
    }
    default:
      throw new Defect('Unhandled state');
  }
};

const createStateMachine = (videoUrl: Nullable<string>) => {
  type Context = {
    command: Nullable<VideoCommand>;
    videoUrl: Nullable<string>;
  };

  type Event =
    | { type: Action.Confirm }
    | { type: Action.Cancel }
    | { type: Action.Remove; command: RemoveVideoCommand }
    | { type: Action.SetVideo; videoUrl: string }
    | { type: Action.Edit; command: AddVideoCommand }
    | { type: Action.InputUrl; command: AddVideoCommand }
    | { type: Action.Reset };

  type Services = {
    handle: { data: Promise<void> };
  };

  return createMachine(
    {
      id: 'product-video-state-machine',
      predictableActionArguments: true,
      schema: {
        events: {} as Event,
        context: {} as Context,
        services: {} as Services
      },
      context: {
        videoUrl: videoUrl,
        command: null
      },
      initial: videoUrl ? State.WithVideo : State.Empty,
      states: {
        [State.Empty]: {
          on: {
            [Action.Edit]: {
              target: State.Editing,
              actions: 'savePendingCommand'
            },
            [Action.SetVideo]: {
              target: State.WithVideo,
              actions: assign({ videoUrl: (_, event) => event.videoUrl })
            }
          }
        },
        [State.WithVideo]: {
          on: {
            [Action.Edit]: {
              target: State.Editing,
              actions: 'savePendingCommand'
            },

            [Action.Reset]: {
              target: State.Empty,
              actions: assign({ videoUrl: '', command: null })
            }
          }
        },
        [State.Editing]: {
          on: {
            [Action.InputUrl]: {
              actions: 'savePendingCommand'
            },
            [Action.Confirm]: {
              target: State.PersistingChanges,
              cond: context => (context.command?.videoUrl.trim().length ?? 0) > 0
            },
            [Action.Remove]: {
              target: State.PendingConfirmation,
              actions: 'savePendingCommand'
            },
            [Action.Cancel]: [
              {
                target: State.WithVideo,
                cond: context => Boolean(context.videoUrl),
                actions: assign({ command: null })
              },
              {
                target: State.Empty,
                actions: assign({ command: null })
              }
            ]
          }
        },
        [State.PendingConfirmation]: {
          on: {
            [Action.Cancel]: {
              target: State.Editing,
              actions: assign({
                command: context => {
                  if (!context.command) throw new Defect('Expected to have remove command set in context.');
                  const command: AddVideoCommand = {
                    type: 'add',
                    pc9: context.command.pc9,
                    videoUrl: context.command.videoUrl
                  };
                  return command;
                }
              })
            },
            [Action.Confirm]: State.PersistingChanges
          }
        },
        [State.PersistingChanges]: {
          invoke: {
            src: 'handle',
            onError: {
              target: State.Editing
            },
            onDone: [
              {
                target: State.WithVideo,
                cond: context => context.command?.type === 'add',
                actions: assign({
                  videoUrl: context => {
                    if (!context.command) throw new Defect('Expected command to be set in context.');
                    return context.command.videoUrl;
                  },
                  command: null
                })
              },
              {
                target: State.Empty,
                cond: context => context?.command?.type === 'remove',
                actions: assign({ videoUrl: null, command: null })
              }
            ]
          }
        }
      }
    },
    {
      actions: {
        savePendingCommand: assign({
          command: (_, event) => {
            if (!('command' in event)) throw new Defect('Expected command in event');
            return event.command;
          }
        })
      }
    }
  );
};
