import { useState } from 'react';
import { canvasDeleteFromComparisonTag } from 'utils';

import { deleteBoardItem as apiDeleteBoardItem } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export const useDeleteBoardItem = () => {
  const { customerId, assortmentId, lineId, canvasBoardId } = useCurrentCanvasContext();
  const { setBoards, setBoardItems } = useCanvas();
  const [isDeletingBoardItem, setIsDeletingBoardItem] = useState<string>('');
  const [hasError, setHasError] = useState<string>();

  const deleteBoardItem = (boardItemId: string, pc9?: string) => {
    setIsDeletingBoardItem(boardItemId);
    apiDeleteBoardItem({ boardItemId }, { lineId, assortmentId })
      .then(() => {
        setBoardItems(prev => prev.filter(b => b.id !== boardItemId));
        canvasDeleteFromComparisonTag({
          assortmentId,
          customerId,
          comparisonId: canvasBoardId,
          pc9
        });
        setIsDeletingBoardItem(boardItemId);
        setBoards(prev =>
          prev.map(board => {
            if (board.boardId === canvasBoardId) {
              return {
                ...board,
                imagePc9Codes: board.imagePc9Codes?.filter(p => p !== pc9)
              };
            }
            return board;
          })
        );
      })
      .catch(() => {
        setHasError('Failed adding item to board');
        setIsDeletingBoardItem(boardItemId);
      });
  };

  return { deleteBoardItem, isDeletingBoardItem, hasError };
};
