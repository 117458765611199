import styled from 'styled-components';
import { rem } from 'utils';

import type { GridItemProps } from './interfaces';

export const GridItem = styled.div<GridItemProps>`
  width: 100%;
  max-width: ${({ $col, $gap = 1 }) => ($col && $col < 12 ? `${(100 * $col) / 12 - $gap}%` : '100%')};
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? rem($marginBottom) : 'unset')};
  display: ${({ $stretch, $alignCenter }) => ($stretch || $alignCenter ? 'flex' : 'initial')};
  align-self: ${({ $stretch }) => ($stretch ? 'stretch' : 'auto')};
  align-items: center;
  justify-content: center;
`;
