import { useCallback, useState } from 'react';

import { iam } from '@yourxx/ui-utils/src/utils/permissions';

export const useIAM = () => {
  const [refreshAccess, setRefreshAccess] = useState<boolean>(false);
  window.addEventListener('UserAccessUpdated', () => {
    setRefreshAccess(!refreshAccess);
  });
  const canUse = useCallback((action: string, prefix?: string) => iam.canUse(action, prefix), [refreshAccess]);
  const canNotUse = useCallback((action: string, prefix?: string) => iam.canNotUse(action, prefix), [refreshAccess]);
  return {
    canUse,
    canNotUse
  };
};
