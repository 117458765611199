import type { Truthy } from '@yourxx/types';

export const chunkArray = <T = any>(inputArray: T[], chunkSize: number): T[][] => {
  return inputArray.reduce((resultArray: T[][], item: T, index: number) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};

// Filtering function which narrows the type Array<T | undefined> down into Array<T> when used in [].filter() function
// TODO: remove when Typescript starts narrowing down [].filter(Boolean) resulting type automatically
export const removeFalsy = <T>(value: T): value is Truthy<T> => Boolean(value);

export type FilterBy<D> = {
  collection: ReadonlyArray<D>;
} & (KeysMatch<D> | PerKeyMatch<D>);

type Supported = string | number;
type KeysMatch<D> = { strict?: boolean; ignoreCase?: boolean; toBe: Supported | Supported[]; keys: (keyof D)[] };
type PerKeyMatch<D> = { keys: { [K in keyof D]?: <V = D[K]>(value: V) => boolean } };

export const filterBy = <D>(params: FilterBy<D>): D[] => {
  const response: D[] = [];

  if ('toBe' in params) {
    const filterNumber = filterBy.number({ toBe: params.toBe });
    const filterString = filterBy.string({ strict: params.strict, toBe: params.toBe });
    const filterAsString = filterBy.string({ toBe: `${params.toBe}` });
    let filterAsArray: <V>(v: V) => boolean;

    const matchKey = (collectionItem: D, key: keyof D) => {
      const value = collectionItem[key];
      if (typeof params.toBe == 'number' && typeof value == 'number') return filterNumber(value);
      if (typeof params.toBe == 'string' && typeof value == 'string') return filterString(value);
      if (Array.isArray(params.toBe) && (typeof value == 'number' || typeof value == 'string')) {
        if (!filterAsArray) filterAsArray = filterBy.array({ strict: params.strict, toBeIn: params.toBe });
        return filterAsArray(value);
      }

      return filterAsString(`${value}`);
    };

    for (let i = 0; i < params.collection.length; i++) {
      const collectionItem = params.collection[i];
      for (const key of params.keys)
        if (matchKey(collectionItem, key)) {
          response.push(collectionItem);
          break;
        }
    }
  } else {
    for (let i = 0; i < params.collection.length; i++) {
      const collectionItem = params.collection[i];
      for (const key in params.keys)
        if (params.keys[key]?.(collectionItem[key])) {
          response.push(collectionItem);
          break;
        }
    }
  }

  return response;
};

const includesAsString = (a: unknown, b: Supported) => `${a}`.includes(`${b}`);
const includesAsStringCaseless = (a: unknown, b: Supported) => `${a}`.toLowerCase().includes(`${b}`.toLowerCase());

filterBy.string = <V = string>({ toBe, strict }: { strict?: boolean; toBe: Supported | Supported[] }) => {
  if (Array.isArray(toBe)) {
    if (strict) return (value: V) => toBe.some(toBeV => value === toBeV);
    return (value: V) => toBe.some(toBeV => includesAsString(value, toBeV));
  } else {
    if (strict) return (value: V) => value === toBe;
    return (value: V) => includesAsString(value, toBe);
  }
};

filterBy.caselessString = <V = string>({ toBe }: { toBe: Supported | Supported[] }) => {
  if (Array.isArray(toBe)) {
    return (value: V) => toBe.some(toBeV => includesAsStringCaseless(value, toBeV));
  } else {
    return (value: V) => includesAsStringCaseless(value, toBe);
  }
};

filterBy.number =
  <V = number>({ toBe }: { toBe: Supported | Supported[] }) =>
  (value: V) =>
    value === toBe;

filterBy.array =
  <V = Supported>({ strict, toBeIn }: { strict?: boolean; toBeIn: Supported[] }) =>
  (value: V) =>
    toBeIn.some((toBe: Supported) => {
      if (typeof toBe === 'string' && typeof value === 'string') {
        return filterBy.string({ strict, toBe })(value);
      }
      if (typeof toBe === 'number' && typeof value === 'number') {
        return filterBy.number({ toBe })(value);
      }
      return filterBy.string({ strict, toBe: `${toBe}` })(`${value}`);
    });

export const toggleArrayValue = <T>(values: ReadonlyArray<T>, prevValues: ReadonlyArray<T>): T[] => {
  let newValues = [...prevValues];

  for (const item of values) {
    const index = newValues.findIndex(x => x === item);
    if (index === -1) newValues.push(item);
    else newValues = [...newValues.slice(0, index), ...newValues.slice(index + 1)];
  }

  return newValues;
};

export const toSorted = <T = any>(entries?: T[] | readonly T[] | null, compareFn?: (a: T, b: T) => number): T[] => {
  return [...(entries ?? [])].sort(compareFn);
};

export const toReversed = <T = any>(entries?: T[] | readonly T[] | null): T[] => {
  return [...(entries ?? [])].reverse();
};
