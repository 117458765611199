import { Entity } from 'domain-events';
import type { UseCustomersReturn } from 'providers/CustomersDataProvider/useCustomers';
import { useCallback } from 'react';
import { AddedProductsToStory, type EventBus, ProductsAdded, ProductsRemoved, ProductsUpdated } from 'services';
import { useEventSubscriptions } from 'utils';

// TODO: Refactor to use CacheClearRequested event instead of directly calling the utility functions.
export const useHandleLineUpdated = ({
  eventBus,
  clearCache,
  loadProductsFor
}: {
  eventBus: EventBus;
} & Pick<UseCustomersReturn, 'clearCache' | 'loadProductsFor'>): null => {
  useEventSubscriptions(
    useCallback(() => {
      const handler = (event: ProductsAdded | ProductsUpdated | ProductsRemoved | AddedProductsToStory) => {
        if (event.payload.context.entity !== Entity.Line) return;

        clearCache(
          { id: event.payload.context.id, tag: 'line' },
          { id: event.payload.context.id, tag: 'droppedAndRemoved' },
          { id: event.payload.context.id, tag: 'parentLine' },
          { id: event.payload.context.id, tag: 'parentLineDroppedAndRemoved' }
        );

        loadProductsFor(event.payload.context.id, 'line', event.traceId());
      };

      return [
        eventBus.on(ProductsAdded, handler),
        eventBus.on(ProductsUpdated, handler),
        eventBus.on(ProductsRemoved, handler),
        eventBus.on(AddedProductsToStory, handler)
      ];
    }, [clearCache, eventBus, loadProductsFor])
  );

  return null;
};
