import { Select, SelectOption } from 'components/Select';
import { SimpleNav, SlotId } from 'pages';
import { useCustomersData, useLocalisation, useSlot } from 'providers';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { landingSeasonChangeTag, relativePath, rem, sortSeasons, themed, toSeasonLabel, useIAM } from 'utils';

import { FeatureFlags } from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

import { customerRoute } from './customer-route-mapping';

const SeasonSelect = styled(Select)`
  position: relative;
  z-index: 10;
  margin: 0;
  width: ${rem(110)};

  span {
    ${themed('typography.h3')};
  }
`;

export const useSetupCustomerView = ({
  customerId,
  season,
  currentView
}: {
  customerId: string;
  season: string | undefined;
  currentView: keyof typeof customerRoute;
}) => {
  const navigate = useNavigate();
  const [str] = useLocalisation();
  const { canNotUse } = useIAM();
  const { assortmentsFor } = useCustomersData();
  const customerData = useMemo(() => assortmentsFor(customerId), [assortmentsFor, customerId]);
  const assortments = useMemo(() => {
    if (!season) return [];
    return customerData?.assortments?.[season] ?? [];
  }, [customerData?.assortments, season]);

  useSlot(SlotId.PageTitle, customerData?.customerName);

  const [hasArchived, hasFinal, hasOrders] = useMemo<[boolean, boolean, boolean]>(() => {
    if (!season || !assortments.length) return [false, false, true];

    const hasArchived = !!assortments?.find(a => a.archivedAt);
    const hasFinal = !!assortments?.find(a => a.assortmentType === 'FINAL');
    const hasOrders = true; // TODO:

    return [hasArchived, hasFinal, hasOrders];
  }, [assortments, season]);

  useSlot(
    SlotId.CustomerMenu,
    useMemo(
      () => (
        <SimpleNav
          id="customer-menu"
          key={season}
          items={[
            {
              to: relativePath(`../${customerRoute.active}`),
              label: str('Landing.customers.activeAssortments')
            },
            {
              to: relativePath(`../${customerRoute.archived}`),
              label: str('Landing.customers.archivedAssortments'),
              isHidden: !hasArchived || !canUse(FeatureFlags.Assortment_ViewArchived)
            },
            {
              to: relativePath(`../${customerRoute.final}`),
              label: str('Landing.customers.finalAssortments'),
              isHidden: !hasFinal
            },
            {
              to: relativePath(`../${customerRoute.orders}`),
              label: str('Landing.customers.orders'),
              isHidden: canNotUse(`orders.view`)
            }
          ]}
        />
      ),
      [hasArchived, hasFinal, hasOrders, season, str]
    )
  );

  const seasons = useMemo(() => sortSeasons(Object.keys(customerData?.assortments ?? {})), [customerData?.assortments]);

  const setSeason = useCallback(
    (season: string, replace = false) => {
      if (season) navigate(relativePath(`../../${season}/${customerRoute[currentView]}`), { replace });
    },
    [currentView, navigate]
  );

  useEffect(() => {
    if (!season) setSeason(seasons[0], true);
  }, [setSeason, seasons, season]);

  useEffect(() => {
    if ((currentView === 'archived' && !hasArchived) || (currentView === 'final' && !hasFinal))
      navigate(relativePath(`../${customerRoute.active}`), { replace: true });
  }, [currentView, hasArchived, hasFinal, navigate]);

  useSlot(
    SlotId.CustomerToolbar,
    useMemo(
      () => (
        <SeasonSelect
          options={seasons.map(s => ({
            value: s,
            label: toSeasonLabel(s).toUpperCase()
          }))}
          onChange={option => {
            const season = option as SelectOption;
            landingSeasonChangeTag({ season: season.label, customerId });
            setSeason(season.value);
          }}
          value={season && { value: season, label: toSeasonLabel(season).toUpperCase() }}
          isSearchable={false}
          maxMenuHeight={300}
        />
      ),
      [customerId, setSeason, seasons, season]
    )
  );
};
