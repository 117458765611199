import { Entity, type EntityContext } from 'domain-events';
import { LegacyStoriesClosed, LegacyStoriesContext, LegacyStoriesRequested } from 'providers';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useServices } from 'services';
import { useRoute } from 'utils';

import { Defect } from '@yourxx/support';

import { useHandleLegacyStoriesClosedEvent } from './useHandleLegacyStoriesClosedEvent';

export const LegacyStories = ({ context: entityContext }: { context: EntityContext }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const storyId = searchParams.get('storyId') ?? undefined;
  const { state } = useLocation();
  const { cameFrom } = state ?? {};
  const route = useRoute();
  const context = useContext(LegacyStoriesContext);
  const { eventBus } = useServices();
  const storiesRequestEvent = useRef<LegacyStoriesRequested>();

  if (!context) throw new Defect('Expected LegacyStoriesProvider to be rendered in your components hierarchy.');

  const storiesContext = useMemo(() => {
    if (
      entityContext.entity !== Entity.Assortment &&
      entityContext.entity !== Entity.FinalAssortment &&
      entityContext.entity !== Entity.Line
    )
      return;

    return entityContext;
  }, [entityContext]);

  useHandleLegacyStoriesClosedEvent(eventBus);

  useEffect(() => {
    context.registerOnClose(() => {
      if (storiesContext) {
        const event = new LegacyStoriesClosed({ context: storiesContext });
        if (storiesRequestEvent.current) event.trace(storiesRequestEvent.current);
        eventBus.emit(event);
      }

      if (cameFrom) return navigate(cameFrom);

      const productsRoute = route.route?.with({ productPageType: 'products', productListMode: 'grid' });
      if (productsRoute) navigate(productsRoute.toString());
    });
  }, [cameFrom, context, eventBus, navigate, route.route, storiesContext]);

  useEffect(() => {
    if (!storiesContext) return;

    storiesRequestEvent.current = new LegacyStoriesRequested({ context: storiesContext, storyId });
    eventBus.emit(storiesRequestEvent.current);
  }, [eventBus, storiesContext, storyId]);

  return null;
};
