import { ChevronLeft, ChevronRight, CopyAlt, Newness, Replaced, Replen } from 'assets/icons';
import { Button } from 'components/Button';
import { FlexWrapper } from 'components/FlexWrapper';
import { IAM } from 'components/IAM';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Tab, Tabs } from 'components/Tabs';
import { Tag } from 'components/Tag';
import { Tooltip } from 'components/Tooltip';
import type { EntityContext } from 'domain-events';
import { State, useGetAssortmentProduct, useProductVideoUI } from 'pages/Products';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductVideoAdded, ProductVideoRemoved, useServices } from 'services';
import styled from 'styled-components';
import { pdpSaveVideoTag, rem, themed, useEventSubscriptions, usePrevious } from 'utils';
import { useIAM } from 'utils/access';

import { LocalisationStrings } from '@yourxx/translations/en';
import { PageDetails, ProductDetailsData, ProductListData, UIProduct } from '@yourxx/types';

import { ProductDetailsContext } from './ProductDetailsProvider';
import { ForecastView, NotesView, ProductView } from './Views';
import { ParentNotesView } from './Views/ParentNotesView';

const Wrapper = styled.div`
  height: 90vh;
  max-width: 90vw;
  width: ${rem(1300)};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${themed('spacing.m')};
  padding: ${themed('spacing.m')} ${themed('spacing.xxl')} 0;
  width: 100%;
  box-sizing: border-box;

  svg {
    width: ${rem(28)};
    height: ${rem(28)};
  }
`;
const PC9Subtitle = styled.span`
  display: inline-flex;
  align-items: center;
  text-align: left;
  padding: ${themed('spacing.xs')} 0;
  cursor: pointer;

  svg {
    width: ${rem(16)};
    height: ${rem(16)};
    margin-left: ${themed('spacing.xs')};
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;
const FinishLabel = styled.span`
  font-size: ${themed('font.size.s')};
  color: ${themed('color.greyDark')};
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${themed('color.greyLight')};
  padding: ${themed('spacing.l')};
  box-sizing: border-box;
  overflow: auto;
`;
const Title = styled.h1`
  font-size: ${themed('font.size.xl')};
  margin: 0;
`;
const Navigation = styled.div`
  display: flex;
  padding-right: ${themed('spacing.xxl')};

  svg {
    align-self: center;
    padding: 0 ${themed('spacing.s')};
  }
`;
const NavButton = styled(Button)<{ $hideButton?: boolean }>`
  display: ${({ $hideButton }) => ($hideButton ? 'none' : 'inherit')};
`;
const HeaderDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${themed('spacing.s')} 0;

  button {
    align-items: center;
    font-weight: ${themed('font.weight.regular')};
  }
`;
const TabContainer = styled.div`
  width: ${rem(450)};

  > div {
    height: ${rem(45)};
  }
`;
const NavProductTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${rem(200)};
  gap: ${themed('spacing.s')};

  p {
    margin: 0;
    word-break: break-word;
  }

  span {
    color: ${themed('color.greyMid')};
  }
`;
const TagList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${themed('spacing.xs')};

  svg {
    width: ${rem(12)};
    height: ${rem(12)};
    padding: 0;
  }
`;

type ProductDetailsTabs = 'PRODUCT' | 'FORECAST';
type ProductDetailsTabData = {
  id: ProductDetailsTabs;
  titleId: keyof LocalisationStrings;
  isDisabled: () => boolean; // Function instead of pure boolean, as this has to be evaluated in the current-user context
};

const useForceProductDataReloadAfterVideoUpdate = ({ callback }: { callback: VoidFunction }) => {
  const { eventBus } = useServices();
  useEventSubscriptions(
    useCallback(
      () => [eventBus.on(ProductVideoAdded, callback), eventBus.on(ProductVideoRemoved, callback)],
      [callback, eventBus]
    )
  );
};

export const ProductDetails = ({
  className,
  context,
  pc9,
  isParentLine,
  pricingGroupId,
  previousProduct,
  nextProduct,
  onPrevious,
  onNext
}: {
  className?: string;
  context: EntityContext | undefined;
  pc9: string;
  isParentLine?: boolean;
  pricingGroupId?: string;
  previousProduct?: UIProduct<ProductListData>;
  nextProduct?: UIProduct<ProductListData>;
  onNext?: VoidFunction;
  onPrevious?: VoidFunction;
}) => {
  const [str] = useLocalisation();
  const { toastService } = useServices();
  const { customerId, assortmentId } = useParams();
  const { getAssortmentProduct } = useGetAssortmentProduct({ assortmentOrLineId: context?.id });
  const { canUse, canNotUse } = useIAM();
  const [product, setProduct] = useState<UIProduct<ProductDetailsData>>();
  const [details, setDetails] = useState<PageDetails>();
  const [selectedTab, setSelectedTab] = useState<string>('PRODUCT');
  const [isLoadingProduct, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const productDetailsTabs = (): Record<string, ProductDetailsTabData> => ({
    PRODUCT: {
      id: 'PRODUCT',
      titleId: 'Tab.PDP.product.label',
      isDisabled: () => false
    },
    ...(canUse('forecast')
      ? {
          FORECAST: {
            id: 'FORECAST',
            titleId: 'Tab.PDP.forecast.label',
            isDisabled: () => canNotUse('forecast.view')
          }
        }
      : {})
  });

  const handleGetProduct = useCallback(
    (pc9: string) =>
      getAssortmentProduct({
        pc9,
        parent: isParentLine,
        pricingGroupId,
        handleLoading: () => setIsLoading(true),
        handleSuccess: ({ products, details }) => {
          setProduct(products?.[0]);
          setDetails(details);
          setIsLoading(false);
        },
        handleError: () => {
          setErrorMessage(str('PDP.product.retrievingError'));
          setIsLoading(false);
        }
      }),
    [getAssortmentProduct, isParentLine, pricingGroupId, str]
  );

  const prevPC9 = usePrevious(pc9);
  const [forcedTimestamp, forceRerender] = useState(new Date());
  const dataPromise = useRef<Promise<any>>();

  const forceReloadCallback = useCallback(() => {
    dataPromise.current = undefined;
    forceRerender(new Date());
  }, []);
  useForceProductDataReloadAfterVideoUpdate({ callback: forceReloadCallback });

  const productVideoUI = useProductVideoUI({
    pc9,
    videoUrl: product?.video ?? null,
    onSave: () => {
      if (product) {
        pdpSaveVideoTag({ productId: product.pId, customerId, assortmentId });
      }
    }
  });
  const isLoading = isLoadingProduct || productVideoUI.state.state === State.PersistingChanges;

  useEffect(() => {
    if (prevPC9 !== pc9) dataPromise.current = undefined;
  }, [pc9, prevPC9]);

  useEffect(() => {
    if (!dataPromise.current) dataPromise.current = handleGetProduct(pc9);
  }, [handleGetProduct, isLoading, pc9, product, forcedTimestamp]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      const isAllowedToNavigateAway =
        !isLoading &&
        productVideoUI.state.state !== State.Editing &&
        !document.getElementsByClassName('imageSliderFullscreen')?.length;
      if (!isAllowedToNavigateAway) return;
      if (e.key === 'ArrowLeft') onPrevious?.();
      if (e.key === 'ArrowRight') onNext?.();
    };
    document.addEventListener('keydown', handler);
    return () => document.removeEventListener('keydown', handler);
  }, [isLoading, onNext, onPrevious, productVideoUI.state.state]);

  if (isLoading) {
    return (
      <Wrapper className={className}>
        <LoadingSpinner label={str('PDP.product.loading')} />
      </Wrapper>
    );
  }
  if (errorMessage) {
    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <p>{errorMessage}</p>
        </FlexWrapper>
      </Wrapper>
    );
  }
  if (!product) {
    return (
      <Wrapper className={className}>
        <FlexWrapper>
          <p>{str('PDP.product.notFound')}</p>
        </FlexWrapper>
      </Wrapper>
    );
  }

  const isCarryOver = !!(product.newness && ['co', 'c/o'].includes(product.newness.toLowerCase()));
  const isReplen = product.replen === 'TRUE';

  return (
    <Wrapper className={className}>
      <Header>
        <Navigation>
          <TagList>
            <IAM action="view.newness">
              {isCarryOver && (
                <Tag $backgroundColor={'red'}>
                  <Newness />
                  <p>{str('general.carryOverLabel')}</p>
                </Tag>
              )}
            </IAM>
            <IAM action="view.replen">
              {isReplen && (
                <Tag $backgroundColor={'gray'}>
                  <Replen />
                  <p>{str('general.replenLabel')}</p>
                </Tag>
              )}
            </IAM>
          </TagList>
          <Tooltip
            textComponent={
              <NavProductTooltipContent>
                <p>{previousProduct?.name}</p>
                <span>{previousProduct?.pc9}</span>
              </NavProductTooltipContent>
            }
            position="bottom"
            offsetX={50}
            offsetY={-10}
            disabled={!onPrevious}
          >
            <NavButton onClick={onPrevious} $hideButton={!onPrevious}>
              <ChevronLeft />
            </NavButton>
          </Tooltip>
          <div>
            <Title>{product.name}</Title>
            <Tooltip text={str('PDP.product.pc9Copy')} position="bottom">
              <PC9Subtitle
                onClick={e => {
                  e.stopPropagation();
                  navigator.clipboard
                    .writeText(product.pc9)
                    .then(() => toastService.send(str('PDP.product.pc9Copied')));
                }}
              >
                {product.pc9} <CopyAlt />
              </PC9Subtitle>
            </Tooltip>
            {product.finish && (
              <Tooltip text={str('PDP.labels.finish')} position="bottom">
                <FinishLabel>{product.finish}</FinishLabel>
              </Tooltip>
            )}
          </div>
          <Tooltip
            textComponent={
              <NavProductTooltipContent>
                <p>{nextProduct?.name}</p>
                <span>{nextProduct?.pc9}</span>
              </NavProductTooltipContent>
            }
            position="bottom"
            offsetY={-10}
            disabled={!onNext}
          >
            <NavButton onClick={onNext} $hideButton={!onNext}>
              <ChevronRight />
            </NavButton>
          </Tooltip>
        </Navigation>
        <HeaderDetails>
          <TabContainer>
            <Tabs>
              {Object.values(productDetailsTabs()).map(tab => (
                <Tab
                  key={tab.id}
                  value={tab.id}
                  onClick={() => setSelectedTab(tab.id)}
                  isSelected={selectedTab === tab.id}
                  borderWidth={2}
                  isDisabled={tab.isDisabled()}
                  tooltip={tab.isDisabled() ? str('general.featureNotAvailable') : undefined}
                >
                  {str(tab.titleId)}
                </Tab>
              ))}
            </Tabs>
          </TabContainer>
          <ButtonContainer>
            {product.replacedByPc9 && (
              <Tooltip text={str('PDP.replaced.tooltip')} position="left">
                <Button onClick={() => product.replacedByPc9 && handleGetProduct(product.replacedByPc9)}>
                  <Replaced />
                </Button>
              </Tooltip>
            )}
            <IAM action="view.note">
              <NotesView context={context} note={product.note} pc9={product.pc9} pId={product.pId} />
            </IAM>
            <IAM action="view.parentNote">{product.parentNote && <ParentNotesView note={product.parentNote} />}</IAM>
          </ButtonContainer>
        </HeaderDetails>
      </Header>
      <Content>
        <ProductDetailsContext.Provider value={productVideoUI.state}>
          {productVideoUI.Renderable}
          {
            {
              PRODUCT: <ProductView context={context} product={product} details={details} />,
              FORECAST: <ForecastView />
            }[selectedTab]
          }
        </ProductDetailsContext.Provider>
      </Content>
    </Wrapper>
  );
};
