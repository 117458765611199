import { Copy, Edit } from 'assets/icons';
import { Button, Checkbox, ListView, SortDirection } from 'components';
import { IAM } from 'components/IAM';
import { format } from 'date-fns';
import { useLocalisation } from 'providers';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { formatPrice, themed } from 'utils';

import type { CustomerAddress } from '@yourxx/types';

import { StatusCapsule } from '../../../Orders/components';
import type { Order, OrderModalType } from '.';

const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${themed('spacing.s')};

  button {
    padding: ${themed('spacing.s')};
  }
`;

const OrderLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  color: ${themed('color.black')};
  ${themed('typography.h4')};
  font-variant: tabular-nums;

  @media (hover: hover) {
    &:hover {
      color: ${themed('color.grey')};
    }
  }
`;

export const OrderList = ({
  orders,
  selectedOrders,
  setSelectedOrders,
  setCurrentOrder,
  setOrderModalType
}: {
  orders: Order[];
  selectedOrders: Order[];
  setSelectedOrders: Dispatch<SetStateAction<Order[]>>;
  setCurrentOrder: Dispatch<SetStateAction<Order | undefined>>;
  setOrderModalType: Dispatch<SetStateAction<OrderModalType | undefined>>;
}) => {
  const [str] = useLocalisation();

  return (
    <ListView
      data={orders}
      columns={[
        {
          accessorKey: 'select',
          header: () => (
            <Checkbox
              id="headerCheckbox"
              checked={orders.length === selectedOrders.length}
              onChange={() => setSelectedOrders(orders.length === selectedOrders.length ? [] : orders)}
              extraPadding
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              id={row.original.order}
              checked={selectedOrders.includes(row.original)}
              onChange={() =>
                setSelectedOrders(prev =>
                  prev.includes(row.original)
                    ? prev.filter(p => p.orderId !== row.original.orderId)
                    : [...prev, row.original]
                )
              }
              extraPadding
            />
          ),
          enableSorting: false,
          meta: { noPadding: true },
          size: 50
        },
        {
          accessorKey: 'orderName',
          header: str('Landing.order.table.orderName'),
          cell: ({ renderValue, getValue }) => <OrderLink to={getValue()}>{renderValue()}</OrderLink>,
          meta: { flex: 2, minSize: 140, align: 'left' }
        },
        {
          accessorKey: 'finalAssortment.assortmentName',
          header: str('Landing.order.table.finalAssortment'),
          cell: ({ renderValue }) => renderValue(),
          meta: { flex: 1, minSize: 140, align: 'left' }
        },
        {
          accessorKey: 'locations',
          header: str('Landing.order.table.location'),
          cell: ({ getValue }) =>
            `${getValue()
              .slice(0, 3)
              .map((l: CustomerAddress) => `${l.name} (${l.city})`)
              .join(', ')}${getValue().length > 3 ? ` (${getValue().length - 3} more)` : ''}`,
          meta: { flex: 2, minSize: 140, align: 'left' }
        },
        {
          accessorKey: 'created',
          header: str('Landing.order.table.orderCreated'),
          cell: ({ getValue }) => (getValue() ? format(getValue(), 'MMM dd, yyyy') : ''),
          defaultSortDir: SortDirection.DESC,
          size: 140
        },
        {
          accessorKey: 'products',
          header: str('Landing.order.table.products'),
          cell: ({ getValue }) => getValue() ?? '-',
          meta: { align: 'right', alignCellOnly: true },
          size: 140
        },
        {
          accessorKey: 'units',
          header: str('Landing.order.table.units'),
          cell: ({ getValue }) => getValue() ?? '-',
          meta: { align: 'right', alignCellOnly: true },
          size: 120
        },
        {
          accessorKey: 'price',
          header: str('Landing.order.table.price'),
          cell: ({ getValue, row }) => formatPrice(row.original.currency, getValue()) ?? '-',
          meta: { align: 'right', alignCellOnly: true },
          size: 120
        },
        {
          accessorKey: 'status',
          header: str('Landing.order.table.status'),
          cell: ({ getValue }) => <StatusCapsule status={getValue()} />,
          meta: { align: 'right', alignCellOnly: true },
          size: 120
        },
        {
          accessorKey: 'options',
          header: '',
          cell: ({ row }) => (
            <ButtonsWrapper>
              <IAM action="orders.edit">
                <Button
                  onClick={() => {
                    setCurrentOrder(row.original);
                    setOrderModalType('edit');
                  }}
                >
                  <Edit />
                </Button>
              </IAM>
              <IAM action="orders.edit">
                <Button
                  onClick={() => {
                    setCurrentOrder(row.original);
                    setOrderModalType('duplicate');
                  }}
                >
                  <Copy />
                </Button>
              </IAM>
            </ButtonsWrapper>
          ),
          enableSorting: false,
          size: 70
        }
      ]}
      alternateStyle
    />
  );
};
