import { useLocalisation } from 'providers';
import { Metric } from 'providers/CanvasProvider';
import styled from 'styled-components';
import { themed } from 'utils';

import { CanvasProductDetails } from '@yourxx/types';

import { useCanvas } from './hooks';

const MetricList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
`;
const MetricItem = styled.div`
  background-color: ${themed('color.greyMid')};
  color: ${themed('color.greyDark')};
  padding: ${themed('spacing.m')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${themed('spacing.l')};

  p {
    font-weight: ${themed('font.weight.semiBold')};
    font-size: ${themed('font.size.m')};
    padding: 0;
  }
  span {
    font-size: ${themed('font.size.s')};
  }
`;
const NoMetrics = styled(MetricItem)`
  padding: ${themed('spacing.xl')};
  justify-content: center;
`;

export type MetricsProps = {
  product: CanvasProductDetails;
};

const metricMap: Record<Metric, (product: CanvasProductDetails) => string | undefined> = {
  COLOUR: product => product.colour,
  COLOUR_GROUP: product => product.colourGroup,
  FABRIC: product => product.fabricName,
  FIT: product => product.categories?.find(c => c.level === 2)?.name,
  MFP_CATEGORY: product => product.mfpCategory,
  PLANNED_LIFECYCLE: product => product.plannedLifecycle,
  PRICE_POSITIONING: product => product.pricePositioning,
  RETAIL_PRICE: product => product.retailPrice,
  SEASONALITY: product => product.seasonality,
  WHOLESALE_PRICE: product => product.wholesalePrice
};
const renderMetric = (metric: Metric, product: CanvasProductDetails) => {
  const name = metric.replace('_', ' ');
  const value = metricMap[metric](product) || 'UNKNOWN';

  return (
    <MetricItem key={metric}>
      <p>{name}</p>
      <span>{value}</span>
    </MetricItem>
  );
};

export const Metrics = ({ product }: MetricsProps) => {
  const { metrics } = useCanvas();
  const [str] = useLocalisation();
  if (!metrics.length) return <NoMetrics>{str('Canvas.metrics.noneHint')}</NoMetrics>;
  return <MetricList>{metrics.map(metric => renderMetric(metric, product))}</MetricList>;
};
