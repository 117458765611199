import { Entity, type EntityContext } from 'domain-events';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Defect, Nullable, searchProducts, toggleArrayValue } from '@yourxx/support';
import type { GetCustomerAssortmentResponse } from '@yourxx/types';

export interface UseAddProductProps<P> {
  context: EntityContext | undefined;
  isLoading: boolean;
  parentLineId: string | undefined;
  loadAssortmentsFor: (customerId: string) => void;
  assortmentsFor: (customerId: string) => Nullable<GetCustomerAssortmentResponse>;
  canAddFromCurrentAssortment?: boolean;
  canAddFromLine?: boolean;
  loadProductsFor: (assortmentId: string, tag?: 'line' | 'parentLine') => void;
  productsFor: (
    assortmentOrLineId: string,
    tag?: 'line' | 'parentLine'
  ) => Nullable<{ parentLineId: string; products: ReadonlyArray<P> }>;
  onAdd: (params: { context: EntityContext; sourceParentLineId: string; products: P[] }) => Promise<void>;
  onSearchFor?: (searchTerm: string) => void;
  onSelectAll?: (source: string) => void;
  onClearAll?: (source: string) => void;
  onSelectProducts?: (pc9s: string[], source: string) => void;
}

type Assortment = { assortmentId: string; assortmentName: string; count: number };
type Product = { pc9: string; url: string; name: string };
export type Source<A> = { type: 'assortment'; item: A } | { type: 'line' } | { type: 'parentLine' };

export interface UseAddProductReturn<P, A> {
  isLoading: boolean;
  assortments: ReadonlyArray<A>;
  products: ReadonlyArray<P>;
  source: Source<A> | undefined;
  changeSource: (newSource: Source<A>) => void;
  selectedProducts: P[];
  selectProducts: (...products: P[]) => void;
  selectAll: VoidFunction;
  searchTerm: string;
  searchFor: (searchTerm: string) => void;
  canSelectAll: boolean;
  canClearAll: boolean;
  clearAll: VoidFunction;
  canAdd: boolean;
  canAddFromParentLine: boolean;
  canAddFromAssortments: boolean;
  canAddFromLine: boolean;
  add: VoidFunction;
  isAdding: boolean;
}

export const useAddProduct = <P extends Product, A extends Assortment>({
  context,
  isLoading: isLoadingProp,
  parentLineId,
  assortmentsFor,
  // TODO: Prop is not tested. Add coverage for this.
  canAddFromCurrentAssortment,
  canAddFromLine,
  productsFor,
  loadAssortmentsFor,
  loadProductsFor,
  onAdd,
  onSearchFor,
  onSelectAll,
  onClearAll,
  onSelectProducts
}: UseAddProductProps<P>): UseAddProductReturn<P, A> => {
  // TODO: Extract source logic and data retrieval to separate module. Pass in prop to tell the hook what source is allowed.
  const [source, setSource] = useState<Source<A>>(
    context?.entity === Entity.Line && canAddFromLine ? { type: 'line' } : { type: 'parentLine' }
  );
  const userHasManuallySetASource = useRef(false);
  const [selectedPC9s, setSelectedPC9s] = useState<string[]>([]);

  useEffect(() => {
    if (context && 'customerId' in context && !assortmentsFor(context.customerId))
      loadAssortmentsFor(context.customerId);
  }, [assortmentsFor, context, loadAssortmentsFor]);

  const assortments =
    context && 'customerId' in context
      ? Object.values(assortmentsFor(context.customerId)?.assortments ?? {}).flatMap(assortments =>
          assortments
            .filter(({ assortmentId }) => canAddFromCurrentAssortment ?? assortmentId !== context.id)
            .map(
              ({ assortmentId, assortmentName, add = 0, pending = 0 }) =>
                ({ assortmentId, assortmentName, count: add + pending }) as A
            )
        )
      : [];

  useEffect(() => {
    if (context?.entity === Entity.Line && canAddFromLine) {
      setSource({ type: 'line' });
      return;
    }
  }, [canAddFromLine, context]);

  useEffect(() => {
    if (!assortments.length && source?.type === 'assortment') {
      setSource({ type: 'parentLine' });
      return;
    }

    const hasStaleSource =
      source?.type === 'assortment' && !assortments.find(x => x.assortmentId === source.item.assortmentId);

    if (hasStaleSource) setSource({ type: 'assortment', item: assortments[0] });
  }, [assortments, source]);

  useEffect(() => {
    if (assortments.length && source.type === 'parentLine' && !userHasManuallySetASource.current)
      setSource({ type: 'assortment', item: assortments[0] });
  }, [assortments, source.type]);

  useEffect(() => {
    if (source.type === 'assortment') loadProductsFor(source.item.assortmentId);
    else if (context) loadProductsFor(context.id, source.type);
  }, [context, loadProductsFor, source]);

  const changeSource = useCallback<UseAddProductReturn<P, A>['changeSource']>(newSource => {
    userHasManuallySetASource.current = true;
    setSource(newSource);
  }, []);

  const { products: allProducts, parentLineId: assortmentParentLineId } = (() => {
    const noProducts: { products: P[]; parentLineId?: string } = { products: [], parentLineId: undefined };

    if (source.type === 'assortment') return productsFor(source.item.assortmentId) ?? noProducts;
    else if (context) return productsFor(context.id, source.type) ?? noProducts;
    else return noProducts;
  })();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const products = useMemo(
    () => searchProducts(searchTerm, allProducts).map(({ item }) => item),
    [allProducts, searchTerm]
  );

  const onSearch = useCallback(
    (term: string) => {
      onSearchFor?.(term);
      setSearchTerm(term);
    },
    [onSearchFor]
  );

  const selectProducts = useCallback<UseAddProductReturn<P, A>['selectProducts']>(
    (...newlySelected) => {
      setSelectedPC9s(prev => {
        const newProducts = toggleArrayValue(prev, Array.from(new Set(newlySelected.map(product => product.pc9))));
        onSelectProducts?.(newProducts, source.type);
        return newProducts;
      });
    },
    [onSelectProducts, source.type]
  );

  const selectAll = useCallback(() => {
    setSelectedPC9s([]);
    setSelectedPC9s(products.map(product => product.pc9));
    onSelectAll?.(source.type);
  }, [onSelectAll, products, source.type]);

  const clearAll = useCallback(() => {
    setSelectedPC9s([]);
    onClearAll?.(source.type);
  }, [onClearAll, source.type]);

  const selectedProducts = useMemo(() => {
    const selected: P[] = [];
    for (const selectedPC9 of selectedPC9s) {
      const match = products.find(({ pc9 }) => pc9 === selectedPC9);
      if (match) selected.push(match);
    }
    return selected;
  }, [products, selectedPC9s]);
  const hasSelectedProducts = selectedProducts.length > 0;

  const [pendingAdd, setPendingAdd] = useState<Promise<void>>();
  const isLoading = isLoadingProp || Boolean(pendingAdd);
  const canAdd = hasSelectedProducts && !isLoading;

  const add = useCallback(() => {
    if (!canAdd)
      throw new Defect('Operation not allowed: make sure this handler is not called when `canAdd` is false.');

    const sourceParentLineId = source.type === 'assortment' ? assortmentParentLineId : parentLineId;

    // FIXME: Add these as part of the "canAdd" validation
    if (!sourceParentLineId) throw new Defect('Could not get sourceParentLineId.');
    if (!context) throw new Defect('Context not available.');

    setPendingAdd(
      onAdd({ context, sourceParentLineId, products: selectedProducts }).finally(() => setPendingAdd(undefined))
    );
  }, [assortmentParentLineId, canAdd, context, onAdd, parentLineId, selectedProducts, source.type]);

  return {
    assortments,
    products,
    source,
    changeSource,
    selectedProducts,
    selectProducts,
    selectAll,
    canSelectAll: Boolean(products.length) && products.length !== selectedPC9s.length,
    clearAll,
    canClearAll: hasSelectedProducts,
    searchTerm,
    searchFor: onSearch,
    canAdd,
    add,
    isLoading,
    isAdding: Boolean(pendingAdd),
    canAddFromParentLine: Boolean(parentLineId),
    canAddFromAssortments: Boolean(
      (context?.entity === Entity.Assortment || context?.entity === Entity.FinalAssortment) && assortments.length
    ),
    canAddFromLine: Boolean(context?.entity === Entity.Line && canAddFromLine)
  };
};
