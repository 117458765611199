import { Plus } from 'assets/icons';
import { Button } from 'components';
import { useLocalisation } from 'providers';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${themed('spacing.s')};
  padding: ${themed('spacing.m')} ${themed('spacing.xxxl')};
  background-color: ${themed('color.white')};
  text-transform: uppercase;
  box-shadow: ${themed('boxShadow')};
  z-index: 2;

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding-left: ${themed('spacing.xl')};
    padding-right: ${themed('spacing.xl')};
  }

  span {
    color: ${themed('color.grey')};

    &:first-of-type {
      font-weight: ${themed('font.weight.medium')};
      line-height: 1.8;
      margin-right: ${themed('spacing.m')};
      padding: ${themed('spacing.s')} 0;
      color: ${themed('color.black')};
    }
  }

  button {
    padding: 0;
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.medium')};

    svg {
      width: ${rem(22)};
      height: ${rem(22)};
    }
  }
`;
const SelectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: ${themed('spacing.m')};

    span:last-of-type {
      color: ${themed('color.grey')};
      margin-left: -${themed('spacing.m')};
    }
  }
`;

export const Footer = ({
  items,
  selectedItems,
  onDeselectAll,
  breakdown,
  leftActionButtons,
  rightActionButtons
}: {
  items: any[];
  selectedItems: number;
  onDeselectAll: VoidFunction;
  breakdown?: ReactNode;
  leftActionButtons?: ReactNode;
  rightActionButtons?: ReactNode;
}) => {
  const [str] = useLocalisation();

  return (
    <Wrapper>
      {selectedItems > 0 ? (
        <SelectedWrapper>
          <div>
            <span>
              {selectedItems} {selectedItems === 1 ? str('general.item.singular') : str('general.item.plural')}{' '}
              {str('general.selected')}
            </span>
            <span>
              {str('general.outOf')} {items.length}
            </span>
            <Button onClick={onDeselectAll}>
              <Plus />
              {str('general.deselectAll')}
            </Button>
            {leftActionButtons}
          </div>
          <div>{rightActionButtons}</div>
        </SelectedWrapper>
      ) : (
        <>
          <span>
            {items.length} {items.length === 1 ? str('general.item.singular') : str('general.item.plural')}
          </span>
          {breakdown}
        </>
      )}
    </Wrapper>
  );
};
