import type { Language, LocalisationService } from 'services';

import English from '@yourxx/translations/en.json';

/**
 * Temporary service implementation which returns the local (bundled) English localisation file
 */
export const localLocalisationService = (): LocalisationService => ({
  // TODO: Add support for other locales
  loadLocale: async _locale => English,
  supportedLocales: async (): Promise<ReadonlyArray<Language>> => [{ locale: 'en', label: 'Eng' }]
});
