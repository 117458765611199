import { assign, createMachine } from 'xstate';

import { Defect } from '@yourxx/support';

import type { DefaultFilterCombination } from '../../DefaultFilterCombination';
import type { FilterCombination } from '../../FilterCombination';
import { asDefaultCombination } from '../asDefaultCombination';
import { Action, State } from '../types';
import { lastOr } from '../utils';
import type { Context, Events, Services } from './types';

export const createStateMachine = ({
  defaultFilterCombination: initialDefaultFilterCombination
}: {
  defaultFilterCombination: DefaultFilterCombination;
}) => {
  return createMachine(
    {
      id: 'product-filters-state-machine',
      predictableActionArguments: true,
      preserveActionOrder: true,
      schema: {
        context: {} as Context,
        services: {} as Services,
        events: {} as Events
      },
      context: {
        filterCombinations: [],
        filtersOrder: [],
        defaultFiltersOrder: [],
        error: null,
        pendingCommand: null,
        activeCombination: initialDefaultFilterCombination
      },
      initial: State.LoadingPersistedData,
      states: {
        [State.LoadingPersistedData]: {
          invoke: {
            src: 'loadPersistedData',
            onError: {
              target: State.FailedLoadingPersistedData,
              actions: assign({
                error: (_context, event) => event.data
              })
            },
            onDone: {
              target: State.Idle,
              actions: [
                assign((context, event) => {
                  const { activeCombination } = context;

                  if (activeCombination?.base) {
                    const { base } = activeCombination;
                    const match = event.data.filterCombinations?.find((fc: FilterCombination) => base.id === fc.id);
                    if (match) activeCombination.baseOn(match);
                  }

                  if (context.pendingCommand?.type === 'save') {
                    const lastSavedCombination = lastOr(event.data.filterCombinations, null);
                    if (lastSavedCombination) activeCombination.baseOn(lastSavedCombination);
                  } else if (
                    context.pendingCommand?.type === 'delete' &&
                    activeCombination?.base === context.pendingCommand.item
                  ) {
                    activeCombination.removeBase();
                  }

                  return { ...context, ...event.data, activeCombination, error: null };
                }),
                'clearPendingCommand'
              ]
            }
          }
        },
        [State.FailedLoadingPersistedData]: {
          on: {
            [Action.Retry]: State.LoadingPersistedData
          }
        },
        [State.Idle]: {
          on: {
            [Action.Reorder]: {
              target: State.ReorderingFilters,
              actions: 'storePendingCommand'
            },
            [Action.Save]: {
              target: State.NewFilterCombination,
              actions: 'storePendingCommand'
            },
            [Action.Rename]: {
              target: State.RenamingFilterCombination,
              actions: 'storePendingCommand'
            },
            [Action.Delete]: {
              target: State.PendingDeleteConfirmation,
              actions: 'storePendingCommand'
            },
            [Action.ChangeActiveFilter]: {
              actions: assign({
                activeCombination: (context, event) => {
                  return context.activeCombination.baseOn(event.item);
                }
              })
            },
            [Action.UpdateDefaultFilterCombination]: {
              actions: assign({ activeCombination: (_, event) => event.item })
            },
            [Action.Set]: [
              {
                actions: [
                  assign({
                    activeCombination: ({ activeCombination }, event) => {
                      return asDefaultCombination(activeCombination).applyFilters(event.command.filters);
                    }
                  }),
                  'onSet'
                ]
              }
            ]
          }
        },
        [State.ReorderingFilters]: {
          on: {
            [Action.UpdateOrder]: {
              actions: 'storePendingCommand'
            },
            [Action.Cancel]: {
              target: State.Idle,
              actions: 'clearPendingCommand'
            },
            [Action.Confirm]: State.PersistingChanges
          }
        },
        [State.NewFilterCombination]: {
          on: {
            [Action.InputName]: {
              actions: 'storePendingCommand'
            },
            [Action.Cancel]: State.Idle,
            [Action.Confirm]: State.PersistingChanges
          }
        },
        [State.PendingDeleteConfirmation]: {
          on: {
            [Action.Cancel]: {
              target: State.Idle,
              actions: 'clearPendingCommand'
            },
            [Action.Confirm]: State.PersistingChanges
          }
        },
        [State.RenamingFilterCombination]: {
          on: {
            [Action.InputName]: {
              actions: 'storePendingCommand'
            },
            [Action.Cancel]: {
              target: State.Idle,
              actions: 'clearPendingCommand'
            },
            [Action.Confirm]: State.PersistingChanges
          }
        },
        [State.PersistingChanges]: {
          invoke: {
            src: 'handleCommand',
            onError: {
              actions: assign({ error: (_, event) => event.data })
            },
            onDone: {
              target: State.LoadingPersistedData
            }
          }
        }
      }
    },
    {
      actions: {
        storePendingCommand: assign({
          pendingCommand: (_, event) => {
            if (!('command' in event)) throw new Defect('Expected command in event');
            return event.command;
          }
        }),

        clearPendingCommand: assign({ pendingCommand: null })
      }
    }
  );
};
