import { Close, Home, Plus } from 'assets/icons';
import { Button } from 'components/Button';
import { Dropdown, DropdownItemContentType } from 'components/Dropdown';
import { EditableText } from 'components/EditableText';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Tabs = styled.div`
  display: flex;
  align-items: stretch;
`;
const Tab = styled(Button)<{ $active: boolean }>`
  display: flex;
  font-weight: ${themed('font.weight.regular')};
  gap: ${themed('spacing.xl')};
  background-color: ${({ $active }) => ($active ? themed('color.greyLight') : themed('color.greyMid'))};
  will-change: background-color;
  transition: background-color 0.2s ease-out;
  border-radius: ${themed('spacing.m')} ${themed('spacing.m')} 0 0;
  font-size: ${themed('font.size.s')};
  padding: ${themed('spacing.m')};
  justify-content: space-between;
  align-items: center;
`;
const TabText = styled(EditableText)`
  span {
    font-size: ${themed('font.size.s')};
    padding: 0;
    min-width: ${themed('spacing.xxl')};
    max-width: ${rem(150)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const IconContainer = styled.div`
  width: ${themed('spacing.l')};
  height: ${rem(14)};
`;
const AddButton = styled.button`
  position: relative;
  border: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
`;
const DropdownContainer = styled.div`
  z-index: 9;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

export type EditableTabProp = {
  name: string;
  id: string;
};
export type EditableTabProps = {
  className?: string;
  tabs: EditableTabProp[];
  onSelect: (id?: string) => void;
  onRename: (id: string, name: string) => void;
  onClose: (id: string) => void;
  onAdd: VoidFunction;
  selectedTab?: string;
  disabledTab?: string;
  showAddIcon?: boolean;
  showCloseIcon?: boolean;
  canEditTabName?: boolean;
};

const getDisplayAndExcessTabs = (
  tabs: EditableTabProp[],
  selectedTab?: string
): { displayTabs: EditableTabProp[]; excessTabs: EditableTabProp[] } => {
  if (tabs.length <= 4) return { displayTabs: tabs, excessTabs: [] };

  let firstTabs = tabs.slice(0, 4);
  let excessTabs = tabs.slice(4);

  const selectedTabInExcess = selectedTab && !firstTabs.some(tab => tab.id === selectedTab);
  if (selectedTabInExcess) {
    const selectedTabObj = tabs.find(tab => tab.id === selectedTab);
    if (selectedTabObj) {
      firstTabs = [...firstTabs.slice(0, 3), selectedTabObj];
      excessTabs = [firstTabs[3], ...excessTabs.filter(tab => tab.id !== selectedTab)];
    }
  }

  return { displayTabs: firstTabs, excessTabs };
};

export const EditableTabs = ({
  className,
  tabs,
  onSelect,
  onClose,
  onAdd,
  selectedTab,
  disabledTab,
  onRename,
  showAddIcon = true,
  showCloseIcon = true,
  canEditTabName = true
}: EditableTabProps) => {
  const { displayTabs, excessTabs } = useMemo(() => getDisplayAndExcessTabs(tabs, selectedTab), [tabs, selectedTab]);

  const onDelete = useCallback(
    (tabId: string) => {
      onClose(tabId);
      if (selectedTab !== tabId) return;
      const index = tabs.findIndex(t => t.id === tabId);
      const selectAfter = tabs[index - 1] || tabs[index + 1];
      onSelect(selectAfter?.id);
    },
    [selectedTab, tabs, onClose, onSelect]
  );

  return (
    <Tabs className={className}>
      <Tab onClick={() => onSelect()} $active={!selectedTab}>
        <IconContainer>
          <Home />
        </IconContainer>
      </Tab>
      {displayTabs.map(tab => (
        <Tab
          key={tab.id}
          id={tab.id}
          onClick={() => onSelect(tab.id)}
          $active={selectedTab === tab.id}
          disabled={disabledTab === tab.id}
        >
          <TabText
            onChange={name => onRename(tab.id, name)}
            canEdit={canEditTabName && selectedTab === tab.id}
            hideButtons
          >
            {tab.name}
          </TabText>
          {showCloseIcon && (
            <Close
              onClick={e => {
                e.stopPropagation();
                onDelete(tab.id);
              }}
            />
          )}
        </Tab>
      ))}
      {excessTabs.length > 0 && (
        <DropdownContainer>
          <Dropdown
            itemContentType={DropdownItemContentType.CANCEL}
            trigger=""
            items={excessTabs.map(excessTab => ({
              key: excessTab.id,
              label: excessTab.name
            }))}
            defaultSelected={selectedTab ? [selectedTab] : undefined}
            onSelect={id => onSelect(id)}
            onDeselect={key => onDelete(key)}
          />
        </DropdownContainer>
      )}
      {showAddIcon && (
        <AddButton onClick={onAdd}>
          <Plus width={24} />
        </AddButton>
      )}
    </Tabs>
  );
};
