import { ChevronDown, ChevronUp, Play } from 'assets/icons';
import { Button } from 'components/Button';
import { Image } from 'components/Image';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { SliderImage } from './ImageSlider';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
const SliderButton = styled(Button)`
  align-self: center;
  padding: ${themed('spacing.m')};

  svg {
    width: ${rem(18)};
    height: ${rem(18)};
  }
`;
const SliderOuter = styled.div`
  max-height: calc(100% - ${rem(72)});
  width: 100%;
  background-color: ${themed('color.white')};
  border-radius: ${themed('spacing.s')};
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const SliderInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${themed('spacing.m')};
  padding: ${themed('spacing.m')};
`;
const SliderItemContainer = styled(Button)<{ $isActive: boolean }>`
  border-color: ${({ $isActive }) => ($isActive ? themed('color.black') : themed('color.white'))};
  border-style: solid;
  border-width: 1px;
  padding: ${themed('spacing.xs')};
  justify-content: center;
  will-change: border-color;
  transition: border-color 0.2s ease-out;
  width: 100%;
  aspect-ratio: 1;
  box-sizing: border-box;

  img,
  video {
    width: 100%;
    height: 100%;
  }
`;

export const VerticalImageSlider = ({
  className,
  images,
  imageSize,
  onSlide,
  activeSlide,
  disableScrollIntoView
}: {
  className?: string;
  images: SliderImage[];
  imageSize: number;
  activeSlide: number;
  onSlide: (index: number) => void;
  disableScrollIntoView?: boolean;
}) => (
  <Container className={className}>
    <SliderButton onClick={() => onSlide(activeSlide - 1 < 0 ? images.length - 1 : activeSlide - 1)}>
      <ChevronUp />
    </SliderButton>
    <SliderOuter>
      <SliderInner>
        {images.map((image, i) => (
          <SliderItem
            key={image.url}
            index={i}
            url={image.url}
            size={imageSize}
            isVideo={image.isVideo}
            isActive={activeSlide === i}
            onSlide={onSlide}
            disableScrollIntoView={disableScrollIntoView}
          />
        ))}
      </SliderInner>
    </SliderOuter>
    <SliderButton onClick={() => onSlide(activeSlide + 1 > images.length - 1 ? 0 : activeSlide + 1)}>
      <ChevronDown />
    </SliderButton>
  </Container>
);

const SliderItem = ({
  index,
  url,
  size,
  isVideo,
  isActive,
  onSlide,
  disableScrollIntoView
}: {
  index: number;
  url: string;
  size: number;
  isVideo?: boolean;
  isActive: boolean;
  onSlide: (index: number) => void;
  disableScrollIntoView?: boolean;
}) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (!disableScrollIntoView && isActive && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isActive, ref, disableScrollIntoView]);

  return (
    <SliderItemContainer ref={ref} key={url} $isActive={isActive} onClick={() => onSlide(index)}>
      {isVideo ? (
        <>{url ? <video src={`${url}#t=5`} /> : <Play />}</>
      ) : (
        <Image src={`${url}&width=${size}&height=${size}`} />
      )}
    </SliderItemContainer>
  );
};
