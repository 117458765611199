import type { LocalisedStringFor } from 'providers';
import { useCallback } from 'react';
import { useEventSubscriptions } from 'utils';

import type { EventBus } from '../EventBus';
import { ToastRequested } from '../ToastService';
import { AddedProductsToStory, FailedAddingProductsToStory } from './events';

export const useRequestToastsForStoriesEvents = ({
  eventBus,
  str
}: {
  eventBus: EventBus;
  str: LocalisedStringFor;
}) => {
  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(AddedProductsToStory, event => {
          eventBus.emit(
            new ToastRequested({
              type: 'success',
              content: (
                <>{str('Assortment.addProductsToStory.success', { total: event.payload.command.products.length })}.</>
              )
            }).trace(event)
          );
        }),

        eventBus.on(FailedAddingProductsToStory, event => {
          eventBus.emit(
            new ToastRequested({
              type: 'error',
              content: <div title={event.payload.reason}>{str('Assortment.addProductsToStory.error')}</div>
            }).trace(event)
          );
        })
      ],
      [eventBus, str]
    )
  );
};
