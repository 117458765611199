import { useMemo } from 'react';

import { ProductListData, type ProductQueryStringParameters, ProductView } from '@yourxx/types';
import { getCustomerAssortments, getCustomerList, getProducts } from '@yourxx/ui-utils';

import type { CustomersService } from './CustomersService';

export const useCreateCustomersService = (): CustomersService =>
  useMemo(
    () => ({
      customers: getCustomerList,
      assortmentsFor: getCustomerAssortments,
      productsFor: async ({ assortmentOrLineId, parentLine, removed, dropped, pricingGroupId }) => {
        let status = ['ADD', 'PENDING'];
        if (removed || dropped) status = [];
        if (removed) status.push('REMOVE');
        if (dropped) status.push('DROP');

        const params: ProductQueryStringParameters = {
          id: assortmentOrLineId,
          view: ProductView.List,
          parent: parentLine ? 'true' : 'false',
          product_status: status.join('+')
        };

        if (pricingGroupId) params.pricing_group_id = pricingGroupId;

        return getProducts<ProductListData>(params);
      }
    }),
    []
  );
