import { CreateCanvasBoardResponse } from '@yourxx/types';
import { getBoardShare } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export const useGetShareBoardData = () => {
  const { canvasBoardId } = useCurrentCanvasContext();
  const { setShareBoardData } = useCanvas();

  const getShareBoardData = async ({
    handleLoading,
    handleSuccess,
    handleError
  }: {
    handleLoading: (loading: boolean) => void;
    handleSuccess: (data?: CreateCanvasBoardResponse[]) => void;
    handleError?: VoidFunction;
  }) => {
    if (canvasBoardId) {
      handleLoading(true);
      getBoardShare({ boardShareId: canvasBoardId })
        .then(data => {
          setShareBoardData(data);
          handleSuccess();
        })
        .catch(e => {
          console.error(e);
          handleLoading(false);
          handleError?.();
        });
    }
  };
  return { getShareBoardData };
};
