import { EntityContext } from 'domain-events';
import { createContext, Dispatch, SetStateAction, useState } from 'react';

import {
  BoardItem,
  CanvasBoardDetails,
  CanvasItemProductDetails,
  GetCanvasBoardShareResponse,
  PageDetails,
  ProductListData,
  ProductSliderData,
  UIProduct
} from '@yourxx/types';

export type ViewMode = 'INFO' | 'IMAGE';
export type Metric =
  | 'COLOUR'
  | 'COLOUR_GROUP'
  | 'FABRIC'
  | 'FIT'
  | 'MFP_CATEGORY'
  | 'PLANNED_LIFECYCLE'
  | 'PRICE_POSITIONING'
  | 'RETAIL_PRICE'
  | 'SEASONALITY'
  | 'WHOLESALE_PRICE';

export type CanvasContextShape = {
  viewMode: ViewMode;
  setViewMode: Dispatch<SetStateAction<ViewMode>>;
  metrics: Metric[];
  setMetrics: Dispatch<SetStateAction<Metric[]>>;
  boardItems: BoardItem[];
  setBoardItems: Dispatch<SetStateAction<BoardItem[]>>;
  boards: CanvasBoardDetails[];
  setBoards: Dispatch<SetStateAction<CanvasBoardDetails[]>>;
  boardDataLoading: boolean;
  setBoardDataLoading: Dispatch<SetStateAction<boolean>>;
  shareBoardData: GetCanvasBoardShareResponse | null;
  setShareBoardData: Dispatch<SetStateAction<GetCanvasBoardShareResponse | null>>;
  showProductSelector: boolean;
  setShowProductSelector: Dispatch<SetStateAction<boolean>>;
  isImageZoomed: boolean;
  setIsImageZoomed: Dispatch<SetStateAction<boolean>>;
  lineProducts: UIProduct<ProductSliderData>[];
  setLineProducts: Dispatch<SetStateAction<UIProduct<ProductSliderData>[]>>;
  lineParentProducts: UIProduct<ProductSliderData>[];
  setLineParentProducts: Dispatch<SetStateAction<UIProduct<ProductSliderData>[]>>;
  productDetails: CanvasItemProductDetails[];
  setProductDetails: Dispatch<SetStateAction<CanvasItemProductDetails[]>>;
  productsContext: EntityContext | undefined;
  setProductsContext: Dispatch<SetStateAction<EntityContext | undefined>>;
  products: UIProduct<ProductListData>[];
  setProducts: Dispatch<SetStateAction<UIProduct<ProductListData>[]>>;
  pageDetails: PageDetails | undefined;
  setPageDetails: Dispatch<SetStateAction<PageDetails | undefined>>;
};

export const CanvasContext = createContext<CanvasContextShape>(undefined!);

export const CanvasProvider = ({ children }: { children?: React.ReactNode }) => {
  const [viewMode, setViewMode] = useState<ViewMode>('IMAGE');
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const [boards, setBoards] = useState<CanvasBoardDetails[]>([]);
  const [boardItems, setBoardItems] = useState<BoardItem[]>([]);
  const [lineProducts, setLineProducts] = useState<UIProduct<ProductSliderData>[]>([]);
  const [lineParentProducts, setLineParentProducts] = useState<UIProduct<ProductSliderData>[]>([]);
  const [productDetails, setProductDetails] = useState<CanvasItemProductDetails[]>([]);
  const [boardDataLoading, setBoardDataLoading] = useState<boolean>(false);
  const [shareBoardData, setShareBoardData] = useState<GetCanvasBoardShareResponse | null>(null);
  const [showProductSelector, setShowProductSelector] = useState<boolean>(false);
  const [isImageZoomed, setIsImageZoomed] = useState<boolean>(false);
  const [productsContext, setProductsContext] = useState<EntityContext | undefined>();
  const [products, setProducts] = useState<UIProduct<ProductListData>[]>([]);
  const [pageDetails, setPageDetails] = useState<PageDetails | undefined>();

  const value = {
    viewMode,
    setViewMode,
    metrics,
    setMetrics,
    boards,
    setBoards,
    boardDataLoading,
    setBoardDataLoading,
    shareBoardData,
    setShareBoardData,
    showProductSelector,
    setShowProductSelector,
    isImageZoomed,
    setIsImageZoomed,
    lineProducts,
    setLineProducts,
    lineParentProducts,
    setLineParentProducts,
    productDetails,
    setProductDetails,
    boardItems,
    setBoardItems,
    productsContext,
    setProductsContext,
    products,
    setProducts,
    pageDetails,
    setPageDetails
  };

  return <CanvasContext.Provider value={value}>{children}</CanvasContext.Provider>;
};
