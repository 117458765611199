import { Reorder } from 'assets/icons';
import { Carousel } from 'components/Carousel';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useLocalisation } from 'providers';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { rem, themed, useIAM } from 'utils';

import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { BoardItem } from '@yourxx/types';

import { CompareImage } from './CompareImage';
import { CompareInfo } from './CompareInfo';
import { useCurrentCanvasContext } from './CurrentCanvasContext';
import {
  useAddProductCanvasUI,
  useCanvas,
  useDeleteBoardItem,
  useGetBoardItems,
  useGetProductDetails,
  useUpdateBoardItems
} from './hooks';

const EmptyLabel = styled.p`
  padding: ${themed('spacing.l')};
  text-align: center;
  font-size: ${themed('font.size.m')};
  background-color: ${themed('color.greyLight')};
`;
const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${themed('color.transparentLightBlack')};
  color: ${themed('color.white')};
`;
const ReorderButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  top: ${rem(10)};
  right: ${rem(10)};
  padding: 0;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  svg {
    width: ${themed('spacing.xl')};
    height: ${themed('spacing.xl')};
  }
`;

const SortableItem = ({
  id,
  children,
  disableSort
}: {
  id: string;
  children: React.ReactNode;
  disableSort: boolean;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition
      }}
    >
      {children}
      {!disableSort && (
        <ReorderButton {...attributes} {...listeners}>
          <Reorder />
        </ReorderButton>
      )}
    </div>
  );
};

export const CompareContent = () => {
  const {
    viewMode,
    productDetails,
    boardItems,
    productsContext,
    pageDetails,
    showProductSelector,
    setShowProductSelector
  } = useCanvas();
  const { canvasBoardId } = useCurrentCanvasContext();
  const { canUse, canNotUse } = useIAM();
  const iamPrefix = 'products.canvas';

  const [isLoadingBoardItems, setIsLoadingBoardItems] = useState<boolean>(false);
  const [isLoadingProductDetails, setIsLoadingProductDetails] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [str] = useLocalisation();

  const { getProductDetails } = useGetProductDetails();
  const { updateBoardItems } = useUpdateBoardItems();
  const { deleteBoardItem, isDeletingBoardItem } = useDeleteBoardItem();
  const { getBoardItems } = useGetBoardItems();

  const addProductUI = useAddProductCanvasUI({
    context: productsContext,
    pageDetails,
    isOpen: showProductSelector,
    onClose: () => setShowProductSelector(false)
  });

  useEffect(() => {
    setShowProductSelector(false);
    return () => setShowProductSelector(false);
  }, [setShowProductSelector]);

  useEffect(() => {
    getBoardItems({
      handleLoading: () => setIsLoadingBoardItems(true),
      handleSuccess: () => setIsLoadingBoardItems(false),
      handleError: () => {
        setErrorMessage(str('Landing.lines.retrievingError'));
        setIsLoadingBoardItems(false);
      }
    });
  }, [canvasBoardId]);

  useEffect(() => {
    getProductDetails({
      handleLoading: () => setIsLoadingProductDetails(true),
      handleSuccess: () => setIsLoadingProductDetails(false),
      handleError: () => {
        setErrorMessage(str('Landing.lines.retrievingError'));
        setIsLoadingProductDetails(false);
      }
    });
  }, [boardItems]);

  const onDragEnd = useCallback(
    (e: DragEndEvent) => {
      const { active, over } = e;
      if (over && active.id !== over.id && boardItems) {
        const toIndex = boardItems?.findIndex(bi => bi.id === over.id);
        const fromIndex = boardItems?.findIndex(bi => bi.id === active.id);
        const boardItem = boardItems[fromIndex];
        boardItems.splice(fromIndex, 1);
        boardItems.splice(toIndex, 0, boardItem);
        updateBoardItems(boardItems.map((bi: BoardItem, i: number) => ({ ...bi, order: i })));
      }
    },
    [boardItems]
  );

  const onDeleteBoardItem = useCallback(
    (boardItemId: string, pc9?: string) => {
      deleteBoardItem(boardItemId, pc9);
    },
    [deleteBoardItem]
  );

  if (isLoadingBoardItems || isLoadingProductDetails)
    return <LoadingSpinner label={str(`Canvas.${isLoadingProductDetails ? 'product' : 'board'}.loading`)} />;
  if (errorMessage)
    return (
      <EmptyLabel>
        {str('Canvas.board.loadingError', { addLabel: str('Canvas.board.loadingError').toUpperCase() })}
      </EmptyLabel>
    );
  const canRemove = canUse(`deleteBoard`, iamPrefix);
  const disableSort = canNotUse(`editBoard`, iamPrefix);
  return (
    <>
      {boardItems.length <= 0 ? (
        <EmptyLabel>
          {str('Canvas.product.noneAddedHint', { addLabel: str('Canvas.actions.addProducts').toUpperCase() })}
        </EmptyLabel>
      ) : (
        <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
          <SortableContext items={boardItems.map(p => p?.id) || []} strategy={horizontalListSortingStrategy}>
            <Carousel>
              {boardItems.map(({ pc9Code, id }) => {
                const product = productDetails?.find(pd => pd.pc9Code === pc9Code);
                if (product)
                  return (
                    <SortableItem key={pc9Code} id={id} disableSort={disableSort || boardItems.length === 1}>
                      {viewMode === 'INFO' ? (
                        <CompareInfo
                          product={product}
                          onRemove={canRemove ? () => onDeleteBoardItem(id, pc9Code) : undefined}
                        />
                      ) : (
                        <CompareImage
                          product={product}
                          onRemove={canRemove ? () => onDeleteBoardItem(id, pc9Code) : undefined}
                        />
                      )}
                      {isDeletingBoardItem === id && (
                        <LoadingOverlay>
                          <LoadingSpinner label={str('Canvas.product.removing')} />
                        </LoadingOverlay>
                      )}
                    </SortableItem>
                  );
              })}
            </Carousel>
          </SortableContext>
        </DndContext>
      )}
      {canvasBoardId && addProductUI}
    </>
  );
};
