import { normalize } from 'path';

import { ConfigParams } from '@yourxx/types/src/api/client';

import { getApiUrl } from './url';

export let baseUrl: string;

type ToRequest = ToRequestInit & ToApiPath;
type ToRequestInit = Omit<RequestInit, 'body'> & { body?: any };
type ToApiPath = { path: string; params?: ConfigParams };

export const toRequest = (input: ToRequest) => {
  const { path, params } = input;
  return new Request(apiPath({ path, params }), toRequestInit(input));
};

export const toRequestInit = (request: ToRequestInit) => {
  if (request.body && typeof request.body === 'object') {
    const headers = new Headers(request.headers);
    headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(request.body);
    return { ...request, headers, body };
  }
  return request;
};

export const apiPath = ({ path, params }: ToApiPath) => {
  baseUrl = baseUrl ?? getApiUrl();
  path = normalize(`${baseUrl}/${path}`);
  const url = new URL(path);
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        url.searchParams.set(key, Array.isArray(value) ? value.join(' ') : value.toString());
      }
    });
  }
  return url.toString();
};
