import { useMemo } from 'react';

import { createProductFiltersService } from './createProductFiltersService';
import { createLocalStorageSink } from './persistence';
import { useFiltersSchema } from './useFiltersSchema';

export const useCreateProductFiltersService = () => {
  const { filtersSchema } = useFiltersSchema();
  return useMemo(() => {
    return createProductFiltersService(filtersSchema, createLocalStorageSink('@yourxx/product-filters'));
  }, [filtersSchema]);
};
