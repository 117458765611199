import { styled } from 'styled-components';
import { themed } from 'utils';

export const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: inherit;
  align-items: inherit;
  justify-content: inherit;
  gap: ${themed('spacing.l')};
`;
