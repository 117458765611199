import { Close } from 'assets/icons';
import { Button } from 'components/Button';
import { useEffect } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  inset: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Backdrop = styled.div`
  background-color: ${themed('color.transparentMidBlack')};
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`;
const Content = styled.div<{ $fullscreen?: boolean; $maxWidth?: number; $minHeight?: number }>`
  display: flex;
  flex-direction: column;
  max-width: ${({ $fullscreen, $maxWidth }) =>
    $fullscreen ? 'revert' : $maxWidth ? `min(90vw, ${$maxWidth}px)` : '90vw'};
  max-height: ${({ $fullscreen }) => ($fullscreen ? 'revert' : '90vh')};
  min-height: ${({ $fullscreen, $minHeight }) => ($fullscreen ? 'revert' : $minHeight ? rem($minHeight) : 'revert')};
  width: ${({ $fullscreen }) => ($fullscreen ? '100vw' : 'revert')};
  height: ${({ $fullscreen }) => ($fullscreen ? '100vh' : 'revert')};
  background-color: ${themed('color.white')};
  border-radius: ${({ $fullscreen }) => ($fullscreen ? 0 : themed('spacing.l'))};
  box-shadow: ${themed('boxShadow')};
  margin: ${({ $fullscreen }) => ($fullscreen ? 0 : 'revert')};
  position: ${({ $fullscreen }) => ($fullscreen ? 'fixed' : 'relative')};
  inset: 0;
  overflow: hidden;
  animation-name: ${themed('animation.slideDown')};
  animation-timing-function: ${themed('transition.timing')};
  animation-duration: ${themed('transition.duration')};
`;
const HeaderWrapper = styled.div<{ $fullscreen?: boolean; $noTitle?: boolean; $notVisible?: boolean }>`
  position: ${({ $fullscreen, $noTitle }) => ($fullscreen || $noTitle ? 'absolute' : 'static')};
  z-index: 10;
  width: ${$noTitle => ($noTitle ? 'auto' : '100%')};
  right: ${$noTitle => ($noTitle ? 0 : 'unset')};
  display: ${({ $notVisible }) => ($notVisible ? 'none' : 'flex')};
  justify-content: ${$noTitle => ($noTitle ? 'space-between' : 'flex-end')};
  align-items: center;
  padding: ${themed('spacing.xl')};
  box-sizing: border-box;

  svg {
    width: ${rem(28)};
    height: ${rem(28)};
  }
`;
const Title = styled.h2`
  flex-grow: 1;
  margin: 0;
  ${themed('typography.h2')};
  font-weight: ${themed('font.weight.bold')};
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: ${rem(0.3)};
  text-align: left;
`;
const Subtitle = styled.h3`
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.regular')};
  margin: ${themed('spacing.m')} 0 0;
`;

export type ModalProps = {
  className?: string;
  onClose: VoidFunction;
  children: React.ReactNode;
  closeOnBackdropClick?: boolean;
  title?: string;
  subtitle?: string;
  fullscreenMode?: boolean;
  maxWidth?: number;
  minHeight?: number;
  hideCloseButton?: boolean;
};

export const Modal = ({
  className,
  onClose,
  closeOnBackdropClick = true,
  children,
  title,
  subtitle,
  fullscreenMode,
  maxWidth,
  minHeight,
  hideCloseButton = false
}: ModalProps) => {
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => e.key === 'Escape' && onClose();
    const htmlEl = document.querySelector('html');

    if (htmlEl) htmlEl.style.overflow = 'hidden';
    document.addEventListener('keydown', handleKeydown);

    return () => {
      if (htmlEl) htmlEl.style.overflow = 'auto';
      document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  return (
    <Wrapper className={className}>
      <Backdrop onClick={() => closeOnBackdropClick && onClose()} />
      <Content $fullscreen={fullscreenMode} $maxWidth={maxWidth} $minHeight={minHeight}>
        <HeaderWrapper
          $fullscreen={fullscreenMode}
          $noTitle={!title && !subtitle}
          $notVisible={!title && !subtitle && hideCloseButton}
        >
          <div>
            {title && <Title>{title}</Title>}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </div>
          {!hideCloseButton && (
            <Button onClick={onClose}>
              <Close />
            </Button>
          )}
        </HeaderWrapper>
        {children}
      </Content>
    </Wrapper>
  );
};
