import { useState } from 'react';

import { Metric } from '@yourxx/types';
import { updateBoard as apiUpdateBoard } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export type UseUpdateBoard = {
  updateBoard: (details: { boardId: string; name?: string; metrics?: Metric[] }) => void;
  updatingBoard: string;
  hasError: string | undefined;
};

export const useUpdateBoard = () => {
  const { assortmentId, lineId } = useCurrentCanvasContext();
  const { setBoards } = useCanvas();
  const [updatingBoard, setUpdatingBoard] = useState<string>('');
  const [hasError, setHasError] = useState<string>();

  const updateBoard = (details: { boardId: string; name?: string; metrics?: Metric[] }) => {
    setUpdatingBoard(details.boardId);
    apiUpdateBoard({ lineId, assortmentId }, details)
      .then(boardDetails => {
        setBoards(prev => prev.map(b => (b.boardId === boardDetails.boardId ? { ...b, ...boardDetails } : b)));
        setUpdatingBoard('');
      })
      .catch(() => {
        setHasError('Failed adding item to board');
        setUpdatingBoard('');
      });
  };

  return { updateBoard, updatingBoard, hasError };
};
