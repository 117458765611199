import { DomainEvent } from 'services';

import type { LoadAssortmentsCommand, LoadProductsCommand } from './customers-state-machine';
import type { ProductCacheTag } from './useCustomers';

export class CustomerAssortmentsRequested extends DomainEvent<{ command: LoadAssortmentsCommand }> {}
export class CustomerAssortmentsLoading extends DomainEvent<{ command: LoadAssortmentsCommand }> {}
export class CustomerAssortmentsLoaded extends DomainEvent<{ command: LoadAssortmentsCommand }> {}
export class CustomerAssortmentsFailed extends DomainEvent<{ command: LoadAssortmentsCommand; error: Error }> {}

export class ProductsDataRequested extends DomainEvent<{ command: LoadProductsCommand }> {}
export class ProductsDataLoading extends DomainEvent<{ command: LoadProductsCommand }> {}
export class ProductsDataLoaded extends DomainEvent<{ command: LoadProductsCommand }> {}
export class ProductsDataLoadingFailed extends DomainEvent<{ command: LoadProductsCommand; error: Error }> {}

export class CacheClearRequested extends DomainEvent<{
  entries: readonly { tag: ProductCacheTag; id: string; reload?: boolean }[];
}> {
  static for(...entries: readonly { tag: ProductCacheTag; id: string; reload?: boolean }[]) {
    return new CacheClearRequested({ entries });
  }
}
