import { Checkbox } from 'components/Checkbox';
import { Image } from 'components/Image';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Container = styled.div`
  display: grid;
`;
const ImageContainer = styled.div`
  border-radius: ${themed('spacing.s')};
  max-width: ${rem(70)};
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  float: left;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  > label {
    width: ${themed('spacing.l')};
    height: ${themed('spacing.l')};
    position: absolute;
    top: ${themed('spacing.s')};
    right: ${themed('spacing.s')};
    pointer-events: none;
  }
`;
const Label = styled.span`
  font-size: ${themed('font.size.xs')};
  line-height: ${rem(12)};
  color: ${themed('color.grey')};
  padding-top: ${themed('spacing.xs')};
  margin: ${rem(1)} 0 0 0;
`;

export type ImageCheckboxProps = {
  className?: string;
  id: string;
  imageUrl: string;
  onCheckedChange: (val: boolean) => void;
  isChecked: boolean;
};

export const ImageCheckbox = ({ className, id, imageUrl, isChecked, onCheckedChange }: ImageCheckboxProps) => {
  const [imageChecked, setImageChecked] = useState<boolean>(isChecked);
  useEffect(() => setImageChecked(isChecked), [isChecked]);

  return (
    <Container className={className}>
      <ImageContainer onClick={() => onCheckedChange(!imageChecked)}>
        <Image src={imageUrl} />
        <Checkbox id={id} checked={isChecked} />
      </ImageContainer>
      <Label>{id}</Label>
    </Container>
  );
};
