/**
 * Helper for programmatically copying some text content to clipboard.
 * It's using the legacy/deprecated `document.execCommand()` method,
 * instead of the new Clipboard API, but it is simple, and it works.
 *
 * Future improvement: Implement the new Clipboard API for browsers to support it.
 */
export const copyToClipboard = async (content: string) => {
  const tempElement = document.createElement('textarea');
  document.body.append(tempElement);

  try {
    tempElement.value = content;
    tempElement.select();
    tempElement.setSelectionRange(0, 99999);
    document.execCommand('copy');
  } catch (error) {
    throw new Error('Could not copy text to clipboard');
  }

  document.body.removeChild(tempElement);
};
