import { redirect } from 'react-router-dom';

import { persistedStore } from '@yourxx/ui-utils';

export const brandsLoader = async () => {
  const brands = persistedStore.getUserProfile()?.brands;
  if (brands && brands.length === 1) {
    return redirect(`/${brands[0].toLowerCase()}/customers`);
  }
  return brands ?? null;
};
