import { useEffect, useRef } from 'react';
import { useAsyncError, useNavigate } from 'react-router-dom';
import { useServices } from 'services';
import { asErrorMessage } from 'utils';

import { ViewTransition } from './ViewTransition';

/**
 * Should, ideally, only be used with the `Await` component as the `errorElement` prop.
 */
export const ShowAsyncErrorAndGoBack = () => {
  const maybeError = useAsyncError();
  const { toastService } = useServices();
  const navigate = useNavigate();
  const isDone = useRef(false);

  useEffect(() => {
    if (!isDone.current) {
      isDone.current = true;
      toastService.send(asErrorMessage(maybeError), 'error');
      navigate(-1);
    }
  }, [maybeError, navigate, toastService]);

  return <ViewTransition children={null} />;
};
