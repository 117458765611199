import { Close as CloseIcon } from 'assets/icons';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Burger = styled.button<{ $menuOpen: boolean }>`
  position: absolute;
  right: ${themed('spacing.xxxl')};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: ${themed('spacing.xxl')};
  height: ${themed('spacing.xl')};
  background-color: transparent;
  border: none;
  padding: 0;
  z-index: 10;
  cursor: pointer;

  span {
    width: ${themed('spacing.xxl')};
    height: ${rem(3)};
    background-color: ${({ $menuOpen }) => ($menuOpen ? themed('color.white') : themed('color.black'))};
    border-radius: ${themed('spacing.m')};
    will-change: transform, opacity;
    transition:
      transform 0.3s ease-out,
      opacity 0.3s ease-out;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ $menuOpen }) => ($menuOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ $menuOpen }) => ($menuOpen ? 0 : 1)};
      transform: ${({ $menuOpen }) => ($menuOpen ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${({ $menuOpen }) => ($menuOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
const Overlay = styled.div<{ $menuOpen: boolean }>`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: ${themed('color.transparentLightBlack')};
  opacity: ${({ $menuOpen }) => ($menuOpen ? 1 : 0)};
  pointer-events: ${({ $menuOpen }) => ($menuOpen ? 'auto' : 'none')};
  will-change: opacity;
  transition: opacity 0.3s ease-out;
`;
const Menu = styled.nav<{ $menuOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: ${themed('color.white')};
  transform: ${({ $menuOpen }) => ($menuOpen ? 'translateX(0)' : 'translateX(100%)')};
  opacity: ${({ $menuOpen }) => ($menuOpen ? 1 : 0)};
  pointer-events: ${({ $menuOpen }) => ($menuOpen ? 'auto' : 'none')};
  height: calc(100vh - 5 * ${themed('spacing.xl')} + ${themed('spacing.m')});
  border-radius: ${rem(25)};
  text-align: left;
  padding: ${themed('spacing.xxl')};
  top: ${themed('spacing.xl')};
  right: ${themed('spacing.xl')};
  will-change: transform, opacity;
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-out;
  z-index: 100;
`;
const CloseButton = styled.div`
  border-radius: 100%;
  height: ${themed('spacing.xxl')};
  width: ${themed('spacing.xxl')};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border: none;
  background-color: ${themed('color.white')};
  right: ${themed('spacing.l')};
  top: ${themed('spacing.l')};
  padding-bottom: ${themed('spacing.l')};
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export type BurgerProps = {
  className?: string;
  open: boolean;
  setOpen: any;
  children?: React.ReactNode;
  label?: string;
};

export const BurgerMenu = ({ className, setOpen, open, children, label }: BurgerProps) => (
  <div className={className}>
    <Burger aria-label={label} aria-expanded={open} $menuOpen={open} onClick={() => setOpen(!open)}>
      <span />
      <span />
      <span />
    </Burger>
    <Overlay $menuOpen={open} onClick={() => setOpen(false)} />
    <Menu $menuOpen={open} aria-hidden={!open}>
      <CloseButton onClick={() => setOpen(false)}>
        <CloseIcon />
      </CloseButton>
      {children}
    </Menu>
  </div>
);
