import { ChevronDown, Close } from 'assets/icons';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { PopOver } from 'components/PopOver';
import { useCallback, useEffect, useState } from 'react';
import { PopoverAlign } from 'react-tiny-popover';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Content = styled.div<{ $disableSelection?: boolean; $maxHeight?: number; $setMinWidth?: boolean }>`
  overflow: auto;
  max-height: ${({ $maxHeight }) => ($maxHeight ? rem($maxHeight) : 'none')};

  > div {
    display: flex;
    flex-direction: column;
    border-radius: ${themed('borderRadius')};
    background-color: ${themed('dropdown.background')};
    min-width: ${({ $setMinWidth }) => ($setMinWidth ? themed('dropdown.minWidth') : 'auto')};
    box-sizing: border-box;
    pointer-events: ${({ $disableSelection }) => ($disableSelection ? 'none' : 'auto')};
  }
`;
const Item = styled.div<{ $isSelected?: boolean; $disableSelectionHighlight?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${themed('color.black')};
  padding: ${themed('spacing.m')};
  border-radius: ${themed('spacing.s')};
  background-color: ${({ $isSelected, $disableSelectionHighlight }) =>
    !$disableSelectionHighlight && $isSelected ? themed('color.offWhite') : 'transparent'};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${themed('color.greyLight')};
    }
  }

  > label {
    pointer-events: none;
  }
`;
const StyledButton = styled(Button)<{ $isOpen?: boolean; $compactTrigger?: boolean }>`
  font-weight: ${themed('font.weight.light')};
  align-items: center;
  text-transform: ${({ $compactTrigger }) => ($compactTrigger ? 'capitalize' : 'uppercase')};
  padding-top: ${({ $compactTrigger }) => ($compactTrigger ? 0 : themed('button.paddingX'))};
  padding-bottom: ${({ $compactTrigger }) => ($compactTrigger ? 0 : themed('button.paddingX'))};

  > div {
    display: flex;
    align-items: center;
  }
  svg {
    width: ${rem(12)};
    height: ${rem(12)};
    will-change: transform;
    transition: transform 0.2s ease-out;
    transform: ${({ $isOpen }) => `rotate(${$isOpen ? 180 : 0}deg)`};
  }
`;

export enum DropdownItemContentType {
  PLAIN = 'plain',
  CHECKBOX = 'checkbox',
  CANCEL = 'cancel'
}
type props = {
  className?: string;
  trigger: React.ReactNode;
  compactTrigger?: boolean;
  items: {
    key: string;
    label: React.ReactNode;
  }[];
  onSelect?: (key: string, selected?: boolean) => void;
  maxHeight?: number;
  setMinWidth?: boolean;
  itemContentType?: DropdownItemContentType;
  showArrow?: boolean;
  placeHolder?: string;
  onDeselect?: (key: string) => void;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  disabled?: boolean;
  disableSelection?: boolean;
  disableSelectionHighlight?: boolean;
  isMultiselect?: boolean;
  defaultSelected?: string[];
  position?: 'bottom' | 'top';
  align?: PopoverAlign;
};

export const Dropdown = ({
  className,
  trigger,
  compactTrigger,
  items,
  onSelect,
  maxHeight,
  setMinWidth = true,
  itemContentType = DropdownItemContentType.PLAIN,
  showArrow = true,
  placeHolder,
  onDeselect,
  disabled,
  disableSelection = false,
  disableSelectionHighlight = false,
  isMultiselect = false,
  defaultSelected,
  onOpen,
  onClose,
  position = 'bottom',
  align
}: props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultSelected || []);

  useEffect(() => defaultSelected && setSelectedItems(defaultSelected), [defaultSelected]);

  const onSelected = useCallback(
    (key: string) => {
      if (isMultiselect) {
        if (!selectedItems.includes(key)) {
          setSelectedItems([...selectedItems, key]);
          onSelect?.(key, true);
        } else {
          setSelectedItems(selectedItems.filter(x => x !== key));
          onSelect?.(key, false);
        }
      } else {
        setSelectedItems([key]);
        onSelect?.(key);
        setIsOpen(false);
      }
    },
    [isMultiselect, selectedItems, setSelectedItems, onSelect, setIsOpen]
  );

  return (
    <PopOver
      isOpen={isOpen}
      className={className}
      showCloseButton={false}
      onClose={() => setIsOpen(false)}
      positions={[position]}
      align={align}
      trigger={
        <StyledButton
          $isOpen={isOpen && showArrow}
          $compactTrigger={compactTrigger}
          disabled={disabled}
          onClick={() => {
            setIsOpen(prev => {
              prev ? onClose?.() : onOpen?.();
              return !prev;
            });
          }}
        >
          {trigger || placeHolder}
          {showArrow && <ChevronDown />}
        </StyledButton>
      }
      children={
        <Content $disableSelection={disableSelection} $maxHeight={maxHeight} $setMinWidth={setMinWidth}>
          <div>
            {items.map(item => (
              <Item
                key={item.key}
                onClick={() => onSelected(item.key)}
                $isSelected={itemContentType !== DropdownItemContentType.CHECKBOX && selectedItems?.includes(item.key)}
                $disableSelectionHighlight={disableSelectionHighlight}
              >
                {item.label}
                {itemContentType === DropdownItemContentType.CHECKBOX && (
                  <Checkbox id={item.key} checked={selectedItems?.includes(item.key)} />
                )}
                {itemContentType === DropdownItemContentType.CANCEL && (
                  <Button onClick={() => onDeselect?.(item.key)}>
                    <Close />
                  </Button>
                )}
              </Item>
            ))}
          </div>
        </Content>
      }
    />
  );
};
