import styled from 'styled-components';
import { rem, themed } from 'utils';

const StyledDivider = styled.div`
  width: ${rem(1)};
  background-color: ${themed('color.greyMid')};
  margin: 0 ${themed('spacing.m')};
  height: ${rem(30)};
`;

export const VerticalDivider = ({ className }: { className?: string }) => <StyledDivider className={className} />;
