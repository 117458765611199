import { SpinnerAlt } from 'assets/icons';
import { css, styled } from 'styled-components';
import { rem, themed } from 'utils';

export const Container = styled.div`
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0 ${themed('spacing.xxxl')} ${themed('spacing.xxxl')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding-left: ${themed('spacing.xl')};
    padding-right: ${themed('spacing.xl')};
  }
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${themed('spacing.m')};

  & > div {
    display: flex;
    align-items: center;
    gap: ${themed('spacing.m')};
  }

  div label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: ${themed('spacing.s')};
  }
`;

export const Row = styled.div<{ $parent?: boolean; $header?: boolean }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  min-height: ${themed('spacing.xxxl')};
  text-align: center;
  will-change: background-color, transform;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};
  animation-name: ${themed('animation.slideDown')};
  animation-timing-function: ${themed('transition.timing')};
  animation-duration: ${themed('transition.duration')};

  // Bottom border
  &::after {
    content: ${({ $parent, $header }) => ($parent || $header ? `''` : 0)};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${rem(1)};
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${({ $header, $parent }) =>
    $header
      ? css`
          ${themed('typography.h3')};
          font-weight: ${themed('font.weight.bold')};
          text-transform: uppercase;
        `
      : $parent
        ? css`
            ${themed('typography.h3')};
          `
        : css`
            ${themed('typography.h4')};
            font-weight: ${themed('font.weight.medium')};

            &:nth-child(2n + 1) {
              background-color: rgba(0, 0, 0, 0.02);
            }
          `};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ $header }) => ($header ? '' : 'rgba(0, 0, 0, 0.03)')};
    }
  }
`;

export const Cell = styled.div<{ $clickable?: boolean }>`
  cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'unset')};
  user-select: ${({ $clickable }) => ($clickable ? 'none' : 'unset')};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
  padding: 0 ${themed('spacing.m')};
  min-width: 0;
  font-variant: tabular-nums;

  &:first-of-type {
    max-width: 100%;
    display: flex;
    font-variant: unset;
    justify-content: space-between;
    text-align: left;
    text-transform: uppercase;
    font-weight: ${themed('font.weight.bold')};
  }
`;

const firstColumnWidth = '25%';

export const Table = styled.div<{ $columnsCount: number }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  > ${Row} {
    > ${Cell} {
      width: ${({ $columnsCount }) => `calc(calc(100% - ${firstColumnWidth}) / ${$columnsCount - 1 || 1})`};
      flex-basis: ${({ $columnsCount }) => `calc(calc(100% - ${firstColumnWidth}) / ${$columnsCount - 1 || 1})`};

      &:first-child {
        width: ${firstColumnWidth};
        flex-basis: ${firstColumnWidth};
      }
    }
  }
`;

export const SectionTitle = styled.h2`
  margin: ${themed('spacing.xxl')} 0 0;
  ${themed('typography.h2')};
  font-weight: ${themed('font.weight.bold')};
  text-transform: uppercase;

  ${Table} + & {
    margin-top: ${themed('spacing.xxxl')};
  }
`;

export const SectionSubtitle = styled.h5`
  margin: ${themed('spacing.m')} 0 0;
  color: ${themed('color.greyDark')};
  ${themed('typography.h5')};
  text-transform: uppercase;
  animation-name: ${themed('animation.fadeIn')};
  animation-timing-function: ${themed('transition.timing')};
  animation-duration: ${themed('transition.duration')};
`;

export const SortingIndicator = styled.div`
  width: ${rem(18)};
  height: ${rem(18)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${themed('spacing.m')};
  animation-name: ${themed('animation.zoomOut')};
  animation-timing-function: ${themed('transition.timing')};
  animation-duration: ${themed('transition.duration')};
`;

export const ExpandButton = styled.button<{ $isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${themed('spacing.xl')};
  height: ${themed('spacing.xl')};
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;

  svg {
    transition-property: transform;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};
    transform: rotateZ(${({ $isActive }) => ($isActive ? '180deg' : '0deg')});
  }
`;

export const RevenuePercentage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PercentBar = styled.div<{ $slim?: boolean; $percent: string }>`
  position: relative;
  display: flex;
  width: 100%;
  min-height: ${rem(28)};

  &::before,
  &::after {
    content: '';
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    height: ${({ $slim }) => ($slim ? rem(6) : '100%')};
    width: 100%;
    border-radius: ${themed('borderRadius')};
    background-color: rgba(0, 0, 0, 0.08);
  }

  &::after {
    width: ${({ $percent }) => $percent};
    background-color: ${themed('color.red')};
    transition-property: width;
    will-change: width;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: 0.5s;
    transition-delay: 0.1s;
  }

  span {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%) translateY(${({ $slim }) => ($slim ? rem(-4) : 0)});
    ${({ $slim }) => ($slim ? themed('typography.h4') : themed('typography.h3'))};
    font-weight: ${themed('font.weight.bold')};
    transition-property: color;
    will-change: color;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};

    &:first-of-type {
      left: ${themed('spacing.m')};
      color: ${({ $slim, $percent }) => ($slim || $percent === '0%' ? 'unset' : themed('color.white'))};
    }

    &:last-of-type {
      right: ${themed('spacing.m')};
    }
  }
`;

export const StyledLoader = styled(SpinnerAlt)`
  width: ${rem(30)};
  height: ${rem(30)};
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${rem(120)};
  border: dashed ${rem(2.2)} ${themed('color.greyMid')};
  border-radius: ${themed('borderRadius')};

  ${SectionTitle} + & {
    margin-top: ${themed('spacing.xl')};
  }
`;

export const Loader = () => {
  return (
    <LoaderWrapper>
      <StyledLoader />
    </LoaderWrapper>
  );
};
