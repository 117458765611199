import { type EntityContext, type SidePanelId, SidePanelOpened } from 'domain-events';
import { useCustomersData } from 'providers';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useServices } from 'services';
import { useEventSubscriptions, useIAM } from 'utils';

import { Defect } from '@yourxx/support';

import { AuditTrailPanel } from '../AssortmentFinalization';
import { removedProductsFromEvents } from '../AssortmentFinalization/removedProductsFromEvents';
import { useAuditTrail } from './useAuditTrail';
import type { UseSidePanelReturn } from './useSidePanel';

export const useAuditTrailUI = ({
  context,
  sidePanel,
  assortmentId: customerAssortmentId
}: {
  context: EntityContext | undefined;
  assortmentId: string;
  sidePanel: UseSidePanelReturn<SidePanelId>;
}) => {
  const { auditTrailService, eventBus, productsService, toastService } = useServices();
  const { loadProductsFor, productsFor } = useCustomersData();
  const { canUse } = useIAM();
  const { isLoading, eventsFor } = useAuditTrail({ service: auditTrailService });
  // TODO: Move to some service or such
  const currentUserId = useRef<string>(JSON.parse(localStorage.getItem('user_profile') ?? '{}').userId ?? '');
  const [shouldLoadData, setShouldLoadData] = useState(false);
  const events =
    canUse(`header.viewAudit`) && customerAssortmentId && shouldLoadData ? eventsFor(customerAssortmentId) : null;

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(SidePanelOpened, event => {
          if (event.payload.sidePanelId === 'auditTrail') setShouldLoadData(true);
        })
      ],
      [eventBus]
    )
  );

  const removed = useMemo(() => {
    if (!customerAssortmentId) return [];

    const removedProductsData = productsFor(customerAssortmentId, 'droppedAndRemoved');

    return removedProductsFromEvents(events ?? [], removedProductsData?.products ?? [], async product => {
      if (product.status === 'DROP') return;

      if (!customerAssortmentId)
        throw new Defect('Cannot add product back to assortment: assortment id is not available.');

      if (!context) throw new Defect('Cannot add product back to assortment: context is not available.');

      return productsService
        .add({ context, items: [product] })
        .catch(error => toastService.send(<>{error instanceof Error ? error.message : `${error}`}</>, 'error'))
        .then(() => toastService.send(<>Product successfully added back into the assortment.</>, 'success'));
    });
  }, [context, customerAssortmentId, events, productsFor, productsService, toastService]);

  const onViewingRemovedProducts = useCallback(() => {
    if (!customerAssortmentId) return;
    loadProductsFor(customerAssortmentId, 'droppedAndRemoved');
  }, [customerAssortmentId, loadProductsFor]);

  const onRefresh = useCallback(() => {
    if (!customerAssortmentId) return;
    auditTrailService.clearCache(customerAssortmentId);
    eventsFor(customerAssortmentId);
  }, [auditTrailService, customerAssortmentId, eventsFor]);

  const onClose = useCallback(() => sidePanel.set('auditTrail'), [sidePanel]);

  return (
    <AuditTrailPanel
      currentUserId={currentUserId.current}
      events={events}
      isLoading={isLoading}
      isOpen={sidePanel.id === 'auditTrail'}
      onClose={onClose}
      onViewingRemovedProducts={onViewingRemovedProducts}
      removed={removed}
      onRefresh={onRefresh}
    />
  );
};
