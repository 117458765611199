import { Compare } from 'assets/icons';
import { Canvas, Modal } from 'components';
import type { EntityContext } from 'domain-events';
import { SlotId, Toolbar } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { canvasViewTag, relativePath, useIAM } from 'utils';

import type { Nullable } from '@yourxx/support';
import { type ApiGetProductOut, ProductListData, UIProduct } from '@yourxx/types';

/**
 * TODO: Render in nested route once ProductsPage gets split up.
 */
export const CompareBoardSetup = ({
  context,
  page
}: {
  context: EntityContext | undefined;
  page: Nullable<ApiGetProductOut<UIProduct<ProductListData>>>;
}) => {
  const [str] = useLocalisation();
  const { pathname } = useLocation();
  const { canUse } = useIAM();
  const navigate = useNavigate();
  const params = useParams();
  const { customerId, assortmentId, lineId, productModalType, canvasBoardId } = params;
  const hasRequestedCompareBoard = productModalType === 'canvas';
  const isAllowedToViewCanvas = canUse(`canvas`, 'products');

  const parentPath = useMemo(() => '../'.repeat(canvasBoardId ? 3 : 2), [canvasBoardId]);
  const goBack = useCallback(() => navigate(relativePath(parentPath)), [navigate, parentPath]);
  const onClose = useCallback(() => goBack(), [goBack]);

  useLayoutEffect(() => {
    if (hasRequestedCompareBoard && !isAllowedToViewCanvas) navigate(relativePath(parentPath));
  }, [hasRequestedCompareBoard, isAllowedToViewCanvas, navigate, parentPath]);

  useSlot(
    SlotId.HeaderToolbarRight,
    useMemo(
      () => (
        <Toolbar.Item
          key={pathname}
          icon={<Compare />}
          to={relativePath('./canvas/comparator')}
          onClick={() => {
            canvasViewTag({
              assortmentId,
              // FIXME: This is probably wrong... Need to check with Nupur for this one. I'll leave it as it was for now.
              customerId: customerId ?? lineId,
              source: 'top_nav'
            });
          }}
          hint={{ text: str('Header.compareBoards'), position: 'left', offset: [0] }}
        />
      ),
      [assortmentId, customerId, lineId, pathname, str]
    ),
    1
  );

  useSlot(
    SlotId.Modals,
    useMemo(
      () =>
        hasRequestedCompareBoard && (
          <Modal key={pathname} onClose={onClose} hideCloseButton>
            <Canvas
              params={params}
              onClose={onClose}
              productsContext={context}
              products={page?.products ?? []}
              pageDetails={page?.details}
            />
          </Modal>
        ),
      [context, hasRequestedCompareBoard, onClose, page?.details, page?.products, params, pathname]
    )
  );

  return null;
};
