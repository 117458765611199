import { css, DefaultTheme } from 'styled-components';
import type { BaseObject } from 'styled-components/dist/types';

type Params = { theme: DefaultTheme };

/**
 * Curried helper for accessing styled components theme values
 *
 * Usage:
 *   ```
 *   const Component = styled.div`
 *     font-size: ${themed('font.size.xxl')}
 *   `;
 *   ```
 */
export const themed =
  <P extends string>(path: P) =>
  ({ theme }: Params) =>
    path.split('.').reduce<any>((value, key) => {
      if (typeof value[key] === 'undefined')
        throw new ReferenceError(`DefaultTheme does not have a value at path ${path}`);
      return value[key];
    }, theme);

interface Rem {
  (...px: number[]): (params: Params) => string;
  (...path: string[]): (params: Params) => string;
}

/**
 * Helper for converting px units to rem based on the default font-size from the theme, controlled via
 * the <html> element styling. Additionally, a theme property path can be passed in to use its value
 * as th px input value.
 *
 * Usage:
 *   ```
 *   const Component = styled.div`
 *     font-size: ${themed('font.size.m')};
 *     padding: 0 ${rem(20)};
 *   `;
 *   ```
 */
export const rem: Rem =
  (...pxOrPaths) =>
  params => {
    const defaultFontSize = themed('font.defaultSize')(params);

    return pxOrPaths
      .map(pxOrPath => {
        const value = typeof pxOrPath === 'number' ? pxOrPath : themed(pxOrPath)(params);
        const valueInRem = parseFloat(value) / parseFloat(defaultFontSize);
        return `${Number.isInteger(valueInRem) ? valueInRem : valueInRem.toFixed(2)}rem`;
      })
      .join(' ');
  };

/**
 * Helper for configuring CSS properties at certain breakpoints
 *
 * Usage:
 *   ```
 *   const Component = styled.div`
 *     font-size: ${themed('font.size.l')};
 *
 *     ${below('m', css`
 *       font-size: ${themed('font.size.m')};
 *     `)};
 *   `;
 *   ```
 */
export const below = <T extends BaseObject>(breakpoint: string, properties: ReturnType<typeof css<T>>) => {
  return css`
    @media (max-width: ${themed(`breakpoints.${breakpoint}`)}) {
      ${properties}
    }
  `;
};
