import { useRoute } from 'utils';

import { CreateCanvasBoardResponse } from '@yourxx/types';
import { getBoardItems as apiGetBoardItems } from '@yourxx/ui-utils';

import { useCanvas } from './useCanvas';

export const useGetBoardItems = () => {
  const { params } = useRoute();
  const { canvasBoardId } = params as Record<string, string | undefined>;
  const { setBoardItems } = useCanvas();

  const getBoardItems = async ({
    handleLoading,
    handleSuccess,
    handleError
  }: {
    handleLoading: (loading: boolean) => void;
    handleSuccess: (data?: CreateCanvasBoardResponse[]) => void;
    handleError?: VoidFunction;
  }) => {
    if (canvasBoardId) {
      handleLoading(true);
      apiGetBoardItems({ boardId: canvasBoardId })
        .then(boardItems => {
          const lastIndex = boardItems.length;
          const sortedItems = boardItems.sort((a, b) => (a?.order ?? lastIndex) - (b?.order ?? lastIndex));
          setBoardItems(sortedItems);
          handleSuccess();
        })
        .catch(e => {
          console.error(e);
          handleLoading(false);
          handleError?.();
        });
    }
  };
  return { getBoardItems };
};
