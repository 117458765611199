import { RefObject, useLayoutEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

type Dimensions = ResizeObserverEntry['contentRect'];

export const useDimensions = <T extends HTMLElement>(ref: RefObject<T>) => {
  const [dimensions, setDimensions] = useState<Dimensions>();
  const [observer] = useState(
    () => new ResizeObserver(([entry]: ResizeObserverEntry[]) => setDimensions(entry.contentRect))
  );

  useLayoutEffect(() => {
    if (ref.current) observer.observe(ref.current);
    return () => observer.disconnect();
  }, [observer, ref]);

  return dimensions;
};

export default useDimensions;
