import { Refresh, SpinnerAlt } from 'assets/icons';
import { SlideInOut } from 'components/SlideInOut';
import { Tabs } from 'components/Tabs';
import styled from 'styled-components';
import { rem, themed } from 'utils';

export const StyledSlideInOut = styled(SlideInOut)`
  height: 100%;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${themed('spacing.l')};
  height: 100%;
`;
export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${themed('spacing.l')} 0 ${themed('spacing.m')};

  svg {
    width: ${rem(28)};
    height: ${rem(28)};
  }
`;
export const StyledTabs = styled(Tabs)`
  height: ${rem(25)};

  ul {
    gap: ${themed('spacing.l')};
  }
  li {
    flex: none;

    span {
      font-size: ${themed('font.size.s')};
      padding: 0;
    }
  }
`;
export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(6)};
  flex: 1;
  overflow: auto;
  padding-bottom: ${themed('spacing.l')};
`;
export const Card = styled.div<{
  $isNew?: boolean;
  $display: 'block' | 'flex';
  $hasGroupDate?: boolean;
  $isGreyedOut?: boolean;
}>`
  position: relative;
  display: ${({ $display }) => $display};
  align-items: center;
  gap: ${themed('spacing.m')};
  background-color: ${({ $isGreyedOut }) => ($isGreyedOut ? themed('color.greyLight') : themed('color.white'))};
  border-radius: ${themed('borderRadius')};
  padding: ${rem(14, 10)};
  margin-bottom: ${themed('spacing.m')};
  border-left-style: solid;
  border-left-width: ${({ $isNew }) => rem($isNew ? 3 : 0)};
  border-left-color: ${themed('color.green')};
  margin-top: ${({ $hasGroupDate }) => ($hasGroupDate ? themed('spacing.xl') : 0)};
  will-change: border-left-width;
  transition-property: border-left-width;
  transition-timing-function: linear;
  transition-duration: ${themed('transition.duration')};

  img {
    width: ${rem(60)};
  }
  h4 {
    margin: ${themed('spacing.s')} 0;
    ${themed('typography.h3')};
    font-weight: ${themed('font.weight.semiBold')};
  }
  span {
    ${themed('typography.h4')};
    font-weight: ${themed('font.weight.light')};
  }
  > div {
    display: -webkit-box;
    flex-grow: 1;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  p {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.light')};

    span {
      font-weight: ${themed('font.weight.semiBold')};
    }
  }
  button {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.medium')};
    padding: 0;

    svg {
      width: ${rem(24)};
      height: ${rem(24)};
    }
  }
`;
export const GroupDate = styled.span`
  position: absolute;
  left: 0;
  top: ${rem(-20)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const RefreshButton = styled(Refresh)`
  align-self: center;
  border-radius: ${themed('spacing.s')};
  cursor: pointer;
`;
export const StyledLoader = styled(SpinnerAlt)`
  margin-left: ${themed('spacing.m')};
  width: ${rem(16)};
  height: ${rem(16)};
`;
