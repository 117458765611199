import { createContext } from 'react';

import type { Nullable } from '@yourxx/support';

import type { CustomerOrders, OrderDetails, OrderSizing, OrderSummary } from './types';

interface OrdersContextValue {
  brand: string;
  customerId: string;
  customerName: string;
  loadCustomerOrders(): Promise<CustomerOrders>;
  loadOrderDetails(orderId: string): Promise<OrderDetails>;
  loadOrderSummary(orderId: string): Promise<OrderSummary>;
  loadOrderSizing(orderId: string, pc9: string): Promise<OrderSizing>;
}

export const OrdersContext = createContext<Nullable<OrdersContextValue>>(null);
