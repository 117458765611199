import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { awsRum, getPathWithoutUuids } from '@yourxx/ui-utils';

export const AWSRumSetup = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    awsRum?.recordPageView(getPathWithoutUuids({ path: pathname, params }));
    if (params?.brandName?.toLowerCase() === 'ui') {
      const path = pathname.replace(/(ui|UI)\//, '');
      navigate(path);
    }
  }, [awsRum, params, pathname, navigate]);

  return null;
};
