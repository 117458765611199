import { tagsCallback } from '.';

export enum PDPTags {
  PDPIMAGEVIEW = 'pdp_image_view',
  PDPVIDEOPLAY = 'pdp_video_play',
  PDPVIDEOURL = 'pdp_video_url',
  PDPSAVEVIDEO = 'pdp_save_video',
  PDPIMAGEFULLSCREEN = 'pdp_image_fullscreen',
  PDPIMAGEZOOM = 'pdp_image_zoom',
  PDPPHASINGSTART = 'pdp_phasing_start',
  PDPPHASINGEND = 'pdp_phasing_end',
  PDPARROWINTERACTION = 'pdp_arrow_interaction',
  PDPNOTES = 'pdp_notes',
  PDPSTORY = 'pdp_story',
  PDPNAMECAROUSEL = 'pdp_name_carousel'
}

export const pdpImageViewTag = ({
  imageType,
  viewType,
  productId,
  assortmentId,
  customerId
}: {
  imageType: string;
  viewType: 'pdp' | 'fullscreen';
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPIMAGEVIEW, {
    image_type: imageType,
    view_type: viewType,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpVideoPlayTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPVIDEOPLAY, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpVideoUrlTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPVIDEOURL, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpSaveVideoTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPSAVEVIDEO, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpImageFullscreenTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPIMAGEFULLSCREEN, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpImageZoomTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPIMAGEZOOM, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpPhasingStartTag = ({
  start,
  productId,
  assortmentId,
  customerId
}: {
  start: string;
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPPHASINGSTART, {
    start_value: start,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpPhasingEndTag = ({
  end,
  productId,
  assortmentId,
  customerId
}: {
  end: string;
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPPHASINGEND, {
    end_value: end,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpArrowInteractionTag = ({
  action,
  section,
  productId,
  assortmentId,
  customerId
}: {
  action: 'collapse' | 'expand';
  section: string;
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPARROWINTERACTION, {
    action,
    section,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpNotesTag = ({
  productId,
  assortmentId,
  customerId
}: {
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPNOTES, {
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpStoryTag = ({
  storyName,
  productId,
  assortmentId,
  customerId
}: {
  storyName: string;
  productId: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPSTORY, {
    story_name: storyName,
    product_id: productId,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const pdpNameCarouselTag = ({
  pc9,
  assortmentId,
  customerId
}: {
  pc9: string;
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(PDPTags.PDPNAMECAROUSEL, {
    pc9_id: pc9,
    assortment_id: assortmentId,
    customer_id: customerId
  });
};
