import { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const GenericTextarea = styled.textarea`
  width: 100%;
  font-size: ${themed('font.size.m')};
  padding: ${themed('spacing.m')};
  background-color: ${themed('color.white')};
  color: ${themed('color.black')};
  border-radius: ${themed('spacing.s')};
  border: solid ${rem(1)} transparent;
  appearance: none;
  outline: none;
  box-sizing: border-box;
  will-change: box-shadow, border;
  transition:
    box-shadow 0.2s ease-out,
    border 0.2s ease-out;

  @media (hover: hover) {
    &:hover {
      box-shadow: ${themed('boxShadow')};
    }
  }
  &:focus {
    border: solid ${rem(1)} ${themed('color.black')};
  }
  &:placeholder {
    color: ${themed('color.grey')};
  }
  &:disabled {
    pointer-events: none;
  }
`;

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea = GenericTextarea;
