import { SpinnerAlt } from 'assets/icons';
import { Dialog, FeatureFlag, Label, Select } from 'components';
import { Checkbox } from 'components/Checkbox';
import { Input } from 'components/Input';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import type { Customer } from 'services';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { Defect } from '@yourxx/support';
import { FeatureFlags } from '@yourxx/types';

import type { CopyAssortmentState } from './CopyAssortmentState';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.xl')};
  width: ${rem(600)};
  max-width: 100%;
  box-sizing: border-box;
`;
const Description = styled.p`
  margin: 0;
  ${themed('typography.h4')};
  color: ${themed('color.grey')};
`;
const SectionTitle = styled.h3`
  margin: 0 0 ${rem(12)};
  color: ${themed('color.grey')};
  ${themed('typography.h3')};
  font-weight: ${themed('font.weight.bold')};
`;
const StyledCheckbox = styled(Checkbox)`
  display: flex;
  gap: ${themed('spacing.m')};
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  min-width: 0;
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.xl')};
`;
const StyledInput = styled(Input)`
  height: ${rem(38)};
  margin: 0;
`;

export const StyledLoader = styled(SpinnerAlt)`
  margin-left: ${themed('spacing.m')};
  width: ${rem(24)};
  height: ${rem(24)};
`;

interface LabeledCustomer {
  original: Customer;
  label: Customer['customerName'];
}

export const CopyAssortmentModal = ({
  isSubmitting,
  onClose,
  onConfirm,
  onChange,
  customers,
  state
}: {
  isSubmitting?: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  onChange: (state: CopyAssortmentState) => void;
  customers: readonly Customer[];
  state: CopyAssortmentState;
}) => {
  const [str] = useLocalisation();

  const labeledCustomers = useMemo(
    () =>
      customers
        .filter(c => !c.customerName.toLowerCase().includes('(archived)'))
        .map<LabeledCustomer>(customer => ({
          original: customer,
          get label() {
            return customer.customerName;
          }
        })),
    [customers]
  );

  return (
    <FeatureFlag flags={[FeatureFlags.Assortment_Copy]}>
      <Dialog
        title={str('Assortment.Copy.Modal.title')}
        onClose={onClose}
        content={
          <Content>
            <Description>{str('Assortment.Copy.Modal.description')}</Description>
            <div>
              <SectionTitle>{str('general.details')}</SectionTitle>
              <Fields>
                <Label text={str('Assortment.Copy.Modal.assortmentNameLabel')} size="h3">
                  <StyledInput
                    placeholder={state.inheritedAssortmentName}
                    value={state.assortmentName}
                    onChange={event => onChange(state.changeName(event.currentTarget.value))}
                  />
                </Label>
                <Label text={str('Assortment.Copy.Modal.targetCustomersLabel')} size="h3">
                  <Select
                    isMulti
                    isDisabled={isSubmitting}
                    placeholder={str('Assortment.Copy.Modal.targetCustomersPlaceholder')}
                    options={labeledCustomers}
                    onChange={(_labeledCustomers, meta) => {
                      switch (meta.action) {
                        case 'select-option':
                          return onChange(state.toggleCustomer(asLabeledCustomer(meta.option).original));
                        case 'deselect-option':
                        case 'pop-value':
                        case 'remove-value':
                          return onChange(state.toggleCustomer(asLabeledCustomer(meta.removedValue).original));
                        case 'clear':
                          return onChange(
                            state.toggleCustomer(
                              ...meta.removedValues.map(option => asLabeledCustomer(option).original)
                            )
                          );
                      }
                    }}
                  />
                </Label>
              </Fields>
            </div>
            <div>
              <SectionTitle>{str('general.settings')}</SectionTitle>
              <Fields>
                <Label ignoreInteractions text={str('Assortment.Copy.Modal.copyOptionsLabel')} size="h3">
                  {state.copyOptions.map(option => (
                    <StyledCheckbox
                      key={option.id}
                      id={option.id}
                      label={option.name}
                      checked={option.isSelected}
                      disabled={option.isDisabled}
                      onChange={_isChecked => onChange(state.toggleOption(option.id))}
                    />
                  ))}
                </Label>
              </Fields>
            </div>
          </Content>
        }
        cancel={{ label: str('general.cancel'), disabled: isSubmitting, handler: onClose }}
        confirm={{
          label: isSubmitting ? <StyledLoader /> : str('Assortment.Copy.Modal.submitLabel'),
          disabled: isSubmitting || !state.isValid,
          handler: onConfirm
        }}
      />
    </FeatureFlag>
  );
};

// Makes TypeScript and ReactSelect work together nicely...
const asLabeledCustomer = (value: unknown): LabeledCustomer => {
  if (!value || typeof value !== 'object' || !('original' in value))
    throw new Defect(`Expected LabeledCustomer type object, instead got ${JSON.stringify(value)}`);

  return value as LabeledCustomer;
};
