import { ShowAsyncErrorAndGoBack, ViewTransition } from 'pages';
import { customerRoute } from 'pages/Customer/customer-route-mapping';
import { Assortments } from 'pages/Landing/Assortments';
import { Suspense } from 'react';
import { Await } from 'react-router-dom';

import { once } from '@yourxx/support';

const mockedLoadAssortments = once(() => Promise.resolve());
export const AssortmentsIndex = ({
  type,
  season,
  customerId
}: {
  type: keyof typeof customerRoute;
  season: string | undefined;
  customerId: string;
}) => {
  return (
    // TODO: Skeleton
    <Suspense fallback={<ViewTransition children={<></>} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={mockedLoadAssortments()}
        children={data => (
          <ViewTransition
            children={<Assortments customerId={customerId} season={season} currentView={type} customerData={data} />}
          />
        )}
      />
    </Suspense>
  );
};
