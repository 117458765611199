import styled from 'styled-components';
import { themed } from 'utils';

const CardWrapper = styled.div<{
  selected: boolean;
  $showPointer: boolean;
}>`
  border-radius: ${themed('spacing.l')};
  background-color: ${themed('color.white')};
  border: solid;
  overflow: hidden;
  border-color: ${({ selected }) => (selected ? themed('color.red') : themed('color.greyLight'))};
  cursor: ${({ $showPointer }) => ($showPointer ? 'pointer' : 'default')};

  @media (hover: hover) {
    &:hover {
      border-color: ${themed('color.greyDark')};
    }
  }
`;

export const Card = ({
  children,
  isSelected = false,
  showPointer = false,
  onClick,
  className
}: {
  children?: React.ReactNode;
  isSelected?: boolean;
  showPointer?: boolean;
  onClick?: VoidFunction;
  className?: string;
}) => (
  <CardWrapper className={className} selected={isSelected} $showPointer={showPointer} onClick={onClick}>
    {children || null}
  </CardWrapper>
);
