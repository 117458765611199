import { Close as CloseIcon, Search as SearchIcon } from 'assets/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import { prefix, useDebouncedFn } from 'utils';

import { useMachine } from '@xstate/react';

import type { SearchSuggestion } from '../ContextfulSearchProvider';
import { RootStyles } from './RootStyles';
import { Action, createSearchMachine, VisibilityState } from './state-machine';

export interface Props {
  className?: string;
  placeholder?: string;
  searchTerm?: string;
  onChange?: (searchTerm: string) => void;
  suggestions?: readonly SearchSuggestion[];
  debounceDelayInMs?: number;
  disableCollapsing?: boolean;
  disableAutoInputCapture?: boolean;
}

export const HeaderSearch = ({
  className,
  placeholder,
  searchTerm = '',
  onChange: onChangeProp,
  suggestions,
  debounceDelayInMs,
  disableCollapsing,
  disableAutoInputCapture
}: Props) => {
  const onChange = useCallback<Required<Props>['onChange']>((...args) => onChangeProp?.(...args), [onChangeProp]);
  const debouncedOnChange = useDebouncedFn(onChange, debounceDelayInMs);

  const stateMachine = useRef(createSearchMachine({ disableCollapsing }));
  const [state, send] = useMachine(stateMachine.current, {
    actions: {
      onChange: context => {
        debouncedOnChange?.(context.searchTerm.trim());
      }
    }
  });
  const { searchTerm: value } = state.context;
  const searchTermRef = useRef(value);

  useEffect(() => {
    searchTermRef.current = value;
  }, [value]);

  const visibility = (state.value as { visibility: VisibilityState }).visibility;
  const isExpanded = visibility === VisibilityState.Expanded;
  const isExpanding = visibility === VisibilityState.Expanding;
  const isCollapsing = visibility === VisibilityState.Collapsing;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [isFocused, setIsFocused] = useState(false);
  const focusTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    const onFocus = () => {
      clearTimeout(focusTimeoutRef.current);
      setIsFocused(true);
    };
    const onBlur = () => {
      clearTimeout(focusTimeoutRef.current);
      // This allows other elements to receive events (e.g. clicking on suggestions);
      focusTimeoutRef.current = setTimeout(() => setIsFocused(false), 250);
    };

    const input = inputRef.current;
    input?.addEventListener('focus', onFocus);
    input?.addEventListener('blur', onBlur);

    return () => {
      input?.removeEventListener('focus', onFocus);
      input?.removeEventListener('blur', onBlur);
    };
  });

  useEffect(() => {
    if (searchTermRef.current.trim() !== searchTerm) {
      send({ type: Action.Input, value: searchTerm });
    }
  }, [searchTerm, send]);

  useEffect(() => {
    if (isExpanding) inputRef.current?.focus();
  }, [isExpanding]);

  useEffect(() => {
    if (isCollapsing && inputRef.current && inputRef.current === document.activeElement) inputRef.current?.blur();
  }, [isCollapsing]);

  const selectedSuggestionEl = useRef<HTMLDivElement | null>(null);
  const [selectedSuggestion, setSelectedSuggestion] = useState<SearchSuggestion>();
  useEffect(() => {
    if (!suggestions?.find(s => s === selectedSuggestion)) {
      setSelectedSuggestion(undefined);
      selectedSuggestionEl.current = null;
    }
  }, [selectedSuggestion, suggestions]);
  useEffect(() => {
    selectedSuggestionEl.current?.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
      behavior: 'smooth'
    });
  }, [selectedSuggestion]);

  useEffect(() => {
    const captureInputWhenNothingElseHasFocus = (event: KeyboardEvent) => {
      if (document.activeElement !== document.body) return;
      if (searchTermRef.current) return;
      if (event.altKey || event.ctrlKey || event.metaKey) return;

      event.preventDefault();

      if (visibility === VisibilityState.Collapsed) send({ type: Action.Toggle });
      send({ type: Action.Input, value: event.key });
      inputRef.current?.focus();
    };

    const clearAndCloseOnEscapePressed = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && inputRef.current === document.activeElement) {
        send({ type: Action.Clear });
        send({ type: Action.Toggle });
      }
    };

    const blurOnEnterPressed = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && inputRef.current === document.activeElement) {
        inputRef.current?.blur();
        if (selectedSuggestion) selectedSuggestion.onSelect();
      }
    };

    const selectSuggestionWithArrowKeys = (event: KeyboardEvent) => {
      if (inputRef.current !== document.activeElement) return;
      const direction = event.key === 'ArrowDown' ? 1 : event.key === 'ArrowUp' ? -1 : 0;

      if (!direction) return;
      event.preventDefault();
      event.stopPropagation();

      setSelectedSuggestion(current => {
        if (!suggestions?.length) return;
        if (!current && direction > 0) return suggestions[0];
        const currentIndex = suggestions.findIndex(s => s === current);
        if (currentIndex < 0) return;
        return suggestions[(currentIndex + direction) % suggestions.length];
      });
    };

    document.addEventListener('keyup', clearAndCloseOnEscapePressed);
    document.addEventListener('keyup', blurOnEnterPressed);
    document.addEventListener('keyup', selectSuggestionWithArrowKeys);
    if (!disableAutoInputCapture) document.addEventListener('keypress', captureInputWhenNothingElseHasFocus);

    return () => {
      document.removeEventListener('keyup', clearAndCloseOnEscapePressed);
      document.removeEventListener('keyup', blurOnEnterPressed);
      document.removeEventListener('keyup', selectSuggestionWithArrowKeys);
      if (!disableAutoInputCapture) document.removeEventListener('keypress', captureInputWhenNothingElseHasFocus);
    };
  }, [disableAutoInputCapture, selectedSuggestion, send, suggestions, visibility]);

  const canClear = state.can({ type: Action.Clear });

  const clear = () => {
    send({ type: Action.Clear });
    // Refocusing provides a better UX
    inputRef.current?.focus();
  };

  return (
    <RootStyles
      className={className}
      data-testid={testIds.root}
      data-is-collapsed={!isExpanded}
      data-state={visibility}
    >
      <div className="input-wrapper">
        <span
          role="button"
          className="clickable clear"
          data-testid={testIds.clear}
          data-can-clear={canClear}
          onClick={clear}
        >
          <CloseIcon />
        </span>
        <input
          ref={inputRef}
          placeholder={placeholder}
          aria-placeholder={placeholder}
          data-testid={testIds.input}
          value={value}
          onChange={event => send({ type: Action.Input, value: event.currentTarget.value })}
        />
      </div>
      <span
        className="trigger-icon clickable"
        data-testid={testIds.trigger}
        onClick={() => send({ type: Action.Toggle })}
        title={placeholder}
      >
        <SearchIcon />
      </span>
      {isExpanded && isFocused && suggestions?.length && (
        <div className="suggestions-list" data-testid={testIds.suggestionsList}>
          {suggestions.map((suggestion, index) => {
            const { label, renderedLabel, onSelect } = suggestion;
            const isSelected = suggestion === selectedSuggestion;
            return (
              <div
                ref={el => {
                  if (isSelected) selectedSuggestionEl.current = el;
                }}
                key={`${label}-${index}`}
                className="suggestions-item"
                title={label}
                onClick={onSelect}
                data-selected={isSelected}
              >
                <span>{renderedLabel ?? label}</span>
              </div>
            );
          })}
        </div>
      )}
    </RootStyles>
  );
};

const rootPrefix = prefix('header-search');

export const testIds = {
  root: rootPrefix('root'),
  input: rootPrefix('input'),
  trigger: rootPrefix('trigger'),
  clear: rootPrefix('clear'),
  suggestionsList: rootPrefix('suggestions-list')
} as const;
