import { normalize } from 'path';

const replaceRegex = /(\/ui(\/)?|\/v1(\/)?|^(\/))/gm;
const v2RegexParts = [
  '(\\/(season|h\\d{3}))',
  '(\\/(archived-assortments.*))',
  '(\\/(final-assortments.*))',
  '(\\/(assortments))',
  '(\\/(orders.*))'
];
const v2Paths = new RegExp(v2RegexParts.join('|'), 'gmi');

export const getAdminUrl = (href: string) => {
  const url = new URL(href);
  url.searchParams.set('from', url.pathname);
  url.pathname = `/admin/lsco-users`;
  return asV1Url(url).href;
};

export const getV1Url = (href: string) => {
  const url = new URL(href);
  return asV1Url(url).href;
};

export const asV1Url = (url: URL) => {
  if (url.hostname === 'localhost') {
    url.port = '3000';
  }
  url.pathname = url.pathname.replace(v2Paths, '');
  url.pathname = normalize(url.pathname.replace(replaceRegex, '/v1/'));
  if (url.pathname.includes('/final/')) {
    url.pathname = url.pathname.replace('/final/', '/products/');
  }
  return url;
};

export const asV2Url = (url: URL) => {
  if (url.hostname === 'localhost') {
    url.port = '5173';
  }
  if (url.pathname.includes('/stories')) {
    url.pathname = url.pathname.replace(new RegExp('/stories.*'), '/stories/grid');
  }
  url.pathname = normalize(url.pathname.replace(new RegExp(replaceRegex), '/'));
  return url;
};
