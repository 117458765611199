import {
  ContentWithSidePanel,
  LoadingSpinner,
  ResourceLoadedEmpty,
  SortDetails,
  SortDirection,
  SortPopOver,
  useContextfulSearch
} from 'components';
import { useCustomersData, useLocalisation } from 'providers';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GridComponents, VirtuosoGrid } from 'react-virtuoso';
import { routes } from 'routes';
import styled from 'styled-components';
import { landingShowSortTag, landingSortChangeTag, multiSort, rem, themed } from 'utils';

import { searchCustomers } from '@yourxx/support';
import { type GetCustomerListResponse } from '@yourxx/types';

import { useSidePanel } from '../../Products';
import { useCustomersFilters } from '../CustomersFilters';
import { CustomerCard } from './CustomerCard';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-grow: 1;

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-left: -${themed('spacing.xl')};
    margin-right: -${themed('spacing.xl')};
    background-color: ${themed('color.offWhite')};
  }

  .virtualGrid {
    height: auto !important;
    flex: 1;
  }
`;
const CardsGrid = styled.div.withConfig({
  shouldForwardProp: prop => prop !== 'context'
})`
  display: grid;
  gap: ${themed('spacing.l')};
  grid-template-columns: repeat(4, 1fr);
  margin-top: ${themed('spacing.l')};
  padding: 0 ${themed('spacing.xxxl')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    margin-top: 0;
    padding-left: ${themed('spacing.xl')};
    padding-right: ${themed('spacing.xl')};
  }

  @media (max-width: 1080px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.m')};
  padding: ${themed('spacing.l')} ${themed('spacing.xxxl')} ${themed('spacing.m')};

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding: 0 ${themed('spacing.xl')} ${themed('spacing.l')};
  }

  p {
    margin: 0;
    ${themed('typography.h4')};
    text-transform: uppercase;
  }

  > div {
    display: flex;
    gap: ${rem(10)};
  }
`;

const CustomersCardsGridComponents: GridComponents = {
  List: forwardRef(({ children, ...props }, ref) => (
    <CardsGrid ref={ref} {...props}>
      {children}
    </CardsGrid>
  ))
};

// TODO: Get data from props
export const Customers = () => {
  const [str] = useLocalisation();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { customers, isLoading } = useCustomersData(); // error, retry

  const defaultSort: SortDetails<GetCustomerListResponse> = { by: 'customerName', dir: SortDirection.ASC };
  const [currentSort, setCurrentSort] = useState<SortDetails<GetCustomerListResponse>>(defaultSort);

  const {
    searchTerm: customersSearchTerm,
    changeTerm: changeCustomersTerm,
    addSuggestions: addCustomersSearchSuggestions
  } = useContextfulSearch({ contextId: routes.customers.toString() });

  const sortedCustomers = useMemo(() => {
    if (!currentSort || !customers) return customers;
    return multiSort(customers, { by: [currentSort.by], dir: currentSort.dir ?? SortDirection.ASC });
  }, [currentSort, customers]);

  const sidePanel = useSidePanel<'filters'>();
  const { ui: filtersUI, filterCustomersFn } = useCustomersFilters({ customers: sortedCustomers, sidePanel });
  const filteredCustomers = useMemo(
    () => filterCustomersFn(sortedCustomers ?? []),
    [filterCustomersFn, sortedCustomers]
  );

  const customersSearchResults = useMemo(() => {
    if (!customersSearchTerm || !filteredCustomers) return [];
    return searchCustomers(customersSearchTerm, filteredCustomers);
  }, [customersSearchTerm, filteredCustomers]);

  useEffect(() => {
    if (!customersSearchResults.length) return;

    const suggestions = customersSearchResults.slice(0, 10).map(result => {
      const firstMatchValue = result.matches[0].value;
      return {
        label: firstMatchValue,
        onSelect: () => {
          changeCustomersTerm(firstMatchValue);
          navigate(result.item.customerId);
        }
      };
    });

    addCustomersSearchSuggestions(suggestions);
  }, [addCustomersSearchSuggestions, navigate, customersSearchResults, changeCustomersTerm]);

  // Raw > Sorted > Filtered > Searched
  const customersRendered = useMemo(() => {
    if (!customersSearchTerm) return filteredCustomers ?? [];
    return customersSearchResults?.map(({ item }) => item) ?? [];
  }, [customersSearchTerm, customersSearchResults, filteredCustomers]);

  if (isLoading) {
    return (
      <Wrapper>
        <LoadingSpinner label={str('Landing.customers.loading')} />
      </Wrapper>
    );
  }
  // TODO: Error for loading customers
  // if (errorMessage) {
  //   return (
  //     <Wrapper>
  //       <ResourceLoadingFailed error={errorMessage} onRetry={retry} retryLabel={str('general.retry')} />
  //     </Wrapper>
  //   );
  // }
  if (!sortedCustomers?.length) {
    return (
      <Wrapper>
        <ResourceLoadedEmpty message={str('Landing.customers.noneFound')} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Toolbar>
        <div>
          {!!sortedCustomers?.length && (
            <p>
              {customersRendered?.length !== sortedCustomers?.length
                ? str('Landing.customers.totalFiltered', {
                    count: customersRendered.length,
                    total: sortedCustomers.length
                  })
                : str('Landing.customers.total', { count: customersRendered.length })}
            </p>
          )}
        </div>
        <div>
          <SortPopOver
            currentSort={currentSort}
            onSort={sortObject => {
              if (sortObject.by) {
                landingSortChangeTag({
                  type: sortObject.by,
                  direction: sortObject.dir === SortDirection.ASC ? 'asc' : 'desc',
                  customerId
                });
              }
              setCurrentSort(sortObject ? { by: sortObject.by, dir: sortObject.dir } : defaultSort);
            }}
            options={[{ key: 'customerName', label: str('ProductListing.sort.by.name') }]}
            onOpen={() => landingShowSortTag({ customerId })}
            showLabel
          />
          {filtersUI.button}
        </div>
      </Toolbar>
      <ContentWithSidePanel hasOpenSidePanel={Boolean(sidePanel.id)} sidePanel={<>{filtersUI.panel}</>}>
        {!customersRendered?.length && <ResourceLoadedEmpty message={str('Landing.customers.noFilterResult')} />}
        {!!customersRendered?.length && !customerId && (
          <VirtuosoGrid
            data={customersRendered}
            className="virtualGrid"
            components={CustomersCardsGridComponents}
            itemContent={(_, data) => <CustomerCard key={data.customerId} data={data} />}
          />
        )}
      </ContentWithSidePanel>
    </Wrapper>
  );
};
