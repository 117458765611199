import styled from 'styled-components';
import { rem, themed } from 'utils';

const ContentAndSidePanelWrapper = styled.div<{ $areSidePanelsOpen: boolean }>`
  display: flex;
  flex-grow: 1;

  > div {
    width: unset;
    will-change: max-width, width;
    transition-property: max-width, width;
    transition-duration: ${themed('transition.duration')};
    transition-timing-function: ${themed('transition.timing')};
  }

  > div:nth-child(1) {
    position: relative;
    flex-grow: 1;
    will-change: width;
    transition-property: width;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};
  }

  > div:nth-child(2) {
    position: relative;
    width: ${({ $areSidePanelsOpen, theme }) => rem($areSidePanelsOpen ? 350 : 0)({ theme })};
  }
`;

export const ContentWithSidePanel = ({
  className,
  children,
  sidePanel,
  hasOpenSidePanel
}: {
  className?: string;
  children: React.ReactNode;
  sidePanel: React.ReactNode;
  hasOpenSidePanel: boolean;
}) => {
  return (
    <ContentAndSidePanelWrapper className={className} $areSidePanelsOpen={hasOpenSidePanel}>
      {children}
      <div>{sidePanel}</div>
    </ContentAndSidePanelWrapper>
  );
};
