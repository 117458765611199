import { CanvasBoardDetails, CreateCanvasBoardResponse } from '@yourxx/types';
import { getBoards as apiGetBoards } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export const useGetBoards = () => {
  const { lineId, assortmentId } = useCurrentCanvasContext();
  const { setBoards } = useCanvas();

  const getBoards = async ({
    handleLoading,
    handleSuccess,
    handleError
  }: {
    handleLoading: (loading: boolean) => void;
    handleSuccess: (data?: CreateCanvasBoardResponse[]) => void;
    handleError?: VoidFunction;
  }) => {
    if (lineId || assortmentId) {
      handleLoading(true);
      apiGetBoards({ lineId, assortmentId })
        .then((boards: CanvasBoardDetails[]) => {
          setBoards(boards);
          handleSuccess();
        })
        .catch(e => {
          console.error(e);
          handleLoading(false);
          handleError?.();
        });
    }
  };

  return { getBoards };
};
