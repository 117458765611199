import styled from 'styled-components';

import { useQuery } from '@tanstack/react-query';
import { logout } from '@yourxx/ui-utils';

import { FlexWrapper, LoadingSpinner, Wrapper } from '../../components';
import { useLocalisation } from '../../providers';

const ContentSection = styled.div`
  display: flex;
  align-items: center;
  flex: 10 0;
`;

export const Logout = () => {
  const [str] = useLocalisation();

  const { isLoading } = useQuery({
    queryKey: ['getTokenFromCodeGrant'],
    queryFn: async () => {
      return logout({ logout_reason: str('Logout.userInitiated') });
    }
  });

  return (
    <Wrapper>
      <ContentSection>
        <FlexWrapper>
          <div className="column is-half-tablet is-third-desktop">
            <div className="section content">
              {isLoading && <LoadingSpinner size={40} label={'Please wait while we log you out'} />}
            </div>
          </div>
        </FlexWrapper>
      </ContentSection>
    </Wrapper>
  );
};
