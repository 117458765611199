import { Entity, type EntityContext } from 'domain-events';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { StoriesService, Story } from 'services';
import { findById } from 'utils';

import { equals } from '@yourxx/support/src/product-filtering/equals';

export enum State {
  Idle = 'Idle',
  RequestedAdding = 'RequestedAdding',
  Adding = 'Adding'
}

export type UseAddProductsToStoryProps = {
  service: StoriesService;
  stories: readonly Story[];
  context: EntityContext | undefined;
  products: readonly { pId: string; pc9: string }[];
};

export type UseAddProductsToStoryReturn =
  | { state: State.Idle; requestAdd: VoidFunction }
  | {
      state: State.RequestedAdding;
      cancel: VoidFunction;
      availableStories: readonly Story[];
      selectedStory: Story;
      selectStory(story: Story): void;
      canConfirm: boolean;
      confirm: VoidFunction;
    }
  | {
      state: State.Adding;
      availableStories: readonly Story[];
      selectedStory: Story;
    };

export const useAddProductsToStory = ({
  service,
  stories,
  context,
  products
}: UseAddProductsToStoryProps): UseAddProductsToStoryReturn => {
  const [state, setState] = useState(State.Idle);
  const availableStories = useMemo(() => {
    switch (context?.entity) {
      case Entity.Assortment:
      case Entity.FinalAssortment:
      case Entity.Line:
        return service.filterStoriesForContext(stories, context.entity === Entity.Line ? 'line' : 'assortment');
      default:
        return [];
    }
  }, [context, service, stories]);
  const [selectedStory, setSelectedStory] = useState(availableStories[0]);

  useEffect(() => {
    const matched = !!selectedStory && findById(selectedStory.id)(availableStories);
    if (!selectedStory || !matched || !equals(selectedStory, matched)) setSelectedStory(availableStories[0]);
  }, [selectedStory, availableStories]);

  const requestAdd = useCallback(() => setState(State.RequestedAdding), []);
  const cancel = useCallback(async () => setState(State.Idle), []);
  const confirm = useCallback(() => {
    if (
      !context ||
      (context.entity !== Entity.Assortment &&
        context.entity !== Entity.FinalAssortment &&
        context.entity !== Entity.Line)
    )
      return;

    setState(State.Adding);
    service
      .addProducts({
        context,
        story: selectedStory,
        products
      })
      .then(() => setState(State.Idle))
      .catch(() => setState(State.RequestedAdding));
  }, [context, products, selectedStory, service]);

  if (state === State.RequestedAdding)
    return {
      state,
      cancel,
      availableStories,
      selectedStory,
      canConfirm: !!availableStories.length,
      selectStory: setSelectedStory,
      confirm
    };

  if (state === State.Adding)
    return {
      state,
      selectedStory,
      availableStories
    };

  return {
    state,
    requestAdd
  };
};
