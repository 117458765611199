export const optionsSorter = <T extends { value: V }, V>(
  order: readonly unknown[],
  options: readonly T[]
): readonly T[] => {
  const indexed = order.reduce<Map<unknown, number>>((accum, v, i) => {
    accum.set(v, i);
    return accum;
  }, new Map());

  return [...options].sort((a, b) => (indexed.get(a.value) ?? 0) - (indexed.get(b.value) ?? 0));
};
