import { Card } from 'components';
import { useLocalisation } from 'providers';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { getSeasonH } from '@yourxx/support';
import { FeatureFlags, GetCustomerListResponse } from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  border: 0;
  border-radius: ${themed('spacing.l')};
  height: 100%;

  a {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: ${themed('spacing.m')};
    min-height: ${rem(160)};
    height: 100%;
    padding: ${themed('spacing.l')};
    color: ${themed('color.black')};
    text-decoration: none;
  }
  h2 {
    margin: 0;
    ${themed('typography.h2')};
    line-height: ${rem(22)}; // Overriding until the Design team can provide it for all typography headings
    font-weight: ${themed('font.weight.bold')};
    text-transform: uppercase;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    ${themed('typography.h5')};

    span {
      margin-right: ${themed('spacing.l')};
      ${themed('typography.h5')};
      line-height: 1.3;

      &:last-of-type {
        margin-right: 0;
      }
    }
    p {
      margin: 0;
    }
  }
`;

export const CustomerCard = ({ data }: { data: GetCustomerListResponse }) => {
  const [str] = useLocalisation();

  return (
    <StyledCard showPointer>
      <Link to={data.customerId}>
        <h2>{data.customerName}</h2>
        <div>
          {data.assortments?.length ? (
            data.assortments.map(a => {
              const activeCount = a.active ? `${a.active} ${str('Landing.customers.activeLabel')} ` : '';
              const archivedCount =
                canUse(FeatureFlags.Assortment_ViewArchived) && a.archived
                  ? `${a.archived} ${str('Landing.customers.archivedLabel')} `
                  : '';
              const finalCount = a.final ? `${a.final} ${str('Landing.customers.finalLabel')} ` : '';
              return (
                <span key={a.season}>
                  {str('Landing.customers.assortmentCount', {
                    season: getSeasonH(a.season) || '',
                    count: `${activeCount}${archivedCount}${finalCount}`
                  })}
                </span>
              );
            })
          ) : (
            <p>{str('Landing.customers.noAssortments')}</p>
          )}
        </div>
      </Link>
    </StyledCard>
  );
};
