import { useLocalisation } from 'providers';
import styled from 'styled-components';
import { themed } from 'utils';

import { ProductDetailsData, UIProduct } from '@yourxx/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
  margin-top: ${themed('spacing.l')};

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${themed('color.white')};
    border-radius: ${themed('spacing.m')};
    padding: ${themed('spacing.l')} ${themed('spacing.m')};

    p {
      margin: 0;
      font-weight: ${themed('font.weight.semiBold')};
    }
    span {
      font-weight: ${themed('font.weight.light')};
      color: ${themed('color.greyDark')};
    }
  }
`;

export const OrderCaptureDetails = ({ product }: { product: UIProduct<ProductDetailsData> }) => {
  const [str] = useLocalisation();
  // TODO: remove log once data is available
  console.log(product);

  return (
    <Container>
      <div>
        <p>{str('AssortmentFinalization.pdp.customTrafficCode')}</p>
        <span>123123</span>
      </div>
      <div>
        <p>{str('AssortmentFinalization.pdp.countryOfOrigin')}</p>
        <span>Turkey</span>
      </div>
      <div>
        <p>{str('AssortmentFinalization.pdp.sustainabilityStory')}</p>
        <span>MMC</span>
      </div>
    </Container>
  );
};
