import { AnimatePresence, motion, type Variants } from 'framer-motion';
import { SlotId } from 'pages';
import { Slot } from 'providers';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

const Container = styled(motion.div)`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.xxl')};
  width: 100%;
  padding: ${themed('spacing.l')} ${themed('spacing.xxl')};
  background-color: ${themed('color.white')};

  &:empty {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${rem(1)};
    background-color: #d9d9d9; // FIXME: Not in colour palette
  }

  > div {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 50%;
  }
`;

const variants: Variants = {
  hidden: { y: '100%', opacity: 0.7 },
  visible: { y: 0, opacity: 1 }
};

const ContainerLeft = styled.div`
  color: ${themed('color.grey')};
  ${themed('typography.h3')};
  text-transform: uppercase;
  font-weight: ${themed('font.weight.regular')};
`;

const ContainerRight = styled.div`
  margin-left: auto;
  justify-content: flex-end;
`;

export const Footer = () => {
  return (
    <AnimatePresence mode="wait">
      <Container initial="hidden" animate="visible" exit="hidden" variants={variants}>
        <Slot id={SlotId.FooterLeft} Container={ContainerLeft} dontRenderEmpty />
        <Slot id={SlotId.FooterRight} Container={ContainerRight} dontRenderEmpty />
      </Container>
    </AnimatePresence>
  );
};
