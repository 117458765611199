import type { AnyFilter, Nullable } from '@yourxx/support';

import { combinationEquals, FilterCombination, type FilterCombinationId, filtersEqual } from './FilterCombination';
import { setFilterInCombination } from './setFilterInCombination';

export interface DefaultFilterCombination extends FilterCombination {
  filtersCount: number;
  containsOnlyDefaultFilters: boolean;
  resetToDefaultFilters(): this;
  // The FilterCombination this is based on (derived from)
  base: Nullable<FilterCombination>;
  // Set a FilterCombination as base. Tells the state when it deviates from its base
  baseOn(other: FilterCombination): this;
  removeBase(): this;
  canClear(filterId: string): boolean;
  clear(filterId: string): this;
}

export const DefaultFilterCombination = (
  id: string,
  filtersOrFactory: AnyFilter[] | (() => AnyFilter[]) = [],
  base?: FilterCombination
): DefaultFilterCombination => {
  const getDefaults = () => (typeof filtersOrFactory === 'function' ? filtersOrFactory() : [...filtersOrFactory]);

  const dfc: DefaultFilterCombination = {
    id: id as FilterCombinationId,
    name: 'Default combination',
    filters: getDefaults(),

    get filtersCount() {
      let count = 0;

      const defaults = getDefaults();
      for (const filter of dfc.filters) {
        const defaultFilterMatch = defaults.find(df => df.id === filter.id);
        if (defaultFilterMatch && defaultFilterMatch.equals(filter)) continue;
        count++;
      }

      return count;
    },

    applyFilters(newFilters) {
      const updated = newFilters.reduce((final, filter) => setFilterInCombination(final, filter), dfc);
      // `updated` is a new instance, but for DefaultFilterCombination we want the same instance, always.
      dfc.filters = updated.filters;
      return dfc.removeBase();
    },

    resetToDefaultFilters() {
      dfc.filters = getDefaults();
      return dfc.removeBase();
    },

    get containsOnlyDefaultFilters() {
      return filtersEqual(dfc.filters, getDefaults());
    },

    canClear(filterId) {
      const defaultFilterMatch = getDefaults().find(filter => filter.id === filterId);
      const currentFilterMatch = dfc.filters.find(filter => filter.id === filterId);

      if (defaultFilterMatch) {
        return !currentFilterMatch || !defaultFilterMatch.equals(currentFilterMatch);
      } else {
        return !!currentFilterMatch;
      }
    },

    clear(filterId) {
      const defaultFilterMatch = getDefaults().find(filter => filter.id === filterId);

      if (defaultFilterMatch && !dfc.filters.find(filter => filter.id === filterId)) {
        dfc.filters = [...dfc.filters, defaultFilterMatch];
      }

      dfc.filters = dfc.filters.reduce<AnyFilter[]>((filters, filter) => {
        // Restore default filter if this is the one being cleared
        if (defaultFilterMatch?.id === filter.id) filters.push(defaultFilterMatch);
        // Or, Remove if it's not a default filter
        else if (filter.id !== filterId) filters.push(filter);
        return filters;
      }, []);

      return dfc.removeBase();
    },

    base: base ?? null,
    baseOn(other) {
      dfc.base = other;
      dfc.filters = [...other.filters];
      return dfc;
    },
    removeBase() {
      dfc.base = null;
      return dfc;
    },

    equals(other) {
      return combinationEquals(dfc, other);
    },
    filtersEqual(other) {
      return filtersEqual(dfc.filters, other.filters);
    }
  };

  return dfc;
};
