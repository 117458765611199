const SEASON_ISO_DATE_REGEX = /^(?<year>20[0-9]{2})-(?<month>0[16])-01/;
const SEASON_NAME_REGEX = /^H(?<half>[12])(\s|\+)*(?<year>[0-9]{2})$/;
const SEASON_CODE_REGEX = /^(?<year>[0-9]{2})\s*(?<month>[1|2|3])$/;
const SEASON_FILENAME_REGEX = /^(?<year>\d{4})(?<half>(01|06))$/;

export const getSeasonH = (season: string | Date): string => {
  if (typeof season !== 'string') {
    season = season.toISOString();
  }
  if (SEASON_ISO_DATE_REGEX.test(season)) {
    return seasonHFromISODate(season);
  }
  if (SEASON_FILENAME_REGEX.test(season)) {
    return seasonHFromYYYYMM(season);
  }
  if (SEASON_CODE_REGEX.test(season)) {
    return seasonHFromYYM(season);
  }
  if (SEASON_NAME_REGEX.test(season)) {
    return seasonHFromH(season);
  }
  return season;
};

const getPrefix = (year: string, month: string) => {
  return `H${Number(month) > 5 ? '2' : '1'}${year.slice(2, 4)}`;
};

export const seasonHFromISODate = (season: string): string => {
  const match = season.match(SEASON_ISO_DATE_REGEX);
  const { year, month } = (match?.groups ?? {}) as { year: string; month: string };
  return !year || !month ? season : getPrefix(year, month);
};

export const seasonHFromYYYYMM = (season: string): string => {
  const match = season.match(SEASON_FILENAME_REGEX);
  const { half, year } = (match?.groups ?? {}) as { year: string; half: string };
  return !year || !half ? season : getPrefix(year, half);
};

export const seasonHFromYYM = (season: string): string => {
  const match = season.match(SEASON_CODE_REGEX);
  const { month, year } = (match?.groups ?? {}) as { year: string; month: string };
  return !year || !month ? season : `H${month}${year}`;
};

export const seasonHFromH = (season: string): string => {
  const match = season.match(SEASON_NAME_REGEX);
  const { half, year } = (match?.groups ?? {}) as { year: string; half: string };
  return !year || !half ? season : `H${half}${year}`;
};

export const getSeasonPrefix = (seasonName?: Date | string): string | undefined => {
  if (seasonName && typeof seasonName === 'string' && SEASON_FILENAME_REGEX.test(seasonName)) {
    return seasonName;
  }
  if (typeof seasonName === 'string') {
    seasonName = new Date(seasonName);
  }
  if (seasonName instanceof Date) {
    return `${seasonName.getUTCFullYear()}${seasonName.getUTCMonth() < 5 ? '01' : '06'}`;
  }
  return seasonName;
};

export const getSeasonISO = (season: string | Date): string => {
  if (typeof season !== 'string' || SEASON_ISO_DATE_REGEX.test(season)) {
    return isoSeasonFromDate(season);
  }
  if (SEASON_NAME_REGEX.test(season)) {
    return isoSeasonFromPrefix(season);
  }
  if (SEASON_FILENAME_REGEX.test(season)) {
    return isoSeasonFromYYYYMM(season);
  }
  return season;
};

export const isoSeasonFromDate = (season: string | Date): string => {
  if (typeof season !== 'string') {
    season = season.toISOString();
  }
  const match = season.match(SEASON_ISO_DATE_REGEX);
  const { year, month } = (match?.groups ?? {}) as { year: string; month: string };
  if (!year || !month) {
    return season;
  }
  return `${year}-${month}-01T00:00:00.000Z`;
};

export const isoSeasonFromPrefix = (season: string): string => {
  const match = season.match(SEASON_NAME_REGEX);
  const { half, year } = (match?.groups ?? {}) as { year: string; half: string };
  if (!year || !half) {
    return season;
  }
  return `20${year}-${half === '1' ? '01' : '06'}-01T00:00:00.000Z`;
};

export const isoSeasonFromYYYYMM = (season: string): string => {
  const match = season.match(SEASON_FILENAME_REGEX);
  const { half, year } = (match?.groups ?? {}) as { year: string; half: string };
  if (!year || !half) {
    return season;
  }
  return `${year}-${half}-01T00:00:00.000Z`;
};
