import { max, min } from 'date-fns';
import { useMemo } from 'react';
import { buildPhasingOptions } from 'utils';

import { EditableData, ProductDetailsData, ProductListData, UIProduct } from '@yourxx/types';

export type PhasingOptionRange = {
  start: Date | undefined;
  end: Date | undefined;
};

export const defined = <T>(value: T): value is NonNullable<T> => value !== null;
export const filterDefined = <T>(values: ReadonlyArray<T>): NonNullable<T>[] => values.filter(defined);

const dateStringProp = (prop: keyof EditableData) => (product: UIProduct<ProductListData>) => {
  if (!product || !product[prop]) {
    return;
  }
  const date = new Date(String(product.inherited?.[prop] ?? product[prop]));
  return isNaN(date.valueOf()) ? undefined : date;
};

export const usePhasingOptions = ({ products }: { products: UIProduct<ProductDetailsData>[] }) => {
  const minFirstOnFloor = useMemo(() => max(filterDefined(products.map(dateStringProp('firstOnFloor')))), [products]);
  const maxLastOnFloor = useMemo(() => min(filterDefined(products.map(dateStringProp('lastOnFloor')))), [products]);

  let phasingOptions = [] as ReturnType<typeof buildPhasingOptions>[0];
  if (minFirstOnFloor && maxLastOnFloor && minFirstOnFloor.getTime() <= maxLastOnFloor.getTime()) {
    // Start and finish options are the same here so we can take only one set.
    [phasingOptions] = buildPhasingOptions(minFirstOnFloor, maxLastOnFloor);
  }

  return { phasingOptions };
};
