import { Button, Checkbox, Dialog, IAM, Input, LoadingSpinner } from 'components';
import { Select } from 'components/Select';
import { useLocalisation } from 'providers';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import type { CustomerAddress, CustomerAssortmentArray } from '@yourxx/types';

import type { Order } from '.';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.l')};
  max-width: ${rem(650)};
`;
const EditSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${themed('spacing.m')};
  padding-bottom: ${themed('spacing.l')};
  margin-bottom: ${themed('spacing.s')};
  border-bottom: solid ${rem(1)} ${themed('color.greyMid')};

  button {
    font-size: ${themed('font.size.s')};
    font-weight: ${themed('font.weight.regular')};
    padding: ${themed('spacing.s')} 0;

    &:last-of-type {
      color: ${themed('color.red')};
    }
  }
`;
const Label = styled.label`
  display: flex;
  flex-direction: column;

  input {
    margin: ${themed('spacing.m')} 0;
  }
`;
const StyledSelect = styled(Select)`
  .Select__value-container--is-multi {
    overflow: auto;
    max-height: ${rem(90)};
  }
`;
const CheckboxLabel = styled.label<{ $isVisible?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
  margin-bottom: ${rem(50)};

  span {
    font-size: ${themed('font.size.s')};
  }
`;

export type OrderModalType = 'create' | 'edit' | 'duplicate';

export const OrderCreateEditModal = ({
  type,
  order,
  assortments,
  locations,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  isLoading
}: {
  type: OrderModalType;
  order?: Order;
  assortments: CustomerAssortmentArray[];
  locations?: CustomerAddress[];
  onClose: VoidFunction;
  onCreate?: ({ order, orderPerLocation }: { order: Order; orderPerLocation?: boolean }) => void;
  onEdit?: ({ order }: { order: Order }) => void;
  onDelete?: VoidFunction;
  isLoading?: boolean;
}) => {
  const [str] = useLocalisation();
  const [assortment, setAssortment] = useState<CustomerAssortmentArray | undefined>(
    order && assortments ? assortments.find(a => a.assortmentId === order.finalAssortment.assortmentId) : undefined
  );
  const [orderName, setOrderName] = useState<string>(type === 'edit' && order ? order?.orderName : '');
  const [orderLocations, setOrderLocations] = useState<CustomerAddress[]>(
    type === 'edit' && order ? order?.locations : []
  );
  const [orderPerLocation, setOrderPerLocation] = useState<boolean>(false);

  // TODO: remove
  // @ts-expect-error: order undefined
  const updatedOrder: Order = useMemo(
    () => ({
      ...order,
      finalAssortment: assortment,
      orderName,
      locations: orderLocations
    }),
    [order, assortment, orderName, orderLocations]
  );

  return (
    <Dialog
      title={str(`Order.modal.${type}.title`)}
      maxWidth={650}
      cancel={{ label: str('general.cancel'), handler: onClose }}
      confirm={{
        label: isLoading ? <LoadingSpinner /> : str(type === 'edit' ? 'general.save' : 'general.create'),
        handler: () =>
          type === 'edit' ? onEdit?.({ order: updatedOrder }) : onCreate?.({ order: updatedOrder, orderPerLocation }),
        disabled: !assortment || !orderName || isLoading
      }}
      onClose={onClose}
      content={
        <Content>
          {type === 'edit' && onCreate && onDelete && (
            <EditSection>
              <Button onClick={() => onCreate({ order: updatedOrder })}>{str('Order.modal.duplicate.title')}</Button>
              <IAM action={`orders.delete`}>
                <Button onClick={onDelete}>{str('Order.modal.delete.title')}</Button>
              </IAM>
              <IAM action={`orders.delete`}>
                <Button onClick={onDelete}>{str('Order.modal.delete.title')}</Button>
              </IAM>
            </EditSection>
          )}
          <Label>
            {str('Order.modal.label.finalAssortment')}
            <Select
              options={assortments.map(a => ({
                key: a.assortmentId,
                label: a.assortmentName
              }))}
              placeholder={str('Order.modal.label.finalAssortmentPlaceholder')}
              isDisabled={type !== 'create'}
              onChange={(option: any) => setAssortment(assortments.find(a => a.assortmentId === option.key))}
              value={
                assortment
                  ? {
                      key: assortment.assortmentId,
                      label: assortment.assortmentName
                    }
                  : undefined
              }
            />
          </Label>
          <Label>
            {str('Order.modal.label.orderName')}
            <Input
              placeholder={str('Order.modal.label.orderNamePlaceholder')}
              value={orderName}
              onChange={e => setOrderName(e.target.value)}
            />
          </Label>
          <Label>
            {str('Order.modal.label.locations')}
            <StyledSelect
              options={locations?.map(l => ({
                key: l.key,
                label: `${l.name} (${l.city})`
              }))}
              value={orderLocations?.map(ol => ({
                key: ol.key,
                label: `${ol.name} (${ol.city})`
              }))}
              onChange={(options: any) =>
                setOrderLocations(prev => options.map((o: any) => locations?.find(l => l.key === o.key)) ?? prev)
              }
              placeholder={str('Order.modal.label.locationsPlaceholder')}
              maxMenuHeight={100}
              isMulti
            />
          </Label>
          <CheckboxLabel $isVisible={orderLocations?.length > 1 && type !== 'edit'}>
            <Checkbox checked={orderPerLocation} onChange={e => setOrderPerLocation(e)} />
            <span>{str('Order.modal.label.orderForEachLocation')}</span>
          </CheckboxLabel>
        </Content>
      }
    />
  );
};
