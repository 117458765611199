import { useCallback } from 'react';

import { ProductDetailsData, ProductView, UIProduct } from '@yourxx/types';
import { GetProductRes, getProducts } from '@yourxx/ui-utils';

// TODO: remove this and use one centralised pdp endpoint
export const useGetAssortmentProduct = ({ assortmentOrLineId }: { assortmentOrLineId?: string }) => {
  const getAssortmentProduct = useCallback(
    async ({
      pc9,
      parent,
      pricingGroupId,
      handleLoading,
      handleSuccess,
      handleError
    }: {
      pc9: string | string[];
      parent?: boolean;
      pricingGroupId?: string;
      handleLoading?: (loading: boolean) => void;
      handleSuccess?: (data: GetProductRes<UIProduct<ProductDetailsData>>) => void;
      handleError?: VoidFunction;
    }) => {
      if (assortmentOrLineId && pc9) {
        handleLoading?.(true);
        getProducts<UIProduct<ProductDetailsData>>({
          id: assortmentOrLineId,
          pc9: Array.isArray(pc9) ? pc9 : [pc9],
          view: ProductView.Details,
          parent: parent ? 'true' : 'false',
          pricing_group_id: pricingGroupId
        })
          .then(data => {
            if (handleSuccess) {
              handleSuccess(data);
            }
          })
          .catch(e => {
            console.error(e);
            handleLoading?.(false);
            handleError?.();
          });
      }
    },
    [assortmentOrLineId]
  );

  return { getAssortmentProduct };
};
