import { useMemo } from 'react';

import { removeProduct, updateProduct } from '@yourxx/ui-utils';

import { EventBus } from '../EventBus';
import { ProductsAdded, ProductsRemoved, ProductsUpdated } from './events';
import { PartialProductsRemovalError, type ProductsService, type RemovedProductsErrorReport } from './ProductsService';

export interface Options {
  eventBus: EventBus;
  add?: typeof updateProduct;
  remove?: typeof removeProduct;
}

const defaults: Required<Omit<Options, 'eventBus'>> = { remove: removeProduct, add: updateProduct };

export const createProductsService = ({
  eventBus,
  add = defaults.add,
  remove = defaults.remove
}: Options): ProductsService => ({
  add: async command =>
    add({
      id: command.context.id,
      products: command.items.map(data => ({ ...data, ...command.attributes, status: 'ADD' }))
    })
      .then(response => {
        if ('error' in response) throw new Error(response.error);
        if ('errors' in response)
          throw new Error(
            `Failed to add ${response.errors.length} out of ${command.items.length} product(s): ${response.errors.map(({ pc9 }) => pc9).join(', ')}`
          );
        return response.products;
      })
      .then(added =>
        eventBus.emit(
          new ProductsAdded({
            context: command.context,
            sourceAssortmentId: command.sourceAssortmentId,
            pc9s: added.map(({ pc9 }) => pc9),
            attributes: command.attributes
          })
        )
      ),

  update: async command =>
    add({
      id: command.context.id,
      products: command.items.map(({ pc9 }) => ({ pc9, ...command.attributes }))
    })
      .then(response => {
        if ('error' in response) throw new Error(response.error);
        if ('errors' in response)
          throw new Error(
            `Failed to update ${response.errors.length} out of ${command.items.length} product(s): ${response.errors.map(({ pc9 }) => pc9).join(', ')}`
          );
        return response.products;
      })
      .then(updated =>
        eventBus.emit(
          new ProductsUpdated({
            context: command.context,
            pc9s: updated.map(({ pc9 }) => pc9),
            attributes: command.attributes
          })
        )
      ),

  remove: async command =>
    remove({
      id: command.context.id,
      pc9s: command.items.map(({ pc9 }) => pc9)
    })
      .then(response => {
        if ('error' in response) throw new Error(response.error);

        const report: RemovedProductsErrorReport<ArrayItem<typeof command.items>> = { errors: [], removed: [] };

        if ('products' in response) {
          for (const product of response.products) {
            const match = command.items.find(({ pc9 }) => pc9 === product.pc9);
            if (match) report.removed.push(match);
          }
        }

        if ('errors' in response) {
          for (const errorEntry of response.errors) {
            const match = command.items.find(({ pc9 }) => pc9 === errorEntry.pc9);
            if (match) report.errors.push({ product: match, reason: errorEntry.error });
          }

          throw new PartialProductsRemovalError(command.context.id, report);
        } else {
          return report.removed;
        }
      })
      .then(removed =>
        eventBus.emit(
          new ProductsRemoved({
            context: command.context,
            pc9s: removed.map(({ pc9 }) => pc9)
          })
        )
      )
});

export const useCreateProductsService = ({ eventBus }: { eventBus: EventBus }): ProductsService =>
  useMemo(() => createProductsService({ eventBus }), [eventBus]);

type ArrayItem<T> = T extends (infer R)[] ? R : never;
