import { ShowAsyncErrorAndGoBack, ViewTransition } from 'pages';
import { Customers } from 'pages/Landing/Customers';
import { useCustomersData } from 'providers';
import { Suspense, useEffect } from 'react';
import { Await } from 'react-router-dom';

import { once } from '@yourxx/support';

const mockedLoadCustomers = once(() => Promise.resolve());

export const CustomersIndex = (_props: { brand: string }) => {
  const { loadCustomers } = useCustomersData();

  // TODO: Get promise from loading customers;
  useEffect(() => {
    loadCustomers();
  }, [loadCustomers]);

  return (
    <Suspense fallback={<ViewTransition children={<>TODO: Skeleton</>} />}>
      <Await
        errorElement={<ShowAsyncErrorAndGoBack />}
        resolve={mockedLoadCustomers()}
        children={_ => <ViewTransition children={<Customers />} />}
      />
    </Suspense>
  );
};
