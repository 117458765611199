import { Close } from 'assets/icons';
import { Button } from 'components/Button';
import { EntityContext } from 'domain-events';
import { useEffect } from 'react';
import styled from 'styled-components';
import { rem, themed, useIAM } from 'utils';

import { PageDetails, ProductListData, UIProduct } from '@yourxx/types';

import { Board } from './Board';
import { BoardsActionButtons } from './BoardsActionButtons';
import { BoardsListTabs } from './BoardsListTabs';
import { CurrentCanvasContext } from './CurrentCanvasContext';
import { useCanvas } from './hooks';
import { ShareBoard } from './ShareBoard';

const Wrapper = styled.div`
  height: 90vh;
  max-width: 90vw;
  width: ${rem(1300)};
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${themed('spacing.l')} ${themed('spacing.l')} 0;
  width: 100%;
  box-sizing: border-box;
`;
const Title = styled.h1`
  font-size: ${themed('font.size.xl')};
  margin: 0;
  position: relative;
  top: -${themed('spacing.s')};
`;
const Toolbar = styled.div<{ $isShareBoard?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isShareBoard }) => ($isShareBoard ? 'flex-end' : 'space-between')};
  flex: 1;
  gap: ${themed('spacing.m')};
`;
const CloseButton = styled(Button)`
  position: relative;
  top: -${themed('spacing.m')};
  right: -${themed('spacing.m')};

  svg {
    width: ${themed('font.size.xl')};
    height: ${themed('font.size.xl')};
  }
`;

export const Canvas = ({
  params,
  onClose,
  productsContext,
  products,
  pageDetails
}: {
  params: Record<string, string | undefined>;
  onClose: VoidFunction;
  productsContext: EntityContext | undefined;
  products: UIProduct<ProductListData>[];
  pageDetails?: PageDetails;
}) => {
  const { shareBoardData, boards, setProductsContext, setProducts, setPageDetails } = useCanvas();
  const { canvasType, canvasBoardId } = params;
  const { canUse } = useIAM();
  const isViewingCompareBoard = canvasType === 'comparator';
  const isViewingSharedBoard = canvasType === 'share';
  const iamPrefix = 'products.canvas';

  useEffect(() => setProductsContext(productsContext), [productsContext]);
  useEffect(() => setProducts(products), [products]);
  useEffect(() => setPageDetails(pageDetails), [pageDetails]);

  return (
    <CurrentCanvasContext.Provider value={params}>
      <Wrapper>
        <Header>
          <Title>{[shareBoardData?.boardName, shareBoardData?.shareBoardName].filter(Boolean)?.join(' - ')}</Title>
          <Toolbar $isShareBoard={isViewingSharedBoard}>
            {boards && isViewingCompareBoard && (
              <BoardsListTabs
                showAddIcon={canUse(`createBoard`, iamPrefix)}
                showCloseIcon={canUse(`deleteBoard`, iamPrefix)}
                canEditTabName={canUse(`editBoard`, iamPrefix)}
              />
            )}
            {(canvasBoardId || shareBoardData) && (
              <BoardsActionButtons showShareButton={isViewingCompareBoard} showAddProduct={isViewingCompareBoard} />
            )}
          </Toolbar>
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
        </Header>
        {isViewingCompareBoard && <Board />}
        {isViewingSharedBoard && <ShareBoard />}
      </Wrapper>
    </CurrentCanvasContext.Provider>
  );
};
