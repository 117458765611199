import { Info, SpinnerAlt } from 'assets/icons';
import styled from 'styled-components';
import { rem, themed } from 'utils';

export const ProductRemovalReport = styled.div`
  overflow: auto;
  max-height: ${rem(460)};

  > p {
    margin: 0 0 ${themed('spacing.xl')};
  }
`;

export const ProductName = styled.div<{ $isRemoved?: boolean }>`
  text-decoration: ${({ $isRemoved }) => ($isRemoved ? 'line-through' : 'unset')};
`;

export const RemovedProductsList = styled.ul<{ $withErrors: boolean }>`
  margin: 0;
  padding: 0;
  list-style: none;

  & + p {
    margin-top: ${themed('spacing.xl')};
  }

  li {
    display: flex;
    justify-content: center;
    gap: ${themed('spacing.l')};
    padding: ${themed('spacing.s')};
    background-color: transparent;
    will-change: background-color;
    transition-property: background-color;
    transition-timing-function: ${themed('transition.timing')};
    transition-duration: ${themed('transition.duration')};

    @media (hover: hover) {
      &:hover {
        background-color: ${themed('color.offWhite')};
      }
    }

    > div:first-child {
      width: ${({ $withErrors }) => ($withErrors ? '30%' : 'unset')};
      ${themed('typography.h4')};
      font-weight: ${themed('font.weight.regular')};
      flex-grow: 1;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 100%;
        height: 1rem;
        white-space: nowrap;
      }
    }

    > div:nth-child(2) {
      color: ${themed('color.greyDark')};
      ${themed('typography.h4')};
      font-weight: ${themed('font.weight.bold')};
      flex-shrink: 0;
      font-variant-numeric: tabular-nums;
      text-align: right;
    }

    > div:nth-child(3) {
      display: flex;
      width: 40%;
      gap: ${themed('spacing.m')};
      ${themed('typography.h4')};
      color: ${themed('color.red')};

      svg {
        flex-shrink: 0;
      }
    }
  }
`;

export const ErrorIcon = styled(Info)`
  width: ${themed('spacing.l')};
  height: ${themed('spacing.l')};

  path {
    stroke: ${themed('color.red')};
  }
`;

export const StyledLoader = styled(SpinnerAlt)`
  margin-left: ${themed('spacing.m')};
  width: ${rem(24)};
  height: ${rem(24)};
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${themed('color.red')};

  svg {
    width: ${themed('spacing.xl')};
    height: ${themed('spacing.xl')};
  }
`;
