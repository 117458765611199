import { useCallback } from 'react';
import { DomainEvent, type EventBus } from 'services/EventBus';
import { useEventSubscriptions } from 'utils';

import { type AnyFilter, Defect, type FiltersSchema } from '@yourxx/support';

export class SetFilterRequested extends DomainEvent<{ filter: AnyFilter }> {}

type UseFiltersSubscriptionsProps = {
  eventBus: EventBus;
  filtersSchema: FiltersSchema;
  setFilter: (filter: AnyFilter) => void;
};

export const useFiltersSubscriptions = ({ eventBus, setFilter, filtersSchema }: UseFiltersSubscriptionsProps) => {
  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(SetFilterRequested, event => {
          guardAgainstUnknownFilter(filtersSchema, event.payload.filter);
          setFilter(event.payload.filter);
        })
      ],
      [eventBus, filtersSchema, setFilter]
    )
  );
};

const guardAgainstUnknownFilter = (schema: FiltersSchema, aFilter: AnyFilter) => {
  if (!schema.find(filter => filter.id === aFilter.id))
    throw new Defect(
      `An unknown filter with id ${aFilter.id} has been requested to be set via an event. Please ensure the desired filter is present in the schema.`
    );
};
