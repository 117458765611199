import { Skeleton as Sk } from 'components';
import { SlotId } from 'pages';
import { useSlot } from 'providers';
import { useMemo } from 'react';

export const OrderSummarySkeleton = () => {
  useSlot(
    SlotId.OrderMenu,
    useMemo(
      () => (
        <Sk.Vertical>
          <Sk.Spacer $size="xs" />
          <Sk.Heading $width="25%" />
          <Sk.Spacer $size="xs" />
        </Sk.Vertical>
      ),
      []
    )
  );

  useSlot(
    SlotId.OrderToolbar,
    useMemo(() => <Sk.Heading $width="30%" />, [])
  );

  useSlot(
    SlotId.FooterLeft,
    useMemo(() => <Sk.Text $width="15%" />, [])
  );

  return (
    <div>
      <Sk.Row>
        <Sk.Col>
          <Sk.Text $width="20%" />
        </Sk.Col>
      </Sk.Row>
      <Sk.Row>
        <Sk.Repeat
          times={4}
          children={
            <Sk.Col>
              <Sk.Box $height={96} />
            </Sk.Col>
          }
        />
      </Sk.Row>
      <Sk.Row>
        <Sk.Table rows={8} />
      </Sk.Row>
    </div>
  );
};
