import type { Persistence } from './AuditTrailService';

const key = '@yourxx/audit-trail/seen-records';

export const persistence: Persistence = {
  save: records => {
    localStorage.setItem(key, JSON.stringify(records));
  },
  load: () => JSON.parse(localStorage.getItem(key) ?? '{}')
};
