import { uuid } from '../common';

export enum ItemType {
  LINE = 'line',
  ASSORTMENT = 'assortment',
  PRODUCT = 'product',
  USER = 'user'
  // Add others as needed
}

export enum Event {
  CREATE_ASSORTMENT = 'create-assortment',
  ARCHIVE_ASSORTMENT = 'archive-assortment',
  UNARCHIVE_ASSORTMENT = 'unarchive-assortment',
  PUBLISH_ASSORTMENT = 'publish-assortment',
  UNPUBLISH_ASSORTMENT = 'unpublish-assortment',
  ADD_STORE_GRADES = 'add-store-grades',
  DELETE_STORE_GRADES = 'delete-store-grades',
  CREATE_PRODUCT = 'create-product',
  UPDATE_PRODUCT = 'update-product',
  ADD_PC9S = 'add-pc9s',
  COPY_PC9S = 'copy-pc9s',
  REMOVE_PC9S = 'remove-pc9s',
  DROP_PC9S = 'drop-pc9s',
  EDIT_PC9S = 'edit-pc9s'
}

export interface EventData<T extends object> {
  itemType: ItemType;
  id: uuid;
  userId?: uuid;
  payload: T;
}
