import styled from 'styled-components';
import { themed } from 'utils';

type Size = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

const StyledLabel = styled.label<{ $size?: Size }>`
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};

  > span {
    display: block;
    ${({ $size }) => themed(`typography.${$size}`)};
    font-weight: ${themed('font.weight.medium')};
  }
`;

export const Label = ({
  text,
  children,
  size = 'h4',
  ignoreInteractions
}: {
  text?: React.ReactNode;
  children: React.ReactNode;
  size?: Size;
  ignoreInteractions?: boolean;
}) => (
  <StyledLabel
    $size={size}
    onClick={event => {
      if (ignoreInteractions) event.preventDefault();
    }}
  >
    <span>{text}</span>
    {children}
  </StyledLabel>
);
