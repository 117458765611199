import { ImageSlider } from 'components/ImageSlider';
import { useLocalisation } from 'providers';
import styled from 'styled-components';
import { canvasImageFullscreenTag, canvasImageZoomTag, themed } from 'utils';

import { CanvasProductDetails } from '@yourxx/types';

import { useCurrentCanvasContext } from './CurrentCanvasContext';
import { useCanvas } from './hooks';
import { Metrics } from './Metrics';

const Container = styled.div`
  padding: ${themed('spacing.m')};
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.m')};
  align-items: stretch;
`;
const ImageWrapper = styled.div`
  max-width: 100%;
  text-align: center;
  position: relative;
`;
const TitleText = styled.h3`
  font-size: ${themed('font.size.m')};
  font-weight: ${themed('font.weight.bold')};
  margin: 0;
  display: block;
`;
const InfoText = styled.p`
  font-size: ${themed('font.size.m')};
  color: ${themed('color.grey')};
  margin: 0;
  line-height: 1.2;
  display: block;
`;
const DescriptionText = styled.pre`
  font-size: ${themed('font.size.s')};
  color: ${themed('color.greyDark')};
  background-color: transparent;
  overflow: hidden;
  line-break: normal;
  line-height: 1.2;
  white-space: pre-wrap;
`;

export const CompareInfo = ({ product, onRemove }: { product: CanvasProductDetails; onRemove?: VoidFunction }) => {
  const { isImageZoomed, setIsImageZoomed } = useCanvas();

  return (
    <Container>
      <ImageWrapper>
        <ImageSlider
          images={product.images?.map(i => ({ url: i.default, imageType: i.imageType! })) ?? []}
          onRemove={onRemove}
          onFullscreenChange={() =>
            canvasImageFullscreenTag({
              assortmentId: product.assortmentId,
              customerId: product.customerId,
              pc9: product.pc9Code
            })
          }
          zoomedIn={isImageZoomed}
          onZoomChange={val => {
            setIsImageZoomed(val);
            canvasImageZoomTag({
              assortmentId: product.assortmentId,
              customerId: product.customerId,
              pc9: product.pc9Code
            });
          }}
        />
      </ImageWrapper>
      <>
        <ProductInfo product={product} />
        <Metrics product={product} />
        <DescriptionText as="pre">{product.details}</DescriptionText>
      </>
    </Container>
  );
};

export const ProductInfo = ({ product }: { product: CanvasProductDetails }) => {
  const [str] = useLocalisation();
  const { assortmentId, lineId } = useCurrentCanvasContext();

  return (
    <Container>
      <TitleText>{product.name}</TitleText>
      <InfoText>
        {product.inAssortment
          ? assortmentId && !lineId
            ? str('Canvas.product.fromAssortment')
            : str('Canvas.product.fromLine')
          : str('Canvas.product.fromParentLine')}
      </InfoText>
      <InfoText>{product.pc9Code}</InfoText>
    </Container>
  );
};
