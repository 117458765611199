import { useCallback, useEffect, useState } from 'react';

import { usePrevious } from '@dnd-kit/utilities';

import { useDoubleBufferState } from './useDoubleBufferState';

type RunnableTask<R> = { run(): Promise<R> };

export const useTaskQueue = <T extends RunnableTask<unknown>>(
  { parallel }: { parallel?: number } = { parallel: 1 }
) => {
  const [queue, setQueue, swap] = useDoubleBufferState<T[]>([], []);
  const [isRunning, setIsRunning] = useState(false);
  const wasRunning = usePrevious(isRunning);

  const run = useCallback(
    async (queue: readonly T[]) => {
      setIsRunning(true);
      swap();

      let mQueue = [...queue];
      while (mQueue.length) {
        await Promise.allSettled(mQueue.slice(0, parallel).map(task => task.run()));
        mQueue = mQueue.slice(parallel);
      }

      setQueue(mQueue);
      setIsRunning(false);
    },
    [parallel, setQueue, swap]
  );

  useEffect(() => {
    if (wasRunning && !isRunning && queue.length) run(queue);
  }, [isRunning, queue, run, wasRunning]);

  const enqueue = useCallback(
    (task: T) => {
      setQueue(prev => {
        const newQueue = [...prev, task];
        if (!isRunning) run(newQueue);
        return newQueue;
      });
    },
    [isRunning, run, setQueue]
  );

  return {
    enqueue
  };
};
