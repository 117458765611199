import { assign, createMachine } from 'xstate';

export enum VisibilityState {
  Collapsed = 'Collapsed',
  Collapsing = 'Collapsing',
  Expanded = 'Expanded',
  Expanding = 'Expanding'
}

export enum Action {
  Toggle = 'Toggle',
  Input = 'Input',
  Clear = 'Clear'
}

type Events = { type: Action.Toggle } | { type: Action.Input; value: string } | { type: Action.Clear };

interface Context {
  searchTerm: string;
}

export const createSearchMachine = ({ disableCollapsing }: { disableCollapsing?: boolean }) => {
  const EMPTY_CONTEXT: Context = {
    searchTerm: ''
  };

  return createMachine({
    schema: {} as {
      context: Context;
      events: Events;
    },
    id: 'header-search-state-machine',
    predictableActionArguments: true,
    type: 'parallel',
    context: EMPTY_CONTEXT,
    states: {
      visibility: {
        initial: disableCollapsing ? VisibilityState.Expanded : VisibilityState.Collapsed,
        states: {
          [VisibilityState.Expanding]: {
            after: {
              200: VisibilityState.Expanded
            },
            on: {
              [Action.Input]: {
                actions: [
                  assign({
                    searchTerm: (_context, event) => event.value
                  }),
                  {
                    type: 'onChange'
                  }
                ]
              },
              // This improves UX by not making the UI jittery when transitioning
              [Action.Clear]: {
                cond: context => context.searchTerm.length > 0,
                actions: [
                  assign({
                    searchTerm: () => ''
                  }),
                  {
                    type: 'onChange'
                  }
                ]
              }
            }
          },
          [VisibilityState.Expanded]: {
            on: {
              [Action.Toggle]: {
                target: VisibilityState.Collapsing,
                cond: () => !disableCollapsing
              },
              [Action.Input]: {
                actions: [
                  assign({
                    searchTerm: (_context, event) => event.value
                  }),
                  {
                    type: 'onChange'
                  }
                ]
              },
              [Action.Clear]: {
                cond: context => context.searchTerm.length > 0,
                actions: [
                  assign({
                    searchTerm: () => ''
                  }),
                  {
                    type: 'onChange'
                  }
                ]
              }
            }
          },
          [VisibilityState.Collapsing]: {
            after: {
              200: VisibilityState.Collapsed
            }
          },
          [VisibilityState.Collapsed]: {
            on: {
              [Action.Toggle]: VisibilityState.Expanding,
              [Action.Input]: {
                actions: assign({
                  searchTerm: (_context, event) => event.value
                })
              }
            }
          }
        }
      }
    }
  });
};
