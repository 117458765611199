import { Dropdown } from 'components/Dropdown';
import { FlexWrapper } from 'components/FlexWrapper';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { ProductCard } from 'components/ProductCard';
import { useLocalisation } from 'providers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { insightsMoreAccountClickTag, insightsPC9CheckboxClickTag, rem, themed } from 'utils';

import { InsightItem, ProductListData, RecommendationItem, UIProduct } from '@yourxx/types';
import { getProducts, InsightStatus, InsightType } from '@yourxx/ui-utils';

import { useInsightKey } from '../hooks';

const Container = styled.div`
  padding: ${themed('spacing.l')};
`;
const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${rem(160)}, 1fr));
  gap: ${themed('spacing.l')};
  padding: ${themed('spacing.l')};
`;
const ProductDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CustomersWrapper = styled.div`
  margin-bottom: ${themed('spacing.m')};
`;
const CustomerListing = styled.div`
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: ${themed('spacing.s')};

  span {
    font-size: ${themed('font.size.s')};
    line-height: 1.4;
    letter-spacing: ${rem(1)};
    border-radius: ${themed('spacing.s')};
    background-color: ${themed('color.greyLight')};
    padding: ${themed('spacing.xs')} ${themed('spacing.m')};
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const CustomersListingDropdownTrigger = styled.span`
  font-size: ${themed('font.size.s')};
  text-decoration: underline;
  font-weight: ${themed('font.weight.semiBold')};
`;
const ProductCustomerHeader = styled.p`
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.bold')};
  letter-spacing: ${rem(1.1)};
  margin: 0 0 ${themed('spacing.s')};
`;

export const ProductList = ({
  insightItem,
  selectedProducts,
  setSelectedProducts
}: {
  insightItem: InsightItem;
  selectedProducts: RecommendationItem[];
  setSelectedProducts: Dispatch<SetStateAction<RecommendationItem[]>>;
}) => {
  const [str] = useLocalisation();
  const navigate = useNavigate();
  const { getInsightKey } = useInsightKey();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [products, setProducts] = useState<RecommendationItem[]>([]);

  useEffect(() => {
    if (insightItem?.items?.length) {
      const controller = new AbortController();
      const { signal } = controller;
      setIsLoading(true);
      getProducts<RecommendationItem>(
        {
          view: 'list',
          id: insightItem.assortmentId,
          pc9: insightItem.items.map(x => x.pc9),
          parent: 'true'
        },
        signal
      )
        .then(res => {
          if (!signal.aborted) {
            setProducts(
              (res.products ?? []).map(p => ({
                ...p,
                ...insightItem.items?.find(x => x.pc9 === p.pc9)
              }))
            );
            setIsLoading(false);
          }
        })
        .catch(e => {
          if (e.name !== 'AbortError') {
            console.error(e);
            setErrorMessage(str('Insights.retrievingProductsError'));
            setIsLoading(false);
          }
        });

      return () => controller.abort();
    }
  }, [str, insightItem?.items]);

  return (
    <Container>
      {isLoading && <LoadingSpinner label={str('Insights.loadingProducts')} />}
      {!isLoading && errorMessage && (
        <FlexWrapper>
          <p>{errorMessage}</p>
        </FlexWrapper>
      )}
      {!isLoading && !errorMessage && (
        <ListWrapper>
          {products?.map(product => (
            <ProductDetailWrapper key={product.pc9}>
              {insightItem.type !== InsightType.SUGGESTION && product.customers?.length > 0 && (
                <CustomersWrapper>
                  <ProductCustomerHeader>{str('Insights.productsSelectedByCustomers')}</ProductCustomerHeader>
                  <CustomerListing>
                    {[...product.customers].splice(0, 2).map(customer => (
                      <span key={customer} title={customer}>
                        {customer}
                      </span>
                    ))}
                  </CustomerListing>
                  {product.customers.length > 2 && (
                    <Dropdown
                      onOpen={() => {
                        insightsMoreAccountClickTag({
                          assortmentId: insightItem.customer.customer_id,
                          customerId: insightItem.assortmentId,
                          recommendationId: getInsightKey(insightItem),
                          pc9Code: product.pc9
                        });
                      }}
                      trigger={
                        <CustomersListingDropdownTrigger>
                          {str('Insights.moreProducts', {
                            count: product.customers.length - 2 > 5 ? 5 : product.customers.length - 2
                          })}
                        </CustomersListingDropdownTrigger>
                      }
                      disableSelection
                      items={[...product.customers].slice(2, 7).map(customer => ({
                        key: customer,
                        label: customer.toUpperCase()
                      }))}
                    />
                  )}
                </CustomersWrapper>
              )}
              <ProductCard
                isSelected={Boolean(selectedProducts.find(p => p.pId === product.productId))}
                onSelect={() => {
                  const selected = Boolean(selectedProducts.find(p => p.pId === product.productId));
                  setSelectedProducts(prev =>
                    selected ? prev.filter(p => p.pId !== product.productId) : [...new Set([...prev, { ...product }])]
                  );
                  insightsPC9CheckboxClickTag({
                    isChecked: selected,
                    pc9Code: product.pc9,
                    customerId: insightItem.customer.customer_id,
                    assortmentId: insightItem.assortmentId,
                    recommendationId: getInsightKey(insightItem)
                  });
                }}
                onClick={() => navigate(product.pc9)}
                product={product as UIProduct<ProductListData>}
                selectDisabled={insightItem.status === InsightStatus.REMOVED}
              />
            </ProductDetailWrapper>
          ))}
        </ListWrapper>
      )}
    </Container>
  );
};
