import { useState } from 'react';

import { UpdateBoardItemRequest } from '@yourxx/types';
import { updateBoardItems as apiUpdateBoardItems } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export const useUpdateBoardItems = () => {
  const { canvasBoardId } = useCurrentCanvasContext();
  const { setBoardItems } = useCanvas();
  const [isUpdatingBoardItems, setIsUpdatingBoardItems] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();

  const updateBoardItems = (details: UpdateBoardItemRequest[]) => {
    setIsUpdatingBoardItems(true);
    apiUpdateBoardItems({ boardId: canvasBoardId }, details)
      .then(updatedItems => {
        setBoardItems(prev =>
          prev
            .map(initialItem => {
              const item = updatedItems.find(updatedItem => initialItem.id === updatedItem.id);
              return item || initialItem;
            })
            .sort((a, b) => (a.order ?? prev.length) - (b.order ?? prev.length))
        );
        setIsUpdatingBoardItems(false);
      })
      .catch(() => {
        setHasError('Failed adding item to board');
        setIsUpdatingBoardItems(false);
      });
  };

  return { updateBoardItems, isUpdatingBoardItems, hasError };
};
