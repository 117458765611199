import { AnimatePresence, motion, type Variants } from 'framer-motion';
import type { ReactNode } from 'react';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

const chevronRight = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMjIyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMC41IDIxTDE3LjUgMTRMMTAuNSA3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+PC9zdmc+`;

const BreadcrumbsList = styled.ul`
  display: flex;
  gap: ${themed('spacing.m')};
  margin: 0;
  padding: 0;
  list-style: none;
  ${themed('typography.h4')};
  color: ${themed('color.black')};
  font-weight: ${themed('font.weight.regular')};

  * {
    text-decoration: none;
    color: inherit;
  }
`;

const BreadcrumbItem = styled(motion.li)`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.m')};

  &:first-child {
    text-transform: uppercase; // This will make the first item uppercase
  }

  &:last-child:not(:first-child) {
    color: ${themed('color.grey')};
  }

  &:not(:last-child:not(:first-child))::after {
    display: block;
    content: '';
    width: ${rem(15)};
    height: ${rem(15)};
    background-image: url(${chevronRight});
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${rem(15)};
  }
`;

const BREADCRUMB_VARIANTS: Variants = {
  hidden: { opacity: 0, translateX: -8 },
  visible: { opacity: 1, translateX: 0 }
};

export const Breadcrumb = ({ children }: { children: ReactNode }) => {
  return (
    <BreadcrumbItem initial="hidden" animate="visible" exit="hidden" variants={BREADCRUMB_VARIANTS}>
      {children}
    </BreadcrumbItem>
  );
};

export const Breadcrumbs = ({ children }: { children: ReactNode }) => {
  return (
    <BreadcrumbsList>
      <AnimatePresence mode="sync">{children}</AnimatePresence>
    </BreadcrumbsList>
  );
};
