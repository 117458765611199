import { Dialog, FlexWrapper, LoadingSpinner, Select, SelectOption } from 'components';
import { useLocalisation } from 'providers';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { rem } from 'utils';

import { PricingGroup } from '@yourxx/types';

const PricingGroupContent = styled.div`
  width: 100%;
  min-height: ${rem(160)};
`;

export const PricingGroupModal = ({
  isLoading,
  pricingGroups,
  pricingGroupId,
  onConfirm,
  onCancel
}: {
  isLoading?: boolean;
  pricingGroups: PricingGroup[];
  pricingGroupId?: string;
  onConfirm: (pricingGroupId?: string) => void;
  onCancel: VoidFunction;
}) => {
  const [str] = useLocalisation();
  const [selected, setSelected] = useState<string | undefined>(pricingGroupId);

  const options = useMemo(
    () => pricingGroups.map(pg => ({ value: pg.id, label: `${pg.name} (${pg.currency})`, ...pg })),
    [pricingGroups]
  );

  return (
    <Dialog
      title={str('AssortmentSettings.pricingGroupSelect.title')}
      onClose={onCancel}
      content={
        <PricingGroupContent>
          {isLoading ? (
            <FlexWrapper>
              <LoadingSpinner label={str('AssortmentSettings.pricingGroupSelect.loading')} />
            </FlexWrapper>
          ) : (
            <>
              <p>
                {str(
                  pricingGroups?.length
                    ? 'AssortmentSettings.pricingGroupSelect.description'
                    : 'AssortmentSettings.pricingGroupSelect.emptyOptions'
                )}
              </p>
              {!!pricingGroups?.length && (
                <Select
                  options={options}
                  onChange={option => setSelected(option ? (option as SelectOption & PricingGroup).id : undefined)}
                  placeholder={str('AssortmentSettings.pricingGroupSelect.title')}
                  value={options.find(({ value }) => value === selected)}
                  maxMenuHeight={160}
                  isClearable
                />
              )}
            </>
          )}
        </PricingGroupContent>
      }
      confirm={{ label: str('general.save'), handler: () => onConfirm(selected) }}
      cancel={{ label: str('general.cancel'), handler: onCancel }}
      maxWidth={600}
    />
  );
};
