import { useAddBreadcrumb } from 'pages';
import { useOrders } from 'providers';
import { Route, Routes } from 'react-router-dom';

import { OrderDetails } from '../OrderDetails';
import { OrderLayout } from '../OrderLayout';
import { OrderSizingRoute } from '../OrderSizing';
import { OrderSummary } from '../OrderSummary';
import { CopyOrderModalDemo, EditOrderDemo, OrderMetadataDemo } from './demo-wiring-and-examples';

export const CustomerOrder = ({ orderId, season }: { orderId: string; season: string }) => {
  const { brand, customerId, customerName } = useOrders();

  // TODO: Hard-coded for now.
  // TODO: Try https://reactrouter.com/en/main/utils/generate-path
  useAddBreadcrumb(customerName, 0, `/${brand}/customers/${customerId}/${season}`);
  useAddBreadcrumb('Orders', 1, `/${brand}/customers/${customerId}/${season}/orders`);

  return (
    <>
      <EditOrderDemo />
      <CopyOrderModalDemo />
      <OrderMetadataDemo orderId={orderId} />
      <Routes>
        <Route Component={OrderLayout}>
          <Route index element={<OrderDetails orderId={orderId} />} />
          <Route path="summary" element={<OrderSummary orderId={orderId} />} />
          <Route path="summary?/:pc9" element={<OrderSizingRoute orderId={orderId} />} />
        </Route>
      </Routes>
    </>
  );
};
