import { getBrand } from 'utils';

import { Defect } from '@yourxx/support';

export const BrandLogo = ({ brand }: { brand: string }) => {
  const brandObj = getBrand(brand);
  if (!brandObj) {
    console.warn(new Defect(`Unrecognised brand "${brand}"`));
    return null;
  }

  return brandObj.icon();
};
