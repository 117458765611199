import { Globe, SpinnerAlt } from 'assets/icons';
import { CSSProperties, useCallback, useLayoutEffect, useRef, useState } from 'react';
import type { Language } from 'services';
import styled from 'styled-components';
import { prefix } from 'utils';
import { rem, themed } from 'utils';

const Root = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  max-height: ${rem(40)};
  border: solid ${rem(1)} ${themed('color.greyMid')};
  border-radius: ${rem(5)};
  padding: ${rem(6)};
`;
const LoadingIcon = styled(SpinnerAlt)`
  width: ${themed('spacing.xl')};
  height: ${themed('spacing.xl')};
`;
const GlobeIcon = styled(Globe)`
  width: ${themed('spacing.xl')};
  height: ${themed('spacing.xl')};
  animation-name: ${themed('animation.zoomOut')};
  animation-timing-function: ${themed('transition.timing')};
  animation-duration: ${themed('transition.duration')};
`;
const LanguageOptionsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: flex;
    margin: 0 ${rem(6)};
    width: ${rem(1)};
    height: ${rem(26)};
    background-color: ${themed('color.greyMid')};
  }
`;
const SelectedOptionPill = styled.span`
  pointer-events: none;
  position: absolute;
  z-index: -1;
  height: 100%;
  border-radius: ${rem(6)};
  transform: translateX(50%);
  will-change: transform, width;
  transition-property: transform, width;
  transition-duration: ${themed('transition.duration')};
  transition-timing-function: ${themed('transition.timing')};
  background-color: ${themed('color.red')};
`;
const StyledLanguageOption = styled.div<{ $isSelected: boolean }>`
  user-select: none;
  border-radius: ${rem(5)};
  padding: ${rem(6)} ${rem(12)};
  color: ${({ $isSelected }) => themed($isSelected ? 'color.white' : 'color.black')};
  will-change: color;
  transition-property: color;
  transition-duration: ${themed('transition.duration')};
  transition-timing-function: ${themed('transition.timing')};
  cursor: pointer;
`;
const LanguageLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: ${rem(14)};
  line-height: ${rem(15)};
  font-weight: ${themed('font.weight.medium')};
  letter-spacing: 0.04em;
`;

export type Props = {
  className?: string;
  default?: Language;
  languages?: ReadonlyArray<Language>;
  selected?: Language;
  onChange?: (lang: Language) => void;
  isLoading?: boolean;
};

export const LanguageSelector = ({
  className,
  default: defaultLanguage,
  languages,
  selected = defaultLanguage,
  onChange,
  isLoading
}: Props) => {
  const selectedOptionRef = useRef<HTMLDivElement | null>(null);

  const onLanguageSelect = useCallback(
    (lang: Language) => {
      if (lang !== selected) onChange?.(lang);
    },
    [selected, onChange]
  );

  const [selectionPillStyle, setSelectionPillStyle] = useState<CSSProperties | undefined>();

  useLayoutEffect(() => {
    const requestId = window.requestAnimationFrame(() => {
      setSelectionPillStyle({
        width: selectedOptionRef.current?.getBoundingClientRect().width,
        transform: `translateX(${selectedOptionRef.current?.offsetLeft}px)`
      });
    });

    return () => window.cancelAnimationFrame(requestId);
  }, [selected]);

  return (
    <Root className={className} data-testid={testIds.root}>
      {isLoading ? <LoadingIcon /> : <GlobeIcon />}
      {!!languages?.length && (
        <LanguageOptionsContainer>
          <SelectedOptionPill style={selectionPillStyle} />
          {languages.map(language => (
            <StyledLanguageOption
              role="button"
              data-testid={testIds.option(language.locale)}
              data-is-selected={language === selected}
              key={language.locale}
              onClick={() => onLanguageSelect(language)}
              $isSelected={language === selected}
              ref={language === selected ? selectedOptionRef : undefined}
            >
              <LanguageLabel>{language.label}</LanguageLabel>
            </StyledLanguageOption>
          ))}
        </LanguageOptionsContainer>
      )}
    </Root>
  );
};

const rootPrefix = prefix('language-selector');

export const testIds = {
  root: rootPrefix('root'),
  option: <T extends string>(locale: T) => rootPrefix(`option-${locale}` as const)
} as const;
