import { FilterButton, FilterSlideOut } from 'components';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DefaultFilterCombination, FilterType, useServices } from 'services';
import { buildFilterOptions, type FilterOptions } from 'services/ProductFiltersService/buildFilterOptions';
import { useFiltersState } from 'services/ProductFiltersService/useFiltersState';
import { findById, landingShowFilterTag } from 'utils';

import { filtersToFilterFunction, type Nullable } from '@yourxx/support';
import type { GetCustomerListResponse } from '@yourxx/types';

import type { UseSidePanelReturn } from '../../Products';
import { useCreateCustomersFiltersService } from './createCustomersFiltersService';
import { customersFiltersSchema } from './customersFiltersSchema';

const getDefaultCustomersFilters = () => {
  const archivedFilter = findById('archived')(customersFiltersSchema);
  if (!archivedFilter || archivedFilter.type === FilterType.PriceRange) return [];
  return [archivedFilter.type === FilterType.Single ? archivedFilter.set(false) : archivedFilter.set([false])];
};
const customersDefaultFilterCombination = DefaultFilterCombination(
  'customers-default-filter-combination',
  getDefaultCustomersFilters
);

export const useCustomersFilters = ({
  customers,
  sidePanel
}: {
  sidePanel: UseSidePanelReturn<'filters'>;
  customers: Nullable<ReadonlyArray<GetCustomerListResponse>> | undefined;
}) => {
  const [str] = useLocalisation();
  const { customerId } = useParams();

  const filtersState = useFiltersState({
    service: useCreateCustomersFiltersService(),
    eventBus: useServices().eventBus,
    context: undefined,
    defaultFilterCombination: customersDefaultFilterCombination
  });

  const filterOptions = useMemo<FilterOptions>(
    () =>
      buildFilterOptions({
        schema: customersFiltersSchema,
        ordering: filtersState.filtersOrder,
        items: customers ?? [],
        labeler: (filterId, optionValue) => {
          switch (filterId) {
            case 'regionName':
              switch (optionValue) {
                case 'LSE':
                case 'LSA':
                case 'AMA':
                case 'ANZ':
                case 'NEU':
                case 'SEU':
                case 'SEA':
                case 'GCH':
                case 'USA':
                case 'CAN':
                case 'CNF':
                case 'KOR':
                case 'JPN':
                case 'LAT':
                case 'MEA':
                case 'ZAF':
                  return str(`Clusters.${optionValue}`);
                default:
                  return String(optionValue);
              }
            case 'archived':
              return str(optionValue ? 'general.archived' : 'general.active');
            default:
              return String(optionValue);
          }
        }
      }),
    [customers, filtersState.filtersOrder, str]
  );

  const filterCustomersFn = useMemo(
    () => filtersToFilterFunction(filtersState.activeCombination.filters),
    [filtersState.activeCombination.filters]
  );

  return {
    filterCustomersFn,
    ui: {
      button: (
        <FilterButton
          label={str('ProductListing.filters.title')}
          onClick={() => {
            if (!sidePanel.is('filters')) {
              landingShowFilterTag({ customerId });
            }
            sidePanel.set('filters');
          }}
          isActive={sidePanel.is('filters')}
          counter={filtersState.filtersCount}
        />
      ),
      panel: (
        <FilterSlideOut
          allowReordering={false}
          allowFilterCombinations={false}
          onClose={() => sidePanel.set('filters')}
          state={filtersState}
          filterOptions={filterOptions}
          isOpen={sidePanel.is('filters')}
        />
      )
    }
  };
};
