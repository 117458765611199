import { LocalStorageItems } from '@yourxx/types';
import { persistedStore } from '@yourxx/ui-utils';

export const useAuth = () => {
  const checkAuth = () => {
    if (persistedStore.getAccessToken()) {
      return true;
    }
    persistRedirect();
    return false;
  };

  const persistRedirect = () => {
    const { pathname } = window.location;
    if (!pathname.includes('/login') && !pathname.includes('/auth/')) {
      persistedStore.setLoginRedirect();
    }
  };
  const getRedirect = () => window.localStorage.getItem(LocalStorageItems.redirectUrl);

  return { checkAuth, persistRedirect, getRedirect };
};
