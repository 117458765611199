import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { type Route, routes } from 'routes';

import type { ViewRoute } from '../ViewRoute';

type UseRouteReturn<R extends Route> = {
  route: R | undefined;
  params: R extends ViewRoute<infer P> ? P : undefined;
};

export const useRoute = <R extends Route>(): UseRouteReturn<R> => {
  const { pathname } = useLocation();
  const { route, params } = useMemo(() => {
    for (const route of Object.values(routes)) {
      const match = matchPath({ path: route.toString() }, pathname);
      if (match) return { route: route.with(match.params), params: match.params };
    }

    return { route: undefined, params: undefined };
  }, [pathname]);

  return { route, params } as UseRouteReturn<R>;
};
