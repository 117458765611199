import { Plus, Remove } from 'assets/icons';
import { Button } from 'components/Button';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { Tooltip } from 'components/Tooltip';
import { useCustomersData, useLocalisation } from 'providers';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { useServices } from 'services';
import styled from 'styled-components';
import { insightsAddedClickTag, insightsAddToAssortmentClickTag, insightsRemovePC9ClickTag, rem, themed } from 'utils';

import { InsightItem, RecommendationItem } from '@yourxx/types';
import { addProducts, InsightStatus, InsightType, rejectProducts, removeInsight } from '@yourxx/ui-utils';

import { useGetInsights, useInsightKey } from '../hooks';
import { ProductList } from './ProductsList';

const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${themed('spacing.l')} ${themed('spacing.xl')};
  background-color: ${themed('color.white')};
  border-top: solid ${rem(1)} ${themed('color.offWhite')};
  position: relative;
  z-index: 1;
`;
const HeaderText = styled.span`
  font-size: ${themed('font.size.m')};
  font-weight: ${themed('font.weight.semiBold')};
  line-height: 1.2;
  letter-spacing: ${rem(1.2)};
  padding-right: ${themed('spacing.l')};
`;
const Menu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    font-weight: ${themed('font.weight.medium')};
    font-size: ${themed('font.size.s')};
    align-items: center;
    padding: 0;
  }
  svg {
    width: ${themed('spacing.xl')};
    height: ${themed('spacing.xl')};
  }
`;
const ContentBody = styled.div`
  width: 100%;
`;
const ProductInfo = styled.div`
  margin: ${themed('spacing.l')};
  border-radius: ${themed('spacing.l')};
  background-color: ${themed('color.white')};
  position: relative;
  overflow: hidden;
`;
const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  inset: 0;
  background-color: ${themed('color.transparentLightBlack')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ToastMessage = styled.p`
  font-size: ${themed('font.size.m')};
  margin-top: ${themed('spacing.xs')};
`;
const ToastLink = styled.p`
  display: inline;
  color: ${themed('color.white')};
  font-size: ${themed('font.size.l')};
  font-weight: ${themed('font.weight.semiBold')};
  margin-bottom: ${themed('spacing.m')};
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AccordionContent = ({ insightItem }: { insightItem: InsightItem }) => {
  const [str] = useLocalisation();
  const navigate = useNavigate();
  const { toastService } = useServices();
  const { getInsights } = useGetInsights();
  const { getInsightKey } = useInsightKey();
  const { clearCache, loadProductsFor } = useCustomersData();

  const [updatingInsight, setUpdatingInsight] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<RecommendationItem[]>([]);

  const { brandName } = useParams();
  const assortmentUrl: string | undefined = useMemo(
    () =>
      selectedProducts[0]?.assortmentUrl
        ? selectedProducts[0].assortmentUrl
        : routes?.assortmentProducts
            .with({
              brandName,
              customerId: insightItem.customer.customer_id,
              assortmentId: insightItem.assortmentId,
              productPageType: 'products',
              productListMode: 'grid'
            })
            .toString(),
    [brandName, selectedProducts, routes, insightItem]
  );

  const addSelected = useCallback(() => {
    insightsAddToAssortmentClickTag({
      customerId: insightItem.customer.customer_id,
      assortmentId: insightItem.assortmentId,
      recommendationId: getInsightKey(insightItem),
      pc9Code: selectedProducts.map(({ pc9 }) => pc9).join(', ')
    });
    if (selectedProducts?.length) {
      setUpdatingInsight(true);
      addProducts(
        insightItem.type,
        insightItem.assortmentId,
        insightItem.timestamp,
        insightItem.items[0].lineId,
        selectedProducts.map(({ pId }) => pId),
        selectedProducts.map(({ ordinal }) => ordinal)
      )
        .then(async ({ results }) => {
          const insight = insightItem.type === InsightType.SUGGESTION ? results.suggestion : results.recommendation;
          if (insight?.items[0].length === insightItem.items.length) {
            await removeInsight(insightItem.assortmentId, insightItem.timestamp).catch(e => console.error(e));
          }
          getInsights({
            handleLoading: loading => setUpdatingInsight(loading),
            handleSuccess: () => {
              setSelectedProducts([]);
              toastService.send(
                <>
                  <ToastMessage>
                    {str('Insights.productsAddedToAssortmentFromParentLine', { count: insight?.items[0].length || 0 })}
                  </ToastMessage>
                  {assortmentUrl && (
                    <ToastLink onClick={() => navigate(assortmentUrl)}>
                      {str('Insights.viewInAssortment', { name: insightItem.assortmentName })}
                    </ToastLink>
                  )}
                </>
              );
            }
          });

          clearCache({ id: insightItem.assortmentId, tag: 'assortment' });
          loadProductsFor(insightItem.assortmentId, 'assortment');
          insightsAddedClickTag({
            customerId: insightItem.customer.customer_id,
            assortmentId: insightItem.assortmentId,
            recommendationId: getInsightKey(insightItem)
          });
        })
        .catch(e => {
          console.error(e);
          setUpdatingInsight(false);
          setSelectedProducts([]);
          toastService.send(str('Insights.addingProductsError', { count: selectedProducts.length }), 'error');
        });
    }
  }, [
    str,
    selectedProducts,
    insightsAddToAssortmentClickTag,
    insightsAddedClickTag,
    addProducts,
    insightItem,
    removeInsight,
    getInsights,
    setUpdatingInsight,
    setSelectedProducts,
    toastService
  ]);

  const rejectSelected = useCallback(() => {
    insightsRemovePC9ClickTag({
      customerId: insightItem.customer.customer_id,
      assortmentId: insightItem.assortmentId,
      recommendationId: getInsightKey(insightItem),
      pc9Code: selectedProducts.map(({ pc9 }) => pc9).join(', ')
    });
    if (selectedProducts?.length) {
      setUpdatingInsight(true);
      rejectProducts(
        insightItem.assortmentId,
        insightItem.timestamp,
        selectedProducts.map(({ ordinal }) => ordinal)
      )
        .then(async ({ results }) => {
          const insight = insightItem.type === InsightType.SUGGESTION ? results.suggestion : results.recommendation;
          if (insight?.items[0].length === insightItem.items.length) {
            await removeInsight(insightItem.assortmentId, insightItem.timestamp).catch(e => console.error(e));
          }
          getInsights({
            handleLoading: loading => setUpdatingInsight(loading),
            handleSuccess: () => {
              setSelectedProducts([]);
              const productsUpdated = insight?.items[0].length || 0;
              toastService.send(str('Insights.productsRejected', { count: productsUpdated }));
            }
          });
        })
        .catch(e => {
          console.error(e);
          setUpdatingInsight(false);
          setSelectedProducts([]);
          toastService.send(str('Insights.rejectingProductsError', { count: selectedProducts.length }), 'error');
        });
    }
  }, [
    str,
    selectedProducts,
    insightsRemovePC9ClickTag,
    insightItem,
    getInsightKey,
    setUpdatingInsight,
    rejectProducts,
    removeInsight,
    getInsights,
    setSelectedProducts,
    toastService
  ]);

  return (
    <>
      <ContentHeader>
        <HeaderText>
          {insightItem.type === InsightType.SUGGESTION && insightItem.storyName
            ? insightItem.storyName
            : insightItem.items?.length > 1
              ? `${str('Insights.pc9sHaveProvenTheir', { count: insightItem.items.length })} `
              : `${str('Insights.pc9HasProvenIts')} `}
          {insightItem.type === InsightType.SUGGESTION
            ? ''
            : str('Insights.popularityInAssortments', { name: insightItem.assortmentName })}
        </HeaderText>
        {insightItem.status !== InsightStatus.REMOVED && (
          <Menu>
            <Tooltip
              text={str('Insights.addToAssortmentHint', { name: insightItem.assortmentName })}
              position="bottom"
              disabled={updatingInsight || selectedProducts.length <= 0}
            >
              <Button onClick={addSelected} disabled={updatingInsight || selectedProducts.length <= 0}>
                <Plus /> {str('Insights.addToAssortment')}
              </Button>
            </Tooltip>
            {insightItem.type !== InsightType.SUGGESTION && (
              <Tooltip
                text={str('Insights.removeRecommendationHint')}
                position="bottom"
                disabled={updatingInsight || selectedProducts.length <= 0}
              >
                <Button onClick={rejectSelected} disabled={updatingInsight || selectedProducts.length <= 0}>
                  <Remove /> {str('Insights.removeRecommendation')}
                </Button>
              </Tooltip>
            )}
          </Menu>
        )}
      </ContentHeader>
      <ContentBody>
        <ProductInfo>
          {updatingInsight && (
            <LoadingWrapper>
              <LoadingSpinner label={str('Insights.updating')} />
            </LoadingWrapper>
          )}
          <ProductList
            insightItem={insightItem}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        </ProductInfo>
      </ContentBody>
    </>
  );
};
