import { UserAlt } from 'assets/icons';
import { Image } from 'components/Image';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Root = styled.div<{ onClick?(): void; $size?: number }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  user-select: none;
  width: ${({ $size }) => ($size ? rem($size) : rem(30))};
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: ${themed('color.greyMid')};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  img {
    max-width: 100%;
  }

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const Avatar = ({
  className,
  onClick,
  avatarSrc,
  size
}: {
  className?: string;
  onClick?: VoidFunction;
  avatarSrc?: string;
  size?: number;
}) => (
  <Root className={className} onClick={onClick} $size={size}>
    {avatarSrc ? <Image src={avatarSrc} /> : <UserAlt />}
  </Root>
);
