import { EditableTabs } from 'components/EditableTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { canvasComparisonTabTag, canvasHomeTag, useIAM } from 'utils';

import { useCurrentCanvasContext } from './CurrentCanvasContext';
import { useCanvas, useCreateBoard, useDeleteBoard, useUpdateBoard } from './hooks';

export type BoardListTabsProps = {
  showAddIcon?: boolean;
  showCloseIcon?: boolean;
  canEditTabName?: boolean;
};

export const BoardsListTabs = ({
  showAddIcon = true,
  showCloseIcon = true,
  canEditTabName = true
}: BoardListTabsProps) => {
  const { customerId, assortmentId, lineId, canvasBoardId } = useCurrentCanvasContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { boards } = useCanvas();
  const { canUse } = useIAM();
  const { createBoard } = useCreateBoard({ customerId, assortmentId, lineId });
  const { deleteBoard, isDeletingBoard } = useDeleteBoard();
  const { updateBoard } = useUpdateBoard();
  const iamPrefix = 'products.canvas';
  const onSelect = (id?: string) => {
    if (id) {
      canvasComparisonTabTag({
        assortmentId,
        customerId,
        comparisonId: id
      });
    } else {
      canvasHomeTag({ assortmentId, customerId });
    }
    navigate(`${pathname.split('/canvas/comparator')[0]}${id ? `/canvas/comparator/${id}` : '/canvas/comparator'}`);
  };

  return (
    <EditableTabs
      tabs={boards.map(b => ({ name: b.name, id: b.boardId }))}
      selectedTab={canvasBoardId}
      disabledTab={isDeletingBoard}
      onSelect={onSelect}
      onRename={(boardId, name) => canUse(`editBoard`, iamPrefix) && updateBoard({ boardId, name })}
      onClose={boardId => canUse(`deleteBoard`, iamPrefix) && deleteBoard({ boardId })}
      onAdd={createBoard}
      showAddIcon={showAddIcon}
      showCloseIcon={showCloseIcon}
      canEditTabName={canEditTabName}
    />
  );
};
