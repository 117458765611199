import { Filter as FilterIcon } from 'assets/icons';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { Button } from '../Button';

const StyledButton = styled(Button)<{ $isOpen?: boolean }>`
  position: relative;
  height: ${rem(34)};
  background-color: ${({ $isOpen }) => ($isOpen ? themed('color.black') : 'transparent')};
  color: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.medium')};

  svg {
    width: ${rem(22)};
    height: ${rem(22)};

    * {
      stroke: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};
      stroke-width: ${rem(1.5)};
    }
  }
`;
const CounterBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: ${themed('borderRadius')};
  padding: ${rem(2)} ${themed('spacing.m')} ${rem(1)};
  background-color: ${themed('color.greyDark')};
  color: ${themed('color.white')};
  ${themed('typography.h5')};
  transform: translate3d(50%, -50%, 0);

  &::before {
    content: attr(data-count);
  }
`;

export const FilterButton = ({
  label,
  onClick,
  isActive,
  counter
}: {
  label?: React.ReactNode;
  onClick: VoidFunction;
  isActive?: boolean;
  counter?: number;
}) => (
  <StyledButton onClick={onClick} $isOpen={isActive}>
    <FilterIcon />
    {label}
    {!!counter && <CounterBadge data-count={counter} />}
  </StyledButton>
);
