import { type ReactNode, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { useSlots } from './useSlots';

/**
 * IMPORTANT!!! Make sure your wrap your ReactNode `content` with a `useMemo` to avoid continuous re-rendering.
 */
export const useSlot = (slotId: string, content: ReactNode, position = 0) => {
  const { renderInSlot } = useSlots();

  // This hook can be called multiple times with the same slotId, so we're assigning each call a unique identifier
  // so that we know what to render and what to unmount when the content updates.
  const slotInstanceId = useRef(uuid());

  useEffect(() => {
    const currentId = slotInstanceId.current;
    return renderInSlot(slotId, currentId, content, position);
  }, [content, position, renderInSlot, slotId]);
};
