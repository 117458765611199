import { ImageMissing } from 'assets/icons';
import { forwardRef, ImgHTMLAttributes, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { rem, themed } from 'utils';

const NoImage = styled.div`
  background-color: ${themed('color.greyLight')};
  color: ${themed('color.greyMid')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 1;

  svg {
    width: 80%;
    height: 80%;
    max-width: ${rem(100)};
    max-height: ${rem(100)};
  }
`;
const sheen = keyframes`
   to { background-position-x: -200%; }
`;
const Img = styled.img<{ $isLoading?: boolean }>`
  background: ${({ theme, $isLoading }) =>
    $isLoading
      ? `linear-gradient(110deg, ${theme.color.greyLight} 18%, ${theme.color.offWhite} 28%, ${theme.color.greyLight} 43%)`
      : 'none'};
  background-size: 200% 100%;
  animation: 2s ${sheen} linear infinite;
`;

export const Image = forwardRef<HTMLImageElement, ImgHTMLAttributes<HTMLImageElement>>((props, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  if (!props.src || error) {
    return (
      <NoImage>
        <ImageMissing />
      </NoImage>
    );
  }
  return (
    <Img
      {...props}
      ref={ref}
      onError={() => setError(true)}
      onLoad={() => setIsLoading(false)}
      $isLoading={isLoading}
    />
  );
});
