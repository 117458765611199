import { useCallback, useState } from 'react';
import type { CustomersService, EventBus } from 'services';
import { v4 as uuid } from 'uuid';

import { useMachine } from '@xstate/react';
import { Defect, type Nullable } from '@yourxx/support';
import {
  type ApiGetProductOut,
  GetCustomerAssortmentResponse,
  type GetCustomerListResponse,
  ProductListData,
  type UIProduct
} from '@yourxx/types';

import {
  Action,
  customersStateMachine,
  FailedLoadingAssortments,
  FailedLoadingProducts,
  type LoadAssortmentsCommand,
  type LoadProductsCommand,
  State
} from './customers-state-machine';
import {
  CustomerAssortmentsFailed,
  CustomerAssortmentsLoaded,
  CustomerAssortmentsLoading,
  CustomerAssortmentsRequested,
  ProductsDataLoaded,
  ProductsDataLoading,
  ProductsDataLoadingFailed,
  ProductsDataRequested
} from './events';

export type Props = {
  service: CustomersService;
  eventBus: EventBus;
  brand: string;
};

export type ProductCacheTag =
  | 'customerAssortments'
  | 'assortment'
  | 'removedFromAssortment'
  | 'droppedAndRemoved'
  | 'parentLine'
  | 'parentLineDroppedAndRemoved'
  | 'line';

export type Return = {
  isLoading: boolean;
  error: Nullable<Error>;
  customers: Nullable<ReadonlyArray<GetCustomerListResponse>>;
  loadCustomers(): void;
  loadAssortmentsFor(customerId: string, traceId?: string): void;
  assortmentsFor(customerId: string): Nullable<GetCustomerAssortmentResponse>;
  loadProductsFor(assortmentOrLineId: string, tag: ProductCacheTag, traceId?: string): void;
  productsFor(assortmentOrLineId: string, tag: ProductCacheTag): Nullable<ApiGetProductOut<UIProduct<ProductListData>>>;
  retry: VoidFunction;
  cancel: VoidFunction;
  clearCache(...command: { id: string; tag: ProductCacheTag }[]): void;
  setPricingGroupId(id?: string): void;
  pricingGroupId?: string;
};

export type UseCustomersReturn = Return;

export const useCustomers = ({ service, brand, eventBus }: Props): Return => {
  const [pricingGroupId, setPricingGroupId] = useState<string | undefined>();
  const loadCustomersData = useCallback(() => service.customers({ brand }), [brand, service]);

  const loadAssortmentsData = useCallback(
    (command: LoadAssortmentsCommand) => {
      const customerAssortmentsLoading = new CustomerAssortmentsLoading({ command });
      const { customerId, traceId } = command;

      if (traceId) customerAssortmentsLoading.trace(traceId);
      eventBus.emit(customerAssortmentsLoading);

      return service
        .assortmentsFor({ brand, customerId })
        .then(response => {
          eventBus.emit(new CustomerAssortmentsLoaded({ command }).trace(customerAssortmentsLoading));
          return response;
        })
        .catch(error => {
          eventBus.emit(new CustomerAssortmentsFailed({ command, error }).trace(customerAssortmentsLoading));
          throw new FailedLoadingAssortments(customerId);
        });
    },
    [brand, eventBus, service]
  );

  const loadProductsData = useCallback(
    (command: LoadProductsCommand) => {
      const { assortmentOrLineId, tag, traceId } = command;
      const params: Parameters<CustomersService['productsFor']>[0] = { assortmentOrLineId };
      if (tag === 'parentLine' || tag === 'parentLineDroppedAndRemoved') params.parentLine = true;
      if (tag === 'removedFromAssortment') params.removed = true;
      if (tag === 'droppedAndRemoved' || tag === 'parentLineDroppedAndRemoved') {
        params.removed = true;
        params.dropped = true;
      }
      if (tag === 'line' && pricingGroupId) params.pricingGroupId = pricingGroupId;

      // TODO: Move events and errors to service and test there.
      const productsDataLoading = new ProductsDataLoading({ command });
      if (traceId) productsDataLoading.trace(traceId);
      eventBus.emit(productsDataLoading);

      return service
        .productsFor(params)
        .then(response => {
          eventBus.emit(new ProductsDataLoaded({ command }).trace(productsDataLoading));
          return response;
        })
        .catch(error => {
          eventBus.emit(new ProductsDataLoadingFailed({ command, error }).trace(productsDataLoading));
          throw new FailedLoadingProducts(assortmentOrLineId, tag, error);
        });
    },
    [eventBus, pricingGroupId, service]
  );

  const [state, send] = useMachine(customersStateMachine, {
    services: {
      load(context, event) {
        switch (context.pendingCommand?.type) {
          case 'loadCustomers':
            return loadCustomersData();
          case 'loadAssortments':
            return loadAssortmentsData(context.pendingCommand);
          case 'loadProducts':
            return loadProductsData(context.pendingCommand);
          default:
            throw new Defect(`Unexpected event type "${event.type}" when attempting to load data.`);
        }
      }
    }
  });

  const loadCustomers = useCallback<Return['loadCustomers']>(() => {
    send({ type: Action.Load, command: { type: 'loadCustomers' } });
  }, [send]);

  const loadAssortmentsFor = useCallback<Return['loadAssortmentsFor']>(
    (customerId, traceId = uuid()) => {
      const command: LoadAssortmentsCommand = { type: 'loadAssortments', customerId, traceId };
      const event = new CustomerAssortmentsRequested({ command }).trace(traceId);
      eventBus.emit(event);
      send({ type: Action.Load, command: command });
    },
    [eventBus, send]
  );

  const cancel = useCallback(() => send({ type: Action.Cancel }), [send]);

  const loadProductsFor = useCallback<Return['loadProductsFor']>(
    (assortmentOrLineId, tag, traceId = uuid()) => {
      const command: LoadProductsCommand = { type: 'loadProducts', assortmentOrLineId, tag, traceId };
      const event = new ProductsDataRequested({ command }).trace(traceId);
      eventBus.emit(event);
      send({ type: Action.Load, command });
    },
    [eventBus, send]
  );

  const assortmentsFor = useCallback<Return['assortmentsFor']>(
    customerId => state.context.assortmentsByCustomerId[customerId] ?? null,
    [state.context.assortmentsByCustomerId]
  );

  const productsFor = useCallback<Return['productsFor']>(
    (assortmentOrLineId, maybeTag) => {
      if (maybeTag) return state.context.productsByTag[maybeTag]?.[assortmentOrLineId] ?? null;

      for (const tag in state.context.productsByTag)
        if (state.context.productsByTag[tag]?.[assortmentOrLineId])
          return state.context.productsByTag[tag]?.[assortmentOrLineId];

      return null;
    },
    [state.context.productsByTag]
  );

  const retry = useCallback<Return['retry']>(() => {
    send({ type: Action.Retry });
  }, [send]);

  const clearCache = useCallback<Return['clearCache']>(
    (...commands) => {
      send({ type: Action.ClearCache, commands });
    },
    [send]
  );

  return {
    isLoading: state.value === State.Loading || state.value === State.CheckQueue,
    customers: state.context.customers,
    loadCustomers,
    loadAssortmentsFor,
    loadProductsFor,
    assortmentsFor,
    productsFor,
    error: state.context.error,
    retry,
    cancel,
    clearCache,
    setPricingGroupId,
    pricingGroupId
  };
};
