import { ExportProductsOutsideDistroBlocks } from '@yourxx/types/src/postgres';

import { apiEndpoints, toRequest } from '../../utils';
import { apiClient } from '../client';

export type ExportResponse = { error?: string; url?: string; resource?: string; Key?: string };

export const getAdminProductDistroReport = async (
  payload: ExportProductsOutsideDistroBlocks
): Promise<ExportResponse> => {
  return pollExport(toRequest({ path: apiEndpoints.export, method: 'POST', body: payload }));
};

type BatchExport = {
  exportType: 'assortment' | 'line';
  exportFormat: 'csv' | 'pdf' | 'excel';
  brand: string;
  season: string;
  region: string;
};
export const getBatchReport = async (payload: BatchExport): Promise<ExportResponse> => {
  return pollExport(toRequest({ path: `/export/batch`, method: 'POST', body: payload }), 100);
};

export const pollExport = async (request: Request, loopLimit: number = 20): Promise<ExportResponse> => {
  let response = await apiClient.request<ExportResponse>(request);
  for (let i = 0; i < loopLimit; i++) {
    if (response.resource) {
      return response;
    }
    if (response.error) {
      return response;
    }
    const request = toRequest({ path: response.url ?? '', method: 'GET' });
    response = await apiClient.request<ExportResponse>(request);
  }
  return { error: 'Export took too long' };
};
