import { type ContextOf, Entity, type EntityContext } from 'domain-events';
import { useCallback, useEffect, useState } from 'react';
import type { AssortmentsService, CopyAssortmentCommand } from 'services';

import { Defect } from '@yourxx/support';

import { CopyAssortmentState } from './CopyAssortmentState';

export enum State {
  Idle = 'Idle',
  RequestedCopy = 'RequestedCopy',
  Copying = 'Copying'
}

export interface UseCopyAssortmentProps {
  context: EntityContext | undefined;
  service: AssortmentsService;
  labeler: (optionId: string) => string;
}

export type UseCopyAssortmentReturn =
  | {
      state: State.Idle;
      requestCopy: VoidFunction;
    }
  | {
      state: State.RequestedCopy;
      form: CopyAssortmentState;
      updateForm: (form: CopyAssortmentState) => void;
      cancel: VoidFunction;
      confirm: VoidFunction;
    }
  | {
      state: State.Copying;
      form: CopyAssortmentState;
    };

export const useCopyAssortment = ({ service, context, labeler }: UseCopyAssortmentProps): UseCopyAssortmentReturn => {
  const [state, setState] = useState(State.Idle);
  const [form, setForm] = useState(CopyAssortmentState({ assortmentName: context?.name ?? '', labeler }));

  useEffect(() => {
    if (!context || (context && context.name === form.inheritedAssortmentName)) return;
    setForm(CopyAssortmentState({ assortmentName: context.name, labeler }));
  }, [context, form.inheritedAssortmentName, labeler]);

  const requestCopy = useCallback(() => setState(State.RequestedCopy), []);

  const clearCustomers = useCallback(() => {
    const formWithoutCustomers = form.toggleCustomer(...form.targetCustomers);
    setForm(formWithoutCustomers);
  }, [form]);

  const cancel = useCallback(() => {
    setState(State.Idle);
    clearCustomers();
  }, [clearCustomers]);

  const confirm = useCallback(() => {
    if (!context) throw new Defect('Attempted to confirm copying an assortment without a context.');
    if (context.entity !== Entity.Assortment && context.entity !== Entity.FinalAssortment)
      throw new Defect(
        `Attempted to confirm copying an assortment with the wrong context entity type: ${context.entity}.`
      );

    setState(State.Copying);
    service
      .handle(commandFromState(form, context))
      .then(() => {
        setState(State.Idle);
        clearCustomers();
      })
      .catch(() => setState(State.RequestedCopy));
  }, [clearCustomers, context, form, service]);

  if (state === State.RequestedCopy)
    return {
      state,
      form,
      updateForm: setForm,
      cancel,
      confirm
    };

  if (state === State.Copying)
    return {
      state,
      form
    };

  return {
    state,
    requestCopy
  };
};

const commandFromState = (
  form: CopyAssortmentState,
  context: ContextOf<Entity.Assortment | Entity.FinalAssortment>
): CopyAssortmentCommand => ({
  context,
  assortmentName: form.assortmentName || context.name,
  targetCustomers: form.targetCustomers.map(({ customerId }) => customerId),
  copyOptions: form.copyOptions.filter(({ isSelected }) => isSelected).map(({ id }) => id)
});
