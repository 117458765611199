import { AnimatePresence } from 'framer-motion';
import { useOutlet } from 'react-router-dom';

export const MotionOutlet = ({ animate: _animate }: { animate?: boolean }) => {
  const element = useOutlet();

  return <AnimatePresence mode="wait">{element}</AnimatePresence>;

  // TODO: Revisit this method. It seems to cause issues with the router history.
  // return (
  //   <AnimatePresence mode="wait">
  //     {animate ? element && cloneElement(element, { key: location.pathname }) : element}
  //   </AnimatePresence>
  // );
};
