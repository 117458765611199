import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { rem, themed } from 'utils';

const TooltipWrapper = styled.div<{ $disabled: boolean }>`
  position: relative;
  display: inline-flex;
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`;
const TooltipTarget = styled.div<{ $showOnFocus: boolean; $rounded?: boolean; $noPadding?: boolean }>`
  background-color: inherit;
  padding: ${({ $rounded, $noPadding }) => ($noPadding ? 0 : $rounded ? rem(15) : rem(5))};
  margin: ${({ $rounded, $noPadding }) => ($noPadding ? 0 : $rounded ? rem(1) : rem(-1))};
  border-style: ${({ $rounded }) => ($rounded ? 'solid' : 'none')};
  border-color: ${themed('color.grey')};
  border-width: ${rem(1)};
  border-radius: ${({ $rounded }) => ($rounded ? rem(5) : 'inherit')};
  font-size: ${({ $rounded }) => ($rounded ? themed('font.size.xl') : 'inherit')};
  outline: ${({ $showOnFocus }) => ($showOnFocus ? 'none' : 'inherit')};
  color: inherit;
  display: flex;
  cursor: inherit;
`;
const CenterContainer = styled.div<{ $position: string; $offsetX: number; $offsetY: number }>`
  position: absolute;
  z-index: 1;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: calc(100% + ${rem(5)});
  transform: translateX(calc(-50% + ${({ $offsetX }) => rem($offsetX)})) translateY(${({ $offsetY }) => rem($offsetY)});
  pointer-events: none;

  ${({ $position, $offsetX, $offsetY }) => {
    switch ($position) {
      case 'bottom':
        return css`
          bottom: unset !important;
          top: calc(100% + ${rem(5)});
        `;
      case 'left':
        return css`
          margin-right: 0;
          width: 100%;
          left: unset;
          top: 50%;
          right: calc(100% + ${rem(5)});
          width: max-content;
          transform: translateX(${rem($offsetX)}) translateY(${rem($offsetY)});
        `;
      case 'right':
        return css`
          margin-left: 0;
          width: 100%;
          top: 50%;
          left: calc(100% + ${rem(5)});
          width: max-content;
          transform: translateX(${rem($offsetX)}) translateY(${rem($offsetY)});
        `;
      default:
        return css`
          bottom: calc(100% + ${rem(5)});
        `;
    }
  }}
`;
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const TooltipBox = styled.span<{ $position: string; $offsetX: number; $offsetY: number }>`
  position: relative;
  background-color: ${themed('color.black')};
  color: ${themed('color.white')};
  text-align: center;
  border-radius: ${themed('spacing.s')};
  padding: ${rem(12)};
  box-shadow: ${rem(0, 4, 8)} ${themed('color.transparentLightBlack')};
  font-weight: ${themed('font.weight.regular')};
  font-size: ${themed('font.size.s')};
  line-height: ${themed('spacing.l')};
  display: flex;
  align-items: center;
  letter-spacing: ${rem(0.6)};
  animation: ${fadeIn} 0.2s linear;
  white-space: pre-line;

  &:after {
    content: '';
    position: absolute;
    width: ${rem(1)};
    height: ${rem(1)};
    border-width: ${rem(5)};
    border-style: solid;
    border-color: ${themed('color.black')} transparent transparent transparent;
    left: calc(50% - ${rem(5)});
    top: 100%;
  }

  ${({ $position, $offsetX }) => {
    switch ($position) {
      case 'bottom':
        return css`
          &:after {
            border-color: transparent transparent ${themed('color.black')} transparent;
            top: unset;
            width: ${rem(1)};
            bottom: 100%;
            left: calc(50% - ${rem(5)} - ${$offsetX}px);
          }
        `;
      case 'left':
        return css`
          &:after {
            border-color: transparent transparent transparent ${themed('color.black')};
            left: 100%;
            top: calc(50% - ${rem(5)});
          }
        `;
      case 'right':
        return css`
          &:after {
            border-color: transparent ${themed('color.black')} transparent transparent;
            right: 100%;
            left: unset;
            top: calc(50% - ${rem(5)});
          }
        `;
      default:
        return css``;
    }
  }}
`;

export type TooltipPosition = 'left' | 'right' | 'top' | 'bottom';
export type TooltipProps = {
  className?: string;
  position?: TooltipPosition;
  text?: string;
  textComponent?: React.ReactNode;
  children: React.ReactNode;
  rounded?: boolean;
  disabled?: boolean;
  disableTooltipOnly?: boolean;
  disableOnMouseDown?: boolean;
  showOnFocus?: boolean;
  noPadding?: boolean;
  offsetX?: number;
  offsetY?: number;
};

export const Tooltip = ({
  className,
  text,
  textComponent,
  children,
  rounded = false,
  position = 'top',
  showOnFocus = false,
  disabled = false,
  disableTooltipOnly = false,
  disableOnMouseDown = false,
  noPadding = false,
  offsetX = 0,
  offsetY = 0
}: TooltipProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isVisible, setVisibility] = useState<boolean>(false);
  const targetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!disableTooltipOnly) {
      if (disableOnMouseDown) {
        setVisibility(!isClicked && (isHovered || (showOnFocus && isFocused)));
      } else {
        setVisibility(isHovered || (showOnFocus && isFocused));
      }
    } else {
      setVisibility(false);
    }
  }, [isHovered, isFocused, isClicked, disableTooltipOnly, disableOnMouseDown, showOnFocus]);

  return (
    <TooltipWrapper className={className} $disabled={disabled}>
      <TooltipTarget
        ref={targetRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={() => setIsClicked(true)}
        onMouseUp={() => setIsClicked(false)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        $rounded={rounded}
        $showOnFocus={showOnFocus}
        $noPadding={noPadding}
      >
        {children}
      </TooltipTarget>
      {isVisible && (text || textComponent) && (
        <CenterContainer $position={position} $offsetX={offsetX} $offsetY={offsetY}>
          <TooltipBox $position={position} $offsetX={offsetX} $offsetY={offsetY}>
            {text && !textComponent && text}
            {textComponent && !text && textComponent}
          </TooltipBox>
        </CenterContainer>
      )}
    </TooltipWrapper>
  );
};
