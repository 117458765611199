import { useCallback, useLayoutEffect, useState } from 'react';

export const useSynchedScroll = ({ shouldSync = () => true }: { shouldSync?: (event: Event) => boolean } = {}) => {
  const [els, setEls] = useState<Set<HTMLElement>>(new Set());
  const register = useCallback(
    <E extends HTMLElement>(el: E) => {
      if (!els.has(el)) setEls(new Set(Array.from(els).concat(el)));
    },
    [els]
  );

  useLayoutEffect(() => {
    const subscriptions: VoidFunction[] = [];
    for (const el of els) {
      const handler = (event: Event) => {
        if (!shouldSync(event)) return;
        for (const oel of els) if (oel !== el) oel.scrollTop = el.scrollTop;
      };
      el.addEventListener('scroll', handler);
      subscriptions.push(() => el.removeEventListener('scroll', handler));
    }

    return () => {
      for (const unsubscribe of subscriptions) unsubscribe();
    };
  }, [els, shouldSync]);

  return {
    register
  };
};
