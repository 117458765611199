import { useCustomersData } from 'providers';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { UpdateAssortmentPayload } from '@yourxx/types';
import { updateAssortment as updateAssortmentApi } from '@yourxx/ui-utils';

export const useUpdateAssortment = () => {
  const { assortmentId } = useParams();
  const { loadProductsFor, clearCache } = useCustomersData();

  const updateAssortment = useCallback(
    async ({
      handleLoading,
      handleSuccess,
      handleError,
      handleFinally,
      attributes
    }: {
      handleLoading?: (loading: boolean) => void;
      handleSuccess?: VoidFunction;
      handleError?: VoidFunction;
      handleFinally?: VoidFunction;
      attributes: UpdateAssortmentPayload;
    }) => {
      if (assortmentId) {
        handleLoading?.(true);
        // TODO: Move to service, emit appropriate event to handle cache clearing and products data reload.
        updateAssortmentApi({ id: assortmentId, data: attributes })
          .then(() => {
            clearCache({ id: assortmentId, tag: 'assortment' });
            // TODO: find a way to await loadProductsFor
            loadProductsFor(assortmentId, 'assortment');
          })
          .then(handleSuccess)
          .catch(e => {
            console.error(e);
            handleError?.();
          })
          .finally(handleFinally);
      }
    },
    [assortmentId, clearCache, loadProductsFor]
  );
  return { updateAssortment };
};
