import { createContext, type ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useServices } from 'services';

import { SearchSuggestionsCleared, SearchSuggestionsCreated } from './events';

interface SearchContexts {
  termsByContext: Record<string, string>;
  onTermChange(contextId: string, newTerm: string): void;
  onClear(contextId: string): void;
  suggestions?: readonly SearchSuggestion[];
  setSuggestions: (contextId: string, newSuggestions: SearchSuggestion[] | undefined) => void;
}

const ContextfulSearchContext = createContext<SearchContexts | null>(null);

export const ContextfulSearchProvider = ({ children }: { children?: React.ReactNode }) => {
  const { eventBus } = useServices();
  const [terms, setTerms] = useState<SearchContexts['termsByContext']>({});

  const onTermChange = useCallback<SearchContexts['onTermChange']>((contextId, newTerm) => {
    setTerms(prev => ({ ...prev, [contextId]: newTerm }));
  }, []);

  const onClear = useCallback<SearchContexts['onClear']>(contextId => {
    setTerms(prev => ({ ...prev, [contextId]: '' }));
  }, []);

  const setSuggestions = useCallback(
    (contextId: string, suggestions: SearchSuggestion[] | undefined) => {
      if (suggestions) eventBus.emit(new SearchSuggestionsCreated({ searchContext: contextId, suggestions }));
      else eventBus.emit(new SearchSuggestionsCleared({ searchContext: contextId }));
    },
    [eventBus]
  );

  return (
    <ContextfulSearchContext.Provider value={{ termsByContext: terms, onTermChange, onClear, setSuggestions }}>
      {children}
    </ContextfulSearchContext.Provider>
  );
};

export type SearchSuggestion = { label: string; renderedLabel?: ReactNode; onSelect: VoidFunction };

export const useContextfulSearch = ({ contextId = '' }: { contextId?: string }) => {
  const value = useContext(ContextfulSearchContext);

  if (!value)
    throw new ReferenceError(
      'Hook "useContextfulSearch" used outside its provider. Make sure <ContextfulSearchProvider /> is a parent to your components.'
    );

  const { onTermChange, onClear, setSuggestions, suggestions } = value;

  const changeTerm = useCallback(
    (newTerm: string) => {
      setSuggestions(contextId, undefined);
      onTermChange(contextId, newTerm);
    },
    [contextId, onTermChange, setSuggestions]
  );

  const clear = useCallback(() => {
    onClear(contextId);
  }, [contextId, onClear]);

  const addSuggestions = useCallback(
    (suggestions: SearchSuggestion[] | undefined) => {
      setSuggestions(contextId, suggestions);
    },
    [contextId, setSuggestions]
  );

  // TODO: Add disabled state when requested contextId is not recognised
  return useMemo(
    () => ({
      searchTerm: value.termsByContext[contextId] ?? '',
      changeTerm,
      clear,
      suggestions,
      addSuggestions
    }),
    [addSuggestions, changeTerm, clear, contextId, suggestions, value.termsByContext]
  );
};
