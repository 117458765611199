import { AuthUrls } from '@yourxx/types';

import { apiEndpoints, persistedStore, toRequest } from '../../utils';

export const getLogout = async (): Promise<void> => {
  const request = toRequest({
    path: apiEndpoints.login('logout'),
    method: 'GET'
  });
  await fetch(request);
  return;
};

export const getLoginUrl = async (): Promise<AuthUrls> => {
  const login = persistedStore.getLoginUrl();
  const logout = persistedStore.getLogoutUrl();
  if (logout && login) {
    return { login, logout };
  }
  return getLoginUrls();
};

export const getLoginUrls = async (url?: string): Promise<AuthUrls> => {
  const request = toRequest({
    path: apiEndpoints.login(url),
    method: 'GET'
  });
  const response = await fetch(request);
  if (!response.ok) {
    return response.json();
  }
  const urls = (await response.json()) as { login: string; logout: string };
  persistedStore.setLoginUrl(urls.login);
  persistedStore.setLogoutUrl(urls.logout);
  return urls;
};
