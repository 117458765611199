import { useCallback, useEffect, useRef } from 'react';

export const useDebouncedFn = <F extends (...args: any[]) => any>(fn: F, delay = 200) => {
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      if (timeoutId.current != null) clearTimeout(timeoutId.current);
    };
  }, []);

  return useCallback(
    (...args: Parameters<F>) => {
      if (timeoutId.current != null) clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => fn(...args), delay);
    },
    [delay, fn]
  );
};
