import { format } from 'date-fns';
import { maxBy, minBy } from 'utils';

import { ProductDelivery } from '@yourxx/types';

export interface PhasingOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export const ymNumber = (date: Date): number => Number(format(date, 'yyyyMM'));

export const buildPhasingOptions = (
  start: Date,
  finish: Date,
  selectedStart?: Date,
  selectedFinish?: Date,
  deliveries: ProductDelivery[] = []
) => {
  const startYear = start.getUTCFullYear();
  const startMonth = start.getUTCMonth();
  const endYear = finish.getUTCFullYear();
  const endMonth = finish.getUTCMonth();

  const startOptions: PhasingOption[] = [];
  const finishOptions: PhasingOption[] = [];

  const nonZeroDeliveries = deliveries.filter(d => d.quantity > 0);
  const minNonZeroDate =
    deliveries.length > 0 ? minBy(nonZeroDeliveries, d => new Date(d.deliveryDate).getTime()) : undefined;
  const maxNonZeroDate =
    deliveries.length > 0 ? maxBy(nonZeroDeliveries, d => new Date(d.deliveryDate).getTime()) : undefined;

  for (
    let year = startYear, month = startMonth, safety = 0;
    year < endYear || (year === endYear && month <= endMonth);
    year = month >= 11 ? year + 1 : year, month = (month + 1) % 12, safety++
  ) {
    if (safety > 1000) {
      console.error(new Error('Tried to generate too many phasing options.'));
      break;
    }
    const date = new Date(Date.UTC(year, month));
    const option = {
      label: format(date, 'MMM yy'),
      value: date.toISOString(),
      disabled:
        minNonZeroDate &&
        maxNonZeroDate &&
        // let's add one day to min date to allow selecting that month
        ymNumber(date) > ymNumber(new Date(minNonZeroDate.deliveryDate)) &&
        ymNumber(date) < ymNumber(new Date(maxNonZeroDate.deliveryDate))
          ? true
          : false
    };
    startOptions.push({
      ...option,
      disabled: Boolean(option.disabled || (selectedFinish && date > selectedFinish)) || undefined
    });
    finishOptions.push({
      ...option,
      disabled: Boolean(option.disabled || (selectedStart && date < selectedStart)) || undefined
    });
  }

  return [startOptions, finishOptions] as const;
};
