import { type EntityContext } from 'domain-events';
import { type LocalisedStringFor, useCustomersData, useLocalisation } from 'providers';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRequestToastsOnCopyAssortment, useServices } from 'services';
import { plpConfirmCopyAssortmentTag } from 'utils';

import { FeatureFlags } from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

import { CopyAssortmentModal } from './CopyAssortmentModal';
import { State, useCopyAssortment } from './useCopyAssortment';

export const useCopyAssortmentUI = ({ context }: { context: EntityContext | undefined }) => {
  const [str] = useLocalisation();
  const { customerId, assortmentId } = useParams();
  const labeler = useCallback((optionId: string) => stringIdFor(optionId, str), [str]);
  const { customers, loadCustomers } = useCustomersData();
  const { assortmentsService: service, eventBus } = useServices();
  useRequestToastsOnCopyAssortment({ eventBus, str });
  const state = useCopyAssortment({ service, context, labeler });

  const canCopyAssortment = canUse(FeatureFlags.Assortment_Copy);

  useEffect(() => {
    if (canCopyAssortment && state.state === State.RequestedCopy && !customers) loadCustomers();
  }, [customers, loadCustomers, state.state, canCopyAssortment]);

  if (!canCopyAssortment || state.state === State.Idle) return { state, ui: null };

  if (state.state === State.Copying)
    return {
      state,
      ui: (
        <CopyAssortmentModal
          isSubmitting
          state={state.form}
          customers={customers ?? []}
          onChange={() => undefined}
          onClose={() => undefined}
          onConfirm={() => undefined}
        />
      )
    };

  return {
    state,
    ui: (
      <CopyAssortmentModal
        state={state.form}
        customers={customers ?? []}
        onChange={state.updateForm}
        onClose={() => {
          if (state.state === State.RequestedCopy) state.cancel();
        }}
        onConfirm={() => {
          if (state.state === State.RequestedCopy) {
            plpConfirmCopyAssortmentTag({
              assortmentName: state.form.assortmentName || state.form.inheritedAssortmentName,
              assortmentId,
              customerId,
              targetCustomersIds: state.form.targetCustomers.map(c => c.customerId),
              settings: state.form.copyOptions.filter(s => s.isSelected).map(s => s.name)
            });
            state.confirm();
          }
        }}
      />
    )
  };
};

const stringIdFor = (optionId: string, str: LocalisedStringFor) => {
  switch (optionId) {
    case 'products':
    case 'productAttributes':
    case 'storeGrades':
    case 'stories':
      return str(`Assortment.Copy.Modal.copyOption.${optionId}`);
    default:
      return optionId;
  }
};
