import { useCustomersData } from 'providers';
import { useEffect } from 'react';

export const CancelFailedDataRequestOnHistoryBack = () => {
  const { error, cancel } = useCustomersData();

  useEffect(() => {
    const handler = () => {
      if (error) cancel();
    };

    window.addEventListener('popstate', handler);

    return () => {
      window.addEventListener('popstate', handler);
    };
  }, [cancel, error]);

  return null;
};
