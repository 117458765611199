import { ProductsFilterSet } from 'domain-events';
import {
  CustomerAssortmentsLoaded,
  CustomerAssortmentsLoading,
  ProductsDataLoaded,
  ProductsDataLoading
} from 'providers';
import { useCallback, useRef, useState } from 'react';
import { appended, unique, useEventSubscriptions, without } from 'utils';

import { type AnyFilter, FilterType, type ProductFilterId } from '@yourxx/support';

import { type EventBus } from '../EventBus';
import { FilterExpanded } from './events';
import { filterHasValue } from './filterHasValue';

type UseFilterExpandedProps = {
  eventBus: EventBus;
};

interface UseFilterExpandedReturn {
  onFilterExpanded(filter: AnyFilter): FilterExpanded;
  loadingFilters: ReadonlyArray<string>;
}

export const useFilterExpanded = ({ eventBus }: UseFilterExpandedProps): UseFilterExpandedReturn => {
  const traceIds = useRef(new Map<ProductFilterId, string>());
  const [loadingFilters, setLoadingFilters] = useState<ProductFilterId[]>([]);

  const onFilterExpanded = useCallback<UseFilterExpandedReturn['onFilterExpanded']>(
    filter => {
      const filterExpanded = new FilterExpanded({ filter });
      eventBus.emit(filterExpanded);
      return filterExpanded;
    },
    [eventBus]
  );

  const handleLoadingEvent = useCallback((event: CustomerAssortmentsLoading | ProductsDataLoading) => {
    for (const [filterId, traceId] of traceIds.current.entries())
      if (event.isLinkedTo(traceId)) setLoadingFilters(prev => unique(appended(filterId, prev)));
  }, []);

  const handleLoadedEvent = useCallback((event: CustomerAssortmentsLoaded | ProductsDataLoaded) => {
    for (const [filterId, traceId] of traceIds.current.entries())
      if (event.isLinkedTo(traceId)) setLoadingFilters(prev => without(filterId, prev));
  }, []);

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(FilterExpanded, event => {
          const filterId = event.payload.filter.id;
          if (filterId !== 'status' && !traceIds.current.has(filterId)) traceIds.current.set(filterId, event.traceId());
        }),

        eventBus.on(ProductsFilterSet, event => {
          const { filter } = event.payload;
          if (filter.id !== 'status' || filter.type === FilterType.PriceRange) return;

          if (!traceIds.current.has(filter.id) && filterHasValue(filter, 'DROP', 'REMOVE'))
            traceIds.current.set(filter.id, event.traceId());
        }),

        eventBus.on(ProductsDataLoading, handleLoadingEvent),
        eventBus.on(ProductsDataLoaded, handleLoadedEvent),
        eventBus.on(CustomerAssortmentsLoading, handleLoadingEvent),
        eventBus.on(CustomerAssortmentsLoaded, handleLoadedEvent)
      ],
      [eventBus, handleLoadedEvent, handleLoadingEvent]
    )
  );

  return {
    onFilterExpanded,
    loadingFilters
  };
};
