import { useMemo } from 'react';

import { generateExport } from '@yourxx/ui-utils';

import type { EventBus } from '../EventBus';
import { createExportsService } from './createExportsService';
import type { ExportsService } from './ExportsService';

interface UseCreateExportsServiceProps {
  eventBus: EventBus;
}

export const useCreateExportsService = ({ eventBus }: UseCreateExportsServiceProps): ExportsService => {
  return useMemo(() => createExportsService({ eventBus, generateExport }), [eventBus]);
};
