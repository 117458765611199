import { type AnyFilter, Filter, FilterType } from './Filter';

export type SerialisedFilter = { id: string; type: string; value: unknown };

const deserialiseFilter = (serialisedFilter: SerialisedFilter) => {
  const maybeType = FilterType[serialisedFilter.type as FilterType];
  if (!maybeType) return;

  return Filter(serialisedFilter.id, maybeType, serialisedFilter.value as AnyFilter['value']) as AnyFilter;
};

export const deserialiseFilters = (serialised: ReadonlyArray<SerialisedFilter>) => {
  const filters: AnyFilter[] = [];

  for (const serialisedFilter of serialised) {
    const maybeFilter = deserialiseFilter(serialisedFilter);
    if (!maybeFilter) continue;
    filters.push(maybeFilter);
  }

  return filters;
};
