export interface Manifest {
  readonly record: Record<string, string[]>;
  scheduleForReload(tag: string, id: string): this;
  isScheduledForReload(tag: string, id: string): boolean;
  remove(tag: string, id: string): this;
}

export const Manifest = (record: Record<string, string[]> = {}): Manifest => ({
  record,
  scheduleForReload: (tag, id) => Manifest({ ...record, [tag]: [...(record?.[tag] ?? []), id] }),
  isScheduledForReload: (tag, id) => record[tag]?.includes(id),
  remove: (tag, id) => Manifest({ ...record, [tag]: (record?.[tag] ?? []).filter(anId => anId !== id) })
});
