import type { EntityContext, SidePanelId } from 'domain-events';
import { useServices } from 'services';

import { useTrackSidePanel } from './useTrackSidePanel';

export const TrackSidePanel = ({
  context,
  sidePanelId
}: {
  context: EntityContext | undefined;
  sidePanelId: SidePanelId | undefined;
}) => {
  const { eventBus } = useServices();
  useTrackSidePanel({ eventBus, sidePanelId, context });
  return null;
};
