import { Upload } from 'assets/icons';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { themed } from 'utils';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  position: relative;
  cursor: pointer;
`;
const StyledInput = styled.input`
  display: none;
`;
const StyledLabel = styled.label<{ $dragActive: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: ${themed('spacing.xs')};
  border-radius: ${themed('spacing.l')};
  border-style: dashed;
  border-color: ${themed('color.greyMid')};
  background-color: ${({ $dragActive }) => ($dragActive ? themed('color.white') : themed('color.offWhite'))};
`;
const StyledButton = styled.button`
  padding: ${themed('spacing.s')};
  font-size: ${themed('font.size.s')};
  border: none;
  background-color: transparent;
  vertical-align: bottom;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration-line: underline;
    }
  }
`;
const DragActiveWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${themed('spacing.l')};
  inset: 0;
`;
const StyledFileUploadIcon = styled(Upload)`
  width: ${themed('spacing.xxl')};
  height: ${themed('spacing.xxl')};
`;

export enum UploadFileTypes {
  CSV = '.csv',
  XLS = '.xls',
  PDF = '.pdf',
  XLSX = '.xlsx'
}

export type FileUploadProps = {
  className?: string;
  title: string;
  handleFiles: (files: any) => void;
  fileAccept?: UploadFileTypes[];
  multipleFiles?: boolean;
  label?: string | React.ReactNode;
};

export const FileUpload = ({ className, title, handleFiles, fileAccept, multipleFiles, label }: FileUploadProps) => {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef<HTMLInputElement>(null);

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };

  return (
    <Wrapper className={className} onDragEnter={handleDrag}>
      <StyledInput
        id="input-file-upload"
        ref={inputRef}
        type="file"
        accept={fileAccept && fileAccept.length ? fileAccept.join(',') : ''}
        multiple={multipleFiles || false}
        onChange={handleChange}
      />
      <StyledLabel htmlFor="input-file-upload" $dragActive={dragActive}>
        <div>
          <p>{title}</p>
          <div>
            <StyledFileUploadIcon />
            <StyledButton onClick={onButtonClick}>{label ?? 'Upload file'}</StyledButton>
          </div>
        </div>
      </StyledLabel>
      {dragActive && (
        <DragActiveWrapper
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </Wrapper>
  );
};
