import type { AnyFilter } from '@yourxx/support';

import { DefaultFilterCombination } from '../DefaultFilterCombination';

export interface DefaultFilterCombinationRepository {
  getById(id: string): DefaultFilterCombination;
}

export const DefaultFilterCombinationRepository = (
  defaultFiltersFactory?: () => AnyFilter[]
): DefaultFilterCombinationRepository => {
  const combinationsById: Record<string, DefaultFilterCombination> = {};

  return {
    getById: id => {
      combinationsById[id] ??= DefaultFilterCombination(`${id}-default-filter-combination`, defaultFiltersFactory);
      return combinationsById[id];
    }
  };
};
