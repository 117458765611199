import { useLocalisation } from 'providers';
import { OrderStatus } from 'services';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

const OrderStatusDiv = styled.div<{ $status: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${rem(6)};
  width: fit-content;
  height: ${themed('spacing.l')};
  border-radius: ${rem(10)};
  padding: ${rem(2, 8, 2, 6)};
  background-color: ${themed('color.greyLight')};
  ${themed('typography.h5')};
  font-weight: ${themed('font.weight.black')};
  text-transform: uppercase;
  white-space: nowrap;

  &::before {
    content: '';
    width: ${themed('spacing.m')};
    height: ${themed('spacing.m')};
    border-radius: ${themed('spacing.s')};
    background-color: ${({ $status }) =>
      ({ [OrderStatus.InProgress]: themed('color.orange'), [OrderStatus.Submitted]: themed('color.green') })[$status] ??
      'grey'};
  }
`;

export const StatusCapsule = ({ status }: { status: OrderStatus }) => {
  const [str] = useLocalisation();
  return <OrderStatusDiv $status={status}>{str(`Order.status.${status}`)}</OrderStatusDiv>;
};
