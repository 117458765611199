import { FlexWrapper } from 'components/FlexWrapper';
import styled from 'styled-components';
import { themed } from 'utils';

const Content = styled.p`
  animation-name: ${themed('animation.slideDown')};
  animation-timing-function: ${themed('transition.timing')};
  animation-duration: ${themed('transition.duration')};
`;

export const ResourceLoadedEmpty = ({ message }: { message: string }) => (
  <FlexWrapper>
    <Content>{message}</Content>
  </FlexWrapper>
);
