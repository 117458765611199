import {
  AssortmentLineId,
  BoardId,
  BoardItem,
  BoardItemId,
  CreateBoardItemsRequest,
  DeleteBoardItemResponse,
  UpdateBoardItemRequest
} from '@yourxx/types';

import { apiEndpoints, checkBoardId, checkBoardItemId, checkLineAssortmentId, toRequest } from '../../utils';
import { apiClient } from '../client';

export const createBoardItems = async (
  body: CreateBoardItemsRequest,
  assortmentLineId: AssortmentLineId
): Promise<BoardItem[]> => {
  checkLineAssortmentId(assortmentLineId);
  const { pc9Codes } = body;
  checkBoardId(body);
  if (pc9Codes && pc9Codes?.length > 0) {
    const request = toRequest({ path: apiEndpoints.canvasBoardItems, method: 'POST', body: body });
    return apiClient.request<BoardItem[]>(request);
  }
  return [];
};

export const deleteBoardItem = async (params: BoardItemId, assortmentLineId: AssortmentLineId): Promise<void> => {
  checkBoardItemId(params);
  checkLineAssortmentId(assortmentLineId);
  const request = toRequest({ path: apiEndpoints.canvasBoardItem, params, method: 'DELETE' });
  await apiClient.request<DeleteBoardItemResponse>(request);
};

export const getBoardItems = async (params: BoardId): Promise<BoardItem[]> => {
  const boardId = checkBoardId(params);
  const request = toRequest({ path: apiEndpoints.canvasBoardItems, params: { boardId }, method: 'GET' });
  return await apiClient.request<BoardItem[]>(request);
};

export const updateBoardItems = async (params: BoardId, body: UpdateBoardItemRequest[]): Promise<BoardItem[]> => {
  checkBoardId(params);
  const request = toRequest({ path: apiEndpoints.canvasBoardItems, method: 'PATCH', body: body });
  return await apiClient.request<BoardItem[]>(request);
};
