export const equals = (x: unknown, y: unknown): boolean => {
  if (Object.is(x, y)) return true;

  if (Array.isArray(x) && Array.isArray(y)) {
    if (!equals(x.length, y.length)) return false;
    return x.every((xv, i) => equals(xv, y[i]));
  }

  if (x !== null && y !== null && typeof x === 'object' && typeof y === 'object') {
    const xks = Object.keys(x);
    const yks = Object.keys(y);
    if (!equals(xks.length, yks.length)) return false;
    return xks.every(k => equals(x[k as keyof typeof x], y[k as keyof typeof y]));
  }

  return false;
};
