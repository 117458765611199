import { type ReactNode, useMemo } from 'react';

import { once } from '@yourxx/support';

import { OrdersContext } from './OrdersContext';
import type { CustomerOrders, OrderDetails, OrderSizing, OrderSummary } from './types';

export const OrdersProvider = ({
  brand,
  customerId,
  children
}: {
  brand: string;
  customerId: string;
  children: ReactNode;
}) => {
  // TODO: Placeholder utilities. Delegate to service and use service-provided cached data
  const customerName = useMemo(() => customerId.replace(/-+/g, ' ').toUpperCase(), [customerId]);

  const loadCustomerOrders = useMemo(
    () =>
      once(
        () =>
          new Promise<CustomerOrders>(res =>
            setTimeout(
              () =>
                res({
                  brand,
                  customerId,
                  customerName,
                  orders: [
                    { id: 'order-1', season: 'h225' },
                    { id: 'order-2', season: 'h125' },
                    { id: 'order-3', season: 'h224' },
                    { id: 'order-4', season: 'h124' }
                  ]
                }),
              500
            )
          )
      ),
    [brand, customerId, customerName]
  );

  const loadOrderDetails = useMemo(
    () =>
      once(
        (orderId: string) =>
          new Promise<OrderDetails>(res =>
            setTimeout(
              () =>
                res({
                  brand,
                  customerId,
                  customerName,
                  orderId,
                  details: 'Some order details'
                }),
              500
            )
          )
      ),
    [brand, customerId, customerName]
  );

  const loadOrderSummary = useMemo(
    () =>
      once(
        (orderId: string) =>
          new Promise<OrderSummary>(res =>
            setTimeout(
              () =>
                res({
                  brand,
                  customerId,
                  customerName,
                  orderId,
                  summary: 'Some order summary'
                }),
              500
            )
          )
      ),
    [brand, customerId, customerName]
  );

  const loadOrderSizing = useMemo(
    () =>
      once((orderId: string, pc9: string) =>
        new Promise<OrderSizing>(res =>
          setTimeout(
            () =>
              res({
                brand,
                customerId,
                customerName,
                orderId,
                pc9,
                sizing: 'Some order sizing'
              }),
            500
          )
        ).then(data => {
          // throw new Error('Some nasty error.');
          return data;
        })
      ),
    [brand, customerId, customerName]
  );

  return (
    <OrdersContext.Provider
      value={{
        brand,
        customerId,
        customerName,
        loadCustomerOrders,
        loadOrderDetails,
        loadOrderSummary,
        loadOrderSizing
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};
