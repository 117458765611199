import { useContext } from 'react';

import { Defect } from '@yourxx/support';

import { SlotsContext } from './SlotsProvider';

export const useSlots = () => {
  const value = useContext(SlotsContext);

  if (!value)
    throw new Defect(
      `useSlots hook called outside its context provider. Make sure <SlotsProvider /> is a parent of your component.`
    );

  return value;
};
