import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { loadingSkeletonAnimation } from './loadingSkeletonAnimation';

export const Box = styled.div<{ $height?: number }>`
  ${loadingSkeletonAnimation};
  display: flex;
  align-self: stretch;
  width: 100%;
  height: ${({ $height = 240 }) => rem($height)};
  border-radius: ${themed('borderRadius')};
  background-color: ${themed('color.offWhite')};
`;
