import type { EntityContext } from 'domain-events';
import { useCallback, useRef } from 'react';
import { PartialProductsRemovalError, type ProductsService, type RemovedProductsErrorReport } from 'services';
import { assign, createMachine } from 'xstate';

import { useMachine } from '@xstate/react';
import { Defect, Nullable, toggleArrayValue } from '@yourxx/support';

export type UseRemoveProductReturn<P, R> = {
  selectedProducts: Array<P>;
} & (
  | {
      state: State.Idle;
      changeSelection(items: ReadonlyArray<P>): void;
      clearSelection: VoidFunction;
      canRemove: boolean;
      remove: VoidFunction;
    }
  | { state: State.PendingConfirmation; confirm: VoidFunction; cancel: VoidFunction }
  | { state: State.Removing }
  | { state: State.ShowingErrorReport; report: R; cancel: VoidFunction }
  | { state: State.ShowingError; error: Error; retry: VoidFunction; cancel: VoidFunction }
);

export interface UseRemoveProductProps<P> {
  service: Pick<ProductsService, 'remove'>;
  afterRemoved?: (items: P[]) => void;
  afterDone?: VoidFunction;
  context: EntityContext | undefined;
}

export const useRemoveProduct = <P extends { pc9: string; name: string }>({
  context: entityContext,
  service,
  afterRemoved,
  afterDone
}: UseRemoveProductProps<P>): UseRemoveProductReturn<P, RemovedProductsErrorReport<P>> => {
  const stateMachine = useRef(createStateMachine<P>());
  const [state, send] = useMachine(stateMachine.current, {
    services: {
      remove: async context => {
        if (!entityContext) throw new Defect('Customer ID is required for removal of products from an assortment.');
        return service.remove({ context: entityContext, items: context.selectedProducts });
      }
    },
    actions: {
      afterRemoved: context => afterRemoved?.(context.selectedProducts),
      afterDone: () => afterDone?.()
    }
  });

  const canRemove = state.can({ type: Action.Remove });

  const changeSelection = useCallback((items: P[]) => send({ type: Action.ChangeSelection, items }), [send]);

  const clearSelection = useCallback(
    () => send({ type: Action.ChangeSelection, items: state.context.selectedProducts }),
    [send, state.context.selectedProducts]
  );

  const remove = useCallback(() => {
    if (!canRemove)
      throw new Defect('Remove was called in an invalid state. Make sure canRemove is used to disable the call.');

    send({ type: Action.Remove });
  }, [canRemove, send]);

  const confirm = useCallback(() => send({ type: Action.Confirm }), [send]);
  const cancel = useCallback(() => send({ type: Action.Cancel }), [send]);
  const retry = useCallback(() => send({ type: Action.Retry }), [send]);

  const { selectedProducts } = state.context;

  switch (state.value) {
    case State.Idle:
      return {
        state: state.value,
        selectedProducts,
        changeSelection,
        clearSelection,
        canRemove,
        remove
      };
    case State.PendingConfirmation:
      return {
        state: state.value,
        selectedProducts,
        confirm,
        cancel
      };
    case State.Removing:
      return {
        state: state.value,
        selectedProducts
      };
    case State.ShowingErrorReport: {
      if (!state.context.errorReport) throw new Defect('Error report not available.');

      return {
        state: state.value,
        selectedProducts,
        report: state.context.errorReport,
        cancel
      };
    }
    case State.ShowingError: {
      if (!state.context.error) throw new Defect('Error not available.');

      return {
        state: state.value,
        selectedProducts,
        error: state.context.error,
        retry,
        cancel
      };
    }
    default:
      throw new Defect('Unhandled state');
  }
};

export enum State {
  Idle = 'Idle',
  Removing = 'Removing',
  PendingConfirmation = 'PendingConfirmation',
  ShowingErrorReport = 'ShowingErrorReport',
  ShowingError = 'ShowingError'
}

enum Action {
  ChangeSelection = 'ChangeSelection',
  Remove = 'Remove',
  Cancel = 'Cancel',
  Confirm = 'Confirm',
  Retry = 'Retry'
}

const createStateMachine = <P>() => {
  type Context = {
    selectedProducts: P[];
    errorReport: Nullable<RemovedProductsErrorReport<P>>;
    error: Nullable<Error>;
  };
  type Services = { remove: { data: void } };
  type Event =
    | { type: Action.ChangeSelection; items: ReadonlyArray<P> }
    | { type: Action.Remove }
    | { type: Action.Cancel }
    | { type: Action.Confirm }
    | { type: Action.Retry };

  return createMachine({
    id: 'remove-product-state-machine',
    predictableActionArguments: true,
    schema: {
      services: {} as Services,
      events: {} as Event,
      context: {} as Context
    },
    context: {
      selectedProducts: [],
      errorReport: null,
      error: null
    },
    initial: State.Idle,
    states: {
      [State.Idle]: {
        on: {
          [Action.ChangeSelection]: {
            actions: assign({
              selectedProducts: (context, event) => toggleArrayValue(event.items, context.selectedProducts)
            })
          },
          [Action.Remove]: {
            target: State.PendingConfirmation,
            cond: context => Boolean(context.selectedProducts.length)
          }
        }
      },
      [State.PendingConfirmation]: {
        on: {
          [Action.Cancel]: State.Idle,
          [Action.Confirm]: State.Removing
        }
      },
      [State.Removing]: {
        exit: ['afterDone'],
        invoke: {
          src: 'remove',
          onDone: {
            target: State.Idle,
            actions: ['afterRemoved', assign({ selectedProducts: () => [] })]
          },
          onError: [
            {
              target: State.ShowingErrorReport,
              cond: (_, event) => event.data instanceof PartialProductsRemovalError,
              actions: assign({ errorReport: (_, event) => event.data.report })
            },
            {
              target: State.ShowingError,
              cond: (_, event) => !(event.data instanceof PartialProductsRemovalError),
              actions: assign({ error: (_, event) => event.data })
            }
          ]
        }
      },
      [State.ShowingError]: {
        on: {
          [Action.Retry]: State.Removing,
          [Action.Cancel]: {
            target: State.Idle,
            actions: assign({ error: null })
          }
        }
      },
      [State.ShowingErrorReport]: {
        on: {
          [Action.Cancel]: {
            target: State.Idle,
            actions: assign({
              selectedProducts: context =>
                context.selectedProducts.filter(p => !context.errorReport?.removed.includes(p)),

              errorReport: null
            })
          }
        }
      }
    }
  });
};
