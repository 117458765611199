import { LocalisationStrings } from '@yourxx/translations/en';

export const RankingMap: { [key: number]: { value: number; key: keyof LocalisationStrings } } = {
  1: { value: 1, key: 'Line.addProduct.rankingLabels.1' },
  2: { value: 2, key: 'Line.addProduct.rankingLabels.2' },
  3: { value: 3, key: 'Line.addProduct.rankingLabels.3' },
  4: { value: 4, key: 'Line.addProduct.rankingLabels.4' },
  5: { value: 5, key: 'Line.addProduct.rankingLabels.5' },
  6: { value: 6, key: 'Line.addProduct.rankingLabels.6' }
} as const;

export const RANKING_OPTIONS = Object.values(RankingMap);
