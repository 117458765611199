import { Refresh } from 'assets/icons';
import { Button, Modal } from 'components';
import { type ContextOf, Entity } from 'domain-events';
import { createContext, type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { DomainEvent, Filter, FilterType, SetFilterRequested, useServices } from 'services';
import { css, styled } from 'styled-components';
import { useEventSubscriptions } from 'utils';

import { asV1Url } from '@yourxx/ui-utils/src/utils/appSwitching';

type StoriesContext = ContextOf<Entity.Assortment | Entity.FinalAssortment | Entity.Line>;

export class LegacyStoriesRequested extends DomainEvent<{ context: StoriesContext; storyId?: string }> {}
export class LegacyStoriesClosed extends DomainEvent<{ context: StoriesContext }> {}

export const LegacyStoriesContext = createContext<{
  hasRequested: boolean;
  registerOnClose: (callback: VoidFunction) => void;
} | null>(null);

export const LegacyStoriesProvider = ({ children }: { children: ReactNode }) => {
  const { eventBus } = useServices();
  const [hasRequested, setHasRequested] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const remoteOnClose = useRef<VoidFunction>();
  const iframe = useRef<HTMLIFrameElement>(null);
  // This needs to be a reference, useState will reload the iframe when updated.
  const initialUrl = useRef('');

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(LegacyStoriesRequested, event => {
          const { context, storyId } = event.payload;
          const maybeStoryId = storyId ? `/${storyId}` : '';
          if (!initialUrl.current) {
            const url = new URL(window.location.href);
            if (context.entity === Entity.Line) {
              url.pathname = `/levi/lines/${context.id}/stories${maybeStoryId}`;
              initialUrl.current = asV1Url(url).toString();
            } else {
              url.pathname = `/levi/customers/${context.customerId}/${context.id}/stories${maybeStoryId}`;
              initialUrl.current = asV1Url(url).toString();
            }
          }

          setHasRequested(true);
          setIsVisible(true);

          const message = Object.assign(
            { type: 'v1-app-stories-requested' as const },
            context.entity === Entity.Line
              ? { lineId: context.id, storyId }
              : { customerId: context.customerId, assortmentId: context.id, storyId }
          );

          iframe.current?.contentWindow?.postMessage(message, '*');
        })
      ],
      [eventBus]
    )
  );

  const onClose = useCallback(() => {
    remoteOnClose.current?.();
    setIsVisible(false);
  }, []);

  useEffect(() => {
    const handler = (event: MessageEvent<any>) => {
      if (!isMessageSentByV1App(event)) return;
      // TODO: Get filter from schema
      eventBus.emit(new SetFilterRequested({ filter: Filter('story', FilterType.Multi, [event.data.storyName]) }));
      onClose();
    };

    window.addEventListener('message', handler);

    return () => {
      window.removeEventListener('message', handler);
    };
  }, [eventBus, onClose]);

  const registerOnClose = useCallback((callback: VoidFunction) => {
    remoteOnClose.current = callback;
  }, []);

  return (
    <LegacyStoriesContext.Provider value={{ registerOnClose, hasRequested }}>
      {children}
      {hasRequested && (
        <Hideable $isHidden={!isVisible}>
          <Modal onClose={onClose}>
            <Button
              onClick={() => {
                iframe.current?.contentWindow?.postMessage({ type: 'v1-app-refresh-requested' }, '*');
              }}
            >
              <Refresh />
            </Button>
            <StyledIframe ref={iframe} src={initialUrl.current} />
          </Modal>
        </Hideable>
      )}
    </LegacyStoriesContext.Provider>
  );
};

const isMessageSentByV1App = (
  event: MessageEvent<any>
): event is MessageEvent<{
  type: 'v2-app-view-story-products-requested';
  customerId: string;
  assortmentId: string;
  storyName: string;
}> => event.data.type === 'v2-app-view-story-products-requested';

const StyledIframe = styled.iframe`
  width: 90vw;
  height: 90vh;
  border: 0;
  padding: 0;
  background-color: white;

  html {
    background-color: transparent;
  }
`;

const Hideable = styled.div<{ $isHidden: boolean }>`
  ${({ $isHidden }) =>
    $isHidden
      ? css`
          display: none;
          width: 0;
          height: 0;
          overflow: hidden;
        `
      : ''};
`;
