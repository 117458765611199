import { useEffect } from 'react';
import { EventBus } from 'services';

export type Subscriptions = () => ReadonlyArray<ReturnType<EventBus['on']>>;

/**
 * Use this hook to automatically handle unsubscribing from EventBus events.
 * Make sure you wrap your event subscriptions within a `useCallback` to prevent
 * subscribing and unsubscribing on every rerender of parent the component or hook.
 *
 * Usage:
 * ```ts
 * // In a React component or custom hook
 * useEventSubscriptions(
 *   useCallback(
 *     () => [
 *       eventBus.on(SomeEvent, event => console.log(event, counter),
 *       eventBus.on(SomeOtherEvent, event => console.warn(event.payload)
 *     ],
 *     [counter, eventBus]
 *   )
 * );
 *
 * ```
 */
export const useEventSubscriptions = (factory: Subscriptions): void => {
  useEffect(() => {
    const subscriptions = factory();
    return () => {
      for (const unsubscribe of subscriptions) unsubscribe();
    };
  }, [factory]);
};
