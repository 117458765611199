import { ProductsLayout, ProductsPage, SuggestedStorySets } from 'pages';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProductListMode } from 'routes';

export const ProductsRoute = ({
  brand,
  customerId,
  entityId,
  customersOrLines
}: {
  brand: string;
  entityId: string;
  customerId?: string;
  customersOrLines: 'customers' | 'lines';
}) => (
  <Routes>
    <Route index element={<Navigate to={`products/${ProductListMode.Grid}`} replace />} />
    <Route Component={ProductsLayout}>
      <Route path=":productPageType/*">
        <Route index element={<Navigate to={ProductListMode.Grid} replace />} />
        {/* TODO: Split this up even further and separate summary, from stories from assortment products */}
        <Route
          path=":productListMode/:productModalType?/:canvasType?/:canvasBoardId?"
          element={
            <ProductsPage
              brand={brand}
              customerId={customerId}
              entityId={entityId}
              customersOrLines={customersOrLines}
            />
          }
        />
        <Route path="*" element={<Navigate to={ProductListMode.Grid} replace />} />
      </Route>
      <Route path="suggested-story" Component={SuggestedStorySets} />
    </Route>
  </Routes>
);
