import { PageLandscape, PagePortrait } from 'assets/icons';
import { Entity, type EntityContext } from 'domain-events';
import React, { useCallback, useMemo, useState } from 'react';
import type { ExportCommand, ExportsService } from 'services';

import { Task } from './Task';
import { useTaskQueue } from './useTaskQueue';

export interface UseExportsProps {
  service: ExportsService;
  context: EntityContext | undefined;
  options?: ExportOptionTemplate[] | ((defaults: ExportOptionTemplate[]) => ExportOptionTemplate[]);
  isForParentLine?: boolean;
  renderedPreAppliedFilters?: Record<string, string | string[]>;
  pc9s: string[];
  status: string[] | undefined;
  pricingGroupId?: string;
  onExportReady(resourceUrl: string): void;
}

export interface UseExportsReturn {
  isExporting: boolean;
  options: ReadonlyArray<ExportOption>;
}

const DEFAULT_STATUS = ['ADD', 'PENDING'];

export const useExports = ({
  service,
  onExportReady,
  context,
  isForParentLine = false,
  renderedPreAppliedFilters,
  status = DEFAULT_STATUS,
  options: optionTemplates = defaultOptionTemplates,
  pc9s,
  pricingGroupId
}: UseExportsProps): UseExportsReturn => {
  const [tasks, setTasks] = useState<Task<void, ExportOptionTemplate>[]>([]);
  const { enqueue } = useTaskQueue();
  const shouldDisableAllOptions = !context || !pc9s.length;

  const onExport = useCallback(
    (option: ExportOptionTemplate) => {
      setTasks(prev => {
        const isExportTypeAlreadyQueued = !!prev.find(task => task.context?.id === option.id);
        if (isExportTypeAlreadyQueued) return prev;

        const executeExport = async () => {
          if (!context || context.entity === Entity.Order) return;

          const command: ExportCommand = {
            exportId: option.id,
            displayName: [option.label, option.desc].filter(Boolean).join(' '),
            context,
            isForParentLine,
            pc9s,
            status,
            pricingGroupId,
            retry: () => onExport(option)
          };

          if (renderedPreAppliedFilters) command.preAppliedFilters = renderedPreAppliedFilters;

          return service
            .export(command)
            .then(({ resourceUrl }) => onExportReady(resourceUrl))
            .finally(() => setTasks(prev => prev.filter(t => t.context?.id !== option.id)));
        };

        const task = Task.of(executeExport, option);
        enqueue(task);
        return [...prev, task];
      });
    },
    [context, enqueue, isForParentLine, onExportReady, pc9s, pricingGroupId, renderedPreAppliedFilters, service, status]
  );

  const templates = useMemo(
    () => (typeof optionTemplates === 'function' ? optionTemplates(defaultOptionTemplates) : optionTemplates),
    [optionTemplates]
  );

  const options = useMemo<ExportOption[]>(
    () =>
      templates.map(template => ({
        ...template,
        onClick: () => onExport(template),
        disabled: shouldDisableAllOptions,
        inProgress: !!tasks.find(task => task.context?.id === template.id)
      })),
    [onExport, shouldDisableAllOptions, tasks, templates]
  );

  return {
    isExporting: !!tasks.length,
    options
  };
};

export interface ExportOption {
  id: string;
  onClick: VoidFunction;
  inProgress: boolean;
  disabled: boolean;
  label: string;
  desc?: string;
  icon?: React.JSX.Element;
}

export type ExportOptionTemplate = Omit<ExportOption, 'onClick' | 'inProgress' | 'disabled'>;

const defaultOptionTemplates: ExportOptionTemplate[] = [
  {
    id: 'xls',
    label: 'XLSX'
  },
  {
    id: 'pdf-portrait',
    label: 'PDF',
    desc: 'Portrait',
    icon: <PagePortrait />
  },
  {
    id: 'pdf-landscape',
    label: 'PDF',
    desc: 'Landscape',
    icon: <PageLandscape />
  },
  {
    id: 'pdf-vlp-portrait',
    label: 'VLP PDF',
    desc: 'Portrait',
    icon: <PagePortrait />
  },
  {
    id: 'pdf-vlp-landscape',
    label: 'VLP PDF',
    desc: 'Landscape',
    icon: <PageLandscape />
  },
  {
    id: 'csv',
    label: 'Product List CSV'
  }
];
