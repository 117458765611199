import { useState } from 'react';
import { canvasDeleteTag } from 'utils';

import { deleteBoard as apiDeleteBoard } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export type UseDeleteBoard = {
  deleteBoard: (details: { boardId: string }) => void;
  isDeletingBoard: string;
  hasError: string | undefined;
};

export const useDeleteBoard = (): UseDeleteBoard => {
  const { customerId, assortmentId } = useCurrentCanvasContext();
  const { setBoards } = useCanvas();
  const [isDeletingBoard, setIsDeletingBoard] = useState<string>('');
  const [hasError, setHasError] = useState<string | undefined>();

  const deleteBoard = (details: { boardId: string }) => {
    setIsDeletingBoard(details.boardId);
    apiDeleteBoard(details)
      .then(() => {
        setBoards(prev => prev.filter(b => b.boardId !== details.boardId));
        canvasDeleteTag({
          assortmentId,
          customerId,
          comparisonId: details.boardId
        });
        setIsDeletingBoard('');
      })
      .catch(() => {
        setHasError('Failed adding item to board');
        setIsDeletingBoard('');
      });
  };

  return { deleteBoard, isDeletingBoard, hasError };
};
