import styled from 'styled-components';
import { rem, themed } from 'utils';

const GenericButton = styled.button.withConfig({
  shouldForwardProp: prop => prop !== 'variant'
})<ButtonSpecificProps>`
  background-color: ${({ theme, variant }) =>
    variant ? theme.button.variant[variant].backgroundColor : 'transparent'};
  color: ${({ theme, variant }) => (variant ? theme.button.variant[variant].color : 'inherit')};
  font-size: ${themed('font.size.m')};
  font-weight: ${themed('font.weight.bold')};
  border: none;
  border-color: ${({ theme, variant }) => (variant ? theme.button.variant[variant].color : 'inherit')};
  border-width: ${({ withBorder }) => rem(withBorder ? 1 : 0)};
  border-style: solid;
  outline: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: ${themed('button.paddingX')} ${themed('button.paddingY')};
  border-radius: ${themed('borderRadius')};
  text-transform: uppercase;
  gap: ${themed('button.gap')};
  white-space: nowrap;
  will-change: background-color, color;
  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
  cursor: pointer;

  svg {
    width: ${themed('font.size.l')};
    height: ${themed('font.size.l')};

    * {
      will-change: stroke;
      transition: stroke 0.2s ease-out;
    }
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

type ButtonSpecificProps = {
  variant?: 'primary' | 'secondary';
  withBorder?: boolean;
  isPressed?: boolean;
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonSpecificProps;

export const Button = GenericButton;
