import { Note } from 'assets/icons';
import { Button } from 'components/Button';
import { PopOver } from 'components/PopOver';
import { Tooltip } from 'components/Tooltip';
import { format } from 'date-fns';
import type { EntityContext } from 'domain-events';
import { useLocalisation } from 'providers';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServices } from 'services';
import styled from 'styled-components';
import { pdpNotesTag, rem, themed, useIAM } from 'utils';

import { NoteField } from '@yourxx/types';

const StyledButton = styled(Button)<{ $isOpen?: boolean }>`
  background-color: ${({ $isOpen }) => ($isOpen ? themed('color.black') : themed('color.white'))};
  color: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};

  svg g {
    stroke: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};
  }
`;
const NoteIndicator = styled.span`
  position: absolute;
  top: ${themed('spacing.m')};
  right: ${themed('spacing.s')};
  width: ${rem(6)};
  height: ${rem(6)};
  border-radius: 50%;
  background-color: ${themed('color.black')};
`;
const Title = styled.p`
  margin-top: 0;
  margin-bottom: ${themed('spacing.m')};
`;
const Content = styled.div`
  width: ${rem(420)};
  height: ${rem(240)};
  background-color: ${themed('color.white')};
  border-radius: ${themed('spacing.s')};
  padding: ${themed('spacing.m')};
  position: relative;
  overflow: auto;
  box-sizing: border-box;
`;
const StyledTextArea = styled.textarea`
  outline: none;
  border: 0;
  width: 100%;
  height: 80%;
  resize: none;
  color: ${themed('color.greyDark')};
  font-weight: ${themed('font.weight.regular')};
`;
const FooterDetail = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: ${themed('spacing.l')};
  width: -webkit-fill-available;
  padding-right: ${themed('spacing.l')};
  color: ${themed('color.grey')};
`;

export const NotesView = ({
  context,
  note,
  pc9,
  pId
}: {
  context: EntityContext | undefined;
  note?: NoteField;
  pc9: string;
  pId: string;
}) => {
  const [str] = useLocalisation();
  const { canUse, canNotUse } = useIAM();
  const { customerId, assortmentId: customerAssortmentId, lineId } = useParams();
  const customerOrLineId = customerId ?? lineId;
  const { toastService, productsService } = useServices();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentNotes, setCurrentNotes] = useState<string | undefined>(note?.comment);
  const [notesCharacterCount, setNotesCharacterCount] = useState<number>(note?.comment?.length ?? 0);
  const maxLength = 400;

  const notesButton = () => (
    <Tooltip
      text={str(note ? 'PDP.notes.existingTooltip' : 'PDP.notes.newTooltip')}
      position="bottom"
      disabled={isOpen}
    >
      <StyledButton onClick={() => setIsOpen(true)} $isOpen={isOpen}>
        <Note />
        {note && <NoteIndicator />}
        {str('PDP.notes.title')}
      </StyledButton>
    </Tooltip>
  );

  const onPopoverClose = () => {
    if (!context) {
      toastService.send('Assortment/line id is missing.', 'error');
      setIsOpen(false);
      return;
    }

    if (currentNotes !== note?.comment && canUse(`edit.note`)) {
      productsService
        .update({
          context,
          items: [{ pc9: pc9 }],
          attributes: {
            note: currentNotes === '' ? null : currentNotes
          }
        })
        .then(() => {
          pdpNotesTag({
            productId: pId,
            customerId: customerOrLineId,
            assortmentId: customerAssortmentId
          });
          toastService.send(<span>{str('PDP.notes.saved')}</span>);
        })
        .catch(() => toastService.send(<span>{str('PDP.notes.savingError')}</span>, 'error'));
    }
    setIsOpen(false);
  };

  return (
    <PopOver isOpen={isOpen} onClose={onPopoverClose} trigger={notesButton()} showCloseButton={false} paddedBorder>
      <>
        <Title>{str('PDP.notes.title')}</Title>
        <Content>
          <StyledTextArea
            onChange={val => {
              setNotesCharacterCount(val.target.value?.length ?? 0);
              setCurrentNotes(val.target.value);
            }}
            value={currentNotes}
            maxLength={maxLength}
            disabled={canNotUse(`edit.note`)}
          />
          <FooterDetail>
            {note && (
              <span>
                {str('PDP.notes.byAuthorOnDate', {
                  author: note.author,
                  date: note.updatedAt ? format(note.updatedAt, 'hh:mm MMM do') : ''
                })}
              </span>
            )}
            <span>
              {notesCharacterCount}/{maxLength}
            </span>
          </FooterDetail>
        </Content>
        <Button style={{ marginLeft: 'auto' }} variant={'primary'} onClick={onPopoverClose}>
          Save
        </Button>
      </>
    </PopOver>
  );
};
