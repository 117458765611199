import { LoadingSpinner } from 'components/LoadingSpinner';
import { useLocalisation } from 'providers';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppBrands, appBrands, getBrand, rem, themed } from 'utils';

import { useQuery } from '@tanstack/react-query';
import { getUserProfile, persistedStore } from '@yourxx/ui-utils';

const BrandsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${themed('spacing.xl')};
`;
const BrandButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: ${themed('spacing.l')};
  border: solid ${rem(1)} ${themed('color.greyLight')};
  width: ${rem(200)};
  aspect-ratio: 1;

  svg {
    width: 90%;
  }
`;

export const Brands = () => {
  const [str] = useLocalisation();
  const { isLoading, data } = useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const user = persistedStore.getUserProfile() ?? (await getUserProfile());
      return (
        user?.brands?.reduce((acc: AppBrands[], brand) => {
          return getBrand(brand) ? [...acc, getBrand(brand)] : acc;
        }, []) ?? []
      );
    }
  });

  return (
    <>
      <BrandsWrapper>
        {isLoading && <LoadingSpinner label={str('general.loading')} />}
        {data && Boolean(data.length)
          ? data.map(({ url, icon }) => (
              <BrandButton key={url} to={`/${url}/customers`}>
                {icon()}
              </BrandButton>
            ))
          : Object.values(appBrands).map(({ url, icon }) => (
              <BrandButton key={url} to={`/${url}/customers`}>
                {icon()}
              </BrandButton>
            ))}
      </BrandsWrapper>
    </>
  );
};
