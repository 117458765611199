import { Close } from 'assets/icons';
import { Button } from 'components/Button';
import { FlexWrapper } from 'components/FlexWrapper';
import { useContext, useRef } from 'react';
import { ArrowContainer, Popover, PopoverAlign } from 'react-tiny-popover';
import { styled, ThemeContext } from 'styled-components';
import { rem, themed } from 'utils';

const Content = styled.div<{ $paddedBorder?: boolean }>`
  padding: ${({ $paddedBorder }) => themed($paddedBorder ? 'spacing.l' : 'spacing.m')};
  background-color: ${({ $paddedBorder }) => themed($paddedBorder ? 'color.offWhite' : 'color.white')};
  box-shadow: ${rem(0, 0, 10, 2)} ${themed('color.transparentLightBlack')};
  border-radius: ${themed('spacing.m')};
`;

export type PopoverPosition = 'top' | 'bottom' | 'right' | 'left';
type PopOverProps = {
  className?: string;
  children?: React.ReactNode;
  trigger: React.ReactNode;
  isOpen: boolean;
  positions?: PopoverPosition[];
  closeIfOutsideClick?: boolean;
  onClose?: VoidFunction;
  heading?: React.ReactNode;
  showCloseButton?: boolean;
  paddedBorder?: boolean;
  arrowColor?: string;
  arrowSize?: number;
  align?: PopoverAlign;
  boundaryInset?: number;
};

export const PopOver = ({
  className,
  isOpen,
  children,
  closeIfOutsideClick = true,
  trigger,
  positions = ['bottom'],
  heading,
  showCloseButton = true,
  paddedBorder,
  onClose,
  arrowColor,
  arrowSize,
  align,
  boundaryInset
}: PopOverProps) => {
  const theme = useContext(ThemeContext);
  const buttonRef = useRef<HTMLButtonElement | undefined>() as React.RefObject<HTMLElement>;

  return (
    <Popover
      containerClassName={className}
      isOpen={isOpen}
      positions={positions}
      onClickOutside={() => closeIfOutsideClick && onClose?.()}
      ref={buttonRef}
      align={align}
      reposition
      boundaryInset={boundaryInset}
      parentElement={buttonRef.current ? buttonRef.current : undefined}
      boundaryElement={document.body}
      containerStyle={{ zIndex: '9' }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={arrowColor || heading || paddedBorder ? theme?.color.offWhite : theme?.color.white}
          arrowSize={arrowSize || 8}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <Content $paddedBorder={paddedBorder || !!heading || showCloseButton}>
            {heading && (
              <FlexWrapper>
                <h4>{heading}</h4>
                {showCloseButton && (
                  <Button onClick={onClose}>
                    <Close />
                  </Button>
                )}
              </FlexWrapper>
            )}
            {children}
          </Content>
        </ArrowContainer>
      )}
    >
      <div onClick={() => isOpen && onClose?.()}>{trigger}</div>
    </Popover>
  );
};
