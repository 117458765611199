import { Erase, SpinnerAlt } from 'assets/icons';
import { Accordion } from 'components/Accordion';
import { Button } from 'components/Button';
import React from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const FilterWidgetWrapper = styled.div<{ $isLoading?: boolean }>`
  opacity: ${({ $isLoading }) => ($isLoading ? 0.6 : 1)};
  pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'unset')};
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: ${rem(20)};
  padding-right: ${themed('spacing.m')};

  span {
    user-select: none;
    ${themed('typography.h4')};
    text-transform: uppercase;
  }
`;
const HeaderContent = styled.div`
  display: flex;
  gap: ${themed('spacing.m')};
  align-items: center;
  align-self: center;
  padding-bottom: ${themed('spacing.s')};

  // Checkbox-wrapping label
  label div {
    margin: 0; // Hack for now until we make Accordion a bit more flexible
    width: ${themed('spacing.l')};
    height: ${themed('spacing.l')};
  }
  div {
    margin: 0 ${rem(10)};
  }
`;
const ClearButton = styled(Button)`
  margin: 0;
  padding: 0;
  animation-name: ${themed('animation.zoomOut')};
  animation-timing-function: ${themed('transition.timing')};
  animation-duration: ${themed('transition.duration')};

  > svg {
    width: ${rem(20)};
    height: ${rem(20)};
    margin: 0 !important;
  }
`;

const StyledLoader = styled(SpinnerAlt)`
  width: ${rem(18)};
  height: ${rem(18)};
`;

export const FilterWidget = ({
  label,
  children,
  canClear,
  onClear,
  isExpanded,
  onExpandToggle,
  isLoading
}: {
  label: React.ReactNode;
  children: React.ReactNode;
  canClear: boolean;
  onClear: VoidFunction;
  isExpanded: boolean;
  onExpandToggle: VoidFunction;
  isLoading?: boolean;
}) => (
  <FilterWidgetWrapper $isLoading={isLoading}>
    <Accordion
      showBottomBorder
      isExpanded={isExpanded}
      onToggle={onExpandToggle}
      calcHeightOnResize={false}
      header={
        <Header>
          <HeaderContent>
            {label}
            {isLoading && <StyledLoader />}
          </HeaderContent>
          {canClear && (
            <ClearButton
              onClick={e => {
                e.stopPropagation();
                onClear();
              }}
            >
              <Erase />
            </ClearButton>
          )}
        </Header>
      }
    >
      {children}
    </Accordion>
  </FilterWidgetWrapper>
);
