import { Share } from 'assets/icons';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { PopOver } from 'components/PopOver';
import { useLocalisation } from 'providers';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  canvasCopyLinkTag,
  canvasNewLinkTag,
  canvasSaveTag,
  canvasShareCloseTag,
  canvasShareTag,
  rem,
  themed
} from 'utils';

import { CreateCanvasBoardShareResponse } from '@yourxx/types';
import { createBoardShare } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from './CurrentCanvasContext';

const ShareContent = styled.div`
  width: ${rem(300)};
`;
const ShareWrapper = styled.div<{ $isVisible?: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  flex-direction: column;
  width: ${rem(300)};
  gap: ${themed('spacing.m')};
`;
const StyledInput = styled(Input)`
  width: 100%;
  margin: 0;
  box-sizing: border-box;
`;
const StyledButton = styled(Button)`
  background-color: ${themed('color.black')};
  color: ${themed('color.white')};
  justify-content: center;
`;
const CopiedText = styled.span`
  color: ${themed('color.grey')};
  font-size: ${themed('font.size.s')};
  font-style: italic;
  text-align: center;
`;
const ResetShare = styled.a`
  text-decoration-line: underline;
  overflow-wrap: break-word;
  align-self: center;
  cursor: pointer;
`;
const GeneratedUrl = styled.span`
  color: ${themed('color.grey')};
  font-size: ${themed('font.size.m')};
  font-style: italic;
  width: 100%;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const TriggerButton = styled(Button)`
  padding: ${themed('spacing.xs')};

  svg {
    width: ${themed('spacing.xl')};
    height: ${themed('spacing.xl')};
  }
`;

export const SharePopup = () => {
  const { customerId, assortmentId, canvasBoardId } = useCurrentCanvasContext();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isCreatingShareLink, setIsCreatingShareLink] = useState<boolean>(false);
  const [shareLink, setShareLink] = useState<string>();
  const [name, setName] = useState<string>('');
  const [str] = useLocalisation();

  const onCreate = useCallback(() => {
    canvasSaveTag({
      assortmentId,
      customerId,
      comparisonId: canvasBoardId
    });
    if (name && !isCreatingShareLink && canvasBoardId) {
      setIsCreatingShareLink(true);
      createBoardShare({ boardId: canvasBoardId, name }).then(({ boardShareId }: CreateCanvasBoardShareResponse) => {
        const url = new URL(`${window.location.href.split('/canvas/')[0]}/canvas/share/${boardShareId}`);
        setShareLink(url.toString());
        setIsCreatingShareLink(false);
      });
    }
  }, [canvasSaveTag, name, isCreatingShareLink, canvasBoardId, setIsCreatingShareLink, createBoardShare, setShareLink]);

  const onReset = useCallback(() => {
    canvasNewLinkTag({
      assortmentId,
      customerId,
      comparisonId: canvasBoardId
    });
    setIsCopied(false);
    setShareLink(undefined);
    setName('');
  }, [canvasNewLinkTag, setIsCopied, setShareLink, setName]);

  const onCopyLink = useCallback(() => {
    if (shareLink) {
      canvasCopyLinkTag({
        assortmentId,
        customerId,
        comparisonId: canvasBoardId
      });
      navigator.clipboard.writeText(shareLink);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 5000);
    }
  }, [shareLink, canvasCopyLinkTag, setIsCopied]);

  return (
    <PopOver
      trigger={
        <TriggerButton
          onClick={() => {
            setIsPopoverOpen(prev => !prev);
            canvasShareTag({
              assortmentId,
              customerId,
              comparisonId: canvasBoardId
            });
          }}
        >
          <Share />
        </TriggerButton>
      }
      isOpen={isPopoverOpen}
      onClose={() => {
        setIsPopoverOpen(false);
        canvasShareCloseTag({
          assortmentId,
          customerId,
          comparisonId: canvasBoardId
        });
      }}
    >
      <ShareContent>
        <ShareWrapper $isVisible={!!shareLink}>
          <GeneratedUrl>{shareLink}</GeneratedUrl>
          <StyledButton onClick={onCopyLink}>{str('Canvas.board.sharing.copyToClipboard')}</StyledButton>
          {isCopied && <CopiedText>{str('Canvas.board.sharing.copiedToClipboard')}</CopiedText>}
          <ResetShare onClick={onReset}>{str('Canvas.board.sharing.createAnotherLink')}</ResetShare>
        </ShareWrapper>
        <ShareWrapper $isVisible={!shareLink}>
          <StyledInput
            value={name}
            onChange={el => setName(el.target.value)}
            placeholder={str('Canvas.board.sharing.nameHint')}
            disabled={isCreatingShareLink}
          />
          <StyledButton onClick={onCreate} disabled={name.length < 1 || isCreatingShareLink}>
            {isCreatingShareLink ? <LoadingSpinner /> : str('general.save')}
          </StyledButton>
        </ShareWrapper>
      </ShareContent>
    </PopOver>
  );
};
