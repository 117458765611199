import { Note } from 'assets/icons';
import { Button } from 'components/Button';
import { PopOver } from 'components/PopOver';
import { Tooltip } from 'components/Tooltip';
import { format } from 'date-fns';
import { useLocalisation } from 'providers';
import { useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { NoteField } from '@yourxx/types';

const StyledButton = styled(Button)<{ $isOpen?: boolean }>`
  background-color: ${({ $isOpen }) => ($isOpen ? themed('color.black') : themed('color.white'))};
  color: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};

  svg g {
    stroke: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};
  }
`;
const NoteIndicator = styled.span`
  position: absolute;
  top: ${themed('spacing.m')};
  right: ${themed('spacing.s')};
  width: ${rem(6)};
  height: ${rem(6)};
  border-radius: 50%;
  background-color: ${themed('color.black')};
`;
const Title = styled.p`
  margin-top: 0;
  margin-bottom: ${themed('spacing.m')};
`;
const Content = styled.div`
  width: ${rem(420)};
  height: ${rem(240)};
  background-color: ${themed('color.white')};
  border-radius: ${themed('spacing.s')};
  padding: ${themed('spacing.m')};
  position: relative;
  overflow: auto;
  box-sizing: border-box;
`;
const StyledTextArea = styled.textarea`
  outline: none;
  border: 0;
  width: 100%;
  height: 80%;
  resize: none;
  color: ${themed('color.greyDark')};
  font-weight: ${themed('font.weight.regular')};
`;
const FooterDetail = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: ${themed('spacing.l')};
  width: -webkit-fill-available;
  padding-right: ${themed('spacing.l')};
  color: ${themed('color.grey')};
`;

export const ParentNotesView = ({ note }: { note: NoteField }) => {
  const [str] = useLocalisation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const maxLength = 400;

  const notesButton = () => (
    <Tooltip text={str('PDP.parentNotes.tooltip')} position="bottom" disabled={isOpen}>
      <StyledButton onClick={() => setIsOpen(true)} $isOpen={isOpen}>
        <Note />
        {note && <NoteIndicator />}
        {str('PDP.parentNotes.title')}
      </StyledButton>
    </Tooltip>
  );

  return (
    <PopOver
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      trigger={notesButton()}
      showCloseButton={false}
      paddedBorder
    >
      <>
        <Title>{str('PDP.parentNotes.title')}</Title>
        <Content>
          <StyledTextArea value={note?.comment} maxLength={maxLength} disabled={true} />
          <FooterDetail>
            {note && (
              <span>
                {str('PDP.notes.byAuthorOnDate', {
                  author: note.author,
                  date: note.updatedAt ? format(note.updatedAt, 'hh:mm MMM do') : ''
                })}
              </span>
            )}
            <span>
              {note?.comment?.length ?? 0}/{maxLength}
            </span>
          </FooterDetail>
        </Content>
      </>
    </PopOver>
  );
};
