import { Sort, SortDown, SortUp } from 'assets/icons';
import { Button } from 'components/Button';
import { PopOver } from 'components/PopOver';
import { Tooltip } from 'components/Tooltip';
import { useLocalisation } from 'providers';
import { type ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const StyledButton = styled(Button)<{ $isOpen?: boolean }>`
  height: ${rem(34)};
  background-color: ${({ $isOpen }) => ($isOpen ? themed('color.black') : 'transparent')};
  color: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.medium')};

  svg {
    width: ${rem(22)};
    height: ${rem(22)};

    * {
      stroke: ${({ $isOpen }) => ($isOpen ? themed('color.white') : themed('color.black'))};
    }
  }
`;
const SortList = styled.ul`
  min-width: ${rem(100)};
  padding: 0;
  margin: 0 -${themed('spacing.m')};
  list-style-type: none;
`;
const SortItem = styled.li<{ $selected?: boolean }>`
  gap: ${themed('spacing.s')};
  padding: ${themed('spacing.m')} ${themed('spacing.xl')} ${themed('spacing.m')} ${themed('spacing.l')};
  font-size: ${themed('font.size.s')};
  font-weight: ${themed('font.weight.bold')};
  color: ${({ $selected }) => themed($selected ? 'color.white' : 'color.black')};
  background-color: ${({ $selected }) => themed($selected ? 'color.black' : 'color.white')};
  will-change: color, background-color;
  transition:
    color 0.2s ease-out,
    background-color 0.2s ease-out;
  user-select: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ $selected }) => themed($selected ? 'color.black' : 'color.offWhite')};
    }
  }
  svg {
    position: absolute;
    margin-left: ${themed('spacing.s')};
    width: ${themed('spacing.l')};
    height: ${themed('spacing.l')};
    pointer-events: none;
  }
`;

export enum SortDirection {
  DESC = -1,
  ASC = 1
}
export type SortDetails<T> = { by: keyof T; dir?: SortDirection };

export const SortPopOver = <T,>({
  currentSort,
  onSort,
  options,
  showLabel,
  onOpen,
  trigger: renderTrigger
}: {
  currentSort?: SortDetails<T>;
  onSort: (sortDetails: SortDetails<T>) => void;
  options: { key: keyof T; label: string }[];
  showLabel?: boolean;
  onOpen?: VoidFunction;
  trigger?: (props: { isDisabled?: boolean; isActive?: boolean; onClick: VoidFunction }) => ReactNode;
}) => {
  const [str] = useLocalisation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleSort = useCallback(
    (by: keyof T) => {
      onSort({
        by,
        dir:
          currentSort?.by === by
            ? currentSort.dir === SortDirection.ASC
              ? SortDirection.DESC
              : SortDirection.ASC
            : SortDirection.ASC
      });
    },
    [currentSort, onSort]
  );

  const onTriggerClick = useCallback(() => {
    setIsOpen(true);
    onOpen?.();
  }, [onOpen]);

  const triggerButton = renderTrigger?.({ isActive: isOpen, onClick: onTriggerClick }) ?? (
    <Tooltip text={str('ProductListing.sort.title').toUpperCase()} noPadding disableTooltipOnly={showLabel}>
      <StyledButton onClick={onTriggerClick} $isOpen={isOpen}>
        <Sort />
        {showLabel && str('ProductListing.sort.title')}
      </StyledButton>
    </Tooltip>
  );

  return (
    <PopOver isOpen={isOpen} onClose={() => setIsOpen(false)} trigger={triggerButton} showCloseButton={false}>
      <SortList>
        {options.map(s => (
          <SortItem key={String(s.key)} onClick={() => handleSort(s.key)} $selected={currentSort?.by === s.key}>
            {s.label}
            {currentSort?.by === s.key && currentSort.dir === SortDirection.ASC && <SortUp />}
            {currentSort?.by === s.key && currentSort.dir === SortDirection.DESC && <SortDown />}
          </SortItem>
        ))}
      </SortList>
    </PopOver>
  );
};
