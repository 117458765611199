import { AwsRum, AwsRumConfig } from 'aws-rum-web';

export const initAwsRum = async () => {
  try {
    const res = await fetch(`/rum.json`);
    if (!res.ok) {
      throw new Error('Failed to fetch rum.json');
    }
    const rumConfig = await res.json();
    const { appId, guestRoleArn, identityPoolId, awsRegion } = rumConfig;
    const { VITE_VERSION } = process.env;
    if (appId && guestRoleArn && identityPoolId) {
      const config: AwsRumConfig = {
        endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
        allowCookies: true,
        identityPoolId,
        guestRoleArn,
        dispatchInterval: 10000,
        sessionSampleRate: 0.5,
        sessionEventLimit: 0,
        sessionLengthSeconds: 0,
        telemetries: [
          [
            'errors',
            {
              stackTraceLength: 500,
              ignore: (err: ErrorEvent) => {
                return err?.message && new RegExp(`(ResizeObserver loop).*`, `gm`).test(err.message);
              }
            }
          ],
          'performance',
          [
            'http',
            {
              stackTraceLength: 500,
              recordAllRequests: false,
              urlsToExclude: [new RegExp(`.*api/v2/image.*`, `gm`), new RegExp(`.*google-analytics.*`, `gm`)]
            }
          ]
        ]
      };
      return new AwsRum(appId, `${VITE_VERSION ?? '0.0.0'}`, awsRegion ?? 'us-west-2', config);
    }
  } catch (err: any) {
    return undefined;
  }
};

export let awsRum: AwsRum | undefined;
(async () => {
  awsRum = await initAwsRum();
})();
