import { ProductSliderData, ProductView } from '@yourxx/types';
import { getProducts as apiGetProducts } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export const useGetProducts = () => {
  const { assortmentId, lineId } = useCurrentCanvasContext();
  const { setLineProducts, setLineParentProducts } = useCanvas();

  const getProducts = async ({
    handleLoading,
    handleSuccess,
    handleError
  }: {
    handleLoading: (loading: boolean) => void;
    handleSuccess: (data?: ProductSliderData[]) => void;
    handleError?: VoidFunction;
  }) => {
    if (lineId || assortmentId) {
      handleLoading(true);
      await Promise.all([
        apiGetProducts<ProductSliderData>({
          id: lineId ?? assortmentId,
          view: ProductView.Slider,
          parent: 'false',
          product_status: 'ADD+PENDING'
        }),
        apiGetProducts<ProductSliderData>({
          id: lineId ?? assortmentId,
          view: ProductView.Slider,
          parent: 'true',
          product_status: 'ADD+PENDING'
        })
      ])
        .then(([products, parentProducts]) => {
          setLineProducts(products.products ?? []);
          setLineParentProducts(parentProducts.products ?? []);
          handleSuccess();
        })
        .catch(e => {
          console.error(e);
          handleLoading(false);
          handleError?.();
        });
    }
  };

  return { getProducts };
};
