import { imageOrder } from '@yourxx/support';
import { ApiGetProductOut, ProductDetails, ProductDetailsData, ProductListData, UIProduct } from '@yourxx/types';

import { persistedStore } from './persistedStore';
import { getImageUrl } from './url';

const isProductData = (input: unknown): input is ProductDetailsData => {
  return !!(input as ProductDetailsData).images;
};

const isProductDetailsData = (value: unknown): value is ProductListData =>
  !!(value && (value as ProductListData).story);

export const mapProduct = <T extends ProductDetails>({
  products,
  details,
  storyImages
}: ApiGetProductOut<T>): UIProduct<T>[] => {
  const { imageUrl, imageUrlParams } = details;
  const imgFmt = persistedStore.getImgFormat() ?? 'jpg';
  return (
    products?.map((p: T): UIProduct<T> => {
      const res: UIProduct<T> = {
        ...p,
        url: getImageUrl({ imageUrl, imageUrlParams, imgFmt, pc9: p.pc9 }),
        currency: details?.currency
      };
      if (isProductData(p) && p.images && p.images.length > 0) {
        res.imageUrls = p.images
          .sort((a, b) => ((imageOrder[a] ?? 99) > (imageOrder[b] ?? 99) ? 1 : -1))
          .map(imageType => ({
            imageType,
            url: getImageUrl({ imageUrl, imageUrlParams, imgFmt, pc9: p.pc9, imageType })
          }));
      }
      if (storyImages && isProductDetailsData(p) && p?.story?.length && p.story.length > 0) {
        res.storyImages = p.story.map(title => {
          const matchedStory = storyImages.find(s => s.title === title);

          if (matchedStory && matchedStory.imageUrl) {
            const url = new URL(imageUrl);
            url.pathname = matchedStory.imageUrl;
            return { title, imageUrl: url.toString() };
          }

          return { title };
        });
      }
      return res;
    }) ?? []
  );
};
