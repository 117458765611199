import { v4 as uuid } from 'uuid';

export abstract class DomainEvent<P = void> {
  protected _traceId: string = uuid();
  public readonly occurredAt = new Date();

  constructor(public readonly payload: P) {}

  public traceId() {
    return this._traceId;
  }

  public trace(traceable: string | DomainEvent<unknown>): this {
    this._traceId = traceable instanceof DomainEvent ? traceable.traceId() : traceable;
    return this;
  }

  public isLinkedTo(traceable: string | DomainEvent<unknown>): boolean {
    return this._traceId === (traceable instanceof DomainEvent ? traceable.traceId() : traceable);
  }
}

export type Handler<E> = (event: E) => void;

export type EventConstructor<E extends InstanceType<typeof DomainEvent>> = { new (...args: any[]): E };

export interface EventBus {
  on<E extends InstanceType<typeof DomainEvent<unknown>>>(
    eventType: EventConstructor<E>,
    handler: Handler<E>
  ): VoidFunction;
  emit<E extends InstanceType<typeof DomainEvent<unknown>>>(event: E): void;
  trace(traceable: string | DomainEvent<unknown>): DomainEvent<unknown>[];
}

export const EventBus = (): EventBus => {
  const subscribers: Map<typeof DomainEvent<unknown>, Set<Handler<unknown>>> = new Map();
  const events: DomainEvent<unknown>[] = [];

  return {
    on(eventConstructor, handler) {
      const handlers = subscribers.get(eventConstructor) ?? new Set();
      subscribers.set(eventConstructor, handlers.add(handler as Handler<unknown>));

      return () => {
        const handlers = subscribers.get(eventConstructor);
        const newHandlers = new Set(handlers);
        newHandlers.delete(handler as Handler<unknown>);
        subscribers.set(eventConstructor, newHandlers);
      };
    },

    emit(event) {
      events.push(event);
      const handlers = subscribers.get(event.constructor as EventConstructor<typeof event>);
      if (handlers) for (const handler of Array.from(handlers)) handler(event);
    },

    trace(traceable) {
      if (traceable === '*') return [...events];
      return events.filter(event => event.isLinkedTo(traceable));
    }
  };
};
