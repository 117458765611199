import { CanvasProductDetails } from '@yourxx/types';
import { getCanvasProductDetails as apiGetCanvasProductDetails } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export const useGetProductDetails = () => {
  const { lineId, assortmentId, canvasType } = useCurrentCanvasContext();
  const { setProductDetails, productDetails, boardItems, shareBoardData } = useCanvas();

  const getProductDetails = async ({
    handleLoading,
    handleSuccess,
    handleError
  }: {
    handleLoading: (loading: boolean) => void;
    handleSuccess: (data?: CanvasProductDetails[]) => void;
    handleError?: VoidFunction;
  }) => {
    const filteredPc9Codes =
      canvasType === 'share'
        ? shareBoardData?.pc9Codes
        : boardItems?.reduce((agg: string[], bi) => {
            if (bi.pc9Code && productDetails?.findIndex(pd => pd.pc9Code === bi.pc9Code) === -1) {
              agg.push(bi.pc9Code);
            }
            return agg;
          }, []);
    if ((lineId || assortmentId) && filteredPc9Codes && filteredPc9Codes.length > 0) {
      handleLoading(true);
      apiGetCanvasProductDetails({
        lineId,
        assortmentId,
        pc9Codes: filteredPc9Codes
      })
        .then((data: CanvasProductDetails[]) => {
          setProductDetails([...(productDetails ?? []), ...data]);
          handleSuccess();
        })
        .catch(() => {
          handleLoading(false);
          handleError?.();
        });
    }
  };

  return { getProductDetails };
};
