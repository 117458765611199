import { SortDown, SortUp } from 'assets/icons';
import { SortDirection } from 'components';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import {
  AccessorFn,
  CellContext,
  ColumnDefTemplate,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  HeaderContext,
  RowSelectionState,
  useReactTable
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';

const Container = styled.div`
  position: relative;
  width: 100%;
`;
const TableContainer = styled.div`
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 0 ${themed('spacing.xxxl')};
  box-sizing: border-box;

  // TODO: Temporary layout fix until old views get migrated to new layout.
  [class^='CommonLayout__Container'] & {
    padding: 0 ${themed('spacing.xl')};
  }
`;
const StyledTable = styled.table`
  width: 100%;
  box-sizing: border-box;
  border-radius: ${themed('spacing.m')};
  font-size: ${themed('font.size.s')};
  padding-bottom: ${themed('spacing.m')};
  border-collapse: collapse;

  thead {
    position: sticky;
    z-index: 1;
    top: 0;
  }
  tbody {
    position: relative;
    top: -2px;
  }
`;
const HeadRow = styled.tr<{ $alternateStyle?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${themed('spacing.m')} ${({ $alternateStyle }) => ($alternateStyle ? 0 : themed('spacing.m'))};
  background-color: ${themed('color.white')};
  box-shadow: ${({ $alternateStyle }) => ($alternateStyle ? 'none' : themed('boxShadow'))};
  border-top-left-radius: ${({ $alternateStyle }) => ($alternateStyle ? 0 : themed('spacing.m'))};
  border-top-right-radius: ${({ $alternateStyle }) => ($alternateStyle ? 0 : themed('spacing.m'))};
  box-sizing: border-box;
  border-bottom-style: ${({ $alternateStyle }) => ($alternateStyle ? 'solid' : 'none')};
  border-bottom-width: ${themed('spacing.xs')};
  border-bottom-color: ${themed('color.black')};

  > th {
    position: relative;

    &::after {
      content: ${({ $alternateStyle }) => ($alternateStyle ? `''` : 0)};
      position: absolute;
      right: 0;
      width: ${rem(1)};
      height: ${rem(15)};
      background-color: ${themed('color.greyMid')};
    }
  }
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% - ${themed('spacing.m')});
    background-color: ${themed('color.offWhite')};
  }
`;
const HeadCell = styled.th<{ $meta?: ColumnMeta; $width: number; $canSort?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ $meta }) =>
    !$meta?.alignCellOnly
      ? $meta?.align
        ? $meta.align === 'left'
          ? 'flex-start'
          : $meta.align === 'right'
            ? 'flex-end'
            : 'center'
        : 'center'
      : 'center'};
  text-align: ${({ $meta }) => ($meta?.align ? $meta.align : 'center')};
  width: ${({ $meta, $width }) => ($meta?.flex ? 'auto' : rem($width))};
  min-width: ${({ $meta }) => ($meta?.minSize ? rem($meta.minSize) : 'auto')};
  flex: ${({ $meta }) => ($meta?.flex ? $meta.flex : 'none')};
  padding: 0 ${({ $meta }) => ($meta?.noPadding ? 0 : themed('spacing.m'))};
  box-sizing: border-box;
  user-select: ${({ $canSort }) => ($canSort ? 'none' : 'auto')};
  cursor: ${({ $canSort }) => ($canSort ? 'pointer' : 'default')};
`;
const SortContainer = styled.div`
  position: relative;

  svg {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: ${themed('spacing.l')};
    height: ${themed('spacing.l')};
    margin-left: ${themed('spacing.s')};
  }
`;
const TableRow = styled.tr<{ $alternateStyle?: boolean }>`
  width: 100%;
  display: flex;
  align-items: ${({ $alternateStyle }) => ($alternateStyle ? 'stretch' : 'center')};
  position: absolute;
  padding: 0 ${({ $alternateStyle }) => ($alternateStyle ? 0 : themed('spacing.m'))};
  background-color: ${themed('color.white')};
  box-sizing: border-box;
  border-radius: ${({ $alternateStyle }) => ($alternateStyle ? 0 : themed('spacing.m'))};
  border-bottom-style: ${({ $alternateStyle }) => ($alternateStyle ? 'none' : 'solid')};
  border-bottom-width: ${themed('spacing.m')};
  border-bottom-color: ${themed('color.offWhite')};

  &:after {
    content: ${({ $alternateStyle }) => ($alternateStyle ? 0 : `''`)};
    width: 100%;
    height: ${themed('spacing.m')};
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${themed('color.white')};
    box-shadow: 0 ${themed('spacing.m')} 0 ${themed('spacing.m')} ${themed('color.offWhite')};
    border-bottom-left-radius: ${themed('spacing.m')};
    border-bottom-right-radius: ${themed('spacing.m')};
  }
`;
const TableCell = styled.td<{ $meta?: ColumnMeta; $width: number; $alternateStyle?: boolean }>`
  width: ${({ $meta, $width }) => ($meta?.flex ? 'auto' : rem($width))};
  min-width: ${({ $meta }) => ($meta?.minSize ? rem($meta.minSize) : 'auto')};
  flex: ${({ $meta }) => ($meta?.flex ? $meta.flex : 'none')};
  text-align: ${({ $meta }) => ($meta?.align ? $meta.align : 'center')};
  aspect-ratio: ${({ $meta }) => ($meta?.aspectRatio ? $meta.aspectRatio : 'auto')};
  padding: ${({ $meta }) => ($meta?.noPadding ? 0 : themed('spacing.m'))};
  box-sizing: border-box;
  display: ${({ $alternateStyle }) => ($alternateStyle ? 'flex' : 'table-cell')};
  border-style: ${({ $alternateStyle, $meta }) => (!$alternateStyle || $meta?.noBorder ? 'none' : 'solid')};
  border-color: ${themed('color.greyMid')};
  border-width: ${rem(1)};
  border-top: none;
  align-items: center;
  justify-content: ${({ $meta }) =>
    !$meta?.alignHeaderOnly
      ? $meta?.align
        ? $meta.align === 'left'
          ? 'flex-start'
          : $meta.align === 'right'
            ? 'flex-end'
            : 'center'
        : 'center'
      : 'center'};

  img {
    width: 100%;
    height: 100%;
  }
`;

type ColumnMeta = {
  flex?: number;
  minSize?: number;
  align?: 'left' | 'center' | 'right';
  alignCellOnly?: boolean;
  alignHeaderOnly?: boolean;
  aspectRatio?: number;
  noBorder?: boolean;
  noPadding?: boolean;
};
export type ListColumn = {
  accessorKey: string;
  accessorFn?: AccessorFn<any>;
  header: ColumnDefTemplate<HeaderContext<any, any>>;
  cell: ColumnDefTemplate<CellContext<any, any>>;
  enableSorting?: boolean;
  defaultSortDir?: SortDirection;
  size?: number;
  meta?: ColumnMeta;
};
export type ListViewProps = {
  className?: string;
  data: any[];
  columns: ListColumn[];
  enableMultiRowSelection?: boolean;
  onSelect?: (selectedIndexes: number[]) => void;
  deselectAllRows?: VoidFunction;
  alternateStyle?: boolean;
};

export const ListView = forwardRef((props: ListViewProps, ref) => {
  const { className, data, columns, enableMultiRowSelection, onSelect, alternateStyle } = props;
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const tableRef = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(ref, () => ({
    deselectAllRows() {
      setRowSelection({});
    }
  }));

  const columnWithDefaultSort = useMemo(() => columns.find(c => c.defaultSortDir), [columns]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableMultiRowSelection,
    initialState: {
      ...(columnWithDefaultSort && {
        sorting: [
          {
            id: columnWithDefaultSort.accessorKey,
            desc: columnWithDefaultSort.defaultSortDir === -1
          }
        ]
      }),
      rowSelection
    },
    onRowSelectionChange: setRowSelection
  });

  const rows = useMemo(() => table.getRowModel().rows, [getSortedRowModel()]);
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 80,
    getScrollElement: () => tableRef.current,
    overscan: 5,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined
  });

  useEffect(() => {
    const selectedIndexes = Object.entries(rowSelection).map(([key]) => +key);
    onSelect?.(selectedIndexes);
  }, [rowSelection]);

  return (
    <Container className={className}>
      <TableContainer ref={tableRef}>
        <StyledTable>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <HeadRow key={headerGroup.id} $alternateStyle={alternateStyle}>
                {headerGroup.headers.map(header => (
                  <HeadCell
                    key={header.id}
                    $meta={header.column.columnDef.meta}
                    $width={header.getSize()}
                    $canSort={header.column.getCanSort()}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <SortContainer>
                        {header.column.getIsSorted() === 'asc' ? (
                          <SortUp />
                        ) : header.column.getIsSorted() === 'desc' ? (
                          <SortDown />
                        ) : null}
                      </SortContainer>
                    )}
                  </HeadCell>
                ))}
              </HeadRow>
            ))}
          </thead>
          <tbody style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index];
              return (
                <TableRow
                  key={row.id}
                  data-index={virtualRow.index}
                  ref={node => rowVirtualizer.measureElement(node)}
                  $alternateStyle={alternateStyle}
                  style={{ transform: `translateY(${virtualRow.start + (alternateStyle ? 0 : 10)}px)` }}
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      key={cell.id}
                      $meta={cell.column.columnDef.meta}
                      $width={cell.column.getSize()}
                      $alternateStyle={alternateStyle}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </tbody>
        </StyledTable>
      </TableContainer>
    </Container>
  );
});
