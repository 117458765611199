import { ChevronLeft, ChevronRight } from 'assets/icons';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { themed } from 'utils';

const StyledPagination = styled(ReactPaginate)`
  list-style-type: none;
  width: 100%;
  margin: ${themed('spacing.l')} 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: ${themed('spacing.s')};
  box-sizing: border-box;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${themed('spacing.xxl')};
    height: ${themed('spacing.xxl')};
    line-height: ${themed('spacing.xxl')};
    border-radius: ${themed('spacing.s')};
    will-change: background-color;
    transition: background-color 0.2s ease-out;
    font-size: ${themed('font.size.m')};
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        background-color: ${themed('color.greyMid')};
      }
    }
    &.selected {
      color: ${themed('color.white')};
      background-color: ${themed('color.black')};

      @media (hover: hover) {
        &:hover {
          background-color: ${themed('color.black')};
        }
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
    }
    &.disabled {
      pointer-events: none;

      a {
        color: ${themed('color.grey')};
      }
      svg {
        stroke: ${themed('color.grey')};
      }
    }
    &.previous,
    &.next {
      svg {
        width: ${themed('spacing.l')};
        height: ${themed('spacing.l')};
      }
    }
  }
`;

export type PaginationProps = {
  className?: string;
  itemsCount: number;
  itemsPerPage: number;
  onPageChange: (offset: number) => void;
};

export const Pagination = ({ className, itemsCount, itemsPerPage, onPageChange }: PaginationProps) => {
  const pageCount = Math.ceil(itemsCount / itemsPerPage);

  return pageCount > 1 ? (
    <StyledPagination
      className={className}
      breakLabel="..."
      nextLabel={<ChevronRight />}
      onPageChange={({ selected }) => onPageChange(selected * itemsPerPage)}
      pageRangeDisplayed={2}
      pageCount={pageCount}
      previousLabel={<ChevronLeft />}
      renderOnZeroPageCount={null}
    />
  ) : null;
};
