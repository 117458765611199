import { SpinnerAlt } from 'assets/icons';
import { FlexWrapper } from 'components/FlexWrapper';
import styled from 'styled-components';
import { rem } from 'utils';

const Spinner = styled(SpinnerAlt)<{ $size: number }>`
  width: ${({ $size }) => rem($size)};
  height: ${({ $size }) => rem($size)};
`;

export const LoadingSpinner = ({
  className,
  size = 30,
  label
}: {
  className?: string;
  size?: number;
  label?: string;
}) => (
  <FlexWrapper className={className}>
    <Spinner $size={size} />
    {label && <p>{label}</p>}
  </FlexWrapper>
);
