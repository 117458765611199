import { Entity, type EntityContext } from 'domain-events/domain-events';
import { useCallback, useMemo, useState } from 'react';
import type { AssortmentsService } from 'services';

import type { SummaryEntry } from '@yourxx/types';

import { AssortmentSummaryState } from './AssortmentSummaryState';

export enum State {
  Idle = 'Idle',
  Loaded = 'Loaded',
  Loading = 'Loading'
}

type UseAssortmentSummaryProps = {
  service: Pick<AssortmentsService, 'loadSummary'>;
  context: EntityContext | undefined;
  currency: string;
};

export type UseAssortmentSummaryReturn =
  | { state: State.Idle; isExcludingCarryOver: boolean; loadSummary: VoidFunction }
  | { state: State.Loading; isExcludingCarryOver: boolean }
  | {
      state: State.Loaded;
      isExcludingCarryOver: boolean;
      toggleCarryOver: VoidFunction;
      summary: { makeup: AssortmentSummaryState; revenue: AssortmentSummaryState };
    };

// Static reference
const emptyData: SummaryEntry[] = [];

export const useAssortmentSummary = ({
  service,
  context,
  currency
}: UseAssortmentSummaryProps): UseAssortmentSummaryReturn => {
  const [state, setState] = useState(State.Idle);
  const [isExcludingCarryOver, setIsExcludingCarryOver] = useState(false);
  const [data, setData] = useState<{
    withCarryOver: readonly SummaryEntry[];
    withoutCarryOver: readonly SummaryEntry[];
  }>({ withCarryOver: emptyData, withoutCarryOver: emptyData });
  const [_, setRenderTimestamp] = useState(new Date());

  const forceRerender = useCallback(() => setRenderTimestamp(new Date()), []);

  const summary = useMemo(() => {
    const currentData = isExcludingCarryOver ? data.withoutCarryOver : data.withCarryOver;
    return {
      // Two instances with separate expansion and sorting states
      makeup: AssortmentSummaryState(currentData, currency, forceRerender),
      revenue: AssortmentSummaryState(currentData, currency, forceRerender)
    };
  }, [currency, data.withCarryOver, data.withoutCarryOver, forceRerender, isExcludingCarryOver]);

  const loadSummary = useCallback(
    (excludeCarryOver = false) => {
      if (!context) {
        console.warn('No context passed when requesting to load assortment summary data.');
        return;
      }
      if (context.entity !== Entity.Assortment && context.entity !== Entity.FinalAssortment) {
        console.warn(`Wrong Entity used ("${context.entity}") when requesting to load assortment summary data.`);
        return;
      }

      setState(State.Loading);
      service
        .loadSummary({ context, excludeCarryOver })
        .then(data => {
          setData(prev => ({ ...prev, [excludeCarryOver ? 'withoutCarryOver' : 'withCarryOver']: data }));
          setState(State.Loaded);
        })
        .catch(() => {
          setState(State.Idle);
        });
    },
    [context, service]
  );

  const toggleCarryOver = useCallback(() => {
    setIsExcludingCarryOver(prev => {
      const newIsExcludingCarryOver = !prev;

      setTimeout(() => {
        if (newIsExcludingCarryOver && data.withoutCarryOver === emptyData) loadSummary(newIsExcludingCarryOver);
      }, 0);

      return newIsExcludingCarryOver;
    });
  }, [data.withoutCarryOver, loadSummary]);

  if (state === State.Idle) return { state, isExcludingCarryOver, loadSummary };

  if (state === State.Loading) return { state, isExcludingCarryOver };

  return {
    state,
    summary,
    isExcludingCarryOver,
    toggleCarryOver
  };
};
