import { type AnyFilter, FilterType } from '@yourxx/support';

export const getStatusValueFrom = (filters: ReadonlyArray<AnyFilter>): string[] | undefined => {
  if (!filters.length) return;
  const statusFilter = filters.find(filter => filter.id === 'status');
  if (!statusFilter?.value) return;
  switch (statusFilter.type) {
    case FilterType.Single:
      return [statusFilter.value] as string[];
    case FilterType.Multi:
      return statusFilter.value as string[];
    default:
      return undefined;
  }
};
