import { persistedStore } from './persistedStore';

const permissionsPrefix = () => {
  const path = window.location.pathname
    .replace(new RegExp('/([\\dA-Z]{5}-[\\dA-Z]{4})$', 'gm'), 'pdp')
    .replace(new RegExp(`(customers\\/.*\\/\\w*-\\w*-\\w*-\\w*-\\w*)`, 'gm'), 'customers/assortment/')
    .replace(new RegExp(`\\/(stories|summary)`, 'gm'), 'products');
  const matches = path.match(new RegExp('(customers|assortment|lines|parent|products|final|pdp)', 'gm'));
  return matches ? matches?.join('.') : '';
};

const user = () => {
  let featureAccess: Record<string, boolean> = persistedStore?.getUserProfile()?.ui ?? {};
  let enableLogging = false;

  const updateAccess = (access?: Record<string, boolean>) => {
    featureAccess = access ?? {};
    window.dispatchEvent(new Event('UserAccessUpdated'));
    enableLogging = Boolean(featureAccess['profile.refreshPermissions']);
  };
  const canUse = (feature: string, prefix?: string) => {
    feature = [prefix ?? permissionsPrefix(), feature].filter(Boolean).join('.');
    if (!featureAccess[feature] && enableLogging) {
      console.log({ [feature]: Boolean(featureAccess[feature]) });
    }
    return Boolean(featureAccess[feature]);
  };

  const canNotUse = (feature: string, prefix?: string) => {
    return !canUse(feature, prefix);
  };

  return { canUse, canNotUse, updateAccess };
};

export const iam = user();

//customers.assortment.products.audit.view
