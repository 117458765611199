import styled from 'styled-components';
import { rem, themed } from 'utils';

const Tag = styled.div<{ $backgroundColor: 'red' | 'gray' }>`
  display: inline-flex;
  font-size: ${themed('font.size.xs')};
  font-weight: ${themed('font.weight.bold')};
  justify-content: center;
  align-items: center;
  gap: ${themed('spacing.xs')};
  text-transform: uppercase;
  background-color: ${({ $backgroundColor: $color }) =>
    $color === 'red' ? themed('color.red') : themed('color.offWhite')};
  color: ${({ $backgroundColor: $color }) => ($color === 'red' ? themed('color.white') : themed('color.black'))};
  padding: ${themed('spacing.xs')};
  border-radius: ${themed('spacing.s')};
  min-width: ${rem(32)};
  width: fit-content;

  > * {
    margin: 0 !important;
  }
`;

export { Tag };
