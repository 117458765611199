import { IAM } from 'components';
import { SlotId, Toolbar } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { rem, themed } from 'utils';

import { getV1Url } from '@yourxx/ui-utils/src/utils/appSwitching';

const Wrapper = styled.div`
  a {
    text-decoration: none;

    span {
      position: relative;
      top: ${rem(1)};
      ${themed('typography.h5')};
      font-weight: ${themed('font.weight.bold')};
      text-decoration: none;
      text-transform: uppercase;
    }
  }
`;

const Setup = () => {
  const [str] = useLocalisation();
  const { pathname } = useLocation();

  const v1Url = useMemo(() => {
    return getV1Url(window.location.href);
  }, [window.location.href]);

  useSlot(
    SlotId.HeaderTopToolbar,
    useMemo(
      () => (
        <Wrapper>
          <Toolbar.Item
            key={pathname}
            icon={<span>{str('general.goToV1')}</span>}
            onClick={() => {
              return (window.location.href = v1Url);
            }}
          />
        </Wrapper>
      ),
      [pathname, str]
    ),
    -Infinity
  );

  return null;
};

export const GoToV1LinkSetup = () => {
  return (
    <IAM action={'viewGoToV1'} prefix={'header'}>
      <Setup />
    </IAM>
  );
};
