import { Dropdown, DropdownItemContentType } from 'components/Dropdown';
import { useLocalisation } from 'providers';
import { Metric } from 'providers/CanvasProvider';
import { useMemo } from 'react';
import { canvasMetricValueTag, debounce } from 'utils';

import { useCurrentCanvasContext } from './CurrentCanvasContext';
import { useCanvas } from './hooks';

export const allMetrics: Metric[] = [
  'COLOUR',
  'COLOUR_GROUP',
  'FIT',
  'MFP_CATEGORY',
  'FABRIC',
  'PLANNED_LIFECYCLE',
  'PRICE_POSITIONING',
  'RETAIL_PRICE',
  'SEASONALITY',
  'WHOLESALE_PRICE'
];

const METRIC_SAVE_DEBOUNCE_DELAY = 2000; // ms

export const MetricSelector = () => {
  const { customerId, assortmentId, canvasBoardId } = useCurrentCanvasContext();
  const [str] = useLocalisation();
  const { metrics, setMetrics } = useCanvas();

  const debounceTags = useMemo(
    () =>
      debounce((updatedMetrics: Metric[]) => {
        canvasMetricValueTag({
          assortmentId: assortmentId,
          customerId: customerId,
          canvasBoardId,
          val: updatedMetrics.join(', ')
        });
      }, METRIC_SAVE_DEBOUNCE_DELAY),
    [metrics, canvasBoardId, canvasMetricValueTag]
  );

  const updateCheckedState = (isChecked: boolean, metric: Metric) => {
    const others = metrics.filter(m => m !== metric);
    const updatedMetrics = isChecked ? [...others, metric] : others;
    setMetrics(updatedMetrics);
    debounceTags(updatedMetrics);
  };

  return (
    <Dropdown
      isMultiselect
      itemContentType={DropdownItemContentType.CHECKBOX}
      trigger={
        metrics.length === 0 ? str('Canvas.metrics.select') : str('Canvas.metrics.count', { count: metrics.length })
      }
      items={allMetrics.map(metric => ({
        key: metric,
        label: metric.replace('_', ' ')
      }))}
      onSelect={(key, selected) => {
        updateCheckedState(selected ?? true, allMetrics.find(x => x === key) ?? allMetrics[0]);
      }}
      defaultSelected={metrics}
    />
  );
};
