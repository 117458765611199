import { Entity, type EntityContext } from 'domain-events';
import { useServices } from 'services';

import { AssortmentSummary } from './AssortmentSummary';
import { useAssortmentSummary } from './useAssortmentSummary';

export const useAssortmentSummaryUI = ({ context, currency }: { context?: EntityContext; currency: string }) => {
  const { assortmentsService: service } = useServices();
  const state = useAssortmentSummary({ service, context, currency });

  if (context?.entity !== Entity.Assortment && context?.entity !== Entity.FinalAssortment)
    return { ui: <div>Not the right context</div> };

  return {
    ui: <AssortmentSummary state={state} />
  };
};
