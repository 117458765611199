import { Button } from 'components/Button';
import { FlexWrapper } from 'components/FlexWrapper';
import { styled } from 'styled-components';
import { themed } from 'utils';

const Controls = styled.div`
  display: flex;
  gap: ${themed('spacing.l')};
`;

export const ResourceLoadingFailed = ({
  onRetry,
  retryLabel,
  onCancel,
  cancelLabel,
  error
}: {
  onRetry?: VoidFunction;
  retryLabel?: string;
  onCancel?: VoidFunction;
  cancelLabel?: string;
  error: string;
}) => (
  <FlexWrapper>
    <p>{error}</p>
    <Controls>
      {onCancel && cancelLabel && (
        <Button onClick={onCancel} variant="secondary">
          {cancelLabel}
        </Button>
      )}
      {onRetry && retryLabel && (
        <Button onClick={onRetry} variant="primary">
          {retryLabel}
        </Button>
      )}
    </Controls>
  </FlexWrapper>
);
