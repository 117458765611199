import { SlotId, Toolbar, type ToolbarItemProps } from 'pages';
import { useSlot } from 'providers';
import { type ComponentType, type ReactNode, useMemo } from 'react';

export type ToolbarControlRenderer = (params: { Control: ComponentType<ToolbarItemProps> }) => ReactNode;

/**
 * IMPORTANT!!! Make sure your wrap your `item` with a `useMemo` or `useCallback` to avoid continuous re-rendering.
 */
export const useAddOrderToolbarControl = (item: ToolbarItemProps | ToolbarControlRenderer, position = 0) => {
  useSlot(
    SlotId.OrderToolbar,
    useMemo(() => {
      return typeof item === 'function' ? item({ Control: Toolbar.Item }) : <Toolbar.Item {...item} />;
    }, [item]),
    position
  );
};
