import type { EntityContext } from 'domain-events';

export interface AddProductsCommand<Product, Attributes> {
  context: EntityContext;
  sourceAssortmentId?: string;
  attributes?: Attributes;
  items: Product[];
}

export interface UpdateProductsCommand<Product, Attributes> {
  context: EntityContext;
  sourceAssortmentId?: string;
  attributes: Attributes;
  items: Product[];
}

export interface RemoveProductsCommand<Product> {
  context: EntityContext;
  items: Product[];
}

type PC9Product = { pc9: string };

export interface ProductsService {
  add<P extends PC9Product, A extends Record<string, any>>(command: AddProductsCommand<P, A>): Promise<void>;
  update<P extends PC9Product, A extends Record<string, any>>(command: UpdateProductsCommand<P, A>): Promise<void>;
  remove<P extends PC9Product>(command: RemoveProductsCommand<P>): Promise<void>;
}

export interface RemovedProductsErrorReport<Product> {
  removed: Product[];
  errors: { reason: string; product: Product }[];
}

export class PartialProductsRemovalError<P> extends Error {
  constructor(
    public readonly targetAssortmentId: string,
    public readonly report: RemovedProductsErrorReport<P>
  ) {
    super();
  }
}
