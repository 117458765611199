import { Dialog, Toggle } from 'components';
import { useLocalisation } from 'providers';
import { useState } from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

import { FeatureFlags } from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

const Content = styled.div`
  width: 100%;
  min-height: ${rem(160)};
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.l')};
`;
const ToggleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.xs')};

  label {
    font-weight: ${themed('font.weight.semiBold')};
    text-transform: uppercase;
    letter-spacing: ${rem(-0.1)};
    flex: 1;
  }
  p {
    font-size: ${themed('font.size.s')};
    color: ${themed('color.grey')};
    margin: 0;
    padding-right: ${rem(60)};
  }
`;

enum VisibilityOptions {
  parentLine = 'paretLine',
  prebookDeliveries = 'prebookDeliveries',
  atOnceDeliveries = 'atOnceDeliveries',
  forecastInfo = 'forecastInfo'
}
type TypeFromVisibilityOptions<T extends Record<string, string>> = {
  [K in keyof T]: boolean;
};
export type VisibilitySettings = TypeFromVisibilityOptions<typeof VisibilityOptions>;

export const VisibilitySettingsModal = ({
  visibilitySettings,
  onConfirm,
  onCancel
}: {
  visibilitySettings: VisibilitySettings;
  onConfirm: (settings: VisibilitySettings) => void;
  onCancel: VoidFunction;
}) => {
  const [str] = useLocalisation();
  const [settings, setSettings] = useState<VisibilitySettings>(visibilitySettings);

  return (
    <Dialog
      title={str('Assortment.visibilitySettings.title')}
      onClose={onCancel}
      content={
        <Content>
          {canUse(FeatureFlags.Assortment_ViewParentCollection) && (
            <ToggleWrapper>
              <Toggle
                defaultChecked={settings.parentLine}
                onChange={checked => setSettings(prev => ({ ...prev, parentLine: checked }))}
                label={str('Assortment.visibilitySettings.parentLine.title')}
              />
              <p>{str('Assortment.visibilitySettings.parentLine.description')}</p>
            </ToggleWrapper>
          )}
          {canUse(FeatureFlags.Product_ViewDeliveries) && (
            <>
              <ToggleWrapper>
                <Toggle
                  defaultChecked={settings.prebookDeliveries}
                  onChange={checked => setSettings(prev => ({ ...prev, prebookDeliveries: checked }))}
                  label={str('Assortment.visibilitySettings.prebookDeliveries.title')}
                />
                <p>{str('Assortment.visibilitySettings.prebookDeliveries.description')}</p>
              </ToggleWrapper>
              <ToggleWrapper>
                <Toggle
                  defaultChecked={settings.atOnceDeliveries}
                  onChange={checked => setSettings(prev => ({ ...prev, atOnceDeliveries: checked }))}
                  label={str('Assortment.visibilitySettings.atOnceDeliveries.title')}
                />
                <p>{str('Assortment.visibilitySettings.atOnceDeliveries.description')}</p>
              </ToggleWrapper>
            </>
          )}
          {canUse(FeatureFlags.Product_ViewForecast) && (
            <ToggleWrapper>
              <Toggle
                defaultChecked={settings.forecastInfo}
                onChange={checked => setSettings(prev => ({ ...prev, forecastInfo: checked }))}
                label={str('Assortment.visibilitySettings.forecastInfo.title')}
              />
              <p>{str('Assortment.visibilitySettings.forecastInfo.description')}</p>
            </ToggleWrapper>
          )}
        </Content>
      }
      confirm={{ label: str('general.save'), handler: () => onConfirm(settings) }}
      cancel={{ label: str('general.cancel'), handler: onCancel }}
      maxWidth={600}
    />
  );
};
