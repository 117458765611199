import { useEffect, useMemo } from 'react';

import { addProductVideo } from '@yourxx/ui-utils';

import { type EventBus } from '../EventBus';
import { ToastRequested } from '../ToastService';
import { ProductVideoAdded, ProductVideoRemoved, ProductVideoUpdateFailed } from './events';
import type { ProductVideoService } from './ProductVideoService';

export const createProductVideoService = ({
  handler,
  eventBus
}: {
  handler: typeof addProductVideo;
  eventBus: EventBus;
}): ProductVideoService => {
  return {
    handle: async command => {
      command.videoUrl = command.videoUrl.trim();

      const { pc9, videoUrl } = command;

      /**
       * v1 API removes all videos before adding a new one and if the videoURL is an empty string it
       * immediately returns without updating any records.
       *
       * TODO: Implement a DELETE method for API v2
       */
      return handler({ pc9, videoUrl: command.type === 'add' ? videoUrl : '' })
        .then(() =>
          eventBus.emit(
            command.type === 'add'
              ? new ProductVideoAdded({ pc9, videoUrl })
              : new ProductVideoRemoved({ pc9, videoUrl })
          )
        )
        .catch(error => {
          eventBus.emit(
            new ProductVideoUpdateFailed({
              command,
              reason: error instanceof Error ? error.message : JSON.stringify(error)
            })
          );

          throw error;
        });
    }
  };
};

export const useCreateProductVideoService = ({ eventBus }: { eventBus: EventBus }) => {
  useEffect(() => {
    const subscribers = [
      eventBus.on(ProductVideoAdded, event =>
        eventBus.emit(
          new ToastRequested({
            // TODO: Move to localisation file
            content: <>Video successfully added to {event.payload.pc9}.</>,
            type: 'success'
          })
        )
      ),
      eventBus.on(ProductVideoRemoved, event =>
        eventBus.emit(
          new ToastRequested({
            // TODO: Move to localisation file
            content: <>Video successfully removed from {event.payload.pc9}.</>,
            type: 'success'
          })
        )
      ),
      eventBus.on(ProductVideoUpdateFailed, event =>
        eventBus.emit(
          new ToastRequested({
            content: (
              <>
                {/* TODO: Move to localisation file */}
                Failed to add video to {event.payload.command.pc9}: {event.payload.reason}.
              </>
            ),
            type: 'error'
          })
        )
      )
    ];

    return () => {
      for (const unsubscribe of subscribers) unsubscribe();
    };
  }, [eventBus]);

  return useMemo(() => createProductVideoService({ eventBus, handler: addProductVideo }), [eventBus]);
};
