import { motion, type Variants } from 'framer-motion';
import React from 'react';
import { styled } from 'styled-components';

const VIEW_TRANSITION_VARIANTS: Variants = {
  hidden: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 }
};
const VIEW_TRANSITION_SETTINGS = { duration: 0.2 };

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ViewTransition = ({ children, className }: { className?: string; children: React.ReactNode }) => {
  return (
    <Container
      className={className}
      variants={VIEW_TRANSITION_VARIANTS}
      initial="hidden"
      animate="in"
      exit="out"
      transition={VIEW_TRANSITION_SETTINGS}
    >
      {children}
    </Container>
  );
};
