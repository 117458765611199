import { createContext, Dispatch, type ReactNode, SetStateAction, useState } from 'react';

import { ApiGetLineOut } from '@yourxx/types';

export type LinesContextValue = {
  linesData?: ApiGetLineOut;
  setLinesData: Dispatch<SetStateAction<ApiGetLineOut | undefined>>;
};

export const LinesContext = createContext<LinesContextValue>(undefined!);

export const LinesProvider = ({ children }: { children?: ReactNode }) => {
  const [linesData, setLinesData] = useState<ApiGetLineOut>();

  const value = {
    linesData,
    setLinesData
  };

  return <LinesContext.Provider value={value}>{children}</LinesContext.Provider>;
};
