import { ChevronRight } from 'assets/icons';
import React from 'react';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${themed('spacing.xl')} ${themed('spacing.xxxl')};
`;
const Breadcrumbs = styled.div`
  user-select: none;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    ${themed('typography.h4')};
    text-transform: uppercase;

    &[role='link'] {
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          color: ${themed('color.red')};
        }
      }
    }
    svg {
      width: ${rem(15)};
      height: ${rem(15)};
    }
  }
`;
const TitleWrapper = styled.div<{ $isInverted?: boolean }>`
  display: flex;
  align-items: center;
  margin: ${themed('spacing.xs')} 0 0;

  > h1 {
    position: relative;
    left: ${rem(-2)};
    margin: 0;
    padding: ${themed('spacing.m')} ${({ $isInverted }) => ($isInverted ? themed('spacing.m') : 0)};
    ${themed('typography.h1')};
    text-transform: uppercase;
    border-radius: ${({ $isInverted }) => ($isInverted ? themed('borderRadius') : 0)};
    background-color: ${({ $isInverted }) => ($isInverted ? themed('color.black') : 'unset')};
    color: ${({ $isInverted }) => ($isInverted ? themed('color.white') : 'unset')};
  }
  > span {
    color: ${themed('color.grey')};
    font-size: ${themed('font.size.s')};
  }
`;
const ControlsWrapper = styled.div`
  display: flex;
  flex: 1 0;
  align-items: center;
  justify-content: space-between;
  padding-left: ${themed('spacing.m')};
`;
const Control = styled.div<{ $alignEnd?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${themed('spacing.s')};
  width: 100%;
  justify-content: ${({ $alignEnd }) => ($alignEnd ? 'flex-end' : 'flex-start')};

  svg {
    width: ${rem(30)};
    height: ${rem(30)};
  }
`;

export interface Props {
  title: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  breadcrumbs?: ReadonlyArray<{ label: string; onClick?: VoidFunction }>;
  invertedTitle?: boolean;
  leftControls?: React.ReactNode;
  rightControls?: React.ReactNode;
}

/**
 * @deprecated In favour of layout slots and new CommonLayout route component.
 */
export const SubHeader = ({ title, subtitle, invertedTitle, breadcrumbs, leftControls, rightControls }: Props) => (
  <Wrapper>
    {breadcrumbs && (
      <Breadcrumbs>
        {breadcrumbs.map(({ label, onClick }) => (
          <div key={label} role={onClick && 'link'} onClick={onClick}>
            {label}
            <ChevronRight />
          </div>
        ))}
      </Breadcrumbs>
    )}
    <TitleWrapper $isInverted={invertedTitle}>
      <h1>{title}</h1>
      <span>{subtitle}</span>
      {(leftControls || rightControls) && (
        <ControlsWrapper>
          {leftControls && <Control>{leftControls}</Control>}
          {rightControls && <Control $alignEnd>{rightControls}</Control>}
        </ControlsWrapper>
      )}
    </TitleWrapper>
  </Wrapper>
);
