import styled from 'styled-components';

import { GridItem } from './GridItem';
import type { GridProps } from './interfaces';

const StyledFlex = styled.div<GridProps>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: ${({ $align }) => $align || 'center'};

  &:after {
    content: '';
    max-width: ${({ $col, $gap = 1 }) => ($col && $col < 12 ? `${(100 * $col) / 12 - $gap}%` : '100%')};
    width: 100%;
  }
`;

export const Grid = ({
  className,
  children,
  $col = 12,
  $colTablet,
  $colMobile,
  $gap,
  $gapTablet,
  $gapMobile,
  $align
}: GridProps) => (
  <StyledFlex
    className={className}
    $col={$col}
    $colTablet={$colTablet}
    $colMobile={$colMobile}
    $gap={$gap}
    $gapTablet={$gapTablet}
    $gapMobile={$gapMobile}
    $align={$align}
  >
    {children}
    <GridItem
      $col={$col}
      $colTablet={$colTablet}
      $colMobile={$colMobile}
      $gap={$gap}
      $gapTablet={$gapTablet}
      $gapMobile={$gapMobile}
    />
  </StyledFlex>
);
