import { Entity } from 'domain-events';
import { CacheClearRequested, LegacyStoriesClosed } from 'providers';
import { useCallback } from 'react';
import type { EventBus } from 'services';
import { useEventSubscriptions } from 'utils';

export const useHandleLegacyStoriesClosedEvent = (eventBus: EventBus) => {
  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(LegacyStoriesClosed, event => {
          const tags = (() => {
            const base = ['droppedAndRemoved', 'parentLine', 'parentLineDroppedAndRemoved'] as const;

            switch (event.payload.context.entity) {
              case Entity.Line:
                return ['line', ...base] as const;
              case Entity.Assortment:
              case Entity.FinalAssortment:
                return ['assortment', 'removedFromAssortment', ...base] as const;
            }
          })();

          eventBus.emit(
            CacheClearRequested.for(...tags.map(tag => ({ tag, id: event.payload.context.id, reload: false }))).trace(
              event
            )
          );
        })
      ],
      [eventBus]
    )
  );
};
