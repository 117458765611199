export enum State {
  LoadingPersistedData = 'LoadingPersistedData',
  FailedLoadingPersistedData = 'FailedLoadingPersistedData',
  Idle = 'Idle',
  NewFilterCombination = 'NewFilterCombination',
  PersistingChanges = 'PersistingChanges',
  PendingDeleteConfirmation = 'PendingDeleteConfirmation',
  RenamingFilterCombination = 'RenamingFilterCombination',
  ReorderingFilters = 'ReorderingFilters'
}

export enum Action {
  Retry = 'Retry',
  Save = 'Save',
  Rename = 'Rename',
  InputName = 'InputName',
  Confirm = 'Confirm',
  Cancel = 'Cancel',
  Delete = 'Delete',
  Reorder = 'Reorder',
  UpdateOrder = 'UpdateOrder',
  Reset = 'Reset',
  ChangeActiveFilter = 'ChangeActiveFilter',
  Set = 'Set',
  UpdateDefaultFilterCombination = 'UpdateDefaultFilterCombination'
}
