import { styled } from 'styled-components';
import { rem, themed } from 'utils';

const PC9 = styled.span`
  ${themed('typography.h4')};
  font-weight: ${themed('font.weight.bold')};
  color: ${themed('color.greyDark')};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(1)};
  overflow: hidden;

  @media (hover: hover) {
    &:hover {
      ${PC9} {
        color: ${themed('color.black')};
      }
    }
  }
`;

const Description = styled.span`
  display: block;
  ${themed('typography.h4')};
  color: ${themed('color.black')};
  font-weight: ${themed('font.weight.regular')};
  font-variant: unset;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProductSearchSuggestion = ({ desc, pc9 }: { pc9: string; desc?: string }) => {
  return (
    <Container>
      <PC9>{pc9}</PC9>
      <Description>{desc}</Description>
    </Container>
  );
};
