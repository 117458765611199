import { Accordion } from 'components/Accordion';
import { useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { insightsExpandClickTag, insightsRecommendationViewTag } from 'utils';

import { useInsightKey, useInsights } from '../hooks';
import { AccordionContent } from './AccordionContent';
import { AccordionHeader } from './AccordionHeader';

export const InsightsList = () => {
  const { insights } = useInsights();
  const { getInsightKey } = useInsightKey();
  const [expandedInsights, setExpandedInsights] = useState<string[]>([]);

  return (
    <Virtuoso
      data={insights}
      overscan={500}
      itemContent={(i, insight) => {
        const insightKey = getInsightKey(insight);
        return (
          <Accordion
            key={insightKey}
            showChevron={false}
            isExpanded={expandedInsights.includes(insightKey)}
            showBottomBorder={insights[i + 1] && insight.type !== insights[i + 1].type}
            onToggle={isExpanded => {
              if (isExpanded) {
                insightsExpandClickTag({
                  assortmentId: insight.customer.customer_id,
                  customerId: insight.assortmentId,
                  recommendationId: insightKey
                });
                insightsRecommendationViewTag({
                  assortmentId: insight.customer.customer_id,
                  customerId: insight.assortmentId,
                  recommendationId: insightKey
                });
              }
              setExpandedInsights(prev => {
                if (!isExpanded && prev.includes(insightKey)) {
                  return prev.filter(p => p !== insightKey);
                } else if (isExpanded) {
                  return [...new Set([...prev, insightKey])];
                }
                return prev;
              });
            }}
            header={<AccordionHeader insightItem={insight} isExpanded={expandedInsights.includes(insightKey)} />}
          >
            <AccordionContent insightItem={insight} />
          </Accordion>
        );
      }}
    />
  );
};
