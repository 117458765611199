import { apiEndpoints, toRequest } from '../../utils';
import { apiClient } from '../client';

type AddProductsToStoryParams = {
  assortmentOrLineId: string;
  storyId: string;
  productIds: string[];
};

export const addProductsToStory = async ({ assortmentOrLineId, storyId, productIds }: AddProductsToStoryParams) => {
  if (!productIds.length) throw RangeError('No product ids given to add to the story.');

  // TODO: Validate the rest of the params.

  const request = toRequest({
    path: apiEndpoints.story({ assortmentOrLineId, storyId }),
    method: 'PATCH',
    body: { productIds }
  });

  return apiClient.request<{ message: string } | void>(request).then(response => {
    if (response && 'message' in response) throw new Error(response.message);
  });
};
