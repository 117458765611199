import { Avatar, ProfileModal } from 'components';
import { SlotId, Toolbar } from 'pages';
import { useLocalisation, useSlot } from 'providers';
import { useCallback, useMemo, useState } from 'react';

export const ProfilePanelSetup = () => {
  const [str] = useLocalisation();
  const [isVisible, setIsVisible] = useState(false);
  const toggle = useCallback(() => setIsVisible(p => !p), []);

  useSlot(
    SlotId.HeaderTopToolbar,
    useMemo(
      () => (
        <Toolbar.Item
          icon={<Avatar size={24} />}
          onClick={toggle}
          hint={{ text: str('Header.profile'), position: 'bottom' }}
        />
      ),
      [str, toggle]
    ),
    Infinity
  );

  useSlot(
    SlotId.Modals,
    useMemo(() => isVisible && <ProfileModal onClose={toggle} />, [isVisible, toggle])
  );

  return null;
};
