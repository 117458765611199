export const isValidToken = (token?: string | null) => {
  const parts = token?.split('.');
  return Boolean(parts && parts.length === 3);
};

export const decodeToken = (token?: string | null) => {
  if (!token || !isValidToken(token)) {
    return undefined;
  }

  try {
    const [_header, payload, _signature] = token.split('.');
    const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    return undefined;
  }
};
