import { DomainEvent } from '../EventBus';
import type { ExportCommand } from './ExportsService';

interface BasePayload {
  command: ExportCommand;
}

interface FailedPayload extends BasePayload {
  reason: string;
}

export class ExportRequested extends DomainEvent<BasePayload> {}

export class ExportCompleted extends DomainEvent<BasePayload & { resourceUrl: string }> {}

export class ExportFailed extends DomainEvent<FailedPayload> {}
