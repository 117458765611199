import { Button } from 'components/Button';
import { useLocalisation } from 'providers';
import { useMemo } from 'react';
import styled from 'styled-components';
import { formatPercentage, rem, themed } from 'utils';

import { Nullable } from '@yourxx/support';

const Wrapper = styled.div`
  border-bottom: ${rem(1)} solid ${themed('color.greyMid')};
  background-color: ${themed('color.white')};
`;
const Divider = styled.div`
  border-bottom: ${rem(1)} solid ${themed('color.greyLight')};
  margin: 0 ${themed('spacing.m')};
`;
const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${themed('spacing.m')};
  padding: ${themed('spacing.l')} ${rem(10)};
`;
const Option = styled(Button)<{ $selected: boolean; $unavailable?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: ${themed('spacing.s')} ${themed('spacing.m')};
  background-color: ${({ $selected, $unavailable }) =>
    themed($unavailable ? 'color.transparentRed' : $selected ? 'color.black' : 'color.offWhite')};
  color: ${({ $selected }) => themed($selected ? 'color.white' : 'color.black')};
  ${themed('typography.h5')};
  font-weight: ${themed('font.weight.regular')};
`;

type Option = { value: unknown; label: string; count: number; percentOfTotal: number };

export const DefaultFilter = ({
  className,
  options,
  value,
  onChange
}: {
  className?: string;
  options: ReadonlyArray<Option>;
  value: Nullable<Option | Option[]>;
  onChange: (option: Option) => void;
}) => {
  const [str] = useLocalisation();

  // TODO: Maybe move this outside? Not worth the time, yet.
  const unavailableValues = useMemo(() => {
    if (!value) return [];

    return Array.isArray(value)
      ? value.filter(value => !options.includes(value))
      : !options.includes(value)
        ? [value]
        : [];
  }, [options, value]);

  return (
    <Wrapper className={className}>
      <Divider />
      <OptionsContainer>
        {options.map(option => (
          <Option
            key={String(option.value)}
            title={`${formatPercentage(option.percentOfTotal)} of total`}
            $selected={
              Array.isArray(value) ? value.some(item => item.value === option.value) : value?.value === option.value
            }
            onClick={() => onChange(option)}
          >
            {option.label} ({option.count})
          </Option>
        ))}
        {unavailableValues.map(option => (
          <Option key={String(option.value)} $selected={true} $unavailable={true} onClick={() => onChange(option)}>
            {String(option.value)} ({str('general.notAvailable')})
          </Option>
        ))}
      </OptionsContainer>
    </Wrapper>
  );
};
