import type { GetAuditTrailResponse } from '@yourxx/types';

type EventData = GetAuditTrailResponse extends (infer E)[] ? E : never;

export interface AuditTrailService {
  clearCache(assortmentId: string): void;
  isScheduledForReload(assortmentId: string): boolean;
  loadEvents(command: { assortmentId: string }): Promise<AuditTrailEvent[]>;
  markAsSeen(event: AuditTrailEvent): void;
  hasBeenSeen(event: AuditTrailEvent): boolean;
}

export type SeenRecords = Record<string, Record<string, string>>;

export interface Persistence {
  save(records: SeenRecords): void;
  load(): SeenRecords;
}

export class AuditTrailEvent {
  static of(data: EventData, service: AuditTrailService) {
    return new AuditTrailEvent(data, service);
  }

  markAsSeen = () => {
    AuditTrailEvent.service.markAsSeen(this);
  };

  get hasBeenSeen() {
    return AuditTrailEvent.service.hasBeenSeen(this);
  }

  protected static service: AuditTrailService;
  private constructor(
    public readonly data: Readonly<EventData>,
    service: AuditTrailService
  ) {
    AuditTrailEvent.service = service;
  }
}
