import { Close } from 'assets/icons';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const Wrapper = styled.div<{ $isOpen: boolean; $width?: number; $top?: number }>`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: ${({ $width }) => ($width ? rem($width) : 'auto')};
  top: ${({ $top }) => ($top ? rem($top) : 0)};
  right: 0;
  border-radius: ${rem(12, 0, 0, 12)};
  box-shadow: ${({ $isOpen }) => rem(0, 0, $isOpen ? 40 : 0)} ${themed('color.transparentLightBlack')};
  overflow: hidden;
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'auto' : 'none')};
  will-change: transform;
  transform: ${({ $isOpen }) => `translateX(${$isOpen ? 0 : '100%'})`};
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};
`;
const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top-left-radius: ${themed('spacing.m')};
  padding: ${rem(18, 14)};
  background-color: ${themed('color.white')};
`;
const Body = styled.div`
  flex: 1;
  background-color: ${themed('color.offWhite')};
  position: relative;

  > div {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
`;
const Heading = styled.h3`
  user-select: none;
  position: relative;
  top: ${themed('spacing.xs')};
  display: flex;
  align-items: center;
  margin: 0;
  color: ${themed('color.black')};
  ${themed('typography.h3')}
  font-weight: ${themed('font.weight.bold')};
  text-transform: uppercase;
`;
const StyledClose = styled(Close)`
  font-size: ${themed('font.size.xl')};
  align-self: center;
  cursor: pointer;
`;
const IconContainer = styled.div`
  align-self: center;
  display: flex;
  gap: ${rem(8)};
  will-change: transform;
  animation-duration: ${themed('transition.duration')};
  animation-timing-function: ${themed('transition.timing')};
  animation-name: ${themed('animation.zoomOut')};

  svg {
    width: ${rem(24)};
    height: ${rem(24)};
  }
`;

export type SlideInOutProps = {
  className?: string;
  children: React.ReactNode;
  heading: React.ReactNode;
  width?: number;
  top?: number;
  isOpen: boolean;
  onClose: VoidFunction;
  onReorderClick?: VoidFunction;
  reorderButtonClicked?: boolean;
  headerControls?: React.ReactNode;
};

export const SlideInOut = ({
  className,
  children,
  heading,
  isOpen,
  width,
  top,
  onClose,
  headerControls
}: SlideInOutProps) => (
  <Wrapper className={className} $isOpen={isOpen} $width={width} $top={top}>
    <Header>
      <Heading>{heading}</Heading>
      <IconContainer>
        {headerControls}
        <StyledClose onClick={onClose} />
      </IconContainer>
    </Header>
    <Body>
      <div>{children}</div>
    </Body>
  </Wrapper>
);
