import { ProductMiniCard } from 'components';
import { useLocalisation } from 'providers';
import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { GridComponents, VirtuosoGrid } from 'react-virtuoso';
import { pluralise } from 'utils';

import { FeatureFlags, ProductListData, UIProduct } from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

import { Column, ColumnHead, StyledCheckbox, StyledList, Wrapper } from './styling';
import { useSynchedScroll } from './useSynchedScroll';

const ColumnsViewGridComponents: GridComponents<{ columns: number }> = {
  List: forwardRef(({ style, children, ...props }, ref) => (
    <StyledList $columns={props.context?.columns ?? 3} ref={ref} {...props} style={style}>
      {children}
    </StyledList>
  ))
};

export const ColumnsView = <P extends UIProduct<ProductListData>>({
  isSelected,
  onSelect,
  products,
  maxColumns = 12
}: {
  onSelect: (...item: P[]) => void;
  isSelected: (item: P) => boolean;
  products: ReadonlyArray<P>;
  maxColumns?: number;
}) => {
  const [str] = useLocalisation();
  // Sneaky, cheeky easter egg
  const isHoldingAltKey = useIsHoldingAltKey();
  const shouldSync = useCallback(() => isHoldingAltKey.current, [isHoldingAltKey]);
  const synchedScroll = useSynchedScroll({ shouldSync });

  const canSelectItems = canUse(FeatureFlags.Assortment_SelectItems);

  const groups = useMemo(() => {
    return products.reduce<Record<string, P[]>>((accum, item) => {
      if (!item.category0) return accum;
      (accum[item.category0] ??= []).push(item);
      return accum;
    }, {});
  }, [products]);

  const productCardsPerGroup = useMemo(
    () => Math.floor(maxColumns / (Object.keys(groups).length || 1)),
    [groups, maxColumns]
  );

  return (
    <Wrapper $groupsCount={Object.keys(groups).length}>
      {Object.entries(groups).map(([key, items]) => {
        return (
          <Column key={key}>
            <ColumnHead>
              {canSelectItems && (
                <div
                  onClick={() => {
                    const alreadySelected = items.filter(isSelected);
                    if (alreadySelected.length && alreadySelected.length !== items.length) onSelect(...alreadySelected);
                    onSelect(...items);
                  }}
                >
                  <StyledCheckbox checked={items.every(isSelected)} />
                </div>
              )}
              <h3>
                {key}
                <span>
                  {items.length}
                  <span> {pluralise(items.length, str('general.item.singular'), str('general.item.plural'))}</span>
                </span>
              </h3>
            </ColumnHead>
            <VirtuosoGrid
              data={items}
              context={{ columns: productCardsPerGroup }}
              itemContent={(_index, product) => (
                <ProductMiniCard
                  className="product-card"
                  product={product}
                  canSelectItems={canSelectItems}
                  isSelected={isSelected(product)}
                  imageSize={256}
                  onSelect={() => onSelect(product)}
                  // TODO: Hook up PDP after MVP
                  onClick={() => onSelect(product)}
                />
              )}
              components={ColumnsViewGridComponents}
              scrollerRef={ref => ref && synchedScroll.register(ref)}
            />
          </Column>
        );
      })}
    </Wrapper>
  );
};

const useIsHoldingAltKey = () => {
  const isHoldingAlt = useRef(false);

  useEffect(() => {
    const downHandler = (e: KeyboardEvent) => {
      if (e.key === 'Alt') isHoldingAlt.current = true;
    };
    const upHandler = (e: KeyboardEvent) => {
      if (e.key === 'Alt') isHoldingAlt.current = false;
    };
    document.addEventListener('keydown', downHandler);
    document.addEventListener('keyup', upHandler);

    return () => {
      document.removeEventListener('keydown', downHandler);
      document.removeEventListener('keyup', upHandler);
    };
  }, []);

  return isHoldingAlt;
};
