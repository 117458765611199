import { useState } from 'react';

import { getBoardImagePc9Codes } from '@yourxx/support/src/images/canvas';
import { createBoardItems as apiAddBoardItem } from '@yourxx/ui-utils';

import { useCurrentCanvasContext } from '../CurrentCanvasContext';
import { useCanvas } from './useCanvas';

export const useAddBoardItems = () => {
  const { lineId, assortmentId, canvasBoardId } = useCurrentCanvasContext();
  const { setBoardItems, setBoards } = useCanvas();
  const [isUpdatingBoardItems, setIsUpdatingBoardItems] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();

  const addBoardItems = (pc9Codes?: string[]) => {
    if (canvasBoardId) {
      setIsUpdatingBoardItems(true);
      apiAddBoardItem({ boardId: canvasBoardId, pc9Codes: pc9Codes ?? [] }, { lineId, assortmentId })
        .then(newItems => {
          setBoardItems(prev => [...new Set([...prev, ...newItems])]);
          setBoards(prev =>
            prev.map(board => {
              if (board.boardId === canvasBoardId) {
                getBoardImagePc9Codes;
                const imagePc9Codes = getBoardImagePc9Codes(board.imagePc9Codes, newItems);
                return { ...board, imagePc9Codes };
              }
              return board;
            })
          );
        })
        .catch(() => {
          setHasError('Failed adding item to board');
          setIsUpdatingBoardItems(false);
        });
    }
  };

  return { addBoardItems, isUpdatingBoardItems, hasError };
};
