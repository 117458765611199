import 'react-toastify/dist/ReactToastify.css';

import { Close } from 'assets/icons';
import { Button } from 'components/Button';
import { Slide, ToastContainer, ToastContainerProps } from 'react-toastify';
import styled from 'styled-components';
import { rem, themed } from 'utils';

const StyledToastContainer = styled.div`
  .Toastify {
    &__toast {
      position: relative;
      padding: ${themed('spacing.l')};
      margin: ${themed('spacing.l')} 0;
      border-radius: ${themed('spacing.m')};
      box-shadow: ${rem(0, 8, 20, 0)} ${themed('color.transparentMidBlack')};
      align-items: center;
      border-left-style: solid;
      border-left-width: ${themed('spacing.m')};

      &-container {
        padding: 0;
      }

      &-body {
        margin: 0;
        border: 0;
        padding: 0;
        font-size: ${themed('font.size.m')};
      }

      &--success {
        border-left-color: ${themed('color.green')};
      }

      &--error {
        border-left-color: ${themed('color.red')};
      }
    }
  }
`;
const ToastCancelButton = styled(Button)`
  padding: ${rem(12)};

  svg {
    width: ${themed('spacing.xl')};
    height: ${themed('spacing.xl')};

    path {
      stroke: ${themed('color.white')};
    }
  }
`;

export const Toast = (props: ToastContainerProps & { className?: string }) => (
  <StyledToastContainer className={props.className}>
    <ToastContainer
      closeButton={({ closeToast }) => (
        <ToastCancelButton onClick={closeToast}>
          <Close />
        </ToastCancelButton>
      )}
      icon={false}
      hideProgressBar={props.hideProgressBar ?? true}
      limit={props.limit ?? 3}
      position={props.position ?? 'bottom-right'}
      theme="dark"
      transition={Slide}
      {...props}
    />
  </StyledToastContainer>
);
