import { Close, Search as SearchIcon } from 'assets/icons';
import { Input } from 'components/Input';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { rem, themed } from 'utils';

const Container = styled.div<{ $isOpen: boolean }>`
  position: relative;
  width: ${({ $isOpen }) => ($isOpen ? '50%' : rem(50))};
  height: ${rem(50)};
  box-sizing: border-box;
  will-change: width;
  transition: width 0.1s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  @media (min-width: 768px) {
    width: ${({ $isOpen }) => ($isOpen ? '100%' : rem(50))};
  }
`;
const SearchInput = styled(Input)<{ $showSearchInput: boolean }>`
  position: absolute;
  width: 100%;
  outline: 0;
  padding: ${themed('spacing.m')};
  margin: 0;
  box-sizing: border-box;
  display: ${({ $showSearchInput }) => ($showSearchInput ? 'block' : 'none')};
`;
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;
const StyledSearch = styled(SearchIcon)`
  height: ${rem(20)};
  width: ${rem(20)};
  z-index: 10;
  animation: ${fadeIn} 1s linear;
`;
const CloseIcon = styled(Close)`
  height: ${rem(20)};
  width: ${rem(20)};
  padding-right: ${rem(12)};
  z-index: 10;
  animation: ${fadeIn} 1s linear;
  align-self: flex-end;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      fill: ${themed('color.grey')};
    }
  }
`;

export type SearchParams = {
  className?: string;
  onChange: (text: string) => void;
};

export const Search = ({ className, onChange }: SearchParams) => {
  const targetRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.value = '';
      if (isOpen) {
        targetRef.current?.focus();
      }
    }
  }, [isOpen]);

  const onTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, []);

  return (
    <Container className={className} $isOpen={isOpen}>
      <SearchInput ref={targetRef} $showSearchInput={isOpen} onChange={onTextChange} />
      {isOpen ? (
        <CloseIcon
          onClick={() => {
            onChange('');
            setIsOpen(false);
          }}
        />
      ) : (
        <StyledSearch onClick={() => setIsOpen(true)} />
      )}
    </Container>
  );
};
