import { createContext, useCallback, useContext } from 'react';
import { useEventSubscriptions } from 'utils';

import { CacheClearRequested } from './events';
import { type Props as UseCustomersProps, useCustomers, type UseCustomersReturn } from './useCustomers';

export type Props = UseCustomersProps & { children: React.ReactNode };

const CustomersDataProviderContext = createContext<UseCustomersReturn | null>(null);

export const CustomersDataProvider = ({ service, eventBus, brand, children }: Props) => {
  const value = useCustomers({ service, brand, eventBus });
  const { clearCache, loadAssortmentsFor, loadProductsFor } = value;

  useEventSubscriptions(
    useCallback(
      () => [
        eventBus.on(CacheClearRequested, event => {
          clearCache(...event.payload.entries.map(({ id, tag }) => ({ id, tag })));
          for (const entry of event.payload.entries)
            if (entry.reload)
              switch (entry.tag) {
                case 'customerAssortments':
                  loadAssortmentsFor(entry.id, event.traceId());
                  break;
                default:
                  loadProductsFor(entry.id, entry.tag, event.traceId());
              }
        })
      ],
      [clearCache, eventBus, loadAssortmentsFor, loadProductsFor]
    )
  );
  return <CustomersDataProviderContext.Provider value={value}>{children}</CustomersDataProviderContext.Provider>;
};

export const useCustomersData = () => {
  const value = useContext(CustomersDataProviderContext);

  if (!value)
    throw new ReferenceError(
      'Hook "useCustomersData" used outside its provider. Make sure <CustomersDataProvider /> is a parent to your components.'
    );

  return value;
};
