import dockerLogo from 'assets/logos/dockers.svg?react';
import leviLogo from 'assets/logos/levi.svg?react';

export type AppBrands = { name: string; icon: any; url: string };

export const appBrands: { [key: string]: AppBrands } = {
  levi: {
    name: 'Levi',
    url: 'levi',
    icon: leviLogo
  },
  dockers: {
    name: 'Dockers',
    url: 'dockers',
    icon: dockerLogo
  }
};

export const getBrand = (brand: string) => {
  return appBrands[brand?.toLowerCase()?.trim()];
};

export const BASE_URL = '' as const;

export const REGIONS: { [key: string]: string } = Object.freeze({
  ANZ: 'ANZ',
  CAN: 'CAN',
  GCR: 'GCR',
  JPN: 'JPN',
  KOR: 'KOR',
  LAT: 'LAT',
  MEA: 'MEA',
  NEU: 'NEU',
  ZAF: 'ZAF',
  SEA: 'SEA',
  IDN: 'IDN',
  MYS: 'MYS',
  PHP: 'PHP',
  SGP: 'SGP',
  THD: 'THD',
  VTN: 'VTN',
  SEU: 'SEU',
  USA: 'USA'
});

export const DEFAULT_LANGUAGE = 'en' as const;

export const HEADER_TABS = ['customers', 'lines'] as const;
