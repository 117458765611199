import { formatPercentage, formatPrice } from 'utils';

import { Nullable } from '@yourxx/support';
import { toSorted } from '@yourxx/support/src/utils/array';
import type { SummaryEntry } from '@yourxx/types';

interface Entry {
  original: SummaryEntry;
  parent: Nullable<this>;
  id: string;
  label: string;
  level: number;
  isParent: boolean;
  isExpandable: boolean;
  isExpanded: boolean;
  attributes: readonly { label: string; value: string }[];
  toggleExpansion(): void;
}

interface PrivateEntry extends Entry {
  children: PrivateEntry[];
  assignChild(entry: PrivateEntry): void;
}

interface Sorting {
  by: string;
  direction: 'asc' | 'desc';
}

export interface AssortmentSummaryState {
  entries: readonly Entry[];
  sorting: Nullable<Readonly<Sorting>>;
  sortBy(attribute: string): void;
}

export const AssortmentSummaryState = (
  dataEntries: readonly SummaryEntry[],
  currency: string,
  onUpdate: VoidFunction
): AssortmentSummaryState => {
  const expanded = new Map<string, boolean>();
  let sorting: Nullable<Sorting> = null;

  const entries: PrivateEntry[] = dataEntries.map(data => {
    // TODO: Figure what needs to be a getter for re-evaluation or cached as permanent attribute value.
    const entry: PrivateEntry = {
      original: data,
      get id() {
        return entry.original.id;
      },
      get label() {
        return entry.original.label;
      },
      get parent() {
        if (entry.original.id === 'TOTAL') return null;
        if (!entry.original.parent) return entries.find(other => other.id === 'TOTAL') ?? null;
        return entries.find(other => other.id === entry.original.parent) ?? null;
      },
      get isParent() {
        return entry.id === 'TOTAL' || !!entries.find(other => other.original.parent === entry.id);
      },
      get isExpandable() {
        return entry.id !== 'TOTAL' && entry.isParent;
      },
      get isExpanded() {
        return entry.id === 'TOTAL' || !!expanded.get(entry.id);
      },
      get level() {
        return levelFor(entry);
      },
      // TODO: Make this a dictionary and provide a getter?
      //   Move available attributes array in the main state object so client can iterate over easily
      attributes: data.values.map(value => {
        // Default to "0" if value.value is null or undefined, then format accordingly
        let formattedValue = '0';

        if (value.type === 'currency') {
          formattedValue = formatPrice(currency, value.value ?? 0); // Assuming formatPrice can handle null
        } else if (value.type === 'percentage') {
          formattedValue = formatPercentage(value.value ?? 0); // Assuming formatPercentage can handle null
        } else if (typeof value.value === 'number') {
          formattedValue = value.value.toFixed(0);
        }

        return {
          label: value.name,
          value: formattedValue
        };
      }),
      toggleExpansion: () => {
        if (!entry.isExpandable) return;
        const current = expanded.get(entry.id);
        expanded.set(entry.id, typeof current === 'undefined' ? true : !current);
        onUpdate();
      },

      // Private
      children: [],
      assignChild: (child: PrivateEntry) => {
        entry.children.push(child);
      }
    };

    return entry;
  });

  for (const entry of entries) if (entry.parent) entry.parent.assignChild(entry);
  const topLevelEntries = entries.filter(entry => entry.level === 0);

  return {
    get entries() {
      const flatEntries = sortEntries(topLevelEntries, sorting)
        .flatMap(entry => flatten(entry, sorting))
        .filter(entry => !entry.parent || entry.parent.isExpanded);

      if (sorting) sortEntries(flatEntries, sorting);

      return flatEntries;
    },

    get sorting() {
      return sorting;
    },

    sortBy: attribute => {
      if (sorting?.by === attribute) {
        if (sorting.direction === 'desc') sorting.direction = 'asc';
        else sorting = null;
      } else {
        sorting = { by: attribute, direction: 'desc' };
      }

      onUpdate();
    }
  };
};

const getValue = (entry: PrivateEntry, attribute: string) =>
  entry.original.values.find(({ name }) => name === attribute)?.value;

const sortEntries = (entries: PrivateEntry[], sorting: Nullable<Sorting>) => {
  if (!sorting) return entries;
  return toSorted(entries, (a, b) => {
    if (a.level !== b.level) return 0;
    const bValue = getValue(b, sorting.by) ?? -Infinity;
    const aValue = getValue(a, sorting.by) ?? -Infinity;
    const result = aValue - bValue;
    return sorting.direction === 'asc' ? result : -1 * result;
  });
};

const flatten = (entry: PrivateEntry, sorting: Nullable<Sorting>, accum: PrivateEntry[] = []) => {
  accum.push(entry);
  if (entry.children.length) for (const child of sortEntries(entry.children, sorting)) flatten(child, sorting, accum);
  return accum;
};

const levelFor = (entry: PrivateEntry, index = 0): number => {
  if (!entry.parent) return index;
  return levelFor(entry.parent, ++index);
};
