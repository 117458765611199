import { Carousel } from 'components/Carousel';
import { FlexWrapper } from 'components/FlexWrapper';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useLocalisation } from 'providers';
import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { themed } from 'utils';

import { CompareImage } from './CompareImage';
import { CompareInfo } from './CompareInfo';
import { useCurrentCanvasContext } from './CurrentCanvasContext';
import { useCanvas, useGetProductDetails, useGetShareBoardData } from './hooks';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background-color: ${themed('color.greyLight')};
  padding: ${themed('spacing.xl')};
  box-sizing: border-box;
`;
const EmptyLabel = styled.p`
  padding: ${themed('spacing.l')};
  text-align: center;
  font-size: ${themed('font.size.m')};
  background-color: ${themed('color.greyLight')};
`;

export const ShareBoard = () => {
  const { canvasBoardId } = useCurrentCanvasContext();
  const [str] = useLocalisation();
  const { viewMode, shareBoardData, productDetails } = useCanvas();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoadingBoardItems, setIsLoadingBoardItems] = useState<boolean>();
  const [isLoadingProductDetails, setIsLoadingProductDetails] = useState<boolean>();

  const { getShareBoardData } = useGetShareBoardData();
  const { getProductDetails } = useGetProductDetails();

  useEffect(() => {
    getShareBoardData({
      handleLoading: () => setIsLoadingBoardItems(true),
      handleSuccess: () => setIsLoadingBoardItems(false),
      handleError: () => {
        setErrorMessage(str('Landing.lines.retrievingError'));
        setIsLoadingBoardItems(false);
      }
    });
  }, [canvasBoardId]);

  useEffect(() => {
    getProductDetails({
      handleLoading: () => setIsLoadingProductDetails(true),
      handleSuccess: () => setIsLoadingProductDetails(false),
      handleError: () => {
        setErrorMessage(str('Landing.lines.retrievingError'));
        setIsLoadingProductDetails(false);
      }
    });
  }, [canvasBoardId, shareBoardData]);

  return (
    <Content>
      {isLoadingBoardItems && <LoadingSpinner label={str('Canvas.board.loading')} />}
      {errorMessage && (
        <FlexWrapper>
          <p>{errorMessage}</p>
        </FlexWrapper>
      )}
      {!isLoadingBoardItems && !errorMessage && !shareBoardData?.pc9Codes?.length && (
        <EmptyLabel>{str('Canvas.product.noneAdded')}</EmptyLabel>
      )}
      {!errorMessage && !isLoadingProductDetails && shareBoardData?.pc9Codes?.length && (
        <Carousel>
          {shareBoardData?.pc9Codes.map(pc9Code => {
            const product = productDetails?.find(pd => pd.pc9Code === pc9Code);
            return product ? (
              <Fragment key={pc9Code}>
                {viewMode === 'INFO' ? <CompareInfo product={product} /> : <CompareImage product={product} />}
              </Fragment>
            ) : null;
          })}
        </Carousel>
      )}
    </Content>
  );
};
