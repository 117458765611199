import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { AssortmentInsights, CustomerAssortmentInsights, InsightItem } from '@yourxx/types';
import {
  getAssortmentInsights,
  getAssortmentInsightsCount,
  getCustomerInsights,
  getCustomerInsightsCount,
  InsightItemStatus,
  InsightStatus,
  InsightType
} from '@yourxx/ui-utils';

import { useInsights } from './useInsights';

export const useGetInsights = () => {
  const { customerId, assortmentId } = useParams();
  const { setInsights, setSelectedInsights, setInsightsCount } = useInsights();

  const getInsightsCount = useCallback(
    async ({
      handleLoading = () => {},
      handleSuccess = () => {},
      handleError = () => {}
    }: {
      handleLoading?: (loading: boolean) => void;
      handleSuccess?: VoidFunction;
      handleError?: VoidFunction;
    } = {}) => {
      const insightsCountCall = assortmentId
        ? getAssortmentInsightsCount(assortmentId)
        : customerId
          ? getCustomerInsightsCount(customerId)
          : null;

      if (!insightsCountCall) return;

      handleLoading(true);
      return await insightsCountCall
        .then(count => {
          setInsightsCount(count);
          handleLoading?.(false);
          handleSuccess?.();
        })
        .catch(e => {
          console.error(e);
          handleLoading?.(false);
          handleError?.();
        });
    },
    [assortmentId, customerId, setInsightsCount]
  );

  const handleInsights = useCallback(
    (insights?: CustomerAssortmentInsights | AssortmentInsights) => {
      const allInsights = [
        ...(insights?.suggestions?.map(i => ({
          ...i,
          type: InsightType.SUGGESTION
        })) || []),
        ...(insights?.recommendations?.map(i => ({
          ...i,
          type: InsightType.RECOMMENDATION
        })) || [])
      ] as InsightItem[];
      const nonRemovedInsights = allInsights?.filter(({ status }) => status !== InsightStatus.REMOVED) || [];
      const insightsWithItems = nonRemovedInsights.filter(
        ({ items }) =>
          items && items.length > 0 && items.filter(({ status }) => status === InsightItemStatus.SUGGESTED).length > 0
      );
      const insightsWithSuggestedItems = insightsWithItems.map(insight => ({
        ...insight,
        items: insight.items?.filter(item => item.status === InsightItemStatus.SUGGESTED) || []
      }));
      setInsights(insightsWithSuggestedItems);
    },
    [setInsights]
  );

  const getInsights = useCallback(
    async ({
      handleLoading,
      handleSuccess,
      handleError
    }: {
      handleLoading: (loading: boolean) => void;
      handleSuccess: VoidFunction;
      handleError?: VoidFunction;
    }) => {
      if (assortmentId) {
        handleLoading(true);
        getAssortmentInsights(assortmentId)
          .then(async insightsData => {
            getInsightsCount({ handleLoading, handleSuccess, handleError });
            handleInsights(insightsData.insights);
            setSelectedInsights([]);
          })
          .catch(e => {
            console.error(e);
            handleLoading?.(false);
            handleError?.();
          });
      } else if (customerId) {
        handleLoading(true);
        getCustomerInsights(customerId)
          .then(async insightsData => {
            getInsightsCount({ handleLoading, handleSuccess, handleError });
            handleInsights(insightsData?.insights);
            setSelectedInsights([]);
          })
          .catch(e => {
            console.error(e);
            handleLoading?.(false);
            handleError?.();
          });
      }
    },
    [assortmentId, customerId, getInsightsCount, handleInsights, setSelectedInsights]
  );

  return { getInsightsCount, getInsights };
};
