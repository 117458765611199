export type SearchResult<T> = { score: 0; matches: { meta: string; term: string; value: string }[]; item: T };
export type SearchConfig<T> = { meta: string; exact?: boolean; getter: (x: T) => string };

enum ScoreMultiplier {
  MatchesTerm = 0,
  MatchesAllText = 1,
  MatchesWord = 2,
  StartsWith = 3
}

export const createSearchFn =
  <T>(config: SearchConfig<T>[]) =>
  <U extends T>(text: string, items: readonly U[], narrowing: 'all' | 'most-matches' = 'all'): SearchResult<U>[] => {
    const cleanedText = text.trim().toLowerCase();

    if (!cleanedText) return items.map(item => ({ score: 0, matches: [], item }));

    const terms = splitIntoTerms(text).filter(term => term.length > 1);
    const results: SearchResult<U>[] = [];

    for (const item of items) {
      const result: SearchResult<U> = { score: 0, matches: [], item };

      const bumpScore = (multi: ScoreMultiplier) => {
        result.score += multi * (terms.length + config.length) + 1;
      };

      for (const { getter, meta, exact = false } of config) {
        const value = getter(item);
        const cleanedValue = value.trim().toLowerCase();

        if (!exact && terms.length > 1 && cleanedValue.includes(cleanedText)) {
          bumpScore(ScoreMultiplier.MatchesAllText);
          result.matches.push({ meta, value, term: text });
        }

        for (let i = 0; i < terms.length; i++) {
          const term = terms[i];
          const cleanedTerm = term.toLowerCase();

          if (exact ? value === term : cleanedValue.includes(cleanedTerm)) {
            bumpScore(ScoreMultiplier.MatchesTerm);
            result.matches.push({ meta, value, term: terms[i] });

            // When the value starts with the first term, we bump the score to ensure the result is at the top of
            // the list, even if every term in the search text matches (each bumping by 1).
            if (!exact && i === 0 && cleanedValue.startsWith(cleanedTerm)) bumpScore(ScoreMultiplier.StartsWith);

            // When the value contains a word that exactly matches the term, we want to bump the score again.
            if (!exact && cleanedValue.match(new RegExp(`\\b${cleanedTerm}\\b`, 'g')))
              bumpScore(ScoreMultiplier.MatchesWord);
          }
        }
      }

      if (result.score)
        if (narrowing === 'all') results.push(result);
        else if (narrowing === 'most-matches' && hasMinimumMatches(result, terms.length)) results.push(result);
    }

    return sortByScoreDesc(results);
  };

const splitIntoTerms = (text: string) =>
  text
    .trim()
    .split(/(,*\s+|,)/)
    .map(term => term.trim())
    .filter(Boolean);

const hasMinimumMatches = <T>(result: SearchResult<T>, min: number): boolean => {
  const keyed = result.matches.reduce<Record<string, number>>((accum, match) => {
    accum[match.meta] = (accum[match.meta] ?? 0) + 1;
    return accum;
  }, {});

  return Object.values(keyed).some(count => count >= min);
};

const sortByScoreDesc = <T>(results: SearchResult<T>[]) => results.sort((a, b) => b.score - a.score);
