import { v4 as uuid } from 'uuid';

import { type FiltersSchema } from './Filter';
import { FilterCombination } from './FilterCombination';
import type { FiltersOrder, FiltersService } from './FiltersService';
import { type Sink } from './persistence';

export const createProductFiltersService = (
  schema: FiltersSchema,
  sink: Sink = inMemorySink,
  generateId: () => string = uuid,
  exposeSinkAsGlobalVariable = true
): FiltersService => {
  const defaultFiltersOrder: FiltersOrder = schema.map(item => item.id);
  const reconcileOrder = (customOrder: FiltersOrder) => {
    const order = customOrder.filter(id => defaultFiltersOrder.includes(id));
    for (const id of defaultFiltersOrder) if (!order.includes(id)) order.push(id);
    return order;
  };
  sink.filtersOrder = !sink.filtersOrder.length ? defaultFiltersOrder : reconcileOrder(sink.filtersOrder);

  if (exposeSinkAsGlobalVariable) {
    Object.defineProperty(global, 'filters', {
      configurable: true,
      get() {
        return sink;
      }
    });
  }

  return {
    loadFilterCombinations: async () => sink.items,
    defaultFiltersOrder: async () => defaultFiltersOrder,
    loadFiltersOrder: async () => sink.filtersOrder,
    handle: async command => {
      await new Promise(res => setTimeout(res, 25));

      switch (command.type) {
        case 'save':
          sink.items = [...sink.items, FilterCombination(generateId(), command.name, command.filters)];
          break;
        case 'delete':
          sink.items = sink.items.filter(item => item.id !== command.item.id);
          break;
        case 'rename':
          sink.items = sink.items.map(item =>
            item.id === command.item.id ? FilterCombination(item.id, command.name, item.filters) : item
          );
          break;
        case 'reorder':
          sink.filtersOrder = command.order;
          break;
        case 'set':
          sink.items = sink.items.map(item =>
            item.id === command.item.id ? item.applyFilters(command.filters) : item
          );
          break;
      }
    }
  };
};

export const inMemorySink: Sink = {
  items: [],
  filtersOrder: []
};
