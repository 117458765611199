import { Box } from './Box';
import { Col, Row } from './Grid';
import { Heading } from './Heading';
import { Loading } from './Loading';
import { Repeat } from './Repeat';
import { Spacer } from './Spacer';
import { Table } from './Table';
import { Text } from './Text';
import { Vertical } from './Vertical';

export const Skeleton = {
  Heading,
  Table,
  Text,
  Row,
  Col,
  Box,
  Spacer,
  Repeat,
  Vertical,
  Loading
};
