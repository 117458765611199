export enum App {
  CSV_PARSER = 'CSV Parser',
  CSV_PARSER_ROS = 'CSV Parser ROS',
  CSV_PARSER_CONSO = 'CSV Parser CONSO',
  XML_PARSER = 'XML Parser',
  IMAGE_RESIZER = 'Image Resizer',
  PRICING_CSV_PARSER = 'Pricing CSV Parser',
  LINE_PLAN_REC = 'Line Plan Reconciliation',
  API = 'Api',
  API_AUTH = 'User Auth',
  API_AUTHORIZER = 'Authorizer',
  UNDEFINED = 'UNDEFINED',
  DATA_PIPELINE = 'Data Pipeline'
}

export type TeamsWebhookPayload = {
  type: 'AdaptiveCard';
  body: any[];
  $schema: string;
  version: string;
  msteams?: {
    width: string;
  };
};

export enum LoggerType {
  text = 'text',
  structured = 'struct',
  json = 'json'
}

export enum MessageType {
  FAILED = 'Failed',
  SUCCESS = 'Successful',
  STARTED = 'Started',
  COMPLETED = 'Completed',
  PROCESSING = 'Processing',
  UNKNOWN = 'Unknown'
}

export enum TeamsTextColours {
  default = 'default',
  black = 'dark',
  grey = 'light',
  blue = 'accent',
  green = 'good',
  yellow = 'warning',
  red = 'attention'
}

export type TeamsMessage = DefaultMsTeamsCard;
export type DefaultMsTeamsCard = {
  channel?: App;
  title?: string;
  heading?: string;
  subHeading?: string;
  error?: Error | any;
  messageStatus: MessageType;
  text: string;
};
