import { DomainEvent } from 'services';

import type { SearchSuggestion } from './ContextfulSearchProvider';

export class SearchSuggestionsCreated extends DomainEvent<{
  searchContext: string;
  suggestions: readonly SearchSuggestion[];
}> {}

export class SearchSuggestionsCleared extends DomainEvent<{ searchContext: string }> {}
