import { SpinnerAlt } from 'assets/icons';
import { Dialog, Dropdown } from 'components';
import { type EntityContext } from 'domain-events';
import { useLocalisation } from 'providers';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRequestToastsForStoriesEvents, useServices } from 'services';
import styled from 'styled-components';
import { findById, getPC9s, plpAddtoStoryAddTag, plpAddtoStoryChooseTag, rem, themed } from 'utils';

import { Defect } from '@yourxx/support';

import { State, useAddProductsToStory, type UseAddProductsToStoryProps } from '../hooks/useAddProductsToStory';

export const useAddProductsToStoryUI = ({
  stories,
  context,
  products
}: Omit<UseAddProductsToStoryProps, 'context' | 'service'> & {
  context: EntityContext | undefined;
}) => {
  const [str] = useLocalisation();
  const { eventBus, storiesService: service } = useServices();
  const { customerId, assortmentId, lineId } = useParams();

  useRequestToastsForStoriesEvents({ eventBus, str });
  const state = useAddProductsToStory({ service, stories, context, products });

  const cancel = useCallback(() => {
    switch (state.state) {
      case State.RequestedAdding:
        return state.cancel();
      default:
        return;
    }
  }, [state]);

  // FIXME: Render Dialog separately depending on state;

  return {
    state,
    ui:
      state.state !== State.Idle ? (
        <Dialog
          title={str('Assortment.addProductsToStory')}
          onClose={cancel}
          cancel={{ label: str('general.cancel'), disabled: state.state === State.Adding, handler: cancel }}
          confirm={{
            label: state.state === State.Adding ? <StyledLoader /> : str('Story.addToStory'),
            disabled: (state.state === State.RequestedAdding && !state.canConfirm) || state.state === State.Adding,
            handler:
              (state.state === State.RequestedAdding &&
                (() => {
                  plpAddtoStoryAddTag({
                    storyName: state.selectedStory.title,
                    pc9s: getPC9s(products),
                    assortmentId,
                    // TODO: ??
                    customerId: customerId ?? lineId
                  });
                  state.confirm();
                })) ||
              (() => undefined)
          }}
          maxWidth={450}
          content={
            <ContentContainer>
              {state.state === State.RequestedAdding || state.state === State.Adding ? (
                <>
                  {!state.availableStories.length ? (
                    str('Story.addToStory.empty')
                  ) : (
                    <>
                      <Label>{str('Assortment.addProductsToStory.selectStoryLabel')}</Label>
                      <DropdownWrapper>
                        <Dropdown
                          maxHeight={240}
                          disableSelection={state.state === State.Adding}
                          trigger={<DropdownTrigger>{state.selectedStory?.title ?? '—'}</DropdownTrigger>}
                          items={state.availableStories.map(story => ({ key: story.id, label: story.title }))}
                          onSelect={storyId => {
                            if (state.state === State.Adding) return;

                            const match = findById(storyId)(state.availableStories);
                            if (!match)
                              throw new Defect(
                                `Could not find Story with given id ${storyId} in the available Stories list.`
                              );
                            plpAddtoStoryChooseTag({
                              storyName: match.title,
                              pc9s: getPC9s(products),
                              assortmentId,
                              customerId: customerId ?? lineId
                            });
                            state.selectStory(match);
                          }}
                        />
                      </DropdownWrapper>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </ContentContainer>
          }
        />
      ) : null
  };
};

const ContentContainer = styled.div``;

const Label = styled.label`
  display: block;
  margin-bottom: ${themed('spacing.s')};
  ${themed('typography.h4')};
`;

const DropdownWrapper = styled.div`
  display: flex;
  background-color: ${themed('color.white')};
  border-radius: ${themed('borderRadius')};

  > div {
    display: flex;
    flex-grow: 1;
  }

  > div > button {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    min-height: ${rem(32)};
    text-transform: unset;
  }
`;

const DropdownTrigger = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

export const StyledLoader = styled(SpinnerAlt)`
  margin-left: ${themed('spacing.m')};
  width: ${rem(24)};
  height: ${rem(24)};
`;
