import { useParams } from 'react-router-dom';

import { Defect } from '@yourxx/support';

export class MissingRouteParamError extends Defect {
  constructor(param: string) {
    super(`Route not set up property: param ":${param}" not found.`);
  }
}

/**
 * Get route params and throw error when missing.
 * Usage:
 * ```ts
 * const [brand, lineId] = useEnsureRouteParams('brand', 'lineId');
 * ```
 */
export const useEnsureRouteParams = (...params: string[]) => {
  const actualParams = useParams();
  const values: string[] = [];

  for (const param of params) {
    const value = actualParams[param];
    if (!value) throw new MissingRouteParamError(param);
    values.push(value);
  }

  return values;
};
