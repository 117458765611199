import 'inter-ui/inter.css';

import { createGlobalStyle } from 'styled-components';
import { themed } from 'utils';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${themed('font.family')};
    font-size: ${themed('font.defaultSize')};
    font-weight: ${themed('font.defaultWeight')};
    background-color: ${themed('color.offWhite')};
  }
  html * {
    font-family: ${themed('font.family')};
  }

  html, body {
    min-height: 100%;
    overflow-y: auto;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
  }

  #root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    min-height: 100%;
    height: 100vh;
    interpolate-size: allow-keywords;
  }
`;
