import { memo } from 'react';
import { css, styled } from 'styled-components';
import { rem, themed } from 'utils';

import { Heading } from './Heading';
import { loadingSkeletonAnimation } from './loadingSkeletonAnimation';
import { Repeat } from './Repeat';
import { Text } from './Text';

const border = css`
  solid ${rem(2)} ${themed('color.greyMid')}
`;

const Container = styled.div<{ $cols: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${themed('spacing.s')};

  // Rows
  > div {
    display: flex;
    align-items: center;
    gap: ${themed('spacing.xxxl')};
    padding: ${themed('spacing.l')};

    &:first-child {
      border-bottom: ${border};
    }

    // Columns
    > div {
      ${loadingSkeletonAnimation};

      &:nth-child(1) {
        width: 5%;
      }

      &:nth-child(2) {
        width: 25%;
      }

      width: calc(70% / ${({ $cols }) => $cols - 2});
    }
  }
`;

export const Table = memo(({ cols = 8, rows = 10 }: { cols?: number; rows?: number }) => {
  return (
    <Container $cols={cols}>
      <div>
        <Repeat
          times={cols}
          children={
            <div>
              <Heading />
            </div>
          }
        />
      </div>
      <Repeat
        times={rows}
        children={
          <div>
            <Repeat
              times={cols}
              children={
                <div>
                  <Text />
                </div>
              }
            />
          </div>
        }
      />
    </Container>
  );
});
