import { styled } from 'styled-components';
import { rem, themed } from 'utils';

export const PrimaryButton = styled.button`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${themed('spacing.m')};
  margin: 0;
  border: 0;
  border-radius: ${themed('borderRadius')};
  padding: ${rem(10)};
  background-color: ${themed('color.black')};
  color: ${themed('color.white')};
  ${themed('typography.h4')};
  text-transform: uppercase;
  transition-property: background-color;
  will-change: background-color;
  transition-timing-function: ${themed('transition.timing')};
  transition-duration: ${themed('transition.duration')};

  :disabled {
    cursor: not-allowed;
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      background-color: ${themed('color.greyDark')};
    }
  }

  svg path {
    stroke: ${themed('color.white')};
  }
`;
