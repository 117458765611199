import { ChevronDown, SortDown, SortUp } from 'assets/icons';
import { Checkbox, FeatureFlag } from 'components';
import { useLocalisation } from 'providers';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { formatPercentage } from 'utils';

import type { Nullable } from '@yourxx/support';
import { FeatureFlags } from '@yourxx/types';
import { canUse } from '@yourxx/ui-utils';

import type { AssortmentSummaryState } from './AssortmentSummaryState';
import {
  Cell,
  Container,
  ExpandButton,
  Loader,
  PercentBar,
  RevenuePercentage,
  Row,
  SectionSubtitle,
  SectionTitle,
  SortingIndicator,
  Table,
  Toolbar
} from './styling';
import { State, type UseAssortmentSummaryReturn } from './useAssortmentSummary';

interface AssortmentSummaryProps {
  state: UseAssortmentSummaryReturn;
}

const sortIcons = { asc: <SortUp />, desc: <SortDown /> };

export const AssortmentSummary = ({ state }: AssortmentSummaryProps) => {
  const [str] = useLocalisation();
  const isLoading = state.state === State.Loading;

  useEffect(() => {
    if (state.state === State.Idle) state.loadSummary();
  }, [state]);

  const columns = useMemo(() => {
    if (state.state !== State.Loaded) return [];

    return permissionCheck(state.summary.makeup.entries[0]?.attributes ?? []);
  }, [state]);

  if (state.state === State.Idle) {
    return <Container>{str('Assortment.Summary.dataNotLoaded')}</Container>;
  }

  const excludingCarryOver = state.state !== State.Loading && state.isExcludingCarryOver && (
    <SectionSubtitle>{str('Assortment.Summary.excludingCarryOver')}</SectionSubtitle>
  );

  return (
    <Container>
      <Toolbar>
        <div>
          <Checkbox
            type="icon"
            label={str('Assortment.Summary.excludeCarryOver')}
            checked={state.isExcludingCarryOver}
            onChange={() => {
              if (state.state === State.Loaded) state.toggleCarryOver();
            }}
          />
        </div>
      </Toolbar>

      <SectionTitle>{str('Assortment.Summary.makeup')}</SectionTitle>
      {excludingCarryOver}
      {isLoading ? (
        <Loader />
      ) : (
        <Table $columnsCount={columns.length + 1}>
          <Row $header>
            <Cell key="label"></Cell>
            {columns.map(({ label }) => (
              <Cell key={label} onClick={() => state.summary.makeup.sortBy(label)} $clickable={true}>
                {label}
                {state.summary.makeup.sorting?.by === label && (
                  <SortingIndicator>{sortIcons[state.summary.makeup.sorting.direction]}</SortingIndicator>
                )}
              </Cell>
            ))}
          </Row>
          {state.summary.makeup.entries.map(entry => (
            <Row key={entry.id} $parent={entry.isParent}>
              <Cell key="label">
                {entry.label}
                {entry.isExpandable && (
                  <ExpandButton $isActive={entry.isExpanded} onClick={entry.toggleExpansion}>
                    <ChevronDown />
                  </ExpandButton>
                )}
              </Cell>
              {permissionCheck(entry.attributes).map(({ label, value }) => (
                <Cell key={label}>{value}</Cell>
              ))}
            </Row>
          ))}
        </Table>
      )}

      <FeatureFlag flags={[FeatureFlags.Assortment_ViewRevenueTable]}>
        <SectionTitle>{str('Assortment.Summary.prebookVsAtOnce')}</SectionTitle>
        {excludingCarryOver}
        {isLoading ? (
          <Loader />
        ) : (
          <Table $columnsCount={2}>
            <Row $header>
              <Cell></Cell>
              <Cell>
                <RevenuePercentage>
                  <h4>{str('Assortment.Summary.prebook')}</h4>
                  <h4>{str('Assortment.Summary.atOnce')}</h4>
                </RevenuePercentage>
              </Cell>
            </Row>
            {state.summary.revenue.entries.map(entry => (
              <Row key={entry.id} $parent={entry.isParent}>
                <Cell>
                  {entry.label}
                  {entry.isExpandable && (
                    <ExpandButton $isActive={entry.isExpanded} onClick={entry.toggleExpansion}>
                      <ChevronDown />
                    </ExpandButton>
                  )}
                </Cell>
                <Cell>
                  <Percentage slim={!entry.isParent} value={entry.original.preBook} />
                </Cell>
              </Row>
            ))}
          </Table>
        )}
      </FeatureFlag>
    </Container>
  );
};

const Percentage = ({ slim, value }: { slim?: boolean; value: Nullable<number> }) => {
  const [percent, setPercent] = useState(formatPercentage(0));

  useLayoutEffect(() => {
    const id = setTimeout(() => setPercent(formatPercentage(value ?? 0)), 0);
    return () => {
      clearTimeout(id);
    };
  }, [value]);

  return (
    <PercentBar $slim={slim} $percent={percent}>
      <span>{percent}</span>
      <span>{formatPercentage(value === null ? 0 : 1 - value)}</span>
    </PercentBar>
  );
};

const permissionCheck = (attributes: AssortmentSummaryState['entries'][number]['attributes']) => {
  return attributes.filter(attribute => {
    switch (attribute.label) {
      case '%ao':
        return canUse(FeatureFlags.Assortment_ViewAtOnce);
      default:
        return true;
    }
  });
};
