import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Input } from './Input';

const FullWidthInput = styled(Input)`
  box-sizing: border-box;
  width: 100%;
  margin: 0;
`;

export const AutoFocusInput = ({
  placeholder,
  value,
  onChange
}: {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <FullWidthInput
      ref={inputRef}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.currentTarget.value)}
    />
  );
};
