import {
  AssortmentLineId,
  BoardId,
  CanvasBoardDetails,
  CreateBoardRequest,
  CreateCanvasBoardResponse,
  DeleteBoardResponse,
  GetAllBoardsRequest,
  UpdateBoard
} from '@yourxx/types';

import { apiEndpoints, checkBoardId, checkLineAssortmentId, toRequest } from '../../utils';
import { apiClient } from '../client';

export const getBoards = async (params: GetAllBoardsRequest): Promise<CanvasBoardDetails[]> => {
  checkLineAssortmentId(params);
  const request = toRequest({ path: apiEndpoints.canvasBoards, params, method: 'GET' });
  return await apiClient.request<CanvasBoardDetails[]>(request);
};

export const deleteBoard = async (params?: BoardId): Promise<void> => {
  const boardId = checkBoardId(params);
  const request = toRequest({ path: apiEndpoints.canvasBoard, params: { boardId }, method: 'DELETE' });
  await apiClient.request<DeleteBoardResponse>(request);
};

export const updateBoard = async (params: AssortmentLineId, body: UpdateBoard): Promise<UpdateBoard> => {
  checkLineAssortmentId(params);
  const boardId = checkBoardId(body);
  const request = toRequest({ path: apiEndpoints.canvasBoard, params: { boardId }, method: 'PATCH', body: body });
  return await apiClient.request<UpdateBoard>(request);
};

export const createBoard = async (body: CreateBoardRequest): Promise<CreateCanvasBoardResponse> => {
  checkLineAssortmentId(body);
  if (!body.name) {
    body.name = 'Comparison';
  }
  const request = toRequest({ method: 'POST', body: body, path: apiEndpoints.canvasBoard });
  return await apiClient.request<CreateCanvasBoardResponse>(request);
};
