import { ApiEndpoints, PostAuthRequestBody, PostAuthResponseBody } from '@yourxx/types';

import { apiEndpoints, persistedStore, toRequest } from '../../../';
import { iam } from '../../utils/permissions';
import { logout } from './logout';

export const onApiError = async ({ response }: { response: Response }) => {
  const json = await response.json();
  return onError({ error: json?.error });
};

export const onError = async ({ error }: { error?: string }) => {
  if (error) {
    return logout({ logout_reason: error });
  }
  await logout({});
};

export const getTokenFromCodeGrant = async (code: string) => {
  const body: PostAuthRequestBody = { code };
  const request = toRequest({ path: apiEndpoints.authToken, method: 'POST', body });
  const response = await fetch(request);
  if (!response.ok) {
    return onApiError({ response });
  }
  const { access_token, refresh_token, user } = (await response.json()) as PostAuthResponseBody;
  if (access_token) {
    persistedStore.setAccessToken(access_token);
  }
  if (refresh_token) {
    persistedStore.setRefreshToken(refresh_token);
  }
  if (user) {
    persistedStore.setUserProfile(user);
    iam.updateAccess(user?.ui);
  }
  return { access_token, refresh_token, user };
};

export const refreshAuthToken = async () => {
  const refresh_token = persistedStore.getRefreshToken();
  if (!refresh_token) {
    return onError({ error: 'No refresh token available' });
  }
  const request = toRequest({
    path: ApiEndpoints.authRefresh,
    method: 'POST',
    body: { refresh_token },
    headers: { 'Cache-Control': 'no-cache' }
  });

  const response = await fetch(request);
  if (!response.ok) {
    return onApiError({ response });
  }
  const { access_token, user } = await response.json();
  persistedStore.setAccessToken(access_token);
  persistedStore.setUserProfile(user);
  iam.updateAccess(user?.ui);
};
