import { parse } from 'path';

import {
  GetImageUrlEvent,
  GetImageUrlParams,
  IMAGE_SORT_ORDER as imgSort,
  IncomingProductMediaFileData,
  MarketingMediaFileData,
  OutgoingFilenamePrefixParams,
  OutgoingProductMediaFileData,
  ToCase
} from '@yourxx/types/src';

import { getSeasonPrefix, toCase } from '../utils';

export const imageOrder: Record<string, number> = {
  main: 1,
  front: 2,
  side: 3,
  back: 4,
  gallery: 5,
  gallery1: 6,
  gallery2: 7,
  gallery3: 8,
  gallery4: 9,
  gallery5: 10,
  gallery6: 11,
  gallery7: 12,
  gallery8: 13,
  gallery9: 14,
  gallery10: 15,
  gallery11: 16,
  gallery12: 17,
  gallery13: 18,
  gallery14: 19,
  gallery15: 20,
  gallery16: 21,
  gallery17: 22
};

export const imageOrderIcons: Record<string, number> = {
  main: 1,
  front: 2,
  side: 3,
  back: 4,
  gallery: 5,
  gallery1: 6,
  gallery2: 7,
  gallery3: 8,
  gallery4: 9,
  gallery5: 10,
  gallery6: 11,
  gallery16: 12,
  gallery17: 13
};

export const DEFAULT_DESIRED_IMAGE_TYPES = Object.keys(imageOrder);
export const allowedImageFormats = ['jpeg', 'jpg', 'png'];

export type ImageDimensions = {
  width: number;
  height: number;
  key: string;
};

export const getBaseImageUrl = (pc9Code?: string) => {
  const apiEndpoint = ['api', 'v2', 'image', pc9Code].filter(Boolean).join('/');
  return `${process.env.PUBLIC_APP_URL ?? ''}/${apiEndpoint}`;
};

export const getImageUrlParams = (params: GetImageUrlParams): string => {
  const { seasonName, regionName, fallbackImg, imageFormat, imageType, width, height } = params;
  const queryParams = {
    seasonName: getSeasonPrefix(seasonName),
    region: regionName,
    fallbackImg,
    imageFormat,
    imageType,
    width,
    height
  };

  return Object.entries(queryParams)
    .filter(o => o[1])
    .map(o => `${toCase[ToCase.Snake](o[0])}=${o[1]}`)
    .join('&');
};

export const getImageUrl = (event: GetImageUrlEvent): string => {
  const { pc9Code } = event;
  const params = getImageUrlParams(event);
  return `${getBaseImageUrl(pc9Code)}${params.length > 0 ? `?${params}` : ''}`;
};

export const getProductDetails = (
  dimensions: ImageDimensions[],
  product: {
    images: string[];
    season_name: string;
    region_name: string;
    pc9_code: string;
  }
) => {
  return product.images
    ?.sort((a, b) => imgSort.indexOf(a) - imgSort.indexOf(b))
    ?.map(imageType => {
      const entries = dimensions.map(d => {
        const event = {
          pc9Code: product.pc9_code,
          width: d.width,
          height: d.height,
          seasonName: getSeasonPrefix(product.season_name),
          imageType,
          regionName: product.region_name,
          imageFormat: 'png'
        };
        return [d.key, getImageUrl(event)];
      });

      return {
        imageType,
        ...Object.fromEntries(entries)
      };
    });
};

type GetMediaData = OutgoingProductMediaFileData | IncomingProductMediaFileData | MarketingMediaFileData;
export const getMediaData = (originalFile: string): GetMediaData => {
  const [type] = originalFile.split('/');
  if (type === 'products') {
    return incomingProductMediaData(originalFile);
  }
  if (type === 'sproduct') {
    return outgoingProductMediaData(originalFile);
  }
  return incomingMarketingMediaData(originalFile);
};

export const getMediaIndex = (imageType: keyof typeof imageOrder) => {
  return imageOrder[imageType] ? { mediaIndex: imageOrder[imageType] } : {};
};

export const outgoingMediaPrefix = (d: OutgoingFilenamePrefixParams) => {
  const filename = `${[[d?.width, d?.height].filter(Boolean).join('x'), d?.imageFormat].filter(Boolean).join('_')}`;
  return `${[`sproduct`, d?.season, d?.pc9Code, d?.imageType, d?.region, filename].filter(Boolean).join('/')}`;
};

export const outgoingProductMediaData = (originalFile: string): OutgoingProductMediaFileData => {
  const s = originalFile.split(/[/_]|\.(?=[^/.]*$)/);
  const [type, season, pc9Code, imageType] = s;
  const [width, height] = s[s.length - 4].split('x');
  const baseResponse = {
    type,
    pc9Code,
    season,
    imageType,
    width,
    height,
    name: s[s.length - 2],
    imageFormat: s[s.length - 1].toLowerCase(),
    originalFile,
    ...getMediaIndex(imageType)
  };
  if (s.length > 8) {
    return { ...baseResponse, region: s[4] };
  }
  return baseResponse;
};

export const incomingProductMediaData = (originalFile: string): IncomingProductMediaFileData => {
  const s = originalFile.split(/\/|\.(?=[^/.]*$)/);
  const [type, pc9Code, season, imageType] = s;
  const baseResponse = {
    type,
    pc9Code,
    season,
    imageType,
    name: s[s.length - 2],
    imageFormat: s[s.length - 1].toLowerCase(),
    originalFile,
    ...getMediaIndex(imageType)
  };
  if (s.length > 6) {
    return { ...baseResponse, region: s[4] };
  }
  return baseResponse;
};

export const incomingMarketingMediaData = (originalFile: string): MarketingMediaFileData => {
  const { dir, ext, name } = parse(originalFile);
  const imageFormat = ext.replace('.', '').toLowerCase();
  const sections = dir.split('/');
  const [type] = sections;
  return {
    dir,
    imageFormat,
    name,
    originalFile,
    type
  };
};
