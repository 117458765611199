import { tagsCallback } from '.';

export enum CanvasTags {
  MYCANVASVIEW = 'mycanvas_view',
  MYCANVASHOME = 'mycanvas_home',
  MYCANVASNEWCOMPARISON = 'mycanvas_new_comparison',
  MYCANVASSELECTMETRICS = 'mycanvas_select_metrics',
  MYCANVASMETRICVALUE = 'mycanvas_metric_value',
  MYCANVASPREVIEW = 'mycanvas_preview',
  MYCANVASADDPRODUCT = 'mycanvas_add_product',
  MYCANVASSEARCH = 'mycanvas_search',
  MYCANVASPRODUCTCHECKBOX = 'mycanvas_product_checkbox',
  MYCANVASPRODUCTCHECKBOXSELECT = 'mycanvas_product_checkbox_select',
  MYCANVASPRODUCTCHECKBOXUNSELECT = 'mycanvas_product_checkbox_unselect',
  MYCANVASIMAGE = 'mycanvas_image',
  MYCANVASIMAGEFULLSCREEN = 'mycanvas_image_fullscreen',
  MYCANVASIMAGEZOOM = 'mycanvas_image_zoom',
  MYCANVASINFO = 'mycanvas_info',
  MYCANVASDELETE = 'mycanvas_delete',
  MYCANVASPRODUCTASSORTMENT = 'mycanvas_product_assortment',
  MYCANVASPRODUCTPARENTLINE = 'mycanvas_product_parentline',
  MYCANVASPRODUCTSELECTALL = 'mycanvas_product_select_all',
  MYCANVASPRODUCTCLEARALL = 'mycanvas_product_clear_all',
  MYCANVASADD = 'mycanvas_add',
  MYCANVASCLOSEADDPRODUCT = 'mycanvas_close_add_product',
  MYCANVASDELETEFROMCOMPARISON = 'mycanvas_delete_from_comparison',
  MYCANVASCOMPARISONTAB = 'mycanvas_comparison_tab',
  MYCANVASSHARE = 'mycanvas_share',
  MYCANVASSAVE = 'mycanvas_save',
  MYCANVASCOPYLINK = 'mycanvas_copy_link',
  MYCANVASNEWLINK = 'mycanvas_new_link',
  MYCANVASSHARECLOSE = 'mycanvas_share_close'
}

export const canvasViewTag = ({
  assortmentId,
  customerId,
  source
}: {
  assortmentId?: string;
  customerId?: string;
  source: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASVIEW, {
    assortment_id: assortmentId,
    customer_id: customerId,
    source
  });
};

export const canvasHomeTag = ({ assortmentId, customerId }: { assortmentId?: string; customerId?: string }) => {
  tagsCallback(CanvasTags.MYCANVASHOME, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const canvasNewComparisonTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASNEWCOMPARISON, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasSelectMetricsTag = ({
  assortmentId,
  customerId
}: {
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASSELECTMETRICS, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const canvasMetricValueTag = ({
  assortmentId,
  customerId,
  canvasBoardId,
  val
}: {
  assortmentId?: string;
  customerId?: string;
  canvasBoardId?: string;
  val: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASMETRICVALUE, {
    assortment_id: assortmentId,
    customer_id: customerId,
    canvasBoard_id: canvasBoardId,
    val
  });
};

export const canvasPreviewTag = ({ assortmentId, customerId }: { assortmentId?: string; customerId?: string }) => {
  tagsCallback(CanvasTags.MYCANVASPREVIEW, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const canvasAddProductTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASADDPRODUCT, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasSearchTag = ({
  assortmentId,
  customerId,
  comparisonId,
  term
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
  term: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASSEARCH, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId,
    term
  });
};

export const canvasProductCheckboxTag = ({
  assortmentId,
  customerId,
  comparisonId,
  source,
  pc9s
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
  source: string;
  pc9s: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASPRODUCTCHECKBOX, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId,
    source,
    pc9_codes: pc9s
  });
};

export const canvasProductCheckboxSelectTag = ({
  assortmentId,
  customerId,
  comparisonId,
  source
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
  source: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASPRODUCTCHECKBOXSELECT, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId,
    source
  });
};

export const canvasProductCheckboxUnselectTag = ({
  assortmentId,
  customerId,
  comparisonId,
  source
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
  source: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASPRODUCTCHECKBOXUNSELECT, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId,
    source
  });
};

export const canvasImageTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASIMAGE, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasImageFullscreenTag = ({
  assortmentId,
  customerId,
  comparisonId,
  pc9
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
  pc9: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASIMAGEFULLSCREEN, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId,
    pc9_code: pc9
  });
};

export const canvasImageZoomTag = ({
  assortmentId,
  customerId,
  comparisonId,
  pc9
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
  pc9: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASIMAGEZOOM, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId,
    pc9_code: pc9
  });
};

export const canvasInfoTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASINFO, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasDeleteTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASDELETE, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasProductAssortmentTag = ({
  assortmentId,
  customerId
}: {
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASPRODUCTASSORTMENT, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const canvasProductParentlineTag = ({
  assortmentId,
  customerId
}: {
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASPRODUCTPARENTLINE, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const canvasProductSelectAllTag = ({
  assortmentId,
  customerId
}: {
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASPRODUCTSELECTALL, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const canvasProductClearAllTag = ({
  assortmentId,
  customerId
}: {
  assortmentId?: string;
  customerId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASPRODUCTCLEARALL, {
    assortment_id: assortmentId,
    customer_id: customerId
  });
};

export const canvasAddTag = ({
  assortmentId,
  customerId,
  comparisonId,
  pc9s
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
  pc9s: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASADD, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId,
    pc9_codes: pc9s
  });
};

export const canvasCloseAddProductTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASCLOSEADDPRODUCT, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasDeleteFromComparisonTag = ({
  assortmentId,
  customerId,
  comparisonId,
  pc9
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
  pc9?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASDELETEFROMCOMPARISON, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId,
    pc9_code: pc9
  });
};

export const canvasComparisonTabTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASCOMPARISONTAB, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasShareTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASSHARE, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasSaveTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASSAVE, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasCopyLinkTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASCOPYLINK, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasNewLinkTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASNEWLINK, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};

export const canvasShareCloseTag = ({
  assortmentId,
  customerId,
  comparisonId
}: {
  assortmentId?: string;
  customerId?: string;
  comparisonId?: string;
}) => {
  tagsCallback(CanvasTags.MYCANVASSHARECLOSE, {
    assortment_id: assortmentId,
    customer_id: customerId,
    comparison_id: comparisonId
  });
};
