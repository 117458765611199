import { Plus } from 'assets/icons';
import { IAM } from 'components/IAM';
import { Image } from 'components/Image';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useLocalisation } from 'providers';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { relativePath, rem, themed } from 'utils';

import { useCurrentCanvasContext } from './CurrentCanvasContext';
import { useCanvas, useCreateBoard, useDeleteBoard } from './hooks';

const HomeLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${rem(180)}, 1fr));
  gap: ${themed('spacing.m')};
  padding-bottom: ${themed('spacing.l')};
`;
const LinkItem = styled.div<{ $isDisabled?: boolean }>`
  opacity: ${({ $isDisabled }) => ($isDisabled ? 0.5 : 1)};
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : 'auto')};

  button,
  a {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    margin: 0;
    border: ${rem(1)} solid ${themed('color.greyMid')};
    border-radius: ${themed('spacing.m')};
    display: flex;
    gap: ${themed('spacing.l')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${themed('color.white')};
    color: ${themed('color.black')};
    position: relative;
    text-decoration: none;
    cursor: pointer;

    > span {
      position: absolute;
      top: 100%;
      padding-top: ${themed('spacing.m')};
      width: 100%;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:first-child button {
    background-color: transparent;
  }
`;
const BoardPreview = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(2, [col-start] 1fr);
  grid-gap: ${themed('spacing.m')};
  padding: ${themed('spacing.m')};
  box-sizing: border-box;

  img {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
`;
const StyledPlus = styled(Plus)<{ $size: number }>`
  width: ${({ $size }) => rem($size)};
  height: ${({ $size }) => rem($size)};
`;

export const BoardsList = () => {
  const [str] = useLocalisation();
  const iamPrefix = 'products.canvas';
  const { lineParentProducts, lineProducts, boards } = useCanvas();
  const { customerId, assortmentId, lineId } = useCurrentCanvasContext();
  const { createBoard, isCreatingBoard } = useCreateBoard({ customerId, assortmentId, lineId });
  const { isDeletingBoard } = useDeleteBoard();

  return (
    <HomeLinks>
      <IAM action={'createBoard'} prefix={iamPrefix}>
        <LinkItem>
          <button onClick={() => createBoard()} disabled={isCreatingBoard}>
            {isCreatingBoard ? (
              <LoadingSpinner size={60} label={str('Canvas.board.creating')} />
            ) : (
              <>
                <StyledPlus $size={24} />
                {str('Canvas.actions.addComparison')}
              </>
            )}
          </button>
        </LinkItem>
      </IAM>
      {boards.map(board => (
        <LinkItem key={board.boardId} $isDisabled={isDeletingBoard === board.boardId}>
          <Link to={relativePath(`./${board.boardId}`)}>
            {board.imagePc9Codes?.length ? (
              <BoardPreview>
                {board.imagePc9Codes.map((pc9Code, index) => {
                  const url = [...lineProducts, ...lineParentProducts].find(p => p.pc9 === pc9Code)?.url;
                  return (
                    url && (
                      <Image
                        key={`${board.boardId}_${pc9Code}_${index}`}
                        src={`${url}&width=128&height=128`}
                        alt={str('Canvas.board.productPreviewLabel', { name: board.name })}
                      />
                    )
                  );
                })}
              </BoardPreview>
            ) : (
              <p>{str('Canvas.product.noneAdded')}</p>
            )}
            <span>{board.name}</span>
          </Link>
        </LinkItem>
      ))}
    </HomeLinks>
  );
};
